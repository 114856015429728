import { AnswerModel } from "./AnswerModel";
import { TopicModel } from "./TopicModel";
import { QuestionDAO } from "Common/QuestionDAO";
import API from "api-axios";
import { QuestionContentModel } from "./QuestionContenModel";
import { AnswerType, QuestionType } from "Common/Enums";
import { QuestionSelection, QuestionTutorial } from "Common/QuestionContentDAO";
import { useAlert } from "hooks/UseAlert";
import { LogError } from "Controllers/Logging";
import { SystemQuestions } from "Controllers/SystemQuestionsController";





export class QuestionModel {

    constructor(

        topic: TopicModel

    ) {
        this.topic = topic
    }

    /*  New(q_id: number) {
 
         const QContent = this.topic.SystemQuestions.find(x => x.id === q_id)!
         this.title = QContent.title
         this.type = QContent.type
         this.qMultipleAnswers = QContent.multiAnswers
         this.QuestionContentId = q_id
 
     } */


    // navigation 
    id: number | undefined
    QuestionContentId: number
    title: string                         // String 
    topic: TopicModel
    sequence: number

    // data 

    explanation = ''        // String 
    type: QuestionType | undefined
    qMultipleAnswers: QuestionSelection[] | undefined
    tutorialVideoLink = ''
    tutorials: QuestionTutorial[] = []
    endTopic = false

    get segment() {
        return this.topic!.segment

    }

    get pitch() {
        return this.segment.pitch
    }

    private _answer: AnswerModel | undefined;

    // PUBLIC METHODS 

    setAnswer(value: any, commitToDB = true) {

        this._answer = new AnswerModel(this, value)
        if (commitToDB) {

            this.save(true)

        }
    }


    get answer(): AnswerModel {
        return this._answer as AnswerModel

    }

    set answer(value: any) {
        this.setAnswer(value, false)
    }

    hasAnswer(): boolean {
        return this.answer !== undefined && 
            this.answer.value !== null && 
            this.answer.value
    }

    addTutorial(
        _tutorialVideoLink: string,
        _tutorials: QuestionTutorial[]

    ) {

        this.tutorialVideoLink = _tutorialVideoLink
        this.tutorials = _tutorials

    }

    /* 
        setAsLastQuestionAnswered() {
    
            this.topic!.lastAnsweredQuestion = this
            this.topic!.setLastStartedTopic()
    
    
        } */


    load(q: QuestionDAO) {

        try {


            this.id = q.id

            this.QuestionContentId = q.questioncontentID

            //  const question = questions.find(x=> x.id === this.QuestionContentId)

            if (q.Answer_Value) {

                switch (q.Answer_Type) {

                    case AnswerType.string:

                        this.answer = q.Answer_Value!
                        break

                    case AnswerType.int:

                        this.answer = parseInt(q.Answer_Value!)
                        break


                    case AnswerType.float:

                        this.answer = parseFloat(q.Answer_Value!)
                        break

                    default:

                    // LOG ERROR 

                }
                this.answer.LastUpdate = q.AnswerTimestamp

            }


            this.title = q.title

            this.type = q.type
            this.qMultipleAnswers = q.multiAnswers ? JSON.parse(q.multiAnswers!) as QuestionSelection[] : [] as QuestionSelection[]


            this.sequence = q.sequence
            this.endTopic = q.endTopic


            const QuestionContent = SystemQuestions.questions.find(x => x.id === q.contentId)

            if (QuestionContent) {

                this.explanation = QuestionContent.explanation
                this.tutorialVideoLink = QuestionContent.tutorialsVideoLink ?? ''
                this.tutorials = QuestionContent.tutorials ?? []

            }
            else {

                LogError (`QuestionModel::Load for topic ${this.id} didn't fine QuestionModel id: ${q.contentId}`)
            }



        }
        catch (err) {
            LogError(err)
            throw err
        }
    }

    packQuestionDAO(savingAnswer?: boolean) {

        let answerType: AnswerType | null = null
        let answerValue: string | null = null

        if (this.answer) {

            switch (typeof (this.answer.value)) {

                case 'string':
                    {

                        answerValue = this.answer.value.toString()
                        answerType = AnswerType.string
                        break
                    }

                case 'number':
                    {

                        answerValue = this.answer.value.toString()
                        answerType = AnswerType.int
                        break

                    }

                default:

                    // ERROR 
                    console.log('error in save questionmodel in converting an answer value')
            }
        }

        const data = {

            id: this.id,
            topicID: this.topic?.id,
            questioncontentID: this.QuestionContentId,
            Answer_Value: answerValue,
            Answer_Type: answerType,
            AnswerUpdate: false,
            sequence: this.sequence,
            endTopic: this.endTopic

        } as QuestionDAO

        if (savingAnswer) {
            data.AnswerUpdate = true
        }

        return data
    }


    async save(savingAnswer?: boolean) {

        try {

            const data = this.packQuestionDAO(savingAnswer)

            if (this.id) {

                // update a the question

                await API.post(`/pitch/${this.topic.segment.pitch.id}/segment/${this.topic.segment.id}/topic/${this.topic.id}/question/${this.id}/update`, data)

            }
            else {

                this.id = await (await API.post(`/pitch/${this.topic.segment.pitch.id}/segment/${this.topic.segment.id}/topic/${this.topic.id}/question/create`, data)).data.id

            }
        }
        catch (error) {

            LogError(error)
            useAlert().APIError()

        }

    }

}