import { Box, Checkbox, Grid, List, ListItem, MenuItem, Select, SxProps, TextField, Typography } from "@mui/material"
import { useTemplates } from "hooks/UseTemplates"
import { PitchTemplateModel } from "Model/PitchTemplateModel"
import { QuestionContentModel } from "Model/QuestionContenModel"
import { ChangeEvent, useEffect, useState } from "react"
import { XJButton } from "../../UI Elements/Buttons/XJButton"

import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DropResult

} from 'react-beautiful-dnd'

import { CompanyStage, PitchRound, QuestionType } from "Common/Enums"
import XJPitchTypeDropdown from "../../UI Elements/XJPitchTypeDropdown"
import { templateQuestion, templateSegment, templateTopic } from "Common/PitchTemplateDAO"
import { PitchType } from "Common/Enums"

import { QuestionsEditor } from "./Questions/QuestionsEditor"
import { XJPitchRound } from "Components/XJPitchRound"
import { XJPitchStage } from "Components/XJPitchStage"
import { XJPitchIndustry } from "Components/XJPitchIndustry"
import { XJPitchTag } from "Components/XJPitchTag"
import { useAlert } from "hooks/UseAlert"

import { SystemQuestions } from "Controllers/SystemQuestionsController"
import { Header, PageTitle } from "XJumpTheme"
import { XJSingleInputForm } from "UI Elements/PopUps/XJSingleInputForm"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"



export function TemplateEditor() {


    const [selectedTemplate, setSelectedTemplate] = useState<PitchTemplateModel>()
    const [selectedSegment, setSelectedSegment] = useState<templateSegment>()
    const [selectedTopic, setSelectedTopic] = useState<templateTopic>()
    const [selectedNode, setSelectedNode] = useState<string>('')

    const [selectedTopicQuestions, setSelectedTopicQuestions] = useState<templateQuestion[]>()

    const [selectedQuestion, setSelectedQuestion] = useState<QuestionContentModel>()

    const [selectedTag, setSelectedTag] = useState<string[] | undefined>(undefined)
    const [industry, setIndustry] = useState<string | null>(null)
    const [subIndustry, setSubIndustry] = useState<string | null>(null)
    const [stage, setStage] = useState<CompanyStage | null>(null)

    const [round, setRound] = useState<PitchRound | null>(null)


    //  eventuially this should be loading from the DB:
    const templatesHook = useTemplates()
    const addNewTemplate = useTemplates().addTemplate

    const DuplicateTemplate = useTemplates().duplicateTemplate

    const [selectedQuestionInTopicQuestions, setSelectedQuestionInTopicQuestions] = useState<templateQuestion>()

    const systemQuestions = SystemQuestions.questions

    const success = useAlert()

    // form controller, initialize 

    const [form, setForm] = useState({
        templateTitle: '',
        templateIcon: '',
        templatePartnerId: -1,
        segmentName: '',
        newTemplate: '',
        newSegment: '',
        newTopic: '',
        templateType: PitchType.undefined,
        segmentAIPrompt: ''
       // topicContentId: -1

    })

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value;
        const name = event.currentTarget.name;

        setForm({
            ...form, [name]: value
        });
    }

    useEffect(() => {

        if (selectedTemplate) {

            setStage(selectedTemplate.stage)
            setRound(selectedTemplate.round)
            setIndustry(selectedTemplate.industry)
            setSubIndustry(selectedTemplate.subIndustry)
            setSelectedTag([selectedTemplate.tag!])
        }

    }, [selectedTemplate])

    const EditTemplateSubmit = () => {

        selectedTemplate!.title = form.templateTitle
        selectedTemplate!.icon = form.templateIcon
        selectedTemplate!.partnerId = form.templatePartnerId
        selectedTemplate!.type = form.templateType
        setSelectedTemplate(selectedTemplate)

        selectedTemplate!.tag = selectedTag![0]
        selectedTemplate!.round = round

        selectedTemplate!.stage = stage
        selectedTemplate!.industry = industry

        selectedTemplate!.subIndustry = subIndustry

        selectedTemplate?.save().then
            (() => { success.success("Template saved") }
            )

    }

    const EditTopicSubmit = () => {

        setSelectedTopic(selectedTopic)
        selectedTopic!.questions = selectedTopicQuestions!.sort((a, b) => { return a.sequence > b.sequence ? -1 : 1 })
        selectedTemplate?.save()
    }

    const EditSegmentSubmit = () => {

        selectedSegment!.title = form.segmentName
        selectedSegment!.AIPrompt = form.segmentAIPrompt
        setSelectedSegment(selectedSegment)
        selectedTemplate?.save()
    }

    const [duplicateTemplate, setDuplicateTemplate] = useState(false)

    const onDuplicate = () => {

        setDuplicateTemplate(true)

    }


    useEffect(() => {

        setForm({
            ...form,
            templateTitle: selectedTemplate ? selectedTemplate!.title : '',
            templateIcon: selectedTemplate ? selectedTemplate.icon : '',
            templatePartnerId: selectedTemplate ? selectedTemplate.partnerId : -1,
            templateType: selectedTemplate ? selectedTemplate.type : PitchType.undefined

        })


    }, [selectedTemplate]


    )

    useEffect(() => {

        setForm({
            ...form
         //   topicContentId: selectedTopic ? selectedTopic.topciContentId : -1
        })

        
    }, [selectedTopic])


    useEffect(() => {
        setForm({
            ...form,
            segmentName: selectedSegment ? selectedSegment.title : '',
            segmentAIPrompt: selectedSegment ? selectedSegment.AIPrompt : ''
        })

    }, [selectedSegment])



    const EditQuestion = () => {

        return <>Questions QID: {selectedQuestion?.id}</>

    }

    const AddQuestion = () => {

        if (selectedTopicQuestions!.findIndex(x => x.QID === selectedQuestion!.id) > -1) return

        const newQuestion: templateQuestion =
        {
            QID: selectedQuestion!.id!,
            sequence: selectedTopicQuestions!.length,
            endTopic: false
        }

        setSelectedTopicQuestions([...selectedTopicQuestions!, newQuestion])

    }

    const onTypeChange = (newPitchType: PitchType) => {
        setForm({ ...form, templateType: newPitchType })

    }

    const RemoveQuestion = () => {

        const q = selectedQuestionInTopicQuestions!

        selectedTopicQuestions!.remove(q)
        setSelectedQuestionInTopicQuestions(undefined)

    }

    const TopicQuestionOnDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }

        setSelectedTopicQuestions(selectedTemplate!.reorderQuestions(selectedTopic!, result.source.index, result.destination.index))

    }

    useEffect(() => {
        if (selectedQuestion) setSelectedQuestionInTopicQuestions(undefined)
    }, [selectedQuestion])


    const TopicQuestions = () => {

        return <DragDropContext onDragEnd={TopicQuestionOnDragEnd}>
            <Droppable droppableId="TopicQuestionsList" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef}>

                        <List>
                            {
                                selectedTopicQuestions ?

                                    selectedTopicQuestions.sort((a, b) => { return a.sequence < b.sequence ? -1 : 1 }).map(x => {
                                        return <Draggable key={x.sequence.toString()} draggableId={x.sequence.toString()} index={x.sequence}>
                                            {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                                <div>
                                                    <div
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>

                                                        <ListItem
                                                            sx={x === selectedQuestionInTopicQuestions ? HighlightedItem : null}
                                                            onClick={() => {

                                                                setSelectedQuestionInTopicQuestions(x)
                                                                setSelectedQuestion(undefined)

                                                            }}
                                                            key={`topicQuestionIs${x.QID}`}>
                                                            {systemQuestions.find(y => y.id === x.QID)?.title}

                                                            <Checkbox
                                                                key={`qetChecked${x.QID}`}
                                                                checked={x.endTopic}
                                                                onChange={e => { x.endTopic = e.target.checked }} />
                                                        </ListItem>

                                                    </div>
                                                </div>)}
                                        </Draggable>
                                    }
                                    )

                                    : null
                            }

                            {provided.placeholder}

                        </List>
                    </div>)
                }
            </Droppable>
            <Box display='flex' gap={2} >

                {selectedQuestion && <XJButton variant={'tertiary'} onClick={() => AddQuestion()}>Add Question</XJButton>}
                {selectedQuestionInTopicQuestions && <XJButton variant={'tertiary'} onClick={() => RemoveQuestion()}>Delete Question</XJButton>}
            </Box>
        </DragDropContext>

    }

    const EditTemplate = () => {

        const onIndustryChanged = (value: string) => {
            if (value !== industry) {
                setIndustry(value)
                setSubIndustry("")
            }
        }

        const onSubIndustryChanged = (value: string) => {
            setSubIndustry(value)
        }

        const onStageChanged = (value: CompanyStage) => {
            setStage(value)
        }

        return <>
            <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box>
                        <TextField
                            color='secondary'
                            variant="outlined"
                            value={form.templateTitle}
                            onChange={handleInputChange}
                            name='templateTitle'
                            label='title'
                            id='editTemplateTitle'
                            autoFocus={true}
                        />

                        <TextField
                            color='secondary'
                            variant="outlined"
                            value={form.templateIcon}
                            onChange={handleInputChange}
                            name='templateIcon'
                            id='templateIconKey'

                            label='Icon'
                        />

                        <TextField
                            color='secondary'
                            variant="outlined"
                            value={form.templatePartnerId}
                            onChange={handleInputChange}
                            name='templatePartnerId'
                            id='partneridKey'
                            label='Partner Id'
                        />

                        <XJPitchTypeDropdown
                            value={form.templateType}
                            onPitchTypeChanged={onTypeChange}
                        />
                    </Box>
                    <XJButton variant={'secondary'} onClick={EditTemplateSubmit}>Save</XJButton>


                </Box>

                <Box>
                    <XJPitchRound allowNoSelection={true} round={round} onRoundChanged={(value) => setRound(value)}

                    />
                    <XJPitchStage

                        stage={stage}
                        onStageChanged={onStageChanged}
                        allowNoSelection={true}
                    />

                    <XJPitchIndustry
                        industry={industry ? [industry, subIndustry] : null}
                        onIndustryChanged={onIndustryChanged}
                        onSubIndustryChanged={onSubIndustryChanged}
                        allowNoSelection={true}
                    />
                </Box>

                <Box>

                    <XJPitchTag selectedTag={selectedTag} onChangedSelectedTag={(value) => {

                        setSelectedTag(value)

                    }} />
                </Box>



            </Box>
        </>
    }

    const [topicContentId, setTopicContentId] = useState(-1)


    useEffect(() => {
        
        selectedTopic?.topciContentId ? setTopicContentId (selectedTopic.topciContentId) : -1

    }, [selectedTopic])


    const EditTopic = () => {


        const topicContents = SystemQuestions.topics
        return <>

            <Typography variant="h2">Edit Topic</Typography>
            <Box my={8} display={'flex'} justifyContent={'space-between'}>


                <Box display={'flex'} gap={8} >
                    
                    <Select value={topicContentId} onChange={e => {

                        setTopicContentId(Number(e.target.value))

                    }}>

                        <MenuItem value={-1}>(not selected)</MenuItem>
                        {
                            topicContents.map(x => {

                                return <MenuItem key={`topiccontent${x.id}`} value={x.id}> {x.title} {x.templateHint ? `  - (${x.templateHint})` : null}  </MenuItem>
                            })
                        }

                    </Select>

                    <Box> {topicContents.find(x=>x.id === topicContentId)?.explanation}  </Box>

                </Box>


                <XJButton variant={'secondary'} onClick={EditTopicSubmit}>Save</XJButton>

            </Box>

            <Grid container>

                <Grid item xs={4}>
                    <Typography variant='h3'>Questions:</Typography>
                    {TopicQuestions()}
                </Grid>

                <Grid item xs={8}>

                    <QuestionsEditor onSelectedQuestionsChanged={(Q) => { setSelectedQuestion(Q) }} selectedQuestion={selectedQuestion} />

                </Grid>

            </Grid>



        </>

    }

    const EditSegment = () => {

        return <>

            <Box display={'flex'} justifyContent={'space-between'} >

                <TextField
                    color='secondary'
                    variant="outlined"
                    value={form.segmentName}
                    onChange={handleInputChange}
                    name='segmentName'
                    autoFocus={true}
                    label='Segment name'
                    key='segmentNameEdit'
                />

                <TextField
                    color='secondary'
                    variant="outlined"
                    value={form.segmentAIPrompt}
                    onChange={handleInputChange}
                    name='segmentAIPrompt'
                    multiline={true}
                    rows={2}
                    fullWidth={true}
                    //autoFocus={true}
                    label='AI Prompt'
                    key='segmentAIPrompt'
                />

                <XJButton variant={'secondary'} onClick={EditSegmentSubmit}>Save</XJButton>

            </Box>
        </>


    }


    const onTemplateListClick = (x: PitchTemplateModel) => {

        setSelectedNode('template')
        setSelectedTemplate(x)
        setSelectedSegment(undefined)
        setSelectedTopic(undefined)

    }

    const onSegmentListClick = (x: templateSegment) => {

        setSelectedNode('segment')
        setSelectedSegment(x)
        setSelectedTopic(undefined)
    }

    const onTopicListClick = (x: templateTopic) => {

        setSelectedNode('topic')
        setSelectedTopic(x)
        setSelectedTopicQuestions(x.questions)

    }

    const onNewTemplate = () => {


        setSelectedTemplate(addNewTemplate(form.newTemplate))
        setForm({
            ...form, newTemplate: ''
        })

    }

    const onNewSegment = () => {

        setSelectedSegment(selectedTemplate!.addSegment(form.newSegment))
        setForm({
            ...form, newSegment: ''
        })

    }

    const onNewTopic = () => {

        const newTopic = selectedTemplate!.addTopic(selectedSegment!.sequence)
        setSelectedTopic(newTopic)
        setTopicContentId(-1)
        setSelectedTopicQuestions (newTopic.questions)
    }

    const onDeleteSegment = () => {

        selectedTemplate!.deleteSegment(selectedSegment!)
        setSelectedSegment(undefined)

    }

    const onDeleteTopic = () => {

        selectedTemplate!.deleteTopic(selectedSegment!, selectedTopic!)
        setSelectedTopic(undefined)

    }


    const deleteTemplate = useTemplates().deleteTemplate

    const onDeleteTemplate = () => {

        deleteTemplate(selectedTemplate!.Id!)

    }
    const TopicsOnDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }

        setSelectedSegment(selectedTemplate!.reorderTopics(selectedSegment!, result.source.index, result.destination.index))

    }


    const topics = () => {

        const topicContentList = SystemQuestions.topics
        return <DragDropContext onDragEnd={TopicsOnDragEnd}>
            <Droppable droppableId="TopicsList" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <List sx={ListSx}>
                            {
                                selectedSegment ?
                                    selectedSegment.topics.map(x => {

                                        const tc = topicContentList.find(t => t.id === x.topciContentId)

                                        const topicTitle = tc ? tc.title : '(not set)'

                                        return <Draggable key={x.sequence.toString()} draggableId={x.sequence.toString()} index={x.sequence}>
                                            {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                                <div>
                                                    <div
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>



                                                        <ListItem
                                                            key={`topicId${topicTitle}`}
                                                            sx={(selectedTopic === x) ? HighlightedItem : null}
                                                            onClick={() => onTopicListClick(x)}>
                                                            {topicTitle}
                                                        </ListItem> </div>
                                                </div>)}
                                        </Draggable>

                                    })
                                    : null
                            }
                            {provided.placeholder}
                        </List>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    }


    const SegmentsOnDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }

        setSelectedTemplate(selectedTemplate!.reorderSegments(result.source.index, result.destination.index))

    }

    const Segments = () => {
        return <DragDropContext onDragEnd={SegmentsOnDragEnd}>
            <Droppable droppableId="SegmentsList" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef}>

                        <List sx={ListSx}>
                            {
                                selectedTemplate ?
                                    selectedTemplate.segments.sort((a, b) => { return a.sequence < b.sequence ? -1 : 1 }).map(x => {
                                        return <Draggable key={x.sequence.toString()} draggableId={x.sequence.toString()} index={x.sequence}>
                                            {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                                <div>
                                                    <div
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <ListItem sx={(selectedSegment === x) ? HighlightedItem : null}
                                                            key={`segment${x.sequence}`}
                                                            onClick={() => onSegmentListClick(x)}>
                                                            {x.title}
                                                        </ListItem>
                                                    </div>
                                                </div>)}
                                        </Draggable>
                                    })
                                    : null

                            }
                            {provided.placeholder}
                        </List>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    }

    const ShowEditScreen = () => {

        switch (selectedNode) {
            case 'question':
                return EditQuestion()

            case 'topic':
                return EditTopic()

            case 'segment':
                return EditSegment()

            case 'template':
                return EditTemplate()

            default:
                return null
        }

    }

    const HighlightedItem: SxProps =
    {
        backgroundColor: 'black',
        color: 'white !important'
    }

    const ListSx: SxProps =
    {
        padding: 1,
        cursor: 'pointer'
    }
    return (



        <Grid container>
            <XJSingleInputForm
                open={duplicateTemplate}
                title={"Duplicate a template "}
                inputFieldTitle={"New template name"}
                inputFieldInitValue={""}
                onOK={

                    (value: string) => {

                        DuplicateTemplate(selectedTemplate!.Id!, value)

                            .then(res => {

                                success.success('template duplicated')
                                setDuplicateTemplate(false)

                            }).catch(err => {

                                success.error(err)
                                setDuplicateTemplate(false)

                            })

                    }

                }

                onCancel={() => {
                    setDuplicateTemplate(false)

                }} />

            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
            </Grid>

            <Grid item xs={12}>
                <Box sx={PageTitle}>
                    <Typography variant="h1">
                        Template editor
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Typography variant="h2" >Templates</Typography>
                <List sx={ListSx}>
                    {
                        templatesHook.templates.map(x => {

                            return <ListItem
                                key={`templateKeyId${x.title}`}
                                sx={(selectedTemplate === x) ? HighlightedItem : null}
                                onClick={() => onTemplateListClick(x)}>
                                {x.title}
                            </ListItem>

                        })
                    }
                </List>
            </Grid>

            <Grid item xs={4}>
                <Typography variant="h2">Segments</Typography>

                {Segments()}

            </Grid>

            <Grid item xs={4}>

                <Typography variant="h2" >Topics </Typography>

                {topics()}

            </Grid>

            <Grid mt={8} item xs={12}><p></p>  </Grid>
            <Grid item xs={4}>

                <Box display={'flex'}>

                    <XJButton variant={'tertiary'} onClick={onNewTemplate}>Add template</XJButton>

                    <TextField
                        color='secondary'
                        variant="outlined"
                        value={form.newTemplate}
                        onChange={handleInputChange}
                        name='newTemplate'
                        autoFocus={true}
                        label='New Template'
                        key='newTemplateField'
                    />

                </Box>

                {

                    selectedTemplate && <Box display='flex'>

                        <XJButton variant={'tertiary'} onClick={onDeleteTemplate}>
                            Delete Templete
                        </XJButton>
                        <XJButton variant={'tertiary'} onClick={onDuplicate}>
                            Duplicate
                        </XJButton>

                    </Box>
                }

            </Grid>

            <Grid item xs={4}>

                {selectedTemplate && <Box display={'flex'}>
                    <XJButton variant={'tertiary'} onClick={onNewSegment}>Add Segment</XJButton>
                    <TextField
                        color='secondary'
                        variant="outlined"
                        value={form.newSegment}
                        onChange={handleInputChange}
                        name='newSegment'
                        autoFocus={true}
                        label='New Segment'
                        key='newSegmentField'
                    />
                </Box>
                }

                {selectedSegment && <Box display={'flex'}>
                    <XJButton variant={'tertiary'} onClick={onDeleteSegment}>Delete Segment</XJButton>
                </Box>
                }

            </Grid>

            <Grid item xs={4}>
                {selectedSegment && <Box display={'flex'}>
                    <XJButton variant={'tertiary'} onClick={onNewTopic}>Add Topic</XJButton>
                   {/*  <TextField
                        color='secondary'
                        variant="outlined"
                        value={form.newTopic}
                        onChange={handleInputChange}
                        name='newTopic'
                        autoFocus={true}
                        label='New Topic'
                        key='newTopicField'
                    /> */}


                </Box>
                }

                {selectedTopic && <Box display={'flex'}>

                    <XJButton variant={'tertiary'} onClick={onDeleteTopic}>Delete Topic</XJButton>

                </Box>}
            </Grid>

            <Grid my={4} item xs={12} > <hr /> </Grid>

            <Grid item xs={12}>
                {
                    ShowEditScreen()
                }

            </Grid>
        </Grid>

    )

}

