import { useAuth } from "hooks/UseAuth"
import { ReactNode } from "react"


export function XJShowIfAdminRole(props: { children: ReactNode }) {
    
    const auth = useAuth()

    return <>
        {
            auth.isAdmin() && props.children
        }
    </>
}
