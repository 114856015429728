/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/ 

export {}

declare global {
    interface Number {
        pad(size: number): string
    }
}

function pad(this: number, size: number) {
    let s = String(this)
    while (s.length < (size || 2)) {s = "0" + s}
    return s
}
Object.defineProperty(Number.prototype, "pad", {value: pad})