import { MediaStatus, MediaType } from "Common/Enums"
import { FileUploadEntry, uploaderRefType } from "Components/XJMediaUpload"
import { LogError } from "Controllers/Logging"
import { getMediaTypeFromExtension } from "./VideoModel"

export class MediaModel {


    public UploadeRef: uploaderRefType

    public localBlobUrl: string                     // used only locally - url to a local blob
    public localBlob: Blob | undefined
    public extension: string

    public uploadId: string | undefined                // used only on client to identify upload entry

    public mediaStatus: MediaStatus = MediaStatus.undefinded
    public fileName: string | undefined   // fileName of media file on s3, used when upload happens before saving of the model 
    public type: MediaType = MediaType.undefined

    public ServerUrl: string | undefined
    private localFileName: string
    public duration: number


    public async setServerUrl(url: string) {

        this.ServerUrl = url
        try {

            const response = await fetch(url)

            if (!response.ok) {
                LogError(`MediaModel:setServerrURL failed to fetch: ${url}`)
                LogError(response.statusText)
                LogError(response.status)
            }

            const contentType =  getMediaTypeFromExtension (response)

            const arrayBuffer = await response.arrayBuffer()
            this.localBlob = new Blob([arrayBuffer], { type: contentType })
            this.localBlobUrl = URL.createObjectURL(this.localBlob)


        } catch (e) {
            LogError(e)
        }
    }


    public assignMedia(
        MemoryBlobURL: string,
        MemeorBlob: Blob,
        type: MediaType,
        extension: string,
        localFileName: string,
        UploadRef: uploaderRefType,
        onSuccess: () => void) {

        this.UploadeRef = UploadRef

        this.localBlobUrl = MemoryBlobURL
        this.localBlob = MemeorBlob
        this.extension = extension
        this.type = type
        this.localFileName = localFileName
        this.mediaStatus = MediaStatus.init
        this.uploadMedia(onSuccess)

    }

    /* public async save(completion?: AidCallback) {
        // nothing to save yet
        completion && this._parent && completion(this._parent)
    } */


    private uploaderFileEntryId: string | null = null

    uploadMedia(onSuccess: () => void) {
        try {
            if (this.mediaStatus !== MediaStatus.init && this.mediaStatus !== MediaStatus.uploadError) {
                throw new Error('Inappropriate media status for uploading')
            }

            this.mediaStatus = MediaStatus.uploading

            const uploader = this.UploadeRef

            this.uploadId = uploader?.current?.upload(
                this.type,
                this.localBlob!,
                this.extension!,
                this.localFileName,

                // Complete event 
                ((entry) => {

                    this.fileName = entry.fileName!
                    this.uploaderFileEntryId = entry.id
                    this.mediaStatus = MediaStatus.uploadComplete

                    onSuccess()
                }),

                // OnError 
                () => {

                    LogError('Upload media failed')
                    this.mediaStatus = MediaStatus.uploadError


                },

                // OnStarted
                (entry: FileUploadEntry) => {
                    this.uploaderFileEntryId = entry.id

                }

            )

            /*  const entry = await UploadController.uploadMedia(
                 this.type,
                 this._uploadId!,    // gets uploadId with assigned media via assignMedia 
                 this._localBlob,
                 this.extension!     // gets extension with assigned media via assignMedia 
             )
 
             if (entry !== undefined) {
 
 
                 entry.addCompleteCallback((entry: UploadEntry) => {
                     this.fileName = entry.fileName
                     this.mediaStatus = MediaStatus.uploaded
 
                     if (this._parent && !this._parent.hasPendingMedia) {
                         // TODO: fix with a flag or something more sophisticated later 
                         this._parent?.save()
                     }
                 })
                 entry.addErrorCallback((entry: UploadEntry) => {
                     console.error('Upload media failed: ' + entry.error?.json())
                     this.mediaStatus = MediaStatus.uploadError
                 })
 
             }
             return entry */

        } catch (err) {
            LogError(err)
        }
    }


}

