
import { Link, SxProps } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { theme, secondaryDefault, primaryDefault, primaryDefaultOverlay32, darkPrimaryDefault, secondaryLight75, secondaryLight60, overlaySecondary } from 'XJumpTheme'


export interface IXJLinkProps {

    variant?: LinkVariant,
    to: string,
    children: React.ReactNode,
    onClick?: (React.MouseEventHandler<HTMLAnchorElement>),
    breadcrumbs?: boolean
    newWindow?: boolean
    absolute?: boolean            // use absolute url, by default - relative to the app 
    color? : string
    sx?: SxProps
    disabled?: boolean
}

export enum LinkVariant {
    'primary',
    'secondary'
}

export function XJLink(props: IXJLinkProps) {

    const breadcrumbs = props.breadcrumbs ? true : false

    const color = () => {

        if (props.variant === LinkVariant.primary || !props.variant) {

            if (breadcrumbs) return darkPrimaryDefault
        }

        return props.color ? props.color :  secondaryDefault
    }

    const commonSX = {
        cursor: props.disabled ? 'auto' : 'pointer',
        ':disabled': {
            color: secondaryLight75
        },

        ':focus': {
            color: primaryDefault,
            borderRadius: "2px",
            outline: '2px solid ' + secondaryLight60
        },

        ':visited': !breadcrumbs ? { // removes visited for breadcrumbs 
            color: overlaySecondary
        } : undefined,
    } as SxProps

    const PrimarySX = {
        ...theme.typography.link.primary,
        ...commonSX,

        ':active': {
            color: primaryDefaultOverlay32,
            outlineStyle: 'none'
        },

        ':hover': {
            color: props.disabled ? secondaryLight75 : primaryDefault,
            ...(breadcrumbs ? { ...theme.typography.link.primary } : null)
        },

        ...props.sx
    } as SxProps

    const SecondarySX = {
        ...theme.typography.link.secondary,
        ...commonSX,

        textDecoration: props.breadcrumbs ? 'none' : 'underline',

        ':active': {
            color: primaryDefaultOverlay32
        },

        ':hover': {
            color: props.disabled ? secondaryLight75 : primaryDefault
        },

        ...props.sx
    } as SxProps

    const component = props.disabled ? 'button' : props.absolute ? 'a' : RouterLink
    const to = props.disabled ? '' : props.absolute ? undefined : props.to
    const href = props.disabled ? undefined : props.absolute ? props.to : undefined

    return <Link
        color={color()}
        target={props.newWindow ? '_blank' : '_self'}
        onClick={props.disabled ? undefined : props.onClick}
        sx={(props.variant === LinkVariant.primary || !props.variant) ? PrimarySX : SecondarySX}

        component={component} 

        disabled={props.disabled}
        aria-disabled={props.disabled}
        tabIndex={props.disabled ? -1 : undefined}

        to={to} 
        href={href}  // using hyperlink for an absolute url 
    >
        {props.children}
    </Link>
}