import { TopicModel } from 'Model/TopicModel'
import { QaLayout } from './QaLayout'
import { Box, SxProps, Typography } from '@mui/material'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { ArrowLeftIcon, ArrowRightIcon } from 'UI Elements/XJIcons'
import { useKeyPress } from 'hooks/UseKeyPress'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'
import { TopicStatus } from 'Common/Enums'


export interface IQaTopicProps {

    currentTopic: TopicModel
    onStart: () => void
    onSkip: () => void
    onPrevious: () => void
    onFinishLater: () => void
    FinishLaterLabel?: string
}


export function QaTopic(props: IQaTopicProps) {

    const currentTopic = props.currentTopic
    const topicHasQuestions = currentTopic.questions.length > 0


    // MARK: - Actions 

    const onStart = () => {
        if (topicHasQuestions) {
            // on Start 
            if (currentTopic.status !== TopicStatus.complete) {
                currentTopic.setStatus(TopicStatus.started)
            }
        } else {
            // on Next (in case of topic w/ no questions)
            currentTopic.setStatus(TopicStatus.complete)
        }
        props.onStart()
    }

    const onSkip = () => {
        if (currentTopic.status !== TopicStatus.complete) {
            props.currentTopic.setStatus(TopicStatus.skipped)
        }
        props.onSkip()
    }


    // MARK: - Render 

    const styles = {
        root: {
            display: "flex",
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 16
        } as SxProps,
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 16
        } as SxProps,
        top: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        } as SxProps,
        topicTitle: {
            maxWidth: '592px'
        } as SxProps,
        description: {
            maxWidth: '592px'
        } as SxProps,
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        } as SxProps,
        leftButtons: {
            display: 'flex', 
            gap: 16
        } as SxProps
    }

    const TopicContent = <>

        <Box sx={styles.root}>
            <Box sx={styles.top}>
                <Typography variant='h2' sx={styles.topicTitle}>
                    {currentTopic.title}
                </Typography>
            </Box>

            <ExplanationBox sx={styles.description}>
                {currentTopic.explanation}
            </ExplanationBox>

            <Box sx={styles.buttons}>
                <Box sx={styles.leftButtons}> 
                    <XJButton variant={'tertiary'} iconLeft onClick={props.onPrevious}>
                        <ArrowLeftIcon /> Back
                    </XJButton>
                    <XJButton variant={'secondary'} iconRight={!topicHasQuestions} onClick={onStart}>
                        {topicHasQuestions ? 'Start' : 'Next'}
                        {topicHasQuestions ? null : <ArrowRightIcon />}
                    </XJButton>
                </Box>
                <XJButton variant={'tertiary'} iconRight onClick={onSkip} hidden={!topicHasQuestions}>
                    Skip
                    <ArrowRightIcon />
                </XJButton>
            </Box>
        </Box>
    </>

    useKeyPress({ char: "Enter" }, onStart)
    useKeyPress({ char: "Backspace" }, props.onPrevious)


    return (

        <>
            <QaLayout

                TopicQuestion={TopicContent}
                Tutotrial={currentTopic.tutorials}
                onFinishLater={props.onFinishLater}
                FinishLaterLabel={props.FinishLaterLabel}
                QAProgress={currentTopic.pitch.getQAPercentCompleted()}
                segment={currentTopic.segment}
                TutorialVideo={currentTopic.tutorialVideoLink}
            />

        </>
    )
}
