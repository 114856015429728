import { Box, Grid, SxProps, Typography } from "@mui/material";
import { QuestionType } from "Common/Enums";
import { SystemQuestions } from "Controllers/SystemQuestionsController";

import { QuestionContentModel } from "Model/QuestionContenModel";
import { useEffect, useState } from "react";
import { XJButton } from "UI Elements/Buttons/XJButton";
import { Header, HeaderRightButtons, PageTitle } from "XJumpTheme";
import EditQuestion from "./EditQuestion";
import QuestionsList from "./QuestionsList";
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";



export interface IQuestionsEditorProps {

    onSelectedQuestionsChanged: (selectedQuestion: QuestionContentModel) => void
    selectedQuestion: QuestionContentModel | undefined

}


const HighlightedItem: SxProps =
{
    backgroundColor: 'black',
    color: 'white !important'
}



export function QuestionsEditor(props: IQuestionsEditorProps) {

    const [selectedQuestion, setSelectedQuestion] = useState<QuestionContentModel>()
    const [QuestionEditorState, setQuestionEditorState] = useState(false)

    const allQuestions = SystemQuestions.questions
    const [questions, setQuestions] = useState(allQuestions)
    const [questionFilter, setQuestionFilter] = useState<string>()
    const SystemQuestionsCtrl = SystemQuestions
    
    // effects 

    useEffect(() => {
        setQuestions(
            questionFilter ?
                allQuestions!.filter(x => x.title.toLowerCase().includes(questionFilter.trim().toLowerCase())) :
                allQuestions!)
    }, [questionFilter, allQuestions])


    useEffect(() => {

        setSelectedQuestion(props.selectedQuestion)
        //if (selectedQuestion) props.onSelectedQuestionsChanged(selectedQuestion)

    }, [props.selectedQuestion])


    // events 


    const onQuestionEditorClose = () => {
        setQuestionEditorState(false)
    }

    useEffect(() => {
        if (selectedQuestion) props.onSelectedQuestionsChanged(selectedQuestion)

    }, [selectedQuestion])


    const onNewQuestion = () => {
        setSelectedQuestion(undefined)
        setQuestionEditorState(true)
    }

    // components 

    const QuestionDetails = () => {

        return <>

            <Typography>Title:   {selectedQuestion?.title} </Typography>
            {/*  <Typography>QID:  {selectedQuestion?.id}</Typography> */}
            <Typography>Segment:   {selectedQuestion?.Segment} </Typography>
            <Typography>Topic:   {selectedQuestion?.Topic} </Typography>
            <Typography> Type:  {selectedQuestion?.TypeLabel()}</Typography>
            <Typography> Explanation: {selectedQuestion?.explanation}</Typography>
            {
                selectedQuestion?.type === QuestionType.select &&
                <Box>

                    {selectedQuestion.multiAnswers?.map((x,i) => {
                        return <Box display={'flex'} key={`multianswer${i}`}>
                            <Typography>{x.title}: </Typography>
                            {x.NextQuestionId && <Typography>{SystemQuestionsCtrl.GetQuestion(x.NextQuestionId) ? SystemQuestionsCtrl.GetQuestion(x.NextQuestionId)!.title : ''}</Typography>}
                        </Box>

                    })}

                </Box>
            }
            <Typography> AI: {selectedQuestion?.AIPrompt} </Typography>

            <XJButton variant={'secondary'} onClick={() => setQuestionEditorState(true)} >edit question</XJButton>
        </>

    }
   
    return <>

        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton variant={'secondary'} onClick={onNewQuestion}>New Question</XJButton>
                </Box>

            </Grid>

            <Grid item xs={12}>

                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Questions Editor
                    </Typography>
                </Box>

                <EditQuestion
                    open={QuestionEditorState}
                    qid={selectedQuestion?.id}
                    onClose={onQuestionEditorClose} />

            </Grid>

            <Grid item xs={6}>
                <Box m={'20px'}>
                    <QuestionsList selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} onOpenEditor={()=> setQuestionEditorState(true)} />
                </Box>
            </Grid>
            <Grid item xs={6}>

                <Box m={'20px'}>
                    {selectedQuestion && QuestionDetails()}

                </Box>

            </Grid>


        </Grid>
    </>

}