import { TextField, Typography } from "@mui/material"

import { useAlert } from "hooks/UseAlert"
import { useAuth } from "hooks/UseAuth"
import { ChangeEvent, useState } from "react"
import { useHistory, useParams } from "react-router"
import { XJButton } from "UI Elements/Buttons/XJButton"
import LoginLayout from "./LoginLayout"



export default function EmailLoginChangePassword() {

    const { email } = useParams<any>()
    const { challenge } = useParams<any>()

    const auth = useAuth()

    const history = useHistory()

    const alert = useAlert()

    const [form, setForm] = useState({ newPassword: '' })

    const onSubmit = () => {
        auth.EmailChangePassword(email, challenge, form.newPassword,

            () => {

                history.replace('/login')

            },

            (err: string) => {

                alert.error(err)
            })

    }


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value;
        const name = event.currentTarget.name;

        setForm({
            ...form, [name]: value
        })
    }


    return <>
        <LoginLayout>
            <Typography>New password:</Typography>
            <TextField name="newPassword" color='secondary' variant="outlined" value={form.newPassword} onChange={handleInputChange} />

            <XJButton onClick={onSubmit}>Change password</XJButton>
        </LoginLayout>
    </>






}