import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, SxProps } from '@mui/system'

import ReactPlayer from 'react-player'

import { VisualAidModel, VisualAidPIPPosition } from 'Model/VisualAidModel'

import PlayerTimeline from './PlayerTimeline'
import PlayerControls from './PlayerControls'
import { log } from 'tools/Utils'
import { MediaType, VideoDimensions } from 'Common/Enums'
import { VideoModel } from 'Model/VideoModel'
import { Typography } from '@mui/material'
import { secondaryDefault, whiteDefault } from 'XJumpTheme'
import { LogError } from 'Controllers/Logging'
import { PublicPitchModel } from 'Model/PublicPitchModel'


export const playerMinWidth = '320px'

type VideoPlayerProgressCallback = (
    state: {
        played: number
        loaded: number
    },
    id?: string
) => void

type VideoPlayerActionCallback = (id?: string) => void

interface VideoPlayerProps {
    id: string
    video: IVideo
    directControl?: VideoPlaybackState  // if exist player uses its values instead of imperative

    visualAids?: VisualAidModel[]       // Editor Player: VAs from editor state list

    sx?: SxProps
    fullscreen?: boolean
    showDefaultTimeline?: boolean
    showDefaultControls?: boolean

    isCurrent?: boolean

    onMounted?: VideoPlayerActionCallback
    onReady?: VideoPlayerActionCallback
    onProgress?: VideoPlayerProgressCallback
    onEndPlaying?: VideoPlayerActionCallback
    onError?: VideoPlayerActionCallback
}

export interface VideoPlayerAPI {
    video: IVideo

    play: () => void
    pause: () => void
    seekTo: (progress: number) => void
    rewind: (offset: number) => void
    forward: (offset: number) => void
    mute: () => void
    unmute: () => void
    restart: () => void

    state: () => VideoPlaybackState

    reset: () => void
}

export interface IVideo {
    id: string
    duration: number
    url: string
    visualAids: IVisualAid[]
    model?: VideoModel          // video model that is used to subsribe for url updates
}

export const mapVideoModel = (video: VideoModel) => {
    return {
        id: video.id ? video.id.toString() : "no_id",
        url: video.url,
        duration: video.duration,
        visualAids: video.visibleAids().map((aid) => mapVisualAidModel(aid)),
        model: video
    } as IVideo
}

export const mapVisualAidModel = (aid: VisualAidModel) => {
    return {
        type: aid.type,
        startAt: aid.startsAt,
        duration: aid.duration,
        URL: aid.url,
        PIPPosition: aid.PIPPosition,
        showPIP: aid.showInPIP
    } as IVisualAid
}

export interface IVisualAid {
    type: MediaType
    startAt: number
    duration: number
    URL: string
    PIPPosition: VisualAidPIPPosition | undefined
    showPIP: boolean
}

export interface VideoPlaybackState {
    progress?: number
    playing: boolean
    muted: boolean
}

export const VideoPlayer = forwardRef((props: VideoPlayerProps, ref: Ref<VideoPlayerAPI>) => {

    // MARK: - State 

    const [url, setUrl] = useState(props.video.url)
    const [playing, setPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [muted, setMuted] = useState(false)

    const _onUrlUpdate = useCallback((newUrl: string) => {
        // in order to not interrupt the playback - change only if current url is empty
        if (url.isEmpty()) {
            setUrl(newUrl)
        }
    }, [url])

    useEffect(() => {
        if (props.video.model) {
            setVideoReady(props.video.model.loaded)
            props.video.model.subscribeForUrlUpdates(_onUrlUpdate)
        }

        return () => {
            if (props.video.model) {
                props.video.model.unsubscribeForUrlUpdates(_onUrlUpdate)
            }
        }
    }, [props.video.model, _onUrlUpdate])

    const _onMounted = props.onMounted
    useEffect(() => {
        _onMounted && _onMounted(props.id)
    }, [_onMounted, props.id])


    // MARK: - API 

    useImperativeHandle(ref, () => ({
        video, play, pause, seekTo, forward, rewind, mute, unmute, restart, state, reset
    }))

    const video = props.video

    const play = () => {
        setPlaying(true)
    }

    const pause = () => {
        setPlaying(false)
    }

    const seekTo = (progress: number) => {
        animateChangesRef.current = false
        setProgress(progress)
        _playerRef.current?.seekTo(progress, "seconds")

        // a hack - apply possible changes on seek, then turn animation on 
        window.setTimeout(() => {
            animateChangesRef.current = true
        }, 10)
    }

    const forward = (offset: number) => {
        let newValue = progress + offset
        if (newValue > props.video.duration) {
            newValue = props.video.duration
        }
        seekTo(newValue)
    }

    const rewind = (offset: number) => {
        let newValue = progress - offset
        if (newValue < 0.0) {
            newValue = 0.0
        }
        seekTo(newValue)
    }

    const mute = () => {
        setMuted(true)
    }

    const unmute = () => {
        setMuted(false)
    }

    const restart = () => {
        seekTo(0)
    }

    const state = () => {
        return {
            progress: progress,
            playing: playing,
            muted: muted
        } as VideoPlaybackState
    }

    const reset = () => {
        pause()
        seekTo(0)
        setUrl("")
        setMuted(false)
        _isReady.current = false
    }


    // MARK: - Internal 

    const animateChangesRef = useRef(true)
    const animateChanges = animateChangesRef.current


    // MARK: - Player 

    const _playerRef = useRef<ReactPlayer>(null)
    const _isReady = useRef(false)

    const playerConfig = {
        file: {
            hlsOptions: {
                xhrSetup: function (xhr: any, url: string) {
                   
                        xhr.withCredentials = true// send cookies
              
                }
            },
            attributes: {
                playsInline: true
            }
        }
    }

    const onReady = (player: ReactPlayer) => {
        // fixes that onReady called everytime player seeksTo (https://github.com/cookpete/react-player/issues/1268)

        if (!_isReady.current) {
            _isReady.current = true
            if (progress !== 0) {
                player.seekTo(progress, "seconds")
            }

            setVideoReady(true)
            props.onReady && props.onReady(props.id)
        }
    }

    const onProgress = (state: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) => {
        setProgress(state.playedSeconds)

        // call delegate method 
        props.onProgress && props.onProgress(
            {
                played: state.playedSeconds,
                loaded: state.loadedSeconds
            },
            props.id
        )
    }

    const onEnded = () => {
        setPlaying(false)
        props.onEndPlaying && props.onEndPlaying(props.id)
    }

    const onError = (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
        // TODO: pass to the higher level 

        LogError(`error in VideoPlayer: ${JSON.stringify(error)}, data: ${JSON.stringify(data)}`)

        setVideoError(true)

        props.onError && props.onError()
        /*   log("ERROR: " + error.message + "\n " + error.json())
          log("ERROR str: " + JSON.stringify(error, Object.getOwnPropertyNames(error)))
          log("DATA: " + data?.json()) */


    }

    const isFullscreen = () => {
        return props.fullscreen ?? false
    }

    const showTimeline = () => {
        return props.showDefaultTimeline ?? false
    }

    const showControls = () => {
        return props.showDefaultControls ?? false
    }

    const isDirectControl = props.directControl !== undefined
    const volumeForMuted = (muted: boolean) => {
        return muted ? 0 : 1
    }


    // MARK: - Video 

    const kVideoRatio = VideoDimensions.aspectRatio


    // MARK: - Visual aids 

    const VISUAL_AID_ANIMATION_DURATION = 0.5 // secs 
    const VISUAL_AID_PRESHOWING_PERIOD = 0.5 // secs 
    const VISUAL_AID_POSTSHOWING_PERIOD = 0.5 // secs, allows animation to be finished 

    const _visualAidForOffset = (offset: number, aids: IVisualAid[]) => {
        for (const aid of aids.sort((a, b) => b.startAt - a.startAt)) {     // use reversed array sorted by start time to ensure to return latest aid if aids go one right after another
            if ((offset >= aid.startAt - VISUAL_AID_PRESHOWING_PERIOD) &&
                (offset <= aid.startAt + aid.duration + VISUAL_AID_POSTSHOWING_PERIOD)) {
                return aid
            }
        }

        return null
    }

    const showAid = (offset: number, aids: IVisualAid[]) => {
        for (const aid of aids) {
            if ((aid.startAt <= offset) && (aid.startAt + aid.duration >= offset)) {
                return true
            }
        }

        return false
    }

    const aid = _visualAidForOffset(progress, video.visualAids)

    // MARK: - Timeline

    const _onTimelineClick = (position: number) => {
        seekTo(position)
    }


    // MARK: - Render

    const noAidVideoPositionningStyle: SxProps = {

        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 1.0,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }

    const sizeOfPlayer = 22
    const pipSideDistance = `5pt`


    const sxForAidPosition = (position: VisualAidPIPPosition | undefined): SxProps => {

        const otherSide = `calc(100% - ${sizeOfPlayer}% - ${pipSideDistance})`

        switch (position) {

            case VisualAidPIPPosition.leftBottom: return { left: pipSideDistance, top: otherSide }
            case VisualAidPIPPosition.leftTop: return { left: pipSideDistance, top: pipSideDistance }
            case VisualAidPIPPosition.rightTop: return { left: otherSide, top: pipSideDistance }
            case VisualAidPIPPosition.rightBottom: return { left: otherSide, top: otherSide }

            default: return {}
        }
    }

    const aidOnVideoPositionningStyle = (aid: IVisualAid): SxProps => {
        return {
            transitionProperty: "width, height, left, top, opacity, border-radius",
            transitionDuration: animateChanges ? `${VISUAL_AID_ANIMATION_DURATION}s` : "0s",
            width: aid.showPIP ? `${sizeOfPlayer}%` : "100%",
            height: aid.showPIP ? `${sizeOfPlayer}%` : "100%",
            opacity: aid.showPIP ? 1.0 : 0.0,
            borderRadius: aid.showPIP ? "10px" : null,
            overflow: 'hidden',
            ...(aid.showPIP ? sxForAidPosition(aid.PIPPosition) : {})
        }
    }

    const styles = {
        root: {
            width: "100%",
            minWidth: playerMinWidth,
            backgroundColor: "black",
            position: 'relative',
            ...props.sx,
            ...(isFullscreen() ? { height: "100%" } : {})
        } as SxProps,
        player: {
            position: "relative",
            top: 0,
            left: 0,
            width: "100%",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: aid ? "url(" + aid.URL + ")" : "",
            overflow: 'hidden',
            ...(isFullscreen() ? { height: "100%" } : { aspectRatio: kVideoRatio.toString() })
        } as SxProps,
        // background: {
        //     width: "100%",
        //     height: "100%",
        //     transitionProperty: 'opacity',
        //     transitionDuration: animateChanges ? "0.5s" : "0s",
        //     backgroundColor: "black",
        //     ...(showAid(progress, video.visualAids) ? {opacity: 0} : {opacity: 1})
        // } as SxProps,
        video: {
            width: '100%',
            position: 'absolute',
            transitionProperty: "width, height, left, top, right, bottom, opacity, border-radius",
            transitionDuration: animateChanges ? `${VISUAL_AID_ANIMATION_DURATION}s` : "0s",
            zIndex: 0,
            ...(aid && showAid(progress, video.visualAids) ? aidOnVideoPositionningStyle(aid) : noAidVideoPositionningStyle)
        } as SxProps,
        videoBackground: {
            position: 'absolute',
            zIndex: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: "100%",
            backgroundColor: 'black'
        } as SxProps,
        videoBox: {
            position: 'relative',
            zIndex: 1
        } as SxProps,
        controls: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1
        } as SxProps
    }


    const [videoReady, setVideoReady] = useState(false)
    const [videoError, setVideoError] = useState(false)

    return (
        <Box sx={styles.root}>
            {showControls() && videoReady &&
                <Box sx={styles.controls}>
                    {showTimeline() &&
                        <PlayerTimeline
                            duration={props.video.duration}
                            progress={progress}
                            onClick={(position: number) => { _onTimelineClick(position) }}
                        />
                    }
                    <PlayerControls
                        duration={props.video.duration}
                        progress={progress}
                        playing={playing ? playing : false}
                        muted={muted}

                        onPlay={play}
                        onPause={pause}
                        onRestart={restart}
                        onRewind={() => { rewind(5) }}
                        onForward={() => { forward(5) }}
                        onMute={mute}
                        onUnmute={unmute}
                    />
                </Box>
            }
            <Box sx={{
                width: '100%',
                height: 'auto',
                aspectRatio: kVideoRatio.toString(),
                backgroundColor: secondaryDefault,
                display: videoError ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <Typography color={whiteDefault} variant='h1'>Error while loading video</Typography>

            </Box>

            {!videoError && <Box sx={styles.player}>

                {/* <Box sx={styles.background}>

                </Box> */}

                <Box sx={styles.video}>

                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        aspectRatio: kVideoRatio.toString(),
                        backgroundColor: secondaryDefault,
                        display: !videoReady ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 5
                    }}>

                        <Typography color={whiteDefault} variant='h1'>Loading...</Typography>

                    </Box>

                    <Box sx={styles.videoBackground}>
                    </Box>

                    <Box sx={styles.videoBox}
                    >
                        <ReactPlayer
                            config={playerConfig}
                            key={'player' + video.id}
                            width="100%"
                            height={isFullscreen() ? "100%" : "auto"}
                            ref={_playerRef}
                            url={url}
                            playing={isDirectControl ? props.directControl!.playing : playing}
                            volume={isDirectControl ? volumeForMuted(props.directControl!.muted) : volumeForMuted(muted)}
                            progressInterval={10}

                            onReady={onReady}
                            onProgress={onProgress}
                            onEnded={onEnded}
                            onError={onError}


                        />
                    </Box>

                </Box>
            </Box>
            }
        </Box>
    )
})