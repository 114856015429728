import { Box, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";

import { ChangeEvent, useEffect, useRef, useState } from "react";

import { useAlert } from "hooks/UseAlert";
import { isValidUrl } from "tools/Utils";
import { XJButton } from "UI Elements/Buttons/XJButton"
import { SystemQuestions } from "Controllers/SystemQuestionsController";

import { useKeyPress } from "hooks/UseKeyPress";
import { XJAIHelper } from "../XJAIHelper";
import { QuestionTutorial } from "Common/QuestionContentDAO";
import { TopicContentModel } from "Model/TopicContentModel";
import { LogError } from "Controllers/Logging";
import { XJLinkButton } from "UI Elements/Buttons/XJLinkButton";

export default function EditTopic(props: { open: boolean, tid: number | undefined, onClose: () => void }) {

    const [Topic, setTopic] = useState<TopicContentModel>()

    const SystemTopicsCtrl = SystemQuestions

    const alert = useAlert()

    const SuccessAlert = useAlert().success

    useEffect(() => {

        setTopic(SystemTopicsCtrl.GetTopic(props.tid!))

    }, [props.tid])


    const [form, setForm] = useState({
        Tid: undefined as number | undefined,
        title: '',
        explanation: '',
        tutorialsVideoLink: '',
        tutorials: [] as QuestionTutorial[],
        AIPrompt: null as string | null,
        templateHint: ''

    })

    useEffect(() => {

        setForm({
            ...form,
            Tid: Topic ? Topic.id : undefined,
            title: Topic ? Topic.title : '',
            explanation: Topic ? Topic.explanation : '',
            tutorialsVideoLink: Topic ? (Topic.tutorialsVideoLink ? Topic.tutorialsVideoLink : '') : '',
            tutorials: Topic ? Topic.tutorials : [],
            AIPrompt: Topic ? Topic.AIPrompt : null,
            templateHint: Topic ? Topic.templateHint : ''
        })

    }, [Topic])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value;
        const name = event.currentTarget.name;

        setForm({
            ...form, [name]: value
        });
    }


    const handleInputChangeForTutorials = (v: string, member: string, index: number): void => {


        const tutorials = form.tutorials

        if (tutorials.length === index) tutorials.push({ title: '', body: '' } as QuestionTutorial)

        tutorials[index][member] = v

        setForm({ ...form, tutorials })

    }




    const onClose = async () => {

        const Q = Topic ? Topic : new TopicContentModel()

        Q!.explanation = form.explanation

        Q!.title = form.title

        const u = isValidUrl (form.tutorialsVideoLink)

        if (form.tutorialsVideoLink && isValidUrl(form.tutorialsVideoLink))
        {
            
            Q!.tutorialsVideoLink = form.tutorialsVideoLink 
            
        }
        else
        {
            Q!.tutorialsVideoLink = undefined
            
        }

       
        Q!.tutorials = form.tutorials

        Q!.AIPrompt = form.AIPrompt

        const newTopic = Q.id === undefined

        // save the question 
        if (await Q!.save()) {
            if (Q.id && newTopic) SystemQuestions.AddTopic(Q)
            props.onClose()

        }
        else {

           LogError("couldn't save question")
        }


    }

    const onCancel = () => {

        props.onClose()
    }

    useKeyPress({ char: 's', ctrl: true }, onClose)


    const [AIHelper, setAIHelper] = useState(false)

    const [AIPrompt, setAIPrompt] = useState('')
    const currentTutorial = useRef(-1)

    const onAIReturn = (val: string) => {

        setAIHelper(false)
    
      /*   const textInput = tutorialExplantaionRefs.current.current[currentTutorial.current]
        
        textInput!.value = val */
        
        handleInputChangeForTutorials(val, 'body', currentTutorial.current)
            

    }

    const showAI = (index: number, prompt: string) => {
        
        currentTutorial.current = index

        setAIPrompt(prompt)
        setAIHelper(true)

    }

    const tutorialExplantaionRefs = useRef(useRef<Array<HTMLInputElement | null>>([]))

    return <Dialog open={props.open}  fullWidth={true} onClose={onCancel}>

        <XJAIHelper

            open={AIHelper}
            prompt={AIPrompt}
            onReturn={onAIReturn}
            onCancel={() => setAIHelper(false)} />

        <DialogTitle>Edit Topic</DialogTitle>

        <DialogContent>

            <Box display={'flex'} gap={16} >

                <Box display={'flex'} flexDirection={'column'} gap={16} width={'100%'}>

                    <TextField name='title' value={form.title} onChange={handleInputChange} label='title' fullWidth />



                    <TextField name='explanation'

                        multiline={true}
                        rows={3}
                        value={form.explanation} onChange={handleInputChange} label='explanation' />
                    

                    <TextField name='templateHint' value={form.title} onChange={handleInputChange} label='template hint' fullWidth />
                   
                    <TextField name='tutorialsVideoLink' value={form.tutorialsVideoLink} onChange={handleInputChange} label='tutorialsVideoLink' />
                    <TextField name='AIPrompt' value={form.AIPrompt} onChange={handleInputChange} label='AIPrompt' />


                    <Box>

                        <Typography variant="h3">Tutorials </Typography>
                        {
                            form.tutorials.map((x, index) => {

                                const title = index === 0 && x.title.isEmpty() ? form.title : x.title
                                
                                return <Box display={'flex'} key={`tutokey${index}`} flexDirection={'column'} mt={8}>

                                    <Box display={'flex'} gap={16}  alignItems={'center'}  >
                                        <Typography variant="h3">{index}:</Typography>
                                        <XJLinkButton to={'#'} onClick={() => {

                                            showAI(index, title)

                                        }}>AI</XJLinkButton>

                                    </Box>

                                    <TextField
                                        key={`tutorialtitle${index}`}
                                        value={ title}
                                        onChange={(e) => { handleInputChangeForTutorials(e.target.value, 'title', index) }}
                                    
                                    />
                                    <TextField

                                        inputRef={el => tutorialExplantaionRefs.current.current[index] = el}

                                        multiline rows={3} key={`tutorialbody${index}`} value={x.body} onChange={(e) => { handleInputChangeForTutorials(e.target.value, 'body', index) }} />
                                    
                                </Box>

                            })
                        }

                        <XJButton onClick={() => {

                            const t = form.tutorials
                            t.push({ title: '', body: '' })
                            setForm({ ...form, tutorials: t })

                        }}

                            variant={'tertiary'}>
                            add tutorial
                        </XJButton>
                    </Box>
                </Box>

                


            </Box>


        </DialogContent>
        <DialogActions>
            <XJButton variant={'secondary'} onClick={onCancel}>Cancel</XJButton>
            <XJButton variant={'secondary'} onClick={onClose}>Save</XJButton>
        </DialogActions>
    </Dialog>




}