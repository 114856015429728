import { EmptyPageMessage } from "./EmptyPageMessage";

import { ReactComponent as ErrorIcon } from '../../svg/Error.svg'


export function BadLink() 
{

    return <EmptyPageMessage
    
        title="Invalid link"
        message="The pitch link is not valid"    
        mainIcon={<ErrorIcon/>}
    
    />



}