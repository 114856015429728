import { useRef, useState } from 'react';
import { FounderProfile } from 'Pages/Founder/Profile/FounderProfile';
import { useHistory, useParams } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import { Header, HeaderRightButtons, PageTitle } from 'XJumpTheme';
import XJBreadcrumbs from 'UI Elements/XJBreadcrumbs';
import {  XJButton } from 'UI Elements/Buttons/XJButton';
import { useAlert } from 'hooks/UseAlert';
import XJStatusBadge, { StatusType } from 'UI Elements/XJStatusBadge';
import { VideoCameraIcon } from 'UI Elements/XJIcons';
import { RoutingController } from 'Controllers/RoutingController';

export function ProfileGoLive() {

    const { pid } = useParams<any>()  // Pitch ID 

    const [dataIsComplete, setDataIsComplete] = useState(false)
    const [dataIsDirty, setdataIsDirty] = useState(false)

    const alerts = useAlert()
    const history = useHistory()


    // MARK: - Actions 

    const onSaveComplete = () => {
        alerts.clearWorking()
        _redirectGoLive()
    }

    const onDataIsComplete = (complete: boolean) => {
        setDataIsComplete(complete)
    }

    const onContinue = () => {
        if (dataIsDirty) {
            alerts.working()
            profileComponentRef!.current!.handleSave()
        } else {
            _redirectGoLive()
        }
    }

    const _redirectGoLive = () => {
        history.push(RoutingController.PitchGoLive(Number(pid)))
    }


    // MARK: - Render

    const profileComponentRef = useRef<any>()

    return (
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />

                <Box sx={HeaderRightButtons}>
                    <XJButton to='/dashboard' variant={'tertiary'}>
                        Cancel
                    </XJButton>

                    <XJButton variant={'primary'} disabled={!dataIsComplete} onClick={onContinue}>
                        Next
                    </XJButton>
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Your profile
                    </Typography>
                    {!dataIsComplete ? <XJStatusBadge type={StatusType.error} text={'Fields missing'} icon={<VideoCameraIcon />} /> : null}
                </Box>
            </Grid>

            <Grid item xs={12}>
                <FounderProfile
                    ref={profileComponentRef} onSaveSuccess={onSaveComplete}
                    onDataStatusChange={onDataIsComplete}
                    onDataIsDirty={() => setdataIsDirty(true)}
                    onSaveFailure={() => alerts.error('An error has occured')}
                />
            </Grid>
        </Grid>
    )
}