import { valueOrEmpty } from "tools/valueOrEmpty";
import XJImage from "./XJImage";




export function XJUserLogo(props: { src: string | null | undefined })
{

    return  valueOrEmpty(props.src) !== '' ? <XJImage sx={{ width: '40px', height: '40px', backgroundSize: 'contain', backgroundPosition: 'center' }} src={valueOrEmpty(props.src)} /> : null


}