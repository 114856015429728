import { ChangeEvent, FocusEvent, RefObject, useCallback, useRef, useState } from "react"
import { Box, SxProps } from '@mui/system'
import { ActionCallback } from "tools/Types"
import { InputAdornment, TextField } from "@mui/material"
import React from "react"
import { secondaryDefault, theme } from "XJumpTheme"
import { XJIconButtonTextFieldStepper } from "./Buttons/XJIconButton"
import { ArrowFullDownIcon, ArrowFullUpIcon } from "./XJIcons"

interface XJNumberTextfieldProps {
    sx?: SxProps

    value: number
    step?: number
    id?: string
    EndAdornment?: string
    StartAdornment?: string

    validation?: (value: string) => boolean
    format?: (value: number) => string
    errorMessage?: (input: string) => string
    currency?: boolean
    autofocus?: boolean

    onFocus?: ActionCallback
    onFinish?: (value: string) => void
}

export default function XJNumberTextfield(props: XJNumberTextfieldProps) {

    const step = props.step ?? 0

    const inputRef = useRef() as RefObject<HTMLInputElement>

    // const [currentValue, setCurrentValue] = useState<string>()
    const [isValidInput, setIsValidInput] = useState(true)
    const [errorMessage, setErrorMessage] = useState(" ")

    const formatValue = useCallback((value: number) => {
        let result = ""

        if (props.currency) {

            result = formatToCurrency(value)
        }
        else {

            const format = props.format
            if (format) {
                result = format(value)
            } else {
                result = value.toString()
            }
        }

        return result
    }, [props.format])




    const isValidValue = useCallback((input: string) => {
        let result = false

        const validation = props.validation
        if (validation) {
            result = validation(input)
        } else {
            result = true
        }

        return result
    }, [props.validation])



    const validateInput = useCallback((input: string) => {
        //  setCurrentValue(input)

        if (isValidValue(input)) {
            setErrorMessage(" ")
            setIsValidInput(true)
        } else {
            setErrorMessage(props.errorMessage ? props.errorMessage(input) : " ")
            setIsValidInput(false)
        }

        props.onFinish!(input)

    }, [props, isValidValue])

    /*  useEffect(() => {
        if (props.value !== undefined) {
            validateInput(formatValue(props.value))
        }
    }, [props.value, props.validation, props.format, formatValue, validateInput])  */

    const applyValue = (value: string) => {
        props.onFinish && props.onFinish(value)
    }

    const onFinishTextFieldEditing = (value: string) => {
        let finalValue = value
        if (isValidValue(finalValue)) {
            applyValue(finalValue)
        } else {
            // return previous valid value
            finalValue = formatValue(props.value)
        }
        validateInput(finalValue)
    }

    const onTextFieldKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            if (props.value) {
                onFinishTextFieldEditing(props.value.toString())
                selectCurrentText()
            }
        } else if (event.key === "ArrowUp") {
            stepUp()
        } else if (event.key === "ArrowDown") {
            stepDown()
        }
    }

    const onTextFieldFocus = (event: FocusEvent<HTMLInputElement>) => {
        props.onFocus && props.onFocus()

        selectCurrentText()
    }

    /* const onTextFieldFocusLeft = (event: FocusEvent<HTMLInputElement>) => {
        if (props.value)
            onFinishTextFieldEditing(props.value.toString())
    } */

    const onDurationChange = (changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = changeEvent.currentTarget.value
        validateInput(value)
    }

    const onStepperUp = () => {
        stepUp()
    }

    const onStepperDown = () => {
        stepDown()
    }


    // Textfield 

    const selectCurrentText = () => {
        const tf = inputRef.current
        if (tf) {
            tf.setSelectionRange(0, tf.value.length)
        }
    }

    const formatToCurrency = (val: number): string => {

        const frmttr = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        })

        

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(val)

    }

    // Stepper 

    const stepUp = () => {
        let value = props.value
        value = value + step
        onFinishTextFieldEditing(value.toString())
    }

    const stepDown = () => {
        let value = props.value
        value = value - step
        onFinishTextFieldEditing(value.toString())
    }


    // Styling 

    const styles = {

        text: {
            ...theme.typography.secondary.regular,
            color: theme.palette.darkPrimary.default
        } as SxProps,

        stepper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'space-around',
            color: secondaryDefault,
            marginLeft: theme.spacing(6)

        } as SxProps
    }


    // Render 

    return <Box sx={props.sx} key={`inputbox${props.id}`}>
        <Box display={'flex'}>
            <Box flexGrow={1}>
                <TextField

                    id={props.id}
                    inputRef={inputRef}

                    variant="outlined"
                    color='secondary'
                    value={formatValue(props.value)}

                    sx={{
                        '& .MuiOutlinedInput-root': {

                            borderWidth: 0
                        }

                    }

                    }


                    autoFocus={props.autofocus ?? props.autofocus}

                    error={!isValidInput}
                   // helperText={errorMessage}

                    onChange={(event) => {

                        // const caret = event.target.selectionStart
                        // const element = event.target
                        // window.requestAnimationFrame(() => {
                        //     element.selectionStart = caret
                        //     element.selectionEnd = caret
                        // })
   
                        onDurationChange(event)
                    }}

                    onKeyUp={onTextFieldKeyUp}
                    onFocus={onTextFieldFocus}
                    // onBlur={onTextFieldFocusLeft}

                    InputProps={{

                        startAdornment: props.StartAdornment && <InputAdornment position='start'>{props.StartAdornment}</InputAdornment>,

                        endAdornment: props.EndAdornment &&
                            <InputAdornment position='end'>
                                <Box sx={styles.text}>
                                    {props.EndAdornment}
                                </Box>
                                <Box>
                                    {(props.step !== 0) &&
                                        <Box sx={styles.stepper}>
                                            <Box>
                                                <XJIconButtonTextFieldStepper onClick={onStepperUp}>
                                                    <ArrowFullUpIcon color={secondaryDefault} />
                                                </XJIconButtonTextFieldStepper>
                                            </Box>
                                            <Box>
                                                <XJIconButtonTextFieldStepper onClick={onStepperDown}>
                                                    <ArrowFullDownIcon color={secondaryDefault} />
                                                </XJIconButtonTextFieldStepper>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </InputAdornment>

                    }}
                />
            </Box>

        </Box>
    </Box>
}