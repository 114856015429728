import { Box, LinearProgress, SxProps, Typography } from "@mui/material"
import { useAlert } from "hooks/UseAlert"
import { useEffect } from "react"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import XJIconButton from "UI Elements/Buttons/XJIconButton"
import { IconRestart } from "UI Elements/XJIcons"
import { copyPrimaryRegular, primaryDefault, theme, whiteDefault, whiteOverlay60 } from "XJumpTheme"
import { classes } from "./VideoSetup"


interface IVideoSetupProcProps {
    onComplete: () => void

}

/* const sentence = `The small pup gnawed a hole in the sock. The fish twisted and turned on the bent hook. Press the pants and sew a button on the vest. The swan dive was far short of perfect The beauty of the view stunned the young boy. Two blue fish swam in the tank. Her purse was full of useless trash. The colt reared and threw the tall rider. It snowed, rained, and hailed the same morning. Read verse out loud for pleasure.` */


const sentence  = `This thing right here, that thing over there, these delicate feathers in my hand, and those feathers resting on the shelf are reminders of my father's joy. The thin feather sat upon the bath's edge, while those thicker feathers lay within the mouth. Singing about these specific things, my mother would often reflect on her father's favorite thing: the thought of singing while bathing, contemplating the thin line between something and nothing, and how that made her think of her father and his cherished memories.`


const words = sentence.split(' ').map(x => {

    return {
        word: x,
        scrubbedWord: x.toUpperCase().replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ""),
        highlight: false
    }
})

let PercentComplete = 0


export default function VideoSetupProc(props: IVideoSetupProcProps) {

    const errorAlert = useAlert().error

    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition()


    if (!browserSupportsSpeechRecognition) {
        errorAlert('Speech recognition is unsupported')
    }


    useEffect(() => {

        SpeechRecognition.startListening({ continuous: true, language: 'en-US' })

        return () => {

            SpeechRecognition.stopListening()
        }

    }, [])




    const heardWords = transcript.toUpperCase().split(' ')

    useEffect(() => {

        words.forEach((x, i) => {

            if (heardWords.includes(x.scrubbedWord)) {

                const numberiOfInstancesThusFar = words.slice(0, i + 1).filter(t => t.scrubbedWord === x.scrubbedWord).length

                const countAtTranscript = heardWords.filter(t => t === x.scrubbedWord).length

                if (numberiOfInstancesThusFar <= countAtTranscript) {
                    x.highlight = true

                }
            }

            PercentComplete = Math.round(words.filter(x => x.highlight).length / words.length * 100)
            if (PercentComplete === 100) props.onComplete()
        }

        )
    }, [transcript])



    const reset = () => {

        words.forEach((x, i) => {

            x.highlight = false

        })

        resetTranscript()
        PercentComplete = 0

    }
    const classesWords =
    {
        item:
            {
                paddingX: 2

            } as SxProps,

        highlightedItem:
            {

                backgroundColor: theme.palette.primary.light90,
                paddingX: 2,
                borderRadius: '2px'

            } as SxProps
    }

    const wordsBox: SxProps =
    {
        backgroundColor: whiteDefault,
        padding: 8,
        flexWrap: 'wrap',
        gap: 2,
        display: 'flex',
        ...copyPrimaryRegular
    }

    const lines: SxProps = 
    {
        '& .MuiLinearProgress-root':
        {
            background: whiteOverlay60
        },
        '& .MuiLinearProgress-bar1Determinate':
        {
            backgroundColor:  PercentComplete < 70 ? theme.palette.denotive.warning.default : primaryDefault
        }

    }
    return <>
        <Box sx={classes.header}>
            <Typography variant="h2">
                Practice pronunciation 
            </Typography>
        </Box>

        <Box sx={classes.explaination} textAlign={'center'}>

            Read the text out loud to see how well English speakers can understand you. Investors are used to hearing different accents so don't worry about being perfect. 

        </Box>

        <Box sx={classes.mainBox} >
            <Box mb={4} display='flex' justifyContent={'space-between'} alignItems='center'>
                <Typography variant="h3">Progress: {PercentComplete}% </Typography>
                <XJIconButton onClick={() => {
                    reset()
                }}><IconRestart/>
                </XJIconButton>  
            </Box>
            <Box mb={18} sx={lines}>
                <LinearProgress
                    variant="determinate"
                    value={PercentComplete}
                    sx={{
                        borderRadius: '8px',
                        '.MuiLinearProgress-bar': {

                            borderRadius: '8px'
                        }
                        
                    }}
                />
            </Box>

            <Box sx={wordsBox} >
                {words.map(({ word, highlight }, i) => {
                    return <Box
                        key={i}
                        sx={highlight ? classesWords.highlightedItem : classesWords.item}>{word}
                    </Box>
                })}
            </Box>
        </Box>
    </>
}