import { v4 } from "uuid"
import { LogError } from "Controllers/Logging"

/**
 * Returns newly generated UUID
 * 
 */

export function uuid() {
    return v4()
}


/**
 * @param {string} input - logs 
 * 
 */

export function log(input, addDate = false, addTime = true) {
    const date = new Date()
    const dateStr = date.getFullYear() * 10000 + "-" + ((date.getMonth() + 1) * 100).pad(2) + "-" + date.getDate().pad(2)
    const timeStr = date.getHours().pad(2) + ":" + date.getMinutes().pad(2) + ":" + date.getSeconds().pad(2) + "." + date.getMilliseconds().pad(3)
    console.log((addDate ? dateStr + " " : "") + (addTime ? timeStr : "") + ": " + input)
}


/**
 * @param {Blob} blob
 * @param {string} fileName
 * 
 */

export function saveBlob(blob, fileName) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
}

/**
 * @param {Blob|String} blob
 *
 * @returns {Promise<Number>} Blob duration in seconds.
 */
export async function getBlobDuration(blob) {

    const tempVideoEl = document.createElement('video')

    tempVideoEl.src = typeof blob === 'string' || blob instanceof String
        ? blob
        : window.URL.createObjectURL(blob)

    const durationP = new Promise((resolve, reject) => {

        tempVideoEl.addEventListener('loadedmetadata', () => {
            // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
            if (tempVideoEl.duration === Infinity) {
                tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER
                tempVideoEl.ontimeupdate = () => {
                    tempVideoEl.ontimeupdate = null
                    resolve(tempVideoEl.duration)
                    tempVideoEl.currentTime = 0
                }
            }
            // Normal behavior
            else
                resolve(tempVideoEl.duration)
        })
        tempVideoEl.onerror = (event) => reject(event.target.error)
    })


    return durationP

}


/**
 * @param {boolean} on
 * @param {Element | undefined} element
 * 
 */

export function turnFullscreen(on, element = undefined) {
    if (on && element) {
        if (!document.fullscreenElement) {
            // If the document is not in full screen mode
            // make the video full screen
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.webkitRequestFullScreen) {
                // Safari
                element.webkitRequestFullScreen()
            }
        }
    } else {
        if (document.exitFullscreen && document.fullscreenElement) {
            document.exitFullscreen()
        }
    }
}

/**
 * @param {HTMLElement} element target element
 * 
 */

export function listOfIdsForElement(element) {
    let result = [element.id]
    let parent = element.parentElement
    while (parent) {
        result.push(parent.id)
        parent = parent.parentElement
    }
    result = result.filter((element) => {
        return element
    })
    return result
}

/**
 * @param {HTMLElement} element target element 
 * @param {string} id id of searched element
 * 
 */

export function hasElementInTreeWithId(element, id) {
    return listOfIdsForElement(element).contains(id)
}


// Crop utils

const createImage = (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => {
            resolve(image)
        })
        image.addEventListener('error', (error) => {
            reject(error)
        })
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImage(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg')

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file))
        }, 'image/png')
    })
}

export async function getRotatedImage(imageSrc, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const orientationChanged =
        rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
    if (orientationChanged) {
        canvas.width = image.height
        canvas.height = image.width
    } else {
        canvas.width = image.width
        canvas.height = image.height
    }

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.drawImage(image, -image.width / 2, -image.height / 2)

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file))
        }, 'image/png')
    })
}


// Datetime format utils 

/**
 * @param {number} secondsTotal amount of seconds to convert 
 * 
 * @returns {string} HH:mm:ss.ss
 */

const kTimeSeparator = ":"

export function timeFromSeconds(secondsTotal) {
    let result = ""

    let hours = Math.floor(secondsTotal / 3600)
    let minutes = Math.floor((secondsTotal - (hours * 3600)) / 60)
    let seconds = secondsTotal - (hours * 3600) - (minutes * 60)

    if (hours > 0) {
        if (hours < 10) {
            hours = "0" + hours
        }
        result = hours + kTimeSeparator
    }
    if (minutes < 10) {
        minutes = "0" + minutes
    }
    if (seconds < 10) {
        seconds = "0" + seconds.toFixed(2)
    } else {
        seconds = seconds.toFixed(2)
    }
    result = result + minutes + kTimeSeparator + seconds

    return result
}

/**
 * @param {string} timeString source time string
 * 
 * @returns {number} amount of seconds
 */

export function timeToSeconds(timeString) {
    const parts = timeString.split(kTimeSeparator)

    let result = 0
    for (let index = parts.length - 1; index >= 0; index--) {
        const element = parts[index]
        const value = Number(element)
        if (index === parts.length - 1) {
            // seconds 
            result = value
        } else if (index === parts.length - 2) {
            // minutes 
            result = result + value * 60
        } else if (index === parts.length - 3) {
            // hours 
            result = result + value * 3600
        }
    }

    return result
}



export const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        //LogError(e);
        return false;
    }
    return true;
};


/**
 * @param {any} a - the first object 
 * @param {any} b - the second object 
 * 
 * @returns {Array} array of change objects - name, value of the first object, value of the second object 
 */

export function getObjectChanges(a, b) {

    let results = []

    Object.getOwnPropertyNames(a).forEach((key) => {
        if (a[key] !== b[key]) {
            results.push({
                name: key,
                aValue: a[key],
                bValue: b[key]
            })
        }
    })

    const existingKeys = results.map((r) => { return r.name })
    Object.getOwnPropertyNames(b).forEach((key) => {
        if ((b[key] !== a[key]) && !existingKeys.contains(key)) {
            results.push({
                name: key,
                aValue: a[key],
                bValue: b[key]
            })
        }
    })

    return results
}