import { Box, Collapse, Grid, LinearProgress, SxProps, Typography } from "@mui/material"
import { QuestionTutorial } from "Common/QuestionContentDAO"
import { RoutingController } from "Controllers/RoutingController"
import { QT } from "Model/Types"
import { useEffect, useRef, useState } from "react"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { ArrowLeftIcon, ArrowRightIcon, DropDownIcon, DropUpIcon, QAProgressSegmentArrow, QAProgressSegmentDone, QAProgressSegmentPending } from "UI Elements/XJIcons"
import { darkPrimaryDefault, Header, HeaderRightButtons, PageTitle, secondaryDefault, whiteDefault, whiteOverlay32, whiteOverlay60 } from "XJumpTheme"
import { SegmentModel } from "Model/SegmentModel"
import { XJMenuButton } from "UI Elements/Buttons/XJMenuButton"
import { ExplanationBox } from "UI Elements/XJExplanationBox"
import { PitchStatus } from "Common/Enums"
import { useHistory } from "react-router"

export interface IQandAMasterLayoutProps {
    TopicQuestion: JSX.Element
    Tutotrial: QuestionTutorial[],
    TutorialVideo: string,
    onFinishLater: () => void
    FinishLaterLabel?: string
    QAProgress: number
    segment: SegmentModel
}

export function QaLayout(props: IQandAMasterLayoutProps) {

    const pitch = props.segment.pitch

    const history = useHistory()

    const expandProgress = useRef<React.Dispatch<React.SetStateAction<boolean>>>()
    const expandTutorial = useRef<React.Dispatch<React.SetStateAction<boolean>>>()

    const ProgressPane = () => {

        const ProgressPaneStateSessionStorageKey = 'PitchuraInterviewProgressOpen'

        const [progressExpanded, setProgressExpanded] = useState(sessionStorage.getItem(ProgressPaneStateSessionStorageKey) ? sessionStorage.getItem(ProgressPaneStateSessionStorageKey) === 'true' : false)
        const [hoverSegment, setHoverSegment] = useState<number>(-1)

        useEffect(() => {
            sessionStorage.setItem(ProgressPaneStateSessionStorageKey, String(progressExpanded))
        }, [progressExpanded])

      
        useEffect(() => {
            expandProgress.current = setProgressExpanded
        }, [])

        const onProgressClick = () => {
            setProgressExpanded((value => {   
                if (!value && expandTutorial && expandTutorial.current) expandTutorial.current (false)
                return !value
            }))
        }
        
        const onSectionTitleClick = (segment: SegmentModel) => {
            const nextStep = (segment.getCurrentInterviewStep() !== null ? segment.getCurrentInterviewStep() : segment.topics[0]) as QT

            history.push(isInterview ? RoutingController.InterviewStep(nextStep) : RoutingController.QA_Edit(nextStep)!)
        }
        
        const isInterview = pitch.status === PitchStatus.QandA

        const styles = {
            root: {
                display: 'flex',
                flexDirection: 'column'
            } as SxProps,
            collapse: {
                display: 'flex',
                flexDirection: 'column',
                gap: 12
            }  as SxProps,
            collapseHeader: {
                display: 'flex',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 1,
                cursor: 'pointer',
                ':hover': {
                    '& .MuiIconButton-root': {
                        background: whiteOverlay32
                    } as SxProps
                },
                ':active': {
                    '& .MuiIconButton-root': {
                        backgroundColor: whiteOverlay60
                    }
                } as SxProps
            } as SxProps,
            progressBar: {
                height: '4px',
                backgroundColor: whiteOverlay60,
                borderRadius: '4px',
                color: secondaryDefault
            } as SxProps,
            collapseBody: {
                mt: 12
            } as SxProps,
            segment: {
                height: '40px',
                display: 'flex',
                gap: 8, 
                px: 4,
                alignItems: 'center',
                borderRadius: '2px',
                cursor: 'pointer',
                ':hover': {
                    backgroundColor: whiteOverlay32
                } as SxProps
            } as SxProps, 
            segmentArrow: {
                justifyContent: 'center', 
                alignItems: 'center'
            } as SxProps
        }

        return (
            <Box sx={styles.root}>
                <Box sx={styles.collapse}>
                    <Box sx={styles.collapseHeader} onClick={onProgressClick}>
                        <Typography variant="h2">Progress: {props.QAProgress}%</Typography>
                        <XJMenuButton variant={'primary'}>
                            {progressExpanded ? <DropUpIcon /> : <DropDownIcon />}
                        </XJMenuButton>
                    </Box>

                    <LinearProgress
                        variant="determinate"
                        color='secondary'
                        value={props.QAProgress}
                        sx={styles.progressBar}
                    />
                </Box>

                <Collapse in={progressExpanded} >
                    <Box sx={styles.collapseBody}>
                        {pitch.segments.map(s => {
                            return <Box key={`segment${s.sequence}`} 
                                sx={styles.segment}
                                onMouseEnter={() => {setHoverSegment(s.id!)}}   // emulates hover for segmentArrow 
                                onMouseLeave={() => {setHoverSegment(-1)}}
                                onClick={() => {onSectionTitleClick(s)}} 
                            >
                                {s.isComplete ? <QAProgressSegmentDone color={secondaryDefault}/> : <QAProgressSegmentPending color={darkPrimaryDefault} />}
                                <Typography variant='h3'  sx={{flexGrow: 2}}
                                    color={s.id === props.segment.id ? secondaryDefault : darkPrimaryDefault} 
                                >
                                    {s.sequence + 1}: {s.title} ({s.CompletedTopics}/{s.TotalTopics})
                                </Typography>
                                <Box sx={{...styles.segmentArrow, display: s.id === hoverSegment ? 'flex' : 'none'}}>
                                    <QAProgressSegmentArrow  color={secondaryDefault} />
                                </Box>
                            </Box>
                        })}
                    </Box>
                </Collapse>
            </Box>
        )
    }


    const TutorialVideo = () => {
        return <iframe title={'tutorial'} width="250" height="150" src={props.TutorialVideo}></iframe>
    }

    const Tutorials = () => {

        const TutorialsPaneStateSessionStorageKey = 'PitchuraInterviewTutorialsOpen'

        const [tutorialsExpand, setTutorialsExpand] = useState(sessionStorage.getItem(TutorialsPaneStateSessionStorageKey) ? sessionStorage.getItem(TutorialsPaneStateSessionStorageKey) === 'true' : false)
        const [tutorialIndex, setTutorialIndex] = useState(0)

        useEffect(() => {
            sessionStorage.setItem(TutorialsPaneStateSessionStorageKey, String(tutorialsExpand))
        }, [tutorialsExpand])

        useEffect(() => {
            expandTutorial.current = setTutorialsExpand
        }, [])

        const onExpand = () => {
            setTutorialsExpand(value => {
                if (!value) expandProgress.current! (false)
                return !value
            })
        }
        
        const onClickNext = () => {
            setTutorialIndex(index => (index + 1 === tutorials.length ? 0 : index + 1))
        }

        const onClickBack = () => {
            setTutorialIndex(index => (index === 0 ? tutorials.length - 1 : index - 1))
        }

        const styles = {
            header: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                ':hover': {
                    '& .MuiIconButton-root': {
                        background: whiteOverlay32
                    } as SxProps
                },
                ':active': {
                    '& .MuiIconButton-root': {
                        backgroundColor: whiteOverlay60
                    }
                } as SxProps
            } as SxProps,
            collapse: {
                display: 'flex',
                flexDirection: 'column',
                gap: 12, 
                maxHeight: '50vh'
            } as SxProps,
            content: {
                mt: 12, 
                height: '100%',
                overflow: 'auto'
            } as SxProps,
            buttons: {
                display: 'flex',
                justifyContent: 'start',
                gap: 8
            } as SxProps
        }

        return <>
            <Box sx={styles.header} onClick={onExpand}>
                <Typography variant="h2">Tutorials ({tutorials.length})</Typography>
                <XJMenuButton variant={'primary'}>
                    {tutorialsExpand ? <DropUpIcon /> : <DropDownIcon />}
                </XJMenuButton>
            </Box>

            <Collapse in={tutorialsExpand}>
                <Box sx={styles.collapse}>

                    <Box sx={styles.content}>
                        {tutorials[tutorialIndex]}
                    </Box>

                    {tutorials.length > 1 && <Box sx={styles.buttons}>
                        <XJButton variant={'tertiary'} iconLeft onClick={onClickBack} disabled={tutorialIndex === 0}> 
                            <ArrowLeftIcon />Previous
                        </XJButton>
                        <XJButton variant={'secondary'} iconRight onClick={onClickNext} disabled={tutorialIndex === tutorials.length - 1}>
                            Next<ArrowRightIcon />
                        </XJButton>
                    </Box>}
                </Box>
            </Collapse>
        </>
    }

    const styles = {
        content: {
            backgroundColor: whiteDefault,
            paddingX: 12,
            paddingTop: 8,
            paddingBottom: 12,
            width: '100%'
        } as SxProps, 
        progress: {
            backgroundColor: whiteDefault,
            paddingX: 8,
            paddingTop: 4,
            paddingBottom: 12
        } as SxProps, 
        tutorials: {
            backgroundColor: whiteDefault,
            paddingLeft: 12,
            paddingRight: 5,
            paddingTop: 4,
            paddingBottom: 8
        } as SxProps, 
        rightContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            height: '100%'
        } as SxProps
    }

    const tutorials: JSX.Element[] = []

    if (props.TutorialVideo && !props.TutorialVideo.isEmpty()) {
        tutorials.push(<TutorialVideo />)
    }

    props.Tutotrial.forEach((t, i) => {

        tutorials.push(<Box key={`tutotial${i}`} >

            <Typography variant={'h3'}>
                {`${props.Tutotrial.length > 1 ? `${i + 1}.` : ''}`} {t.title}
            </Typography>
            <Box mt={8} >
                <ExplanationBox>
                <div dangerouslySetInnerHTML={{ __html: t.body }}></div>
                    {/* {t.body} */}
                </ExplanationBox>
            </Box>
        </Box>)
    })

    return <Grid container columnSpacing={16}>

        <Grid item xs={12} sx={Header}>
            <XJBreadcrumbs />
            <Box sx={HeaderRightButtons}>

                <XJButton variant={'secondary'} onClick={props.onFinishLater}>
                    {props.FinishLaterLabel ? props.FinishLaterLabel : 'Finish Later'}
                </XJButton>
            </Box>
        </Grid>

        <Grid item xs={12} sx={PageTitle}>
            <Typography variant="h1">

                {props.segment.title}

            </Typography>
        </Grid>

        <Grid item xs={7}>
            <Box sx={styles.content} >
                {props.TopicQuestion}
            </Box>
        </Grid>

        <Grid item xs={5}>
            <Box sx={styles.rightContent} >

                <Box sx={styles.progress}>
                    <ProgressPane />
                </Box>

                {tutorials.length > 0 && 
                    <Box sx={styles.tutorials}>
                        <Tutorials />
                    </Box>
                }

            </Box>
        </Grid>
    </Grid>
}