import { EmptyPageMessage } from "./EmptyPageMessage";

import { ReactComponent as ErrorIcon } from '../../svg/Error.svg'


export function ErrorPage() 
{

    return <EmptyPageMessage
    
        title="Oops... Error"
        message="An error has occured. Our team has been notified."    
        mainIcon={<ErrorIcon/>}
    
    />



}