
import { VideoDimensions } from "Common/Enums"
import { LogError } from "Controllers/Logging"
import { useEffect, useRef, useState } from "react"
import CameraSelector from "../CameraSelector"
import { usePitchuraLocalStorage } from "hooks/UsePitchuraLocalStorage"

export default function VideoCamera() {

    const localStorage = usePitchuraLocalStorage()

    const videoRef = useRef<HTMLVideoElement>(null)
    const streamRef = useRef<MediaStream>()

    // Get video devices 
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([])
    const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string>(localStorage.selectedCameraId)

    const [stream, setStream] = useState<MediaStream | undefined>()

    useEffect(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => {
                const videoDevices = mediaDevices.filter((x) => x.kind === 'videoinput')
                setVideoDevices(videoDevices)

                if (!videoDevices.find(vd => vd.deviceId === selectedVideoDeviceId)) {
                    setSelectedVideoDeviceId('')
                    if (videoDevices.length > 0) {
                        setSelectedVideoDeviceId(videoDevices[0].deviceId)
                    }
                }
            })
    }, [])

    useEffect(() => {
        localStorage.updateSelectedCameraId(selectedVideoDeviceId)
    }, [selectedVideoDeviceId])

    useEffect(() => {

        const constraints = {
            video: {
                deviceId: {
                    exact: selectedVideoDeviceId ?? videoDevices[0].deviceId
                },
                width: VideoDimensions.width,
                height: VideoDimensions.height
            }
        }

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                setStream(stream)

                const video = videoRef.current
                streamRef.current = stream

                video!.srcObject = stream
                video!.play()
            })
            .catch(err => {
                LogError("error:", err)
            })

        return () => {

            if (streamRef.current) {
                streamRef.current.getTracks().forEach((t) => t.stop())


            }
        }

    }, [selectedVideoDeviceId])

    const onVideoDeviceChange = (deviceID: string) => {
        if (deviceID !== selectedVideoDeviceId) {
            stream?.getTracks().forEach(track => track.stop())

            setSelectedVideoDeviceId(deviceID)
        }
    }

    const showCameraSelector = videoDevices.length > 1

    return <>
        <video width='100%' ref={videoRef} />
        {showCameraSelector && 
        <CameraSelector 
            devices={videoDevices} 
            selectedID={selectedVideoDeviceId} 

            onSelect={onVideoDeviceChange}
        />}
</>

}