/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/ 

export {}

declare global {
    interface String {
        isEmpty(): boolean
        isEmail(): boolean
    }
}

function isEmpty(this: string) {
    return this === undefined || this.length === 0
}

function isEmail(this: string) {

    const regex = /^(([^<>()[\]\\., :\s@"]+(\.[^<>()[\]\\., :\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(this)

}

Object.defineProperty(String.prototype, "isEmpty", {value: isEmpty})
Object.defineProperty(String.prototype, "isEmail", {value: isEmail})