import { Box, Dialog, TextField, Typography } from "@mui/material"
import API from "api-axios"
import { useEffect, useState } from "react"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { XJModal } from "UI Elements/PopUps/XJModal"


export interface IXJAIHelperProps {
    open: boolean
    prompt: string
    onReturn: (AIReturn: string) => void
    onCancel: () => void

}

export function XJAIHelper(props: IXJAIHelperProps) {



    const [prompt, setPrompt] = useState('')

    useEffect(() => {

        const prompt = `explain to a founder what is a ${props.prompt} and what does an investor understands from it`

        setPrompt(prompt)

    }, [props.prompt])


    const [AIReturn, setAIReturn] = useState('')

    const onSentToAI = () => {

        API.post('/admin/AI/prompt', { prompt: prompt })
            .then((res) => {

                const response = res.data.AIReturn
                setAIReturn(response)

            })

    }



    return <XJModal show={props.open} onClose={props.onCancel} width={'75%'} >

        <Typography variant="h3">
            Prompt:
        </Typography>

        <TextField

            fullWidth
            multiline
            rows={8}
            value={prompt}
            onChange={(e) => {
                setPrompt(e.target.value)
            }}
        />

        <Typography variant="h3">
            AI Return:
        </Typography>

        <TextField
            
            multiline
            fullWidth
            rows={8}
            value={AIReturn} onChange={(e) => { setAIReturn(e.target.value) }} />

        <Box display={'flex'} gap={8}  >

            <XJButton

                variant='secondary'
                onClick={onSentToAI} >Send</XJButton>


            <XJButton
                variant='secondary'
                onClick={() => {

                    props.onReturn(AIReturn)

                }}>OK</XJButton>

            <XJButton variant='secondary' onClick={props.onCancel}>Cancel</XJButton>

        </Box>


    </XJModal>


}