import { TopicContentModel } from "Model/TopicContentModel";
import { useState } from "react";
import { TopicsEditor } from "./TopicsEditor";



export function SystemTopics () 
{

    const [selectedTopic, setSelectedTopic] = useState<TopicContentModel>()

    const onSelectedTopicChanged = (newSelectedTopic: TopicContentModel) =>
    {
        setSelectedTopic (newSelectedTopic)
    }
    
    return <TopicsEditor onSelectedTopicsChanged={onSelectedTopicChanged} selectedTopic={selectedTopic}/>


}