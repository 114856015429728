import { Box, SxProps, Typography } from "@mui/material"
import { VideoModel } from "Model/VideoModel"
import { VisualAidModel } from "Model/VisualAidModel"
import { RefObject, MouseEvent, useRef, useState, useEffect } from "react"
import {  theme, videoPlayerTickerFont, whiteDefault, whiteOverlay40 } from "XJumpTheme"
import { TimelineVisualAid } from "./TimelineVisualAid"
import { AidCallback } from "./VideoEditing"
import { DndContext, DragEndEvent, useDroppable, useSensors, useSensor, PointerSensor, DragMoveEvent, DragStartEvent } from '@dnd-kit/core'
import {
    restrictToHorizontalAxis, restrictToParentElement
} from '@dnd-kit/modifiers'
import { TimelineMarker } from "./TimelineMarker"
import { LogError } from "Controllers/Logging"


type MarkerPositionChangeCallback = (position: number) => void

interface TimelineProps {
    sx?: SxProps
    video: VideoModel
    progress: number
    selectedAid?: VisualAidModel
    onMarkerPositionChange: MarkerPositionChangeCallback
    onAidChanged?: AidCallback
}

export function Timeline(props: TimelineProps) {

    const video = props.video

    const timelineRef = useRef() as RefObject<HTMLDivElement>

    const [markerPosition, SetMarkerPosition] = useState(0)

    useEffect(() => {

        if (video.duration > 0) {
            const newPos = Math.round((props.progress / video.duration) * 10000) / 100
            SetMarkerPosition(newPos)
        }

    }, [props.progress])



    const onTimelineClick = (event: MouseEvent<HTMLElement>) => {

        if (timelineRef.current) {
            const tl = timelineRef.current
            const pos = ((event.pageX - tl.getBoundingClientRect().left) / tl.clientWidth) * video.duration

            if (video.duration > 0) {
                props.onMarkerPositionChange(pos)
            }

        } else {
            LogError("VE: No timeline ref element");
        }
    }


    const [markerMoveStart, setMarkerMoveStart] = useState(0)
    
    const onTimeLineMarkerStart = (event: DragStartEvent) => {

        if (event.active.data.current && (event.active.data.current.type === 'marker')) {
            setMarkerMoveStart(markerPosition)
        }

    }

    const onTimelineMarkerMoved = (event: DragMoveEvent) => {
        
        if (event.active.data.current && (event.active.data.current.type === 'marker')) {
            const deltaX = event.delta.x
            const width = timelineRef.current!.clientWidth
            const pos = markerMoveStart + (deltaX / width * 100)

            if (video.duration > 0) {
                props.onMarkerPositionChange(pos / 100 * video.duration)

            }
        }

    }


    const styles = {
        root: {
            ...props.sx
        } as SxProps,

        timelineContainer: {
            borderRadius: '2px',
            backgroundColor: whiteDefault,
            padding: theme.spacing(4),
          //  boxShadow: dropshadowButtonDefault,

        } as SxProps,

        timeline: {
            position: "relative",
            backgroundColor: whiteOverlay40,
            borderRadius: '2px',
            height: theme.spacing(16),
            width: '100%'

        } as SxProps,

        ticker: {
            display: 'flex',
            paddingBottom: theme.spacing(4),
            justifyContent: 'space-between',
            width: '100%'

        } as SxProps
    }



    const renderVideoAids = () => {

        if (video.duration > 0) {

            const aids = props.video.visualAids.filter(va => !va.isRemoved)

            return aids.map((aid: VisualAidModel, index: number) => {
                return <TimelineVisualAid
                    aid={aid}       // TODO: check if it doesn't got rerendered 

                    isSelected={false}
                    // isSelected={props.currentAid?.id === aid.id}
                    index={index}
                    key={`vaKey${index}`}
                />

            })
        }
    }


    const TickerElements = () => {

        const timePadding = (v: number): string => {
            return String(v).padStart(2, '0')
        }

        const videoDuration = video.duration

        const e = []

        let bigStep = 30
        let smallStep = 5

        if (videoDuration < 30) {
            bigStep = 5
            smallStep = 1
        }

        for (let i = 0; i < videoDuration; i++) {

            if (i % 60 === 0) {
                e.push(<Box key={`tickey${i}`}><Typography sx={videoPlayerTickerFont}>{timePadding(i / 60)}:</Typography></Box>)
                continue
            }

            if (i % bigStep === 0) {
                e.push(<Box key={`tickey${i}`}><Typography sx={videoPlayerTickerFont}>:{timePadding(bigStep)}</Typography></Box>)
                continue
            }

            if (i % smallStep === 0) e.push(<Box key={`tickey${i}`}><Typography sx={videoPlayerTickerFont}>&bull;</Typography></Box>)

        }

        return <>{e.map(t => t)}</>

    }

    const { setNodeRef } = useDroppable({
        id: 'timeline'
    })

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // delay: 50,
                // tolerance: 4,
                distance: 5
            }
        })
    )

    const onVisualAidDragEnd = (event: DragEndEvent) => {

        if (event.active.data.current!.type === 'aid') {

            const posPercentageChange = event.delta.x / timelineRef.current!.clientWidth
            const index = event.active.data.current!.index
            const selectedVA = props.video.visualAids[index]
            const newTimePoint = selectedVA.startsAt + (video.duration * posPercentageChange)

            if (props.video.isValidPositionForAid(selectedVA, newTimePoint, selectedVA.duration)) { 

                const aid = props.video.visualAids[index]
                aid.startsAt = newTimePoint

                props.onAidChanged && props.onAidChanged(aid)
            }
        }

    }


    return (

        <Box sx={props.sx} >
            <Box sx={styles.root} >

                <Box sx={styles.ticker} >
                    <TickerElements />
                </Box>


                <DndContext
                    onDragStart={onTimeLineMarkerStart}
                    onDragMove={onTimelineMarkerMoved}
                    onDragEnd={onVisualAidDragEnd} 
                    
                    modifiers={[restrictToHorizontalAxis, restrictToParentElement]} 
                    sensors={sensors}
                >
                    <Box sx={styles.timelineContainer} ref={setNodeRef} onClick={onTimelineClick} >
                        <Box ref={timelineRef} sx={styles.timeline} display={'flex'} >

                            {renderVideoAids()}

                            <TimelineMarker position={markerPosition} />

                        </Box>
                    </Box>
                </DndContext>
            </Box>
        </Box>
    )
}