import {  QuestionModel } from "./QuestionModel"
import { SegmentModel } from "./SegmentModel"
import { TopicDAO } from "Common/TopicDAO"
import API from "api-axios"
import { PitchModel } from "./PitchModel"
import { TopicStatus } from "Common/Enums"
import { QuestionTutorial } from "Common/QuestionContentDAO"
import { useAlert } from "hooks/UseAlert"
import { LogError } from "Controllers/Logging"
import { SystemQuestions } from "Controllers/SystemQuestionsController"


export class TopicModel {


    constructor(Segment: SegmentModel) {
        this.segment = Segment

    }

    get pitch(): PitchModel {
        return this.segment.pitch

    }
    id: number
    segment: SegmentModel
    title: string
    explanation: string
    index: number | undefined

    tutorialVideoLink: string
    tutorials: QuestionTutorial[] = []

    questions: QuestionModel[] = []

    prevTopicId: number | undefined
    nextTopicId: number | undefined

    laswAnsweredQuestionId: number | undefined
    lastAnsweredQuestion: QuestionModel | null = null

    sequence: number

    status: TopicStatus


    // MARK: - Methods 

    public load(topic: TopicDAO)  // passing in the data
    {

        try {

        
            this.title = topic.title!
            this.id = topic.id!
         
            this.id = topic.id
            this.status = topic.status
            this.sequence = topic.sequence
           

            const TopicContent = SystemQuestions.topics.find (x=> x.id === topic.contentId)
            
            if (TopicContent) {   
                
                this.tutorials = TopicContent.tutorials ?? []
                this.tutorialVideoLink = TopicContent.tutorialsVideoLink ?? ''
                this.explanation = TopicContent.explanation ?? ''
            }
            else
            {
                LogError (`TopicModel::Load for topic ${this.id} didn't find TopicContent id: ${topic.contentId}`)    
            }

        
        }
        catch (err)
        {
            LogError(err)
            throw err
        }

    }

    public async save() {

        const TopicData =
            {
                id: this.id,
                title: this.title,
                TopicId: this.id,
                lastAnsweredQuestion: this.laswAnsweredQuestionId,
                tutorialVideoLink: this.tutorialVideoLink,
                tutorials: this.tutorials.length === 0 ? '{}' : this.tutorials.toString(),
                explanation: this.explanation,
                status: this.status,
                sequence: this.sequence

            } as TopicDAO

        try {

            if (this.id) {

                // update

                await API.post(`/pitch/${this.segment.pitch.id}/segment/${this.segment.id}/topic/${this.id}/update`, TopicData)

            }
            else {

                this.id = await (await API.post(`/pitch/${this.segment.pitch.id}/segment/${this.segment.id}/topic/create`, TopicData)).data.id

            }
        }
        catch (error) {
            LogError (error)
            useAlert().APIError()
        }

    }

    setStatus(newStatus: TopicStatus) {
        this.status = newStatus
        this.save()

    }

    updateStatus() {
        if (this.isComplete()) {
            if (this.status !== TopicStatus.complete) {
                this.setStatus(TopicStatus.complete)
            }
        }
    }

    hasAnswers(): boolean {
        return this.questions.some(q => q.hasAnswer())
    }

    isComplete(): boolean {

        return this.hasAnswers()

    }

    setNextTopic(nextTopic: TopicModel) {
        this.nextTopicId = nextTopic.id
        this.save()
    }

    // PUBLIC METHODS 

  /*   setLastStartedTopic() {
        this.segment.LastStartedTopic = this
        this.segment.pitch.LastSegment = this.segment

        this.segment.save()   // TO DO: encapsulat this in a property 
        this.segment.pitch.save() // TO DO: encapsulat this in a property 
    } */


    addTutorial(
        _tutorialVideoLink: string,
        _tutorials: QuestionTutorial[]

    ) {
        this.tutorialVideoLink = _tutorialVideoLink
        this.tutorials = _tutorials
    }

    /* async addQuestion(

        _QID: number,
        _topic: TopicModel

    ): Promise<QuestionModel> {

        try {

            const newQuestion = new QuestionModel(_topic);
            newQuestion.New(_QID)

            const MaxSequence = Math.max(...this.questions.map(x => x.sequence))
            newQuestion.sequence = MaxSequence + 1

            await newQuestion.save()
            this.questions.push(newQuestion)

            return Promise.resolve(newQuestion)

        }
        catch (err) {

            LogError(err)
            return Promise.reject(err)

        }

    } */
}