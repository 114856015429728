import { Box, SxProps, Typography } from "@mui/material"
import { Component, useEffect, useState } from "react"
import { IconListCheckmark } from "./XJIcons"
import { copySecondaryBold, copySecondaryRegular, theme, whiteDefault, whiteOverlay32, whiteOverlay60 } from "XJumpTheme"
import { ActionCallback } from "tools/Types"
import { uuid } from "tools/Utils"

const kOptionHeightPx = 40 

interface XJDropdownItemProps {

    title: string
    selected?: boolean
    icon?: Component

    id?: string 
    maxTitleLines: number          // 0 for no limit 

    onSelect: ActionCallback
}

function XJDropdownItem(props: XJDropdownItemProps) {

    const noLimitLines = props.maxTitleLines === 0

    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: kOptionHeightPx.toString() + 'px',
            my: 2, 
            px: 8,
            borderRadius: 1,
            ':hover': {
                backgroundColor: whiteOverlay32
            },
            ':active': {
                backgroundColor: whiteOverlay60
            },
            wordBreak: 'break-all'
        } as SxProps,
        title: {
            ...props.selected ? {...copySecondaryBold} : {...copySecondaryRegular},
            overflow: 'hidden', 
            display: "-webkit-box", 
            WebkitBoxOrient: noLimitLines ? undefined : "vertical", 
            WebkitLineClamp: noLimitLines ? undefined : props.maxTitleLines
        } as SxProps,
        icon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        } as SxProps
    }

    return (
        <Box id={props.id} sx={styles.root} onClick={props.onSelect}>
            {props.icon}
            <Typography sx={styles.title} textOverflow={'ellipsis'}>{props.title}</Typography>
            <Box sx={styles.icon}>
                {props.selected ? <IconListCheckmark /> : null}
            </Box>
        </Box>
    )
}

interface XJDropdownProps<Type> {

    options: {value: Type, title: string, icon?: Component}[]
    value: Type

    sx?: SxProps
    maxTitleLines?: number          // 0 for no limit  

    onSelect?: (value: Type) => void 
}

export function XJDropdown<Type>(props: XJDropdownProps<Type>) {

    const [id, _] = useState<string>(uuid())

    let maxLines = 1
    if (props.maxTitleLines !== undefined) {
        maxLines = props.maxTitleLines
    }
    
    useEffect(() => {
        const index = props.options.findIndex(option => option.value === props.value)
        if (index >= 0) {
            const element = document.getElementById(_dropdownItemId(index))
            element?.scrollIntoView(false)
        }
    }, [])

    const onSelect = (value: Type) => {
        props.onSelect ? props.onSelect(value) : undefined
    }


    // MARK: - Utils 

    const _dropdownId = () => {
        return 'XJDropdownID-' + id
    }

    const _dropdownItemId = (index: number) => {
        return 'XJDropdownID-' + id + '-Item-' + index.toString()
    }


    // MARK: - Render

    const styles = {
        root: {
            height: (props.options.length * kOptionHeightPx + 24).toString() + 'px',
            minWidth: '100px',
            width: '100%',
            zIndex: theme.zIndex.dropdown,

            backgroundColor: whiteDefault,
            boxShadow: theme.dropshadow.dropdown.default,
            py: 2,
            px: 6,
            overflow: 'scroll',
            borderRadius: 2,

            ...props.sx
        } as SxProps
    }

    return (
        <Box sx={styles.root} id={_dropdownId()}>
            {props.options.map((value, index) => {
                return (
                    <XJDropdownItem 
                        id={_dropdownItemId(index)}

                        key={'dropdownItem' + index.toString()}
                        title={value.title}
                        icon={value.icon}
                        selected={value.value === props.value}

                        maxTitleLines={maxLines}

                        onSelect={() => {onSelect(value.value)}}
                    />
                )
            })}
        </Box>
    )
}