import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { denotiveErrorDefault, denotiveErrorLight90, denotiveInfoDefault, denotiveInfoLight90, primaryDefault, primaryLight90 } from "XJumpTheme";




export enum StatusType {
    info,
    error,
    done
}


export default function XJStatusBadge(props: { text: string, type: StatusType, icon?: ReactNode }) {

    let backColor = primaryLight90
    let textColor = primaryDefault

    const style: SxProps =
    {

        borderRadius: '2px',
        paddingY: 2,
        paddingRight: props.icon ? 6 : 8,
        paddingLeft: 8,
        textTransform: 'uppercase',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        justifyContent: 'space-around'

    }

    switch (props.type) {

        case StatusType.done:
            backColor = primaryLight90
            textColor = primaryDefault
            break


        case StatusType.error:
            backColor = denotiveErrorLight90
            textColor = denotiveErrorDefault
            break

        case StatusType.info:

            backColor = denotiveInfoLight90
            textColor = denotiveInfoDefault
            break

    }

    return <Box

        sx={{ ...style, backgroundColor: backColor, color: textColor }}>

        <Typography variant='h2' color={textColor}>
            {props.text}
        </Typography>

        {
            props.icon ? <Box display={'flex'} alignItems={'center'}>{props.icon}</Box> : null
        }

    </Box>


}