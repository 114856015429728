import { Box, Grid, Link, OutlinedInput, Skeleton, Typography } from "@mui/material"
import { AIStatus, QuestionType } from "Common/Enums"
import { ScriptDAO } from "Common/ScriptDAO"
import { ApproveAIScripts, GetAnswerClarificationFromUser, SendSegmnetToAI } from "Controllers/AdminController"
import { useAlert } from "hooks/UseAlert"
import { usePitches } from "hooks/UsePitches"
import { PitchModel } from "Model/PitchModel"
import { QuestionModel } from "Model/QuestionModel"
import { SegmentModel } from "Model/SegmentModel"
import {  useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs"
import {  XJButton } from "UI Elements/Buttons/XJButton"
import { XJSingleInputForm } from "UI Elements/PopUps/XJSingleInputForm"
import { copyPrimaryBold, Header, HeaderRightButtons, PageTitle, secondaryDefault, whiteOverlay40 } from "XJumpTheme"
import { RoutingController } from "Controllers/RoutingController"




function RenderTextBox(props: { script: scriptType, handleChange: (sequence: number, newScript: string) => void }) {


    const [AIprompt, setAIPrompt] = useState(props.script.prompt)

    const [showAnswers, setShowAnswers] = useState(false)

    const sendToAI = () => {

        SendSegmnetToAI(props.script.segmentId, AIprompt).then(res => {

            props.handleChange(props.script.sequence, res)
        })

    }



    const EdituserAnswerLink = (q: QuestionModel): JSX.Element => {



        const [showClarificationDialog, setShowClarificationDialog] = useState(false)

        const alertSuccess = useAlert().success
        const alertError = useAlert ().error

        let answer = ''

        if (q.answer) {

            if (q.type === QuestionType.select) {
                answer = q.qMultipleAnswers![Number(q.answer.value)].title

            }
            else {
                answer = q.answer.value
            }

        }

        return <>
            <Link
                underline={'none'}
                sx={{ ...copyPrimaryBold, 
                    color: secondaryDefault, 
                    whiteSpace: 'break-spaces' 
                }}
                onClick={() => { setShowClarificationDialog(true) }}
            >
                {answer}
            </Link>

            <XJSingleInputForm
                open={showClarificationDialog}
                title={"Get answer clarification"}
                inputFieldTitle={"Ask clarification question"}
                inputFieldInitValue={""}

                onOK={(clarification) => {

                    GetAnswerClarificationFromUser(q.topic.segment.pitch.id!, q.id!, clarification)
                        .then(
                            (res) => {
                                setShowClarificationDialog(false)
                                alertSuccess("sent a clarification request")
                            }
                    )
                        .catch((err) => {
                            

                            setShowClarificationDialog(false)
                            alertError("Error sending the reuqest")

                    })
                }

                }

                onCancel={() => {

                    setShowClarificationDialog(false)

                }} />

        </>

    }


    return <Grid item xs={12}>

        <Box display={'flex'}>

            <Box flexGrow={1}>


                <Typography variant='h1'>
                    {props.script.title}
                </Typography>

                <Box mt={8}>
                    <Typography variant="h3">Prompt</Typography>
                    <Box display={'flex'}>


                        <OutlinedInput
                            key={`scriptPrompt${props.script.sequence}`}
                            color='secondary'
                            fullWidth={true}
                            multiline={true}
                            rows={2}
                            value={AIprompt}
                            onChange={(e) => { setAIPrompt(e.target.value) }}
                        />

                        <XJButton
                            variant={'tertiary'}
                            onClick={sendToAI}

                        >AI refresh</XJButton>

                        <XJButton variant={'tertiary'}
                            onClick={() => {

                                setShowAnswers((value) => !value)

                            }}>Answers</XJButton>


                    </Box>


                </Box>

                <Box mb={16}>
                    <Typography variant="h3">text</Typography>
                    <OutlinedInput
                        id="outlined-multiline-static"
                        value={props.script.text}
                        key={`script${props.script.sequence}`}
                        color='secondary'
                        multiline
                        minRows={8}
                        fullWidth={true}
                        onChange={e => props.handleChange(props.script.sequence, e.target.value)}

                    />
                </Box>

            </Box>
            <Box m={16} width='35%' display={showAnswers ? 'block' : 'none'}>
                {props.script.segment.topics.map(t => {

                    return t.questions.map(q => {

                        return <Box key={`topic${t.id}Q${q.id}`} display={'flex'} alignItems={'center'} >

                            <Box>
                                {q.title}
                            </Box>

                            <Box mb={4} py={2} px={4} sx={{ background: whiteOverlay40, borderRadius: '2px', cursor: 'pointer' }} >

                                {EdituserAnswerLink(q)}

                            </Box>


                        </Box>

                    })

                })}
            </Box>
        </Box>
    </Grid >


}


const RenderPending = (title: string, seqeunce: number) => {


    return <Grid key={'renderPending' + seqeunce} item xs={12}>

        <Typography variant='h1'>
            {title}
        </Typography>

        <Box>

            <Typography variant="h3">Pending AI processing</Typography>

        </Box>
    </Grid>


}


type scriptType =
    {
        sequence: number,
        text: string,
        title: string,
        segmentId: number,
        prompt: string,
        segment: SegmentModel
    }

export function ScriptReview() {


    const pitchHook = usePitches()

    const [SelectedPitch, setSelectedPitch] = useState<PitchModel | null>(null)
    const { pid } = useParams<any>()

    const SuccessAlert = useAlert().success

    useEffect(() => {

        if (pid) {
            const pitch = pitchHook.getPendingPitch(pid)
            setSelectedPitch(pitch)
        }

    }, [pid])


    const history = useHistory ()

    const [scripts, setScripts] = useState<scriptType[]>([])

    useEffect(() => {

        if (SelectedPitch) {
            const newScripts = new Array<scriptType>()

            for (const s of SelectedPitch.segments.filter(x => x.ScriptAIRequestStatus === AIStatus.pendingManualReview || x.ScriptAIRequestStatus === AIStatus.sent)) {
                const script = {

                    sequence: s.sequence,
                    text: s.ScriptAIRequestStatus === AIStatus.pendingManualReview ? s.automatedScript : null,
                    title: s.title,
                    segmentId: s.id,
                    prompt: s.AIPrompt,
                    segment: s

                } as scriptType

                newScripts.push(script)
            }

            setScripts([...newScripts])
        }


    }, [SelectedPitch])


    const handleOnChange = (sequence: number, newScript: string) => {

        setScripts(scripts => {
            const script = scripts.find(x => x.sequence === sequence) 
            if (script) {
                script.text = newScript
            }
            
            return [...scripts]

        })
    }

    const onSave = () => {

        const updatedScripts = scripts.filter(x => x.text !== null).map((x) => {

            const newScript: ScriptDAO =
            {
                segmentId: x.segmentId,
                script: x.text
            }

            return newScript
        })

        if (SelectedPitch && SelectedPitch.id) {
            ApproveAIScripts(SelectedPitch.id, updatedScripts as ScriptDAO[])
                .then(res => {
                    SuccessAlert("scripts saved")
                    history.push (RoutingController.AdminManualReview())
                })
        }

    }


    return <>
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
            </Grid>
            <Grid item xs={12}>
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Script Manual Review
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box sx={Header}>
                    <Typography variant="h1">
                        {SelectedPitch ? SelectedPitch.title : <Skeleton />}
                    </Typography>
                    <Box sx={HeaderRightButtons}>
                        <XJButton variant={'secondary'} onClick={onSave} >
                            Save
                        </XJButton>
                    </Box>
                </Box>

            </Grid>

            <Grid item xs={12}>

                {scripts?.sort((a, b) => {

                    if (a.sequence < b.sequence) return -1; else return 1

                }).map((s,i) => {

                    return s.text !== null ? <RenderTextBox  key={'scriptedit' + i} script={s} handleChange={handleOnChange} /> : RenderPending(s.title, s.sequence)

                }
                )}

            </Grid >
        </Grid>

    </>



}