import { EmptyPageMessage } from "./EmptyPageMessage";

import { ReactComponent as InfoIcon } from '../../svg/Info.svg'


export function LoadingPitch() 
{

    return <EmptyPageMessage
    
        title="Loading..."
        message=""    
        mainIcon={<InfoIcon/>}
    
    />



}