import { QuestionModel } from "./QuestionModel";


export class AnswerModel {

    constructor(
        _question: QuestionModel,
        _value: any
        

    ) {
        this.qId = _question.QuestionContentId;
        this.value = _value;
        this.question = _question;
    }

    qId: number;
    value: any;
    question: QuestionModel;
    LastUpdate: Date

}