import { Box, IconButton, SxProps } from '@mui/material'
import React from 'react'

import {  secondaryDefault, secondaryLight60, secondaryLight75, secondaryShade20, secondaryShade40, whiteDefault, whiteOverlay32, whiteOverlay60 } from 'XJumpTheme'

interface IXJMenuButtonrProps {


    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    children: React.ReactNode,
    variant?: 'primary' | 'secondary',
    disabled?: boolean,
    sx?: SxProps

}


export function XJMenuButtonRound(props: IXJMenuButtonrProps) {

    const Sx = {

        color: props.variant === 'primary' ? whiteDefault : secondaryDefault,
        background: 'inherit',
        borderRadius: '48px',
        border: '2px',
        padding: '8px',
        margin: 0,
        width: '40px',
        height: '40px',

        ':focus-visible': { 
            background: 'inherit', 
            outline: '2px solid ' + secondaryLight60 
        },

        ':hover':
        {
            background: props.variant === 'primary' ? secondaryShade20 : whiteOverlay32

        },

        ':hover:focus-visible':
        {
            background: props.variant === 'primary' ? secondaryShade20 : whiteOverlay32,
            outline: '2px solid ' + secondaryLight60 
        },

        ':active':
        {
            background: props.variant === 'primary' ? secondaryShade40 : whiteOverlay60
        },

        ...props.sx
    }


    if (props.disabled) {

        Sx.color = secondaryLight75
        Sx.background = 'inherit'
    }


    return <IconButton sx={Sx} onClick={props.onClick}  disabled = {props.disabled} >
        <Box width={'24px'} height={'24px'} display={'flex'} alignContent={'center'} justifyContent={'center'} flexWrap={'wrap'}>
            {props.children}
        </Box>

    </IconButton>


}