import { usePitchuraLocalStorage } from "./UsePitchuraLocalStorage"

export type ShouldShowFunction = (id: ExplainerID, ignoreCount?: boolean) => boolean

interface IUseExplainerShouldShow {

    ExplainerState: (id: ExplainerID) => ExplainerState
    ShouldShow: ShouldShowFunction
    ShowedToUser: (id: ExplainerID) => void,
    ResetShownCounter: (id: ExplainerID) => void,
    IsLastTime: (id: ExplainerID) => boolean,
    DontShowAgain: (id: ExplainerID, dontShow: boolean) => void
}


export enum ExplainerID {

    VideoRecordingSetup,
    VideoEditor,
    SharingLinks,
    Teleprompter,
    Dashboard,
    PitchStudio 

}

export type ExplainerState = {
    id: ExplainerID
    dontShowAgain: boolean
    numberOfTimesShowed: number
    maxTimesToShow: number 
}

const getMaxTimesToShow = (id: ExplainerID) => {
    let result = 3

    switch (id) {
        case ExplainerID.Dashboard:
            result = 1
            break;

        case ExplainerID.PitchStudio:
            result = 1
            break;
    
        default:
            break;
    }

    return result
}

const DEBUG_EXPLAINERS = Boolean(Number(process.env.REACT_APP_ALWAYS_SHOW_EXPLAINER))

export default function useExplainerShouldShow(): IUseExplainerShouldShow {

    const localStorage = usePitchuraLocalStorage()

    const ExplainerState = (id: ExplainerID) => {
        let explainerState = localStorage.explainer(id)
        
        if (!explainerState) {
            explainerState = _getDefaultExplainer(id)
        }

        return explainerState
    }

    const ShouldShow = (explainerId: ExplainerID, ignoreCount?: boolean): boolean => {

        if (DEBUG_EXPLAINERS) return true 
        
        let result = true 

        const explainer = localStorage.explainer(explainerId)

        if (explainer) {
            result = !explainer.dontShowAgain && (explainer.numberOfTimesShowed < explainer.maxTimesToShow || ignoreCount === true)
        }

        return result
    }

    const ShowedToUser = (id: ExplainerID) => {

        if (DEBUG_EXPLAINERS) return true 

        let explainer = localStorage.explainer(id)

        if (!explainer) {
            explainer = _getDefaultExplainer(id)
        }
        
        explainer.numberOfTimesShowed = explainer.numberOfTimesShowed + 1
        localStorage.updateExplainer(explainer)
    }

    const ResetShownCounter = (id: ExplainerID) => {
        
        const explainer = localStorage.explainer(id)
        if (explainer) {
            explainer.numberOfTimesShowed = 0
            localStorage.updateExplainer(explainer)
        }
    }

    const IsLastTime = (id: ExplainerID) => {
        let explainer = localStorage.explainer(id)
        if (!explainer) {
            explainer = _getDefaultExplainer(id)
        }
        // before showed for the last time 
        return explainer.numberOfTimesShowed === explainer.maxTimesToShow
    }

    const DontShowAgain = (id: ExplainerID, dontShow: boolean) => {

        let explainer = localStorage.explainer(id)

        if (!explainer) {
            explainer = _getDefaultExplainer(id)
        }
        
        explainer.dontShowAgain = dontShow
        localStorage.updateExplainer(explainer)
    }

    const _getDefaultExplainer = (id: ExplainerID) => {
        return {

            id: id,
            dontShowAgain: false,
            numberOfTimesShowed: 0,
            maxTimesToShow: getMaxTimesToShow(id)

        } as ExplainerState
    }

    return {

        ExplainerState, 
        ShouldShow,
        ShowedToUser,
        ResetShownCounter,
        IsLastTime,
        DontShowAgain

    } as IUseExplainerShouldShow
}