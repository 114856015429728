import { Divider } from "@mui/material"
import { theme } from "XJumpTheme"



export default function XJVerticalDivider ()
{
    const dividerSx = { height: theme.spacing(12), my: 'auto', color: 'inherit' }

    return  <Divider flexItem={true} orientation="vertical" sx={dividerSx} />

}