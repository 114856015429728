import { useEffect, useState } from "react"
import { Box } from "@mui/system"
import { Card, CardContent, Collapse, Typography } from "@mui/material"
import XJNumberTextfield from "UI Elements/XJNumberTextfield"
import { VisualAidModel } from "Model/VisualAidModel"
import { hasElementInTreeWithId } from "tools/Utils"
import React from "react"
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon, PenIcon, VisibilityOffIcon, VisibilityOnIcon, VisualAidCardThreeDotsIcon, VisualAidSlideIcon } from "UI Elements/XJIcons"
import XJImage from "UI Elements/XJImage"
import XJVerticalDivider from "UI Elements/XJVerticalDivider"
import { AidCallback } from "./VideoEditing"
import {  XJMenuButton } from "UI Elements/Buttons/XJMenuButton"


interface VisualAidCardProps {
    aid: VisualAidModel

    onAidSelected?: AidCallback
    onMoreClicked?: AidCallback
    onAidChanged?: AidCallback
    onAidDelete: AidCallback
}

export function VisualAidCard(props: VisualAidCardProps) {

    const aid = props.aid



    // Events  

    const onCardClick = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLElement
        if (target && !hasActiveElement(target)) {
            props.onAidSelected && props.onAidSelected(aid)
        }
    }

    const onAidVisibilityToggle = (event: React.MouseEvent<HTMLElement>) => {
        aid.isVisible = !aid.isVisible
        props.onAidChanged && props.onAidChanged(aid)
    }

    const onMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        props.onMoreClicked && props.onMoreClicked(aid)
    }


    // Duration Input 

    const validateDuration = (value: string) => {

        let result = false
        const duration = Number(value)

        if (!isNaN(duration)) {
            const video = aid.video
            if (video && video.isValidPositionForAid(aid, aid.startsAt, duration)) {
                result = true
            }
        }

        return result
    }



    const applyValue = (value: string) => {
        const newDuration = Number(value)
        if (!isNaN(newDuration)) {
            aid.duration = newDuration
            props.onAidChanged && props.onAidChanged(aid)
        } else {
            // TODO: log error 
            console.log("ERROR! Applies invalid value: " + value)
        }
    }

    const onDurationTextFieldFocus = () => {
        props.onAidSelected && props.onAidSelected(aid)
    }

    const formatDurationValue = (value: number) => {
        return value.toFixed(2).toString()
    }


    const onDelete = () =>
    {
        
        props.onAidDelete (aid)
    }


    // Utils 


    const kDurationTextFieldID = "durationTextField"
    const kShowHideButtonID = "showHide"
    const kMoreButtonID = "more"

    const hasActiveElement = (element: HTMLElement) => {
        let result = false
        const activeElementIDs = [kDurationTextFieldID, kShowHideButtonID, kMoreButtonID]
        for (const activeId of activeElementIDs) {
            if (hasElementInTreeWithId(element, activeId)) {
                result = true
                break
            }
        }

        return result
    }


    // Render

    // const [expanded, setExpanded] = useState(false)

    const ActionIcons = () => {
        return <>
            <Box display={'flex'} justifyContent={'flex-end'} height={'100%'} gap={2} >

                <XJMenuButton variant={'secondary'} onClick={onAidVisibilityToggle} >
                    {aid.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                </XJMenuButton>

                <XJVerticalDivider />

                <XJMenuButton variant={'secondary'} onClick={onMoreClick} >
                    <PenIcon />
                </XJMenuButton>
                <XJVerticalDivider />

                <XJMenuButton onClick={onDelete} variant={'secondary'}>
                    <DeleteIcon />
                </XJMenuButton>

            </Box>
        </>

    }


    return (<>

        <Card sx={{ px: 8, pb: 0, pt: 0, mt: 8, opacity: aid.isVisible ? 1.0 : 0.5 }} key={aid.id} onClick={onCardClick}>

            <CardContent sx={{ pb: 0 }}>

                <Box  >

                    <Box py={2} display={'flex'} justifyContent='space-between' >
                        <Box display={'flex'} gap={'10px'} alignItems={'center'}  >
                            <VisualAidSlideIcon />
                            <Typography color={'inherit'} variant="h3" sx={{ ml: '11px', mr: '22px' }} >Slide </Typography>
                            {/* <Box display={'flex'}>
                                <XJMenuButton variant={Menu'secondary'} onClick={() => { setExpanded(!expanded) }} >
                                    {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </XJMenuButton>
                            </Box> */}
                        </Box>
                        <Box><ActionIcons /></Box>

                    </Box>

                    <Box>
                        {/* <Collapse in={expanded} > */}
                        <Box display={'flex'} pt={0} gap={'16px'} flexWrap={'wrap'} pb={8}>
                            <Box sx={{ width: '144px', height: '100px' }} >
                                <XJImage src={props.aid.url} showBorder></XJImage>
                            </Box>

                            <Box sx={{ maxWidth: '130px' }}>
                                <Typography sx={{ mb: 8 }} variant="h3">Show for:</Typography>

                                <XJNumberTextfield

                                    key={aid.id + 'duration'}
                                    id={kDurationTextFieldID}
                                    value={aid.duration}
                                    step={1}
                                    EndAdornment="Sec"
                                    errorMessage={() => { return "Invalid value" }}

                                    validation={validateDuration}
                                    format={formatDurationValue}


                                    onFocus={onDurationTextFieldFocus}
                                    onFinish={applyValue}

                                />
                            </Box>


                        </Box>
                        {/* </Collapse> */}
                    </Box>

                    {/*  <Box mt={'auto'} mb={'auto'}><ActionIcons /></Box> */}
                </Box>
            </CardContent>
        </Card >
    </>)
}



