import { VideoModel } from './VideoModel'
import { VisualAidDAO } from 'Common/VisualAidDAO'
import API from 'api-axios'
import { MediaStatus, MediaType } from 'Common/Enums'
import { uuid } from 'tools/Utils'
import { MediaModel } from './MediaModel'
import { cloneDeep } from 'lodash'
import { MediaUploadStatus, uploaderRefType } from 'Components/XJMediaUpload'
import { LogError } from 'Controllers/Logging'


export type VisualAidKeys = keyof typeof VisualAidModel.prototype

export enum VisualAidPIPPosition {
    leftBottom,
    leftTop,
    rightBottom,
    rightTop
}

//const mutexRealm = new MutexRealm<string>()


export class VisualAidModel {

    private _id: number | undefined

    private _startsAt = 0
    private _duration = 0
    private _hasCorrectPosition = true
    private _lastCorrectMovingStartPoint = -1   // used on moving to re-order, 
    // -1 means it wasn't initialized with a drag; 
    private _width = 0
    private _height = 0

    private _PIPPosition: VisualAidPIPPosition = VisualAidPIPPosition.rightBottom
    private _showInPIP = false
    private _isVisible = true
    private _removed = false
    private _video: VideoModel


    private _localID: string = uuid()      // a handle for aids that weren't saved local to the session and array the aid is in        

    private _media: MediaModel | undefined
    // private _pendingMedia: MediaModel | undefined        

    public set ServerUrl(url: string) {
        this.media.setServerUrl(url)
    }

    constructor(video: VideoModel) {
        this._video = video

    }

    public uploaderRef: uploaderRefType

    public clone() {
        return cloneDeep(this)
    }

    private get media(): MediaModel {
        //  if (this._pendingMedia) return this._pendingMedia
        if (this._media) return this._media

        const newMedia = new MediaModel()
        this._media = newMedia

        return this._media
    }

    /*  public get hasPendingMedia(): boolean {
         return this._pendingMedia !== undefined
     } */
    /* 
        public get uploadId(): string {
            return this.media.uploadId ?? ''
        } */


    get mediaStatus() {
        return this.media.mediaStatus ?? MediaStatus.undefinded
    }

    set mediaStatus(status: MediaStatus) {
        this.media.mediaStatus = status
    }

    get localBlob() {
        return this.media.localBlob ? this.media.localBlob : null
    }

    get extension() {
        return this.media.extension ? this.media.extension : ''
    }

    get type() {
        return this.media.type ? this.media.type : MediaType.undefined
    }

    set type(val: MediaType) {
        this.media.type = val
    }

    public get url(): string {
        let result = ""

        if (this.media.localBlobUrl) {
            result = this.media.localBlobUrl
        } else if (this.media.ServerUrl) {
            result = this.media.ServerUrl!
        }

        return result
    }

    public mediaUrl = ''


    public get localBlobUrl(): string {
        if (this.media) {
            return this.media.localBlobUrl
        }

        return ''
    }

    public async updateMedia
        (
            mediaMemoryUrl: string,
            type: MediaType,
            extension: string,
            localFileName: string,
            onSuccess: (needsSaving: boolean) => void
        ) 
    {

        const newMedia = new MediaModel()
        this._media = newMedia

        const onFinish = () => {

            // checking if the model needs to be synced with the backend 
            let needsSaving = false 
            if (this.media.uploadId && this.uploaderRef.current) {
                const uploadEntry = this.uploaderRef.current.getEntry(this.media.uploadId)
                if (uploadEntry && uploadEntry.entityNeedsSaving) {
                    needsSaving = true 
                } 
            }

            onSuccess(needsSaving)
        }

        await fetch(mediaMemoryUrl)
            .then(async res => {
                const blob = res.blob()
                newMedia.assignMedia(mediaMemoryUrl, await blob, type, extension, localFileName, this.uploaderRef, onFinish)
            })
    }


    // MARK: - API

    public packDAO(): VisualAidDAO {
        return {
            id: this.id,
            type: this.type,
            startsAt: this.startsAt,
            duration: this.duration,
            width: this.dimensions.width,
            height: this.dimensions.height,
            isVisible: this.isVisible,
            showPIP: this.showInPIP,
            PIPPosition: this.PIPPosition,
            mediaStatus: this.mediaStatus,
            fileName: this.fileName, 
            isRemoved: this.isRemoved
        } as VisualAidDAO
    }

    public static load(aid: VisualAidDAO, video: VideoModel) {
        const result = new VisualAidModel(video)
        result._id = aid.id
        result._startsAt = aid.startsAt
        result._duration = aid.duration

        result.type = aid.type

        result.fileName = aid.fileName
        result.media.mediaStatus = aid.mediaStatus

        result.dimension = { width: aid.width, height: aid.height }

        result.showInPIP = aid.showPIP
        result.isVisible = aid.isVisible
        result.PIPPosition = aid.PIPPosition as VisualAidPIPPosition
        result.isRemoved = aid.isRemoved

        return result
    }

    updateNewAidID(id: number) {
        this._id = id
    }

    public async save() {


        try {

            if (this.video && this.video.segment && this.media) {

                if (this.media.uploadId && this.uploaderRef.current) {
                    const uploadEntry = this.uploaderRef.current.getEntry(this.media.uploadId)

                    // if upload is on - set the flag for syncing on finishing 
                    if (uploadEntry && 
                        (uploadEntry.status === MediaUploadStatus.started || uploadEntry.status === MediaUploadStatus.Progressing)) 
                    {
                        uploadEntry.entityNeedsSaving = true 
                    }
                }

                const dao = this.packDAO()

                if (!this.id) {
                    // create
                    this._id = await (await API.post(`/pitch/${this.video.segment.pitch.id}/segment/${this.video.segment.id}/video/${this.video.id}/visualaid/create`, dao)).data.id
                } else {
                    // update
                    await API.post(`/pitch/${this.video.segment.pitch.id}/segment/${this.video.segment.id}/video/${this.video.id}/visualaid/${this.id}/update`, dao)
                }

            } else {

                // getting here should never happen 
                LogError("Visual Aid save didnt have a video, segment or media  " + this.id)
                throw Error("Visual Aid save didnt have a video, segment or media. id: " + this.id)

            }


        } catch (error) {

            LogError(error)
            throw Error("Visual Aid save didnt have a video, segment or media. id: " + this.id)
        }

    }



    // MARK: - Getters / Setters 

    get id() {
        return this._id
    }

    get startsAt() {
        return this._startsAt
    }

    set startsAt(startTime: number) {
        this._startsAt = startTime
        this.video && this.video.sortVisualAids()
    }

    get duration() {
        return this._duration
    }

    set duration(duration: number) {
        this._duration = duration
    }

    get endsAt() {
        return this._startsAt + this._duration
    }

    get hasCorrectPosition() {
        return this._hasCorrectPosition
    }

    set hasCorrectPosition(isCorrectPosition: boolean) {
        this._hasCorrectPosition = isCorrectPosition
        if (isCorrectPosition) {
            this._lastCorrectMovingStartPoint = this._startsAt
        }
    }


    public get fileName(): string {
        return this.media.fileName ? this.media.fileName : ''
    }

    set fileName(fileName: string) {

        if (!this.media) {
            this._media = new MediaModel()
        }
        this.media!.fileName = fileName

    }

    get dimensions() {
        return {
            width: this._width,
            height: this._height
        }
    }

    set dimension(dims: { width: number, height: number }) {
        this._width = dims.width
        this._height = dims.height
    }

    get isVisible() {
        return this._isVisible
    }

    set isVisible(isVisible: boolean) {
        this._isVisible = isVisible
    }

    get isRemoved() {
        return this._removed
    }

    set isRemoved(removed: boolean) {
        this._removed = removed
    }

    get showInPIP() {
        return this._showInPIP
    }

    set showInPIP(showPIP: boolean) {
        this._showInPIP = showPIP
    }

    get PIPPosition() {
        return this._PIPPosition
    }

    set PIPPosition(position: VisualAidPIPPosition) {
        this._PIPPosition = position
    }

    get video() {
        return this._video
    }

    set video(video: VideoModel) {
        this._video = video
    }

    get localID() {
        return this._localID
    }


    // MARK: - Functions 

    // TODO: make request-url-as-needed style 
    // probably with loading, same as video url does 
    // OR figure out logic of loading state, i.e if one of the aids 
    // unavailable - does it fails whole video preload and ability to be played? 

    useLastCorrectPosition() {
        if (this._lastCorrectMovingStartPoint >= 0) {
            this.startsAt = this._lastCorrectMovingStartPoint
            this.hasCorrectPosition = true
        }
    }

}