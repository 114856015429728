import React from 'react'

import { dropshadowButtonDefault, primaryDefault, primaryDefaultOverlay32, secondaryDefault, secondaryLight60, secondaryLight75, secondaryLight90, whiteDefault, whiteOverlay32 } from 'XJumpTheme'
import {  XJMenuButtonRound } from './XJMenuButtonRound'
import { PlayerIconPause, PlayerIconPlay, PlayerIconReplay, PlayerIconStop } from 'UI Elements/XJIcons'

interface IXJButtonPlayerProps {

    variant?: PlayerButtonVariant
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean

}

export enum PlayerButtonVariant {
    play,
    pause,
    stop,
    replay  
}

export function XJPlayerButton(props: IXJButtonPlayerProps) {

    const whichIcon = () => {
        switch (props.variant) {

            case PlayerButtonVariant.play:
                {
                    return <PlayerIconPlay />
                }

            case PlayerButtonVariant.pause:
                {
                    return <PlayerIconPause />
                }

            case PlayerButtonVariant.stop:
                {
                    return <PlayerIconStop />
                }

            case PlayerButtonVariant.replay:
                {
                    return <PlayerIconReplay />
                } 
        }
    }

    const Sx = {

        color: secondaryDefault,
        background: whiteDefault,
       
        width: '48px',
        height: '48px',
        
        boxShadow: dropshadowButtonDefault,

        ":focus-visible" :
        {
            outline: '2px solid ' + secondaryLight60 
        },

        ':hover' : 
        {
            color: primaryDefault,
            background: whiteOverlay32,    
        },

        ":hover:focus-visible" :
        {
            color: primaryDefault,
            background: whiteOverlay32,
            outline: '2px solid ' + secondaryLight60 
        },

        ":active" :
        {
            color: primaryDefaultOverlay32
        }, 
        ":disabled":
        {
            color: secondaryLight75,
            background: secondaryLight90
        } 

    }

    return <XJMenuButtonRound sx={Sx} onClick={props.onClick} variant={'primary'} disabled={props.disabled} >
        {whichIcon()}
    </XJMenuButtonRound>


}