import { Box, List, ListItem, ListItemText, SxProps, TextField, Typography } from "@mui/material"
import { SystemQuestions } from "Controllers/SystemQuestionsController"
import { TopicContentModel } from "Model/TopicContentModel"

import { Dispatch, useEffect, useState } from "react"


interface ITopicsListProps {
    selectedTopic: TopicContentModel | undefined
    setSelectedTopic: Dispatch<React.SetStateAction<TopicContentModel | undefined>>
    onOpenEditor: () => void

}

export default function TopicsList(props: ITopicsListProps) {

    const allTopics = SystemQuestions.topics
    const [Topics, setTopics] = useState(allTopics)

    const [topicFilter, setTopicFilter] = useState<string>('')
    const [segmentFilter, setSegmentFilter] = useState<string>('')

    const HighlightedItem: SxProps =
    {
        backgroundColor: 'black',
        color: 'white !important'
    }

   /*  useEffect(() => {
        setTopics(allTopics.filter(
            x =>
            {
                const titleMatch = x.title && x.title.length > 0 ? x.title.toLowerCase().includes(TopicFilter.trim().toLowerCase()) : false

                const topicMatch = x.Topic && x.Topic.length > 0 ? x.Topic.toLowerCase().includes(topicFilter.trim().toLowerCase()) : false

                const segmentMatch = x.Segment && x.Segment.length > 0 ? x.Segment.toLowerCase().includes(segmentFilter.trim().toLowerCase()) : false

                return (titleMatch || !TopicFilter || TopicFilter.length === 0) && (topicMatch || !topicFilter || topicFilter.length === 0)  && (segmentMatch || !segmentFilter || segmentFilter.length === 0)
            }
                
        ))
        

}, [TopicFilter, topicFilter, segmentFilter]) */

/* 
    const FilterTextBox = () => {
        return <Box display={'flex'} gap={32}>

            <TextField
                label='filter'
                value={TopicFilter}
                inputProps={{ key: 'TopicFilter1' }}
                id='Topicsfilter'
                size="small"
                name='TopicsFilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setTopicFilter(e.currentTarget.value)

                }}

            /> */
/* 
<TextField
                label='segment'
                value={segmentFilter}
                inputProps={{ key: 'TopicFilter3' }}
                id='segmentFilter'
                size="small"
                name='segmentFilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setSegmentFilter(e.currentTarget.value)

                }}

            />
 */

         /*    <TextField
                label='topic'
                value={topicFilter}
                inputProps={{ key: 'TopicFilter2' }}
                id='topicfilter'
                size="small"
                name='topicfilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setTopicFilter(e.currentTarget.value)

                }}

            />


        </Box>

 */


    

    const OnClickTopicListTopic = (QID: number, openEdit?: boolean) => {

        const Q = Topics!.find(q => q.id === QID)
        if (Q) props.setSelectedTopic(Q)

        if (openEdit) {

            props.onOpenEditor()
        }

    }


    return <>

      {/*   <Box display={'flex'}>
            <Typography variant="h3">All Topics</Typography>
            {FilterTextBox()
            }</Box>
 */}
        <List>
            {
                Topics!.sort((a, b) => {

                    if (a.id === undefined) return 1
                    if (b.id === undefined) return -1
                    if (a.id > b.id) return -1

                    return 1
                })
                    .map((x: TopicContentModel) => {
                        return <ListItem
                            onDoubleClick={() => {
                                OnClickTopicListTopic(x.id!, true)

                            }}
                            key={`Topic_qid_${x.id}`}
                            sx={props.selectedTopic === x ? HighlightedItem : null}
                            onClick={() => {

                                OnClickTopicListTopic(x.id!)
                            }
                            }>

                            <ListItemText>
                                {x.title} 
                            </ListItemText>

                        </ListItem>
                    })
            }
        </List>

    </>
}