import { SxProps } from "@mui/material";
import { IXJLinkProps, XJLink } from "UI Elements/XJLink";

export function XJLinkButton(props: IXJLinkProps) {

    const sx = {
        ...props.sx,
        ':visited': undefined
    } as SxProps

    return (
        <XJLink 
            sx={sx}
            variant={props.variant} 

            to={props.to}
            onClick={props.onClick}

            newWindow={props.newWindow}
            color={props.color}
            disabled={props.disabled}
        >
            {props.children}
        </XJLink>
    )

}