import { SegmentModel } from 'Model/SegmentModel';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { theme } from 'XJumpTheme';

import { XJButton } from '../../../UI Elements/Buttons/XJButton';
import { Box, Card, CardContent, Typography, SxProps, Badge } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { SegmentStatus } from 'Common/Enums';
import { SegmentStatusCompleteIcon } from 'UI Elements/XJIcons';
import { RoutingController } from 'Controllers/RoutingController';


// Used in the segment editor view to show details of a segment 

export interface ISegmentCardProps {
    segment: SegmentModel
}

const root: SxProps = {

    marginLeft: 0,
    marginRight: 16,
    marginBottom: 16,
    //  minHeight: 200,
    padding: 12,
    color: theme.palette.lightPrimary.default,
    background: theme.palette.white.default,
    boxShadow: theme.dropshadow.card.default,
    width: 200,
    ':hover': {
        boxShadow: theme.dropshadow.card.hover,
    },
    ":active": {
        boxShadow: theme.dropshadow.card.pressed
    }
}

const dragButton: CSSProperties = {
    cursor: "pointer"
}

export function SegmentCard(segment: SegmentModel) {

    const segmentLink = RoutingController.SegmentEditor (segment)

    let ButtonTitle = "Continue"

    switch (segment.status) {
        case SegmentStatus.Script:
            ButtonTitle = "Record"
            break

        case SegmentStatus.Video:
            ButtonTitle = "Edit"
            break
    }

    return <Draggable key={segment.sequence} draggableId={`segmentlistkeyDD${segment.sequence}`} index={segment.sequence}>

        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (

            <div
                ref={providedDraggable.innerRef}
                {...providedDraggable.draggableProps}
            >

                <Box display='flex' flexDirection="column" >
                    

                    <Card sx={root} {...providedDraggable.dragHandleProps}>

                        <CardContent sx={{ height: '100%' }} >

                            <Box display={'flex'} flexDirection={'column'} gap={10} >

                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography mr={4} variant='h3' color={theme.palette.secondary.default}>
                                        {segment.displaySequence}. {segment.title}
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent={'space-between'} alignItems={'center'}  >

                                    <Box sx={{ ...theme.typography.secondary.regular, color: theme.palette.primary.dark }}>
                                        {segment.StatusDescription}
                                    </Box>

                                    {segment.status === SegmentStatus.Video && <SegmentStatusCompleteIcon color={theme.palette.primary.default } />}
                                </Box>
                                <Badge badgeContent={segment.showPitchuraScriptReady ? 'AI' : 0} color={'primary'}  >

                                    <XJButton to={segmentLink} width="100%" sx={{ margin: 0 }}
                                        variant={segment.status === SegmentStatus.Video ? 'tertiary' : 'secondary'}
                                    >
                                        {ButtonTitle}
                                    </XJButton>
                                </Badge>
                            </Box>
                        </CardContent>

                    </Card>

                </Box>

            </div>
        )}
    </Draggable >
}