import { Box, SxProps } from "@mui/material"
import { lightPrimaryOverlay32 } from "XJumpTheme"


interface IXJImageProps {

    src: string
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    showBorder?: boolean,
    sx?: SxProps
}


export default function XJImage(props: IXJImageProps) {

    

    return <Box
        onClick={props.onClick}
        sx={{
            ...(props.showBorder && { border: '1px solid ' + lightPrimaryOverlay32 }),
            borderRadius: '2px',
            backgroundImage: `url('${props.src}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            ...(props.sx)
            

        }} />


}