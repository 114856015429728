import { Box, Typography } from "@mui/material"




export function Terms() {

    return <Box m={16}>

        <Typography variant="h1">Terms of Service</Typography>

        Welcome to Pitchura!

        Thank you for choosing our video pitch platform. By using Pitchura, you agree to the following terms. Please read them carefully.


        <h3>1. Your Privacy and Confidentiality</h3>

        We understand the importance of keeping your information private. We take steps to protect your confidential information. However, please understand that no system is completely secure. By using Pitchura, you acknowledge and agree that we cannot guarantee absolute security.

        <h3>2. Content Ownership</h3>
        You retain all rights to any videos you upload or record. We do not claim ownership of your content. By uploading or recording videos, you grant us a non-exclusive license to host and share your videos on our platform.

        <h3>3. Security Measures</h3>

        We use standard security measures to protect your information. Despite our efforts, we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur. By using Pitchura, you accept this risk.

        <h3>4. Limitation of Liability</h3>
        In the event of a security breach, we will take appropriate action to address the issue. However, we are not liable for any loss or damage that may result from such breaches. This includes loss of data, financial loss, or any other damages.

        <h3>5. User Responsibilities</h3>
        You are responsible for keeping your account information secure. Do not share your password with others. If you suspect unauthorized use of your account, notify us immediately. You agree to not use Pitchura for any unlawful purposes.

        <h3>6. Prohibited Activities</h3>

        Do not use Pitchura for illegal activities, including but not limited to:
        <ul>
            <li>Promote any scams or unlawful offers</li>
            <li>Infringing on intellectual property rights</li>
            <li>Mispresent facts about your company</li>

        </ul>


        <h3>7. Termination of Service</h3>
        We reserve the right to terminate or suspend your account if you violate these terms or engage in prohibited activities. We also reserve the right to modify or discontinue our service at any time.

        <h3>8. Changes to Terms</h3>
        We may update these terms from time to time. We will notify you of any significant changes. Continued use of Pitchura after such changes indicates your acceptance of the new terms.

        <h3>9. Contact Us</h3>
        If you have any questions or concerns about these terms, please contact us at terms@pitchura.ai

        Thank you for using Pitchura. We appreciate your trust in us and are committed to providing a secure and enjoyable experience.


    </Box>




}
