import { Box, SxProps, Typography } from "@mui/material"
import { useAlert } from "hooks/UseAlert"
import { useAuth } from "hooks/UseAuth"
import { useContext } from "react"
import { XJLinkButton } from "UI Elements/Buttons/XJLinkButton"
import BigAlertContext from "UI Elements/XJBigAlerts"

const styles: SxProps =
{
    backgroundColor: 'yellow',
    display: 'flex',
    justifyItems: 'center',
    width: '100%',
    color: 'black'
}

export default function XJVerifyEmailBanner() {

    const sendEmail = useAuth().SendEmailVerification
    const emailVerified = useAuth().EmailVerifiedStatus
    const email = useAuth().user.User?.email

    const BigAlert = useContext (BigAlertContext)
    const alerts = useAlert()

    const onVerifyClick = () => {
        alerts.working()
        sendEmail(onVerifyEmailSent, errorSendingEmail)
    }

    const onVerifyEmailSent = () => {
        alerts.clearWorking()
      //  alerts.success(`Email verification sent to ${email}`)

        BigAlert.success (`We sent a verification email to ${email}. Click on the link in it to verify your email address.`,'Email verification' )
        
    }

    const errorSendingEmail = (err: string) => {
        alerts.clearWorking()
        alerts.error(err)
    }


    const ShowBanner = () => {

        return <>
            
            
            <Box sx={styles}>
            <Box mx={'auto'} display={'flex'} gap={8} alignItems={'center'} py={8}>

                <Typography variant="h3">
                    Email needs to be verified before pitches can be live

                </Typography>

                <XJLinkButton to={"#"} onClick={onVerifyClick}>
                    <Typography variant="h3">verify</Typography>
                </XJLinkButton>
            </Box>
        </Box>
        </>
    }
    return <>
        {emailVerified === false ? ShowBanner() : null}
    </>


}