import { assert } from "console";
import { ExplainerID, ShouldShowFunction } from "hooks/UseExplainersShouldShow";
import { PitchModel } from "Model/PitchModel";
import { QuestionModel } from "Model/QuestionModel";
import { SegmentModel } from "Model/SegmentModel";
import { TopicModel } from "Model/TopicModel";
import { QT, QT_Null } from "Model/Types";
import { VideoModel } from "Model/VideoModel";

export enum PitchStudioSection {
    segments = 0,
    video = 1,
    about = 2
}

export class RoutingController {

    // MARK: - Login 

    static Login(returnUrl?: string): string {
        let result = '/login'
        if (returnUrl) {
            result = result + '?return=' + returnUrl
        }
        return result
    }

    // MARK: - Main 

    static Home(): string {
        return '/dashboard'
    }

    static Landing(): string {
        return '/'
    }

    static Profile(): string {
        return '/profile'
    }

    static Support(): string {
        return '/support'
    }

    // MARK: - Pitch 

    static NewPitch(): string {
        return `/pitch`
    }

    static PitchEditor(pitch: PitchModel, section?: PitchStudioSection): string {

        if (section) {

            return `/pitch/${pitch.id}/edit?tab=${section}`
        }

        return `/pitch/${pitch.id}/edit`
    }

    static PitchGoLiveProfile(pitch: PitchModel) {
        return `/pitch/${pitch.id}/profilegolive`
    }

    static PitchGoLive(pitchId: number) {
        return `/pitch/${pitchId}/golive`
    }

    static PitchLinks(pitchId: number) {
        return `/pitch/${pitchId}/links`
    }


    // MARK: - Segments 

    static SegmentEditor(segment: SegmentModel): string {
        return `/pitch/${segment.pitch.id}/segment/${segment.id}/edit`
    }


    // MARK: - Video 

    static SegmentVideoRecordOrSetup(segment: SegmentModel, shouldShow: ShouldShowFunction) {

        if (shouldShow(ExplainerID.VideoRecordingSetup, true)) {

            return RoutingController.SegmentVideoRecordSetupIntro(segment)

        } else {

            return RoutingController.SegmentVideoRecord(segment)

        }
        
    }

    static SegmentVideoRecord(segment: SegmentModel) {

        return `/pitch/${segment.pitch.id}/segment/${segment.id}/video/record`

    }



    static SegmentVideoRecordSetupIntro(segment: SegmentModel) {
        return `/pitch/${segment.pitch.id}/segment/${segment.id}/video/record/setup/intro`
    }



    static SegmentVideoRecordSetup(segment: SegmentModel) {
        return `/pitch/${segment.pitch.id}/segment/${segment.id}/video/record/setup`
    }


    static SegmentVideoUpload(segment: SegmentModel) {
        return `/pitch/${segment.pitch.id}/segment/${segment.id}/video/upload`
    }

    static VideoEditor(video: VideoModel): string {
        
        return `/pitch/${video.segment!.pitch.id}/segment/${video.segment!.id}/video/${video.id}/editor`
    }


    // MARK: - Media 

    static TranscodeVideo(video: VideoModel): string {
        return `/media/${video.id}/transcode`
    }

    static GetVideoTranscodeStatus(video: VideoModel): string {
        return `/media/${video.id}/getTranscodeStatus`
    }
    

    // MARK: - Interview 

    static QA_Edit(Q_T: QT_Null): string | null {

        if (Q_T instanceof TopicModel) {

            return `/pitch/${Q_T.segment.pitch.id}/segmentEdit/topic/${Q_T.id}`
        }

        else if (Q_T instanceof QuestionModel) {

            return `/pitch/${Q_T.topic.segment.pitch.id}/segmentEdit/question/${Q_T.id}`

        }

        return null
    }

    static InterviewStep(step: QT): string {

        if (step instanceof TopicModel) {

            return (`/pitch/${step.segment.pitch.id}/topic/${step.id}`)

        }

        if (step instanceof QuestionModel) {

            return (`/pitch/${step.segment.pitch.id}/question/${step.id}`)

        }

        return "ERROR"
    }


    // MARK: - Investor 

    static Watch(code: string): string {
        return '/watch?v=' + code
    }


    // MARK: - Admin

    static AdminManualReview(): string {
        return '/admin/manualreview'
    }

    static AdminManualReviewPitch(p: PitchModel): string {
        return `/admin/manualreview/${p.id}`
    }

    static AdminQuestionsEditor(): string {
        return '/admin/questions'
    }

    static AdminTopicsEditor(): string {
        return '/admin/topics'
    }
    
    static AdminTutorialsEditor(): string {
        return '/admin/tutorials'
    }

    static AdminTemplates(): string {
        return '/admin/templates'
    }


    // MARK: - Files 

    static PitchuraLogo(): string {
        return '/PitchuraLogo.png'
    }
}