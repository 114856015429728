import { Box, Step, Typography } from '@mui/material'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { VisualAidModel } from 'Model/VisualAidModel'
import { useEffect, useRef, useState } from 'react'
import { VisualAidCard } from './VisualAidCard'
import { VisualAidView } from './VisualAidView'
import { VideoModel } from 'Model/VideoModel'
import { NewVisualAidMedia } from './NewVisualAidMedia'
import { MediaStatus, MediaType } from 'Common/Enums'
import XJConfirmationDialog from 'UI Elements/PopUps/XJConfirmationDialog'
import { useXJMediaUploadContext} from 'Components/XJMediaUpload'
import { usePitches } from 'hooks/UsePitches'

interface IVisualAidsProps {

    video: VideoModel
    progress: number,
    onAidsListUpdated: (aids: VisualAidModel[]) => void,
    onAidUpdated: (localID: string, data: object) => void, 
    onAidSelected: (aid: VisualAidModel) => void, 
    onVisualAidEdit: (editing: boolean) => void,
    onUploadInProgress: (inProgress: boolean) => void,
    refresh?: boolean
}

const enum step {
    list,
    uploadMedia,
    newAid,
    aid
}

export function VisualAidsList(props: IVisualAidsProps) {

    const [selectedAid, setSelectedAid] = useState<VisualAidModel | null>(null)     // aid to show in the detailed view 
    const [originAid, setOriginAid] = useState<VisualAidModel | null>(null)         // origin aid that should be edited, in case of cancel
    const [mode, setMode] = useState(step.list)

    const uploaderRef = useXJMediaUploadContext()
    const pitchHook = usePitches()

    const video = props.video


    useEffect(() => {

        video.allVisualAids.forEach(x => x.uploaderRef = uploaderRef)

    }, [props.refresh])

    useEffect(() => {
        // Update selected visual aid object when video gets updated 
        if (selectedAid) {
        
            const aid = video.allVisualAids.find(aid => aid.localID === selectedAid.localID)
            if (aid) {
        
                setSelectedAid(aid)
            }
        }
    }, [video])


    // MARK: - Events 

    const [showDeleteAidDialog, setShowDeleteAidDialog] = useState(false)

    const aidBeingDeleted = useRef<VisualAidModel>()

    const onAidDelete = (aid: VisualAidModel) => {
        // ask are you sure 
        aidBeingDeleted.current = aid
        setShowDeleteAidDialog(true)
    }

    const deleteShownVisualAid = () => {

        aidBeingDeleted.current!.isRemoved = true

        onAidEditChange(aidBeingDeleted.current!)

        setMode(step.list)
        setSelectedAid(null)
    }


    const onAidCardSelected = (aid: VisualAidModel) => {
        props.onAidSelected && props.onAidSelected(aid)
        return
    }

    const onMoreClick = (aid: VisualAidModel) => {

        setSelectedAid(aid)
        setOriginAid(aid.clone())
        setMode(step.aid)
    }

    const onNewVisualAid = () => {

        setMode(step.uploadMedia)
    }

    useEffect(() => {

        props.onVisualAidEdit(mode !== step.list)

    }, [mode])



    // MARK: - VisualAidView callbacks 

    const onAidMediaSelect = (aid: VisualAidModel) => {

        setMode(step.uploadMedia)
    }

    const onAidEditChange = (aid: VisualAidModel) => {

        props.onAidUpdated(aid.localID, aid)
    }

    const onCancelEdit = (changedAid: VisualAidModel) => {

        if (mode === step.newAid) {
            // new aid cancel 
            const newList = video.allVisualAids.filter(a => a.localID !== changedAid.localID)
            props.onAidsListUpdated(newList)
        } else {
            // exisiting aid cancel 

            // onCancel for the exisiting aid returns 'original' unchanged aid 
            // so it's getting into the list at the same index instead of the changed clone 

            const aids = [...video.allVisualAids]
            const index = aids.findIndex(a => a.localID === changedAid.localID)
            if (originAid) {
                aids[index] = originAid
            } else {
                aids.filter(a => a.localID !== changedAid.localID)
            }
            props.onAidsListUpdated(aids)
        }

        setMode(step.list)          // close aid view 
        setSelectedAid(null)
    }

    const onAidViewClose = async (aid: VisualAidModel) => {

        // on Save button clicked 

        /*  // start upload media for the new or changed
         /*  if (aid.isReadyForUpload) {
  
              if (!aid.uploaderRef) aid.uploaderRef = uploaderRef
  
              aid.uploadMedia()
              /*  aid.uploadMedia().then((entry: UploadEntry) => {
                   // adding completions callback to update fields in the clone (as aids get cloned on every update);
                   // transfering most likely updated fields during async upload of the file 
                   // to a clone of the visual aid object. 
                   entry.addCompleteCallback((entry) => {
                       setAidsList((aidsList) => {
                           // looking for an aid with the same uploadId, bc for new aids this serves as local ID 
                           const index = aidsList.findIndex(va => va.uploadId === aid.uploadId)
                           if (index >= 0 && index < aidsList.length) {
                               const theAid = aidsList[index]
   
                               theAid.fileName = entry.fileName
                               theAid.mediaStatus = entry.status
                           }
                           return aidsList
                       })
                   })
                   entry.addErrorCallback((entry) => {
                       setAidsList((aidsList) => {
                           // transfer most likely updated fields during async upload of the file 
                           // to a clone of the visual aid object. 
                           // looking for an aid with the same uploadId, bc for new aids this serves as local ID 
                           const index = aidsList.findIndex(va => va.uploadId === aid.uploadId)
                           if (index >= 0 && index < aidsList.length) {
                               const theAid = aidsList[index]
                               theAid.mediaStatus = entry.status
                           }
                           return aidsList
                       })
                   }) 
         // })
     } */

        
    


     //   props.onAidsListUpdated([...aidsList])
        setMode(step.list)
        setSelectedAid(null)
    }

    const onDeleteVisualAid = (aid: VisualAidModel) => {

        const aids = [...video.allVisualAids]
        const index = aids.findIndex(va => va.localID === aid.localID)

        aids[index] = aid

        props.onAidsListUpdated(aids)
        setMode(step.list)
        setSelectedAid(null)

    }


    // MARK: - Media selector 

    const updateUploadedAid = async (uploadedAid: VisualAidModel, fileName: string, status: MediaStatus, needsSaving?: boolean) => {

        if (needsSaving === undefined) {
            needsSaving = false 
        }
        
        const aid = video.allVisualAids.find(aid => aid.localID === uploadedAid.localID)
        if (aid) {
            
            aid.fileName = fileName
            aid.mediaStatus = status
            
            props.onAidUpdated(aid.localID, {
                fileName: fileName,
                mediaStatus: status
            })

            if (needsSaving) {

                const pid = aid.video.segment?.pitch.id
                if (pid) {
                    
                    const actualAid = pitchHook.getVisualAid(undefined, aid.localID)
                    if (actualAid){
                        actualAid.fileName = fileName
                        actualAid.mediaStatus = status

                        actualAid.save()
                    }
                }
            }
        }
    }

    const mediaCropped = async (mediaUrl: string, filename: string, extension: string) => {

        // create new aid 

        const isAdding = selectedAid === null ? true : false  

        const uploadingAid = isAdding ? video.createVisualAid(props.progress) : selectedAid! 

        uploadingAid.uploaderRef = uploaderRef
        

        await uploadingAid.updateMedia(mediaUrl, MediaType.visualAidImage,  extension, filename, 
            (needsSaving: boolean) => {
                updateUploadedAid(uploadingAid, uploadingAid.fileName, uploadingAid.mediaStatus, needsSaving)
            }
        )

        let newAidsList = [...video.allVisualAids]
        if (isAdding) {
            newAidsList.push(uploadingAid)
            newAidsList = newAidsList.sort(video.sortingVisualAidsMethod())
        
            props.onAidsListUpdated(newAidsList)
            setSelectedAid(uploadingAid)
            props.onAidSelected(uploadingAid)
        } else {
            props.onAidUpdated(uploadingAid.localID, uploadingAid)
        }
        
        setMode(isAdding ? step.newAid : step.aid)
    }

    const onCancelNewMedia = () => {

        setMode(step.list)
        setSelectedAid(null)

    }

    

    // MARK: - Render 

    return <>
        
       
        {/* <XJMediaUpload ref={(ref) => uploaderRef.current = ref!} onUploadInProgress= {props.onUploadInProgress} /> */}

        {(mode === step.aid || mode === step.newAid) && selectedAid &&
            <Box>
                <VisualAidView
                    aid={selectedAid}
                    onMediaSelect={onAidMediaSelect}
                    onAidChanged={onAidEditChange}
                    onCancel={onCancelEdit}
                    onClose={onAidViewClose}
                    onDelete={onAidDelete}
                />
            </Box>}

        {mode === step.list &&
            <Box>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'top'} mb={16}>
                    <Typography variant='h2'>
                        Slides
                    </Typography>

                    <XJButton variant={'secondary'} onClick={onNewVisualAid}>Add a Slide</XJButton>

                </Box>
                <Box>
                    <Box>
                        {video.allVisualAids
                        .filter(x => !x.isRemoved)
                        .sort(video.sortingVisualAidsMethod())
                        .map((visualAid: VisualAidModel) => 
                        {
                            return <VisualAidCard
                                key={`key-visualAidCard-${visualAid.localID}`}
                                aid={visualAid}

                                onAidSelected={onAidCardSelected}
                                onMoreClicked={onMoreClick}
                                onAidChanged={onAidEditChange}
                                onAidDelete={onAidDelete}
                            />
                        })}
                    </Box>

                </Box>
            </Box>}

        {mode === step.uploadMedia &&
            <NewVisualAidMedia
                onMediaCropped={mediaCropped}
                onCancel={onCancelNewMedia}
            />}

        <XJConfirmationDialog
            open={showDeleteAidDialog}
            title={'Delete visual aid?'}

            onConfirm={() => {
                deleteShownVisualAid()
                setShowDeleteAidDialog(false)
            }}

            message={'You are about to delete this visual aid. This action is irreversible. Continue?'}

            onCancel={() => { setShowDeleteAidDialog(false) }}
        />
    </>
}

