import React, { FocusEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Button, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { lightPrimaryOverlay24, lightPrimaryOverlay32, lightPrimaryOverlay48, lightPrimaryOverlay8, primaryDefault, primaryDefaultOverlay16, primaryDefaultOverlay24, primaryDefaultOverlay32, secondaryDefault, secondaryDefaultOverlay24, secondaryDefaultOverlay32, secondaryDefaultOverlay8, secondaryLight60, secondaryLight75, secondaryLight85, whiteDefault } from 'XJumpTheme';

interface IXJButtonProps {

    iconLeft?: boolean      // whether or not to show am icon on the left
    iconRight?: boolean     // whether or not to show am icon on the right
    dropdown?: boolean      // whether or not to show the drop down icon
    variant?: 'primary' | 'secondary' | 'tertiary' | 'error'

    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onDropDownClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onFocus?: (event: FocusEvent<HTMLElement>) => void
    onBlur?: (event: FocusEvent<HTMLElement>) => void

    children: React.ReactNode
    disabled?: boolean
    submit?: boolean
    sx?: SxProps
    fontSx?: SxProps
    width?: string,
    hidden?: boolean

    to?: string
    newWindow?: boolean

}

/* export enum ButtonVariant {
    'primary',
    'secondary',
    'tertiary',
    'error'
} */

export function XJButton(props: IXJButtonProps) {

    const theme = useTheme();


    const backgroundSXprimary = {

        background: primaryDefault,
        ':focus-visible': { 
            background: primaryDefault, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':focus-visible:hover': { 
            background: primaryDefaultOverlay24, 
            outline: '2px solid ' + secondaryLight60 },
        ':hover': { background: primaryDefaultOverlay16 },
        ':active': { background: primaryDefaultOverlay32 },     // pressed
        color: whiteDefault
    }

    const backgroundSXsecondary = {

        background: secondaryDefault,
        ':focus-visible': { 
            background: secondaryDefault, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':focus-visible:hover': { 
            background: secondaryDefaultOverlay32, 
            outline: '2px solid ' + secondaryLight60 },
        ':hover': { background: secondaryDefaultOverlay24 },
        ':active': { background: secondaryDefaultOverlay8 },    // pressed
        color: whiteDefault
    }

    const backgroundSXtertiary = {

        background: lightPrimaryOverlay8,
        ':focus-visible': { 
            background: lightPrimaryOverlay8, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':focus-visible:hover': { 
            background: lightPrimaryOverlay32, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':hover': { background: lightPrimaryOverlay24 },
        ':active': { background: lightPrimaryOverlay48 },       // pressed
        color: secondaryDefault
    }

    const backgroundSX = () => {

        if (props.disabled) {   
            return {
                background: secondaryLight85,
                color: secondaryLight75
            }
        }

        switch (props.variant) {

            case 'secondary':
                {
                    return backgroundSXsecondary
                }
            case 'tertiary':
                {
                    return backgroundSXtertiary
                }
            default:
                {
                    return backgroundSXprimary
                }
        }

    }

    let paddingLeft = 12; if (props.iconLeft) paddingLeft = 8
    let paddingRight = 12; if (props.iconRight) paddingRight = 8; if (props.dropdown) paddingRight = 7;

    const buttonWidth = props.width ? props.width : 'auto';

    const buttonStyle: SxProps =
    {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(paddingLeft),
        paddingRight: theme.spacing(paddingRight),
        borderRadius: '2px',
        whiteSpace: 'nowrap',
        width: buttonWidth,
        height: '40px',
    }

    const ButtonInner = <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center'>

        <Typography variant='h3' sx={props.fontSx}>
            {/* <Box display={'flex'} alignItems='center' gap={4} pl={'16px'} pr={'24px'}> */}
            <Box display={'flex'} alignItems='center' gap={4}>
                {props.children}
            </Box>
        </Typography>

        {/* {props.dropdown && <Box pl={8} style={{ fontSize: '0px' }} >  <IconButton onClick={props.onDropDownClick}><ArrowDropDown /></IconButton> </Box>} */}
    </Box>

    const sx = { ...buttonStyle, ...backgroundSX(), ...props.sx }

    const LinkButton = (
        <Button sx={sx}
            to={props.to as string}
            target={props.newWindow ? '_blank' : '_self'}
            component={RouterLink}
            disabled={props.disabled}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
        >
            {ButtonInner}
        </Button>
    )

    const ClickButton = <Button sx={sx} onClick={props.onClick} disabled={props.disabled} onFocus={props.onFocus} onBlur={props.onBlur}>
        {ButtonInner}
    </Button>

    if (!props.hidden) {
        if (props.to && !props.to.isEmpty()) {
            return LinkButton;
        }
        else {
            return ClickButton;
        }
    }
    else return <></>

}