import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { XJPitchuraLogo } from "UI Elements/XJPitchuraLogo";
import { copyPrimaryRegular, darkPrimaryDefault } from "XJumpTheme";



export interface IPropsEmptyMessage {

    brandName?: string,
    brangLogo?: ReactElement | null,
    mainIcon: ReactElement,
    title: string,
    message?: string
}

export function EmptyPageMessage(props: IPropsEmptyMessage)
{
    return <>
        <Box mx={'auto'} mt={'12%'} textAlign="center">
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={8} mb={40}>
                <XJPitchuraLogo variant={'SVG+Type'} noBackground color={darkPrimaryDefault} />
                <Box display={'flex'} gap={4} alignItems={'center'} >
                    <Box>{props.brangLogo ?? null} </Box>
                    <Box> <Typography variant="h1"> {props.brandName ?? null} </Typography></Box>
                    
                </Box>
            </Box>
            <Box mb={16} >
                {props.mainIcon}
            </Box>
            <Box mb={8}>
                <Typography variant="h2">
                    {props.title}
                </Typography>
            </Box>
            <Box>
                <Box sx={{ ...copyPrimaryRegular, color: darkPrimaryDefault }}>
                    {props.message ?? null}
                </Box>
            </Box>
        </Box>
    </>

}