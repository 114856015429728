
import { Backdrop, Box, CircularProgress, Fade, LinearProgress, SxProps, Typography } from "@mui/material"
import { ceil, floor, min } from "lodash"
import { createRef, forwardRef, Ref, RefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { ActionCallback } from "tools/Types"
import PlayerControls from "./PlayerControls"
import PlayerTimeline from "./PlayerTimeline"
import { IVideo, IVisualAid, VideoPlayer, VideoPlayerAPI } from "./VideoPlayer"
import { secondaryDefault, theme, whiteDefault } from "XJumpTheme"
import { VideoModel } from "Model/VideoModel"
import { PlayerMiniControls } from "./PlayerMiniControls"
import { LogError } from "Controllers/Logging"


type PitchPlayerSegmentChangeCallback = (currentSegmentId: number, previousSegmentId?: number | null) => void
type PitchPlayerProgressCallback = (played: number) => void

export interface IPublicPitch {
    id: number,
    segments: IVideoSegment[]
}

export interface IVideoSegment {
    seq: number
    title: string
    duration: number,
    url: string,
    visualAids: IVisualAid[],
    model?: VideoModel          // video model that is used to subsribe for url updates
}

interface PitchPlayerProps {
    pitch: IPublicPitch

    fullscreen?: boolean
    showPreloading?: boolean
    hideControls?: boolean
    hideTimeline?: boolean
    showOnScreenControls?: boolean

    onMounted?: ActionCallback
    onReady?: ActionCallback
    onProgess?: PitchPlayerProgressCallback
    onSegmentChange?: PitchPlayerSegmentChangeCallback
    onEnded?: ActionCallback
}

interface PitchPlayerState {
    playing: boolean
    muted: boolean
    progress: number
    currentSegmentId: number
}

export interface PitchPlayerAPI {
    preload: () => void
    play: () => void
    pause: () => void
    seekTo: (progress: number) => void
    seekToSegment: (segmentId: number) => void
    rewind: (offset: number) => void
    forward: (offset: number) => void
    mute: () => void
    unmute: () => void
    restart: () => void

    state: () => PitchPlayerState
}


export const PitchPlayer = forwardRef((props: PitchPlayerProps, ref: Ref<PitchPlayerAPI>) => {

    // MARK: - State 

    const [playing, setPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [muted, setMuted] = useState(false)

    const [segments, setSegments] = useState(props.pitch.segments)
    const [segment, setSegment] = useState<IVideoSegment>(segments[0])      // active segment 

    const [loading, setLoading] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0.0)
    const [error, setError] = useState<Error | null>(null)


    // MARK: - API 

    useImperativeHandle(ref, () => ({
        preload, play, pause, seekTo, seekToSegment, forward, rewind, mute, unmute, restart, state
    }))

    // Calling when mounted
    const _onMounted = props.onMounted
    useEffect(() => {
        _onMounted && _onMounted()
    }, [_onMounted])

    const preload = () => {

        let aidCounter = 0
        setLoading(true)

        const updateProgress = () => {
            setLoadingProgress((loadingProgress) => {
                const newProgess = loadingProgress + ceil((1.0 / aidCounter) * 1000000) / 1000000
                if (newProgess >= 1) {
                    setLoading(false)
                }
                return newProgess
            })
        }

        segments.forEach((segment: IVideoSegment) => {
            segment.visualAids.forEach((aid: IVisualAid) => {
                aidCounter += 1
                const img = new Image()
                img.onload = () => {
                    updateProgress()
                }
                img.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
                    updateProgress()

                    LogError (event.toString())
                    // TODO: fix error on 
                    // setError(error ?? new Error("Something seriously went wrong here"))
                }
                img.src = aid.URL
            })
        })
    }

    const play = () => {
        setPlaying(true)
        setTimeout(() => {
            setShowOnScreen(false)

        }, 500)
    }

    const pause = () => {
        setPlaying(false)
    }

    const seekTo = (progress: number) => {
        let newSegment = segments[0]
        let duration = 0
        for (const segment of segments) {
            newSegment = segment
            duration += segment.duration
            if (progress < duration) {
                break
            }
        }

        const segmentChanged = newSegment.seq !== segment?.seq
        const segmentOffset = progress - _startOfSegment(newSegment)
        if (segmentChanged) {
            _offsetForNextSegment.current = segmentOffset
            setSegment(newSegment)
        } else {
            _player()?.seekTo(segmentOffset)
            setProgress(progress)
        }
    }

    const seekToSegment = (segmentId: number) => {
        let segmentSeekTo = undefined

        segmentSeekTo = segments.find((segment) => segment.seq === segmentId)

        if (segmentSeekTo) {
            _offsetForNextSegment.current = 0
            seekTo(_startOfSegment(segmentSeekTo))
        }
    }

    const forward = (offset: number) => {
        const currentValue = progress
        let newValue = currentValue + offset
        if (newValue > _pitchDuration()) {
            newValue = _pitchDuration()
        }
        seekTo(newValue)
    }

    const rewind = (offset: number) => {
        const currentValue = progress
        let newValue = currentValue - offset
        if (newValue < 0.0) {
            newValue = 0.0
        }
        seekTo(newValue)
    }

    const restart = () => {
        seekTo(0)
    }

    const mute = () => {
        setMuted(true)
    }

    const unmute = () => {
        setMuted(false)
    }

    const state = () => {
        return {
            playing: playing,
            muted: muted,
            progress: progress,
            currentSegmentId: segment?.seq
        } as PitchPlayerState
    }


    // MARK: - Private 


    // MARK: - Segments 

    // Method controls the amount of players 
    const _getPlayersAmount = () => {
        return segments.length
    }

    const [playersAmount, setPlayersAmount] = useState(_getPlayersAmount())

    const _playersRef = useRef<RefObject<VideoPlayerAPI>[]>((() => {
        const result = []
        for (let index = 0; index < playersAmount; index++) {
            result.push(createRef<VideoPlayerAPI>())
        }
        return result
    })())
    const _players = useCallback(() => {
        return _playersRef.current
    }, [])

    const _playerSegmentsRef = useRef<IVideoSegment[]>((() => {
        const result = []
        for (let index = 0; index < playersAmount; index++) {
            result.push(segments[index % segments.length])
        }
        return result
    })())
    const _playerSegments = useCallback(() => {
        return _playerSegmentsRef.current
    }, [])

    const _offsetForNextSegment = useRef(0)

    useEffect(() => {
        props.onProgess && props.onProgess(progress)
    }, [progress, props])

    const _pitchDuration = () => {
        // sum of all final video segments durations 
        let result = 0

        segments.forEach(segment => {
            result += segment.duration
        })

        return result
    }

    const _startOfSegment = useCallback((segment: IVideoSegment) => {
        let result = 0

        for (const asegment of segments) {
            if (segment.seq === asegment.seq) {
                break
            } else {
                result += asegment.duration
            }
        }

        return result
    }, [segments])

    const _getFirstSegment = () => {
        return segments[0] ?? null
    }

    const _getNextSegment = (ofSegment?: IVideoSegment) => {
        let result: IVideoSegment | null = null

        const currentSegment = ofSegment ?? segment
        const index = segments.indexOf(currentSegment)
        if (index >= 0 && index < segments.length - 1) {
            // if segment is found and it isn't the last segment then return next segment
            result = segments[index + 1]
        }

        return result
    }

    const _getPreviousSegment = (ofSegment?: IVideoSegment) => {
        let result: IVideoSegment | null = null

        const currentSegment = ofSegment ?? segment
        const index = segments.indexOf(currentSegment)
        if (index > 0 && index <= segments.length - 1) {
            // if segment is found and it isn't the first segment then return next segment
            result = segments[index - 1]
        }

        return result
    }

    const _updatePlayersSegments = useCallback((segments: IVideoSegment[]) => {
        // sets new segments url to rendered players 

        const unusedPlayers: RefObject<VideoPlayerAPI>[] = []
        const unassignedSegments: IVideoSegment[] = segments.copy()

        const segmentsIds = segments.map((segment) => _getPlayerId(segment))
        _players().forEach((playerRef: RefObject<VideoPlayerAPI>) => {
            const player = playerRef.current
            if (player) {
                const id = player.video.id
                if (segmentsIds.contains(id)) {
                    // remove segment from unassigned 
                    const index = unassignedSegments.findIndex((seg) => seg.seq.toString() === id)
                    unassignedSegments.splice(index, 1)
                } else {
                    // add player to unused 
                    unusedPlayers.push(playerRef)
                    player.reset()
                }
            } else {
                unusedPlayers.push(playerRef)
            }
        })

        unassignedSegments.forEach((segment: IVideoSegment, index: number) => {
            const player = unusedPlayers[index]
            const playerIndex = _players().indexOf(player)
            _playerSegments()[playerIndex] = segment
        })
    }, [_playerSegments, _players])

    const _segmentsForUpdate = useCallback((fromSegment?: IVideoSegment) => {
        let result: IVideoSegment[] = []

        const currentSegment = fromSegment ?? segment
        const currentSegmentIndex = segments.findIndex((seg) => seg.seq === currentSegment.seq)

        if (currentSegmentIndex >= 0) {     // if the index is correct 
            if ((playersAmount < segments.length) && (playersAmount > 0)) {    // if amount of players is less than segments 

                // firstly getting amount of segment from current segment including the current segment
                const forwardSegmentsAmount = min([playersAmount, segments.length - currentSegmentIndex])
                if (forwardSegmentsAmount && forwardSegmentsAmount > 0) {
                    result = result.concat(segments.slice(currentSegmentIndex, currentSegmentIndex + forwardSegmentsAmount))

                    // if all the segments till the end of the video are pushed and the are more - adding previous segments from the current segment 
                    const backwardAmount = playersAmount - forwardSegmentsAmount
                    if (backwardAmount > 0) {
                        let startIndex = currentSegmentIndex - backwardAmount
                        if (startIndex < 0) startIndex = 0  // if index gets incorrect (impossible at this point) - correct it
                        result = result.concat(segments.slice(startIndex, currentSegmentIndex).reverse())
                    }
                }
            } else {
                // if there are more players than segments - just return all the segments 
                result = segments
            }
        } else {
            LogError("No current segment found in video segments")
        }

        return result
    }, [segments, playersAmount, segment])

    // on current segment update
    useEffect(() => {

        _updatePlayersSegments(_segmentsForUpdate(segment))

    }, [segment, _segmentsForUpdate, _updatePlayersSegments])


    // MARK: - Player

    const _currentPlayerIndex = useCallback(() => {
        let index = _playerSegments().findIndex((seg) => seg.seq === segment?.seq)
        if (index < 0 || index > playersAmount - 1) {
            // if index is out of range, it means that _playerSegments are outdated
            // solution - refresh 
            _updatePlayersSegments(_segmentsForUpdate())
            index = _playerSegments().findIndex((seg) => seg.seq === segment?.seq)
        }
        return index
    }, [segment, _playerSegments, playersAmount, _segmentsForUpdate, _updatePlayersSegments])

    const _player = useCallback(() => {
        const index = _currentPlayerIndex()
        return _players()[index]?.current
    }, [_players, _currentPlayerIndex])

    const _updateProgress = (progress: number) => {
        if (playing) {
            setProgress(progress)
        }
    }

    // generates player id
    const _getPlayerId = (segment: IVideoSegment) => {
        return segment.seq.toString()
    }

    const onSegmentChange = props.onSegmentChange

    useEffect(() => {
        _player()?.seekTo(_offsetForNextSegment.current)
        setProgress(_startOfSegment(segment) + _offsetForNextSegment.current)

        // callback
        onSegmentChange && onSegmentChange(segment?.seq)
    }, [onSegmentChange, segment, _player, _startOfSegment])


    // MARK: - Timeline 

    const _onTimelineClick = (position: number) => {
        // position is progress in seconds 
        seekTo(position)
    }


    // MARK: - Render 

    // styles 

    const isFullscreen = props.fullscreen ?? false
    const showPreloading = props.showPreloading ?? false
    const hideControls = props.hideControls ?? false
    const hideTimeline = props.hideTimeline ?? false

    const FULLSCREEN_BACKGROUND_COLOR = 'black'
    document.body.style.backgroundColor = isFullscreen ? FULLSCREEN_BACKGROUND_COLOR : theme.palette.background.default

    const styles = {
        root: {
            position: "relative",
            width: "100%",
            ...(isFullscreen ? {
                height: "100%",
                backgroundColor: FULLSCREEN_BACKGROUND_COLOR
            } : {}
            )
        } as SxProps,
        player: {
            width: "100%",
            position: "relative",
            top: 0,
            left: 0,
            ...(isFullscreen ? { height: "100%" } : {})
        } as SxProps,
        playerStack: {
            top: 0,
            left: 0,
            width: "100%",
            position: "relative",
            ...(isFullscreen ? { height: "100%" } : {})
        } as SxProps,
        activePlayer: {
            top: 0,
            left: 0,
            position: "relative",
            zIndex: 0,
            ...(isFullscreen ? { height: "100%" } : {})
        } as SxProps,
        backupPlayer: {
            top: 0,
            left: 0,
            position: "absolute",
            zIndex: -1,
            display: "none"
        } as SxProps,
        loading: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
        } as SxProps,
        loadingBackdrop: {
            color: '#fff',
            zIndex: 2,
            position: "absolute",
            display: "flex",
            flexDirection: "column"
        } as SxProps,
        error: {
            position: "absolute",
            zIndex: 3,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
        } as SxProps
    }

    const renderLoading = () => {
        return (
            <Box sx={styles.loading}>
                <Backdrop sx={styles.loadingBackdrop} open>
                    <CircularProgress color="inherit" />
                    <Box display={'flex'} width={'50%'} alignItems={'center'} justifyContent={'start'} gap={'10px'}>
                        <LinearProgress sx={{ minWidth: "80%" }} variant='determinate' value={loadingProgress * 100} />
                        <Typography sx={{ minWidth: "20%" }} color={'white'}>{floor(loadingProgress * 100 * 100) / 100}%</Typography>
                    </Box>
                </Backdrop>
            </Box>
        )
    }

    const renderError = (error: Error) => {
        return (
            <Backdrop sx={styles.error} open>
                <Typography color={'white'}> Error during loading: {error.message} </Typography>
            </Backdrop>
        )
    }

    const _renderPlayer = () => {

        const onReady = (playerId: string) => {
            if (playerId === _getPlayerId(segment)) {
                props.onReady && props.onReady()
            }
            setVideoReady(true)
        }

        const onProgress = (
            state: {
                played: number
                loaded: number
            },
            id: string
        ) => {
            const newProgress = _startOfSegment(segment) + state.played

            const currentPlayerId = _getPlayerId(segment)
            if (id === currentPlayerId) {
                // update the progress only for active segment
                _updateProgress(newProgress)
            }
        }

        // TODO: move to class body
        const onEnded = () => {
            const nextSegment = _getNextSegment()
            if (nextSegment) {
                _offsetForNextSegment.current = 0
                setSegment(nextSegment)
            } else {
                setPlaying(false)

                // TODO: set current segment to the first one
                if (_getFirstSegment()) {
                    setSegment(_getFirstSegment())
                }

                props.onEnded && props.onEnded()
            }
        }

        const isCurrent = (index: number) => {
            return _currentPlayerIndex() === index
        }

        const _mapSegmentVideo = (segment: IVideoSegment) => {
            return {
                id: segment.seq.toString(),
                url: segment.url,
                duration: segment.duration,
                visualAids: segment.visualAids,
                model: segment.model
            } as IVideo
        }

        const [videoReady, setVideoReady] = useState(false)
        const [videoError, setVideoError] = useState(false)

        const onError = (id: string) => {

            setVideoError(true)
            LogError(`'error loading video: ${id}`)

        }

        return (
            <Box sx={styles.playerStack}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: secondaryDefault,
                    // opacity: 0.8,
                    display: videoReady ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: theme.zIndex.player
                }}>

                    <Typography color={whiteDefault} variant='h1'>{!videoError ? 'Loading...' : 'Error when loading clip'}</Typography>

                </Box>
                {_players().map((playerRef: RefObject<VideoPlayerAPI>, index: number) => {
                    return <VideoPlayer
                        ref={playerRef}
                        key={"videoPlayer" + index.toString()}
                        id={_getPlayerId(_playerSegments()[index])}
                        fullscreen={isFullscreen}
                        isCurrent={isCurrent(index)}

                        sx={isCurrent(index) ? styles.activePlayer : styles.backupPlayer}
                        video={_mapSegmentVideo(_playerSegments()[index])}

                        directControl={{
                            playing: isCurrent(index) ? playing : false,
                            muted: muted
                        }}

                        onReady={onReady}
                        onProgress={onProgress}
                        onEndPlaying={onEnded}
                        onError={() => {
                            
                            onError(_mapSegmentVideo(_playerSegments()[index]).id)
                        }
                        }
                    />
                })}
            </Box>
        )
    }


    const controlsSx: SxProps =
    {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1
    }


    const [showOnScreen, setShowOnScreen] = useState(false)

    return (<>
        <Box sx={styles.root}
            onMouseMove={() => { if (playing) setShowOnScreen(true) }}
            onMouseLeave={() => { setShowOnScreen(false) }}
            onClick={(e) => {

                setShowOnScreen(val => !val)
                e.preventDefault()
                e.stopPropagation()
            }}

        >
            <Box sx={styles.player}>
                {_renderPlayer()}

                <Fade in={(!playing || showOnScreen) && props.showOnScreenControls}>
                    <Box>
                        <PlayerMiniControls
                            duration={_pitchDuration()}
                            progress={progress}
                            playing={playing}

                            onPlay={play}
                            onPause={pause}
                            onRestart={restart}
                            onRewind={() => { rewind(5) }}
                            onForward={() => { forward(5) }}
                            onTimelineClick={_onTimelineClick}

                        />
                    </Box>
                </Fade>
                <Box sx={controlsSx}>

                    {!hideTimeline &&
                        <PlayerTimeline
                            duration={_pitchDuration()}
                            progress={progress}

                            onClick={_onTimelineClick}

                        />}

                    {!hideControls &&
                        <PlayerControls
                            duration={_pitchDuration()}
                            progress={progress}
                            playing={playing}
                            muted={muted}

                            onPlay={play}
                            onPause={pause}
                            onRestart={restart}
                            onRewind={() => { rewind(5) }}
                            onForward={() => { forward(5) }}
                            onMute={mute}
                            onUnmute={unmute}
                        />}
                </Box>

            </Box>

            {showPreloading && loading && renderLoading()}
            {error && renderError(error)}
        </Box>
    </>)
})