import { MouseEvent } from 'react'
import { Box, SxProps } from '@mui/system'
import { darkPrimaryDefault, theme, whiteDefault } from 'XJumpTheme'
import { playerMinWidth } from './VideoPlayer'

interface PlayerTimelineProps {
    duration: number                    // seconds  
    progress: number                    // seconds 

    onClick: (position: number) => void // seconds
}

export default function PlayerTimeline(props: PlayerTimelineProps) {

    const _onTimelineClick = (event: MouseEvent<HTMLElement>) => {
        const currentTargetRect = event.currentTarget.getBoundingClientRect()
        const x = event.pageX - currentTargetRect.left

        if (props.duration > 0) {
            const position = x / currentTargetRect.width * props.duration
            props.onClick(position)
        }
    }

    const progressPercentage = Math.min(Math.round(props.progress / props.duration * 1000) / 1000, 1)



    // styles 

    const styles = {
        clickable: {
            width: "100%",
            height: '8px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        } as SxProps, 

        timeline: {
            width: "100%",
            height: '4px',
            minWidth: playerMinWidth,
            backgroundColor: darkPrimaryDefault,
            display: 'flex'
        } as SxProps,

        marker: (progress: number) => {
            return {
                height: '6px',
                width: '6px',
                borderRadius: '8px',
                backgroundColor: whiteDefault,
                left: '-2px',
                top: '-1px',
                position: 'relative',
                cursor: 'default'
            } as SxProps
        },

        timelineProgress: (progress: number) => {
            return {
                left: 0,
                top: 0,
                width: `${progress * 100}%`,
                height: '100%',
                background: whiteDefault
                //opacity: 0.5
            } as SxProps
        }
    }

    return (
        <Box sx={styles.clickable} onClick={(event) => _onTimelineClick(event)}>
            <Box sx={styles.timeline}>
                <Box sx={styles.timelineProgress(progressPercentage)} />
            
                <Box sx={styles.marker(progressPercentage)} > &nbsp; </Box>
            </Box>
        </Box>
    )
}