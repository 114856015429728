import { Box, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { CountdownFont, secondaryDefault, whiteDefault } from "XJumpTheme"

interface ICountdownProps {

    seed: number | null,
    onCountdownFinished: () => void
}

export default function Countdown(props: ICountdownProps) {

    const [countdownTick, setCountdownTick] = useState<number | null>(null)
    const timerIDRef = useRef<NodeJS.Timeout | undefined>()

    useEffect(() => {

        if (!props.seed) {
            setCountdownTick(null)
            return
        }

        setCountdownTick(props.seed > 0 ? props.seed : null)

        return () => {
            clearTimeout(timerIDRef.current)
        }
    }, [props.seed])

    useEffect(() => {

        if (countdownTick !== null) {

            if (countdownTick < 0) {
                props.onCountdownFinished()
                return
            }

            const timer = setTimeout(() => {
                setCountdownTick((value) => {
                    return value !== null ? value - 1 : null
                })
            }, 1500)

            timerIDRef.current = timer
        }
    }, [countdownTick])

    const classes =
    {
        root:
        {

            color: secondaryDefault,
            width: '88px',
            height: '88px',
            borderRadius: '44px',
            backgroundColor: whiteDefault,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

    }


    return <>

        <Box
            display={countdownTick !== null && countdownTick > -1 ? 'flex' : 'none'}
            mt={40}
            mx={'auto'}
            justifyContent={'center'}
            gap={8}
            alignItems='center'
            flexDirection={'column'}
        >
            <Typography variant="h1">Get ready!</Typography>

            <Box sx={{ ...classes.root, ...CountdownFont }} >
                {countdownTick}
            </Box>

        </Box>
    </>

}







