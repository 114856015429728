import React, { useContext, createContext, useState, useEffect } from "react"
import { PitchModel } from 'Model/PitchModel'
import { useAuth } from 'hooks/UseAuth'
import { QuestionModel } from "Model/QuestionModel"
import { SegmentModel } from "Model/SegmentModel"
import { TopicModel } from "Model/TopicModel"
import { PitchTemplateModel } from "Model/PitchTemplateModel"
import { VideoModel } from "Model/VideoModel"

import API from "api-axios"
import { BasePitchDAO, PitchDAO } from "Common/PitchDAO"
import { AIStatus, SegmentType } from "Common/Enums"
import LinkModel from "Model/LinkModel"
import { UserModel } from "Model/UserModel"
import { SystemQuestions } from "Controllers/SystemQuestionsController"
import { useAlert } from "./UseAlert"
import { GetPitchesPendingManualReview } from "Controllers/AdminController"
import { VisualAidModel } from "Model/VisualAidModel"
import { LogError } from "Controllers/Logging"

interface IProviderPitch {
  pitches: PitchModel[] | null
  getPitch: (id: number) => PitchModel
  newPitch: (
    template: PitchTemplateModel,
    title: string,
    publicTitle: string,
    round: number,
    stage: number,
    industry: string,
    subIndustry: string,
    tag: string[],
    roundAsk: number) => Promise<PitchModel>,

  reloadPitch: (id: number) => Promise<PitchModel>,

  loaded: boolean,
  duplicatePitch: (id: number, newTitle: string) => Promise<PitchModel | void>,
  deletePitch: (id: number) => Promise<void>,

  getVisualAid: (id?: number, localID?: string) => VisualAidModel | null,

  pendingPitches: () => PitchModel[],
  getPendingPitch: (id: number) => PitchModel
}

interface IProps {
  children: React.ReactNode
  // any other props that come into the component
}


export const unpackPitchFromDAO = (p: PitchDAO): PitchModel => {

  try {


    const Pitch = new PitchModel()

    const User = new UserModel(p.User.Id)
    User.load(p.User)

    Pitch.founder = User

    Pitch.load(p)

    if (p.segments) {

      Pitch.segments = p.segments
        .sort((a, b) => {

          if (a.sequence < b.sequence) return -1; else return 1

        })
        .map(s => {

          const newSegment = new SegmentModel(s.type!, Pitch, s.id)

          newSegment.sequence = s.sequence!
          newSegment.title = s.title
          // newSegment.FirstTopicId = s.firstTopicId
          newSegment.automatedScript = s.automatedScript ?? null 
          newSegment.ScriptAIRequestTimestamp = s.ScriptAIRequestTimestamp ? s.ScriptAIRequestTimestamp : undefined
          newSegment.ScriptAIRequestStatus = s.ScriptAIRequestStatus ? s.ScriptAIRequestStatus : AIStatus.init
          newSegment._status = s.status
          newSegment.Script = s.manualScript
          newSegment.showPitchuraScriptReady = s.showPitchuraScriptReady
          newSegment.AIPrompt = s.AIPrompt


          if (s.videos && s.videos.length > 0) {
            const newVideo = VideoModel.load(s.videos.slice(-1)[0], newSegment)
            if (newVideo) {
              newSegment.video = newVideo
            }
          }

          if (s.topics) {

            newSegment.topics = s.topics.map(t => {

              const newTopic = new TopicModel(newSegment)
              newTopic.load(t)

              if (t.questions) {

                newTopic.questions = t.questions.map(q => {

                  const newQ = new QuestionModel(newTopic)
                  newQ.load(q)

                  return newQ

                })
              }

              return newTopic

            })
            newSegment.topics.sort((a, b) => a.sequence - b.sequence)
          }

          newSegment.LoadInterviewSequence(s.interviewSequence)

          return newSegment
        })
    }

    //Pitch.LoadInterviewSequence(p.interviewSequence)

    if (p.links) {
      p.links.forEach(l => {

        const newLink = new LinkModel(Pitch)
        newLink.load(l)

      })

    }


    return Pitch
  }
  catch (err) {
    LogError(err)

    throw err
  }

}


const pitchContext = createContext<IProviderPitch>({} as IProviderPitch)


export function ProvidePitches({ children }: IProps) {

  const pitches = useProvidePitch()
  return (

    <pitchContext.Provider value={pitches}>
      {children}
    </pitchContext.Provider>
  )
}

export function usePitches() {
  return useContext(pitchContext)
}




function useProvidePitch(): IProviderPitch {

  const [pitches, setPitches] = useState<PitchModel[]>([])
  const [loaded, setLoaded] = useState(false)

  const auth = useAuth()
  const loggedInUser = auth.user.User

  const questions = SystemQuestions.questions
  const ServerError = useAlert().APIError
  const Working = useAlert().working
  const ClearWorking = useAlert().clearWorking

  useEffect(() => {

    try {

      if (!loggedInUser) {
        setPitches([])
        return
      }

      
      if (questions.length === 0) SystemQuestions.init()

      Working()

      API.get<PitchDAO[]>('/pitch/all')
        .then((pitchesData) => {
          const pitchHookData = pitchesData.data.map((p) => {
            return unpackPitchFromDAO(p)
          })
          setLoaded(true)
          setPitches([...pitchHookData])

          // await createMockPitch()
        })
        .catch(res => {
         LogError(res)
          ServerError()
        })
        .finally(() => {
          ClearWorking()
        })
    }

    catch (err) {
      LogError(err)
      ServerError()
      throw err
    }

  }, [loggedInUser?.id, questions])

  const newPitch = async (

    template: PitchTemplateModel,
    title: string,
    publicTitle: string,
    round: number,
    stage: number,
    industry: string,
    subIndustry: string,
    tag: string[],
    roundAsk: number): Promise<PitchModel | void> => {

    try {

      const newPitchData: BasePitchDAO =
      {
        templateId: template.Id!,
        title,
        publicTitle,
        round,
        stage,
        industry,
        subIndustry,
        tag: PitchModel.packTag(tag),
        roundAsk
      }

      const newPitchId = await (await API.post('/pitch/newfromtemplate', newPitchData)).data.id
      const pitch = await getNewPitchFromDB(newPitchId)
      return pitch
    }

    catch (err) {
      LogError(err)
      ServerError()
      Promise.reject()
    }

  }


  const getNewPitchFromDB = async (pid: number): Promise<PitchModel | void> => {

    // get from DB   

    try {

      const pitchData = (await API.get<PitchDAO>('/pitch', { params: { pid: pid } })).data
      const pitch = unpackPitchFromDAO(pitchData)

      if (!pitches.find((x: PitchModel) => x.id === Number(pid))) {
        setPitches(pitchHookData => [...pitchHookData, pitch])
      }

      return pitch

    }

    catch (err) {
      LogError(err)
      ServerError()
      Promise.reject()
    }
  }


  const getPitch = (id: number): PitchModel => {

    const pitch = pitches.find((x: PitchModel) => x.id === Number(id)) as PitchModel

    return pitch


  }

  const reloadPitch = async (id: number): Promise<PitchModel | undefined> => {
    
    const data = (await API.get<PitchDAO>('/pitch', { params: { pid: id } })).data
    const reloaded = unpackPitchFromDAO(data)

    const index = pitches.findIndex((pitch) => pitch.id === id)
    setPitches(pitches => {
      const updated = [...pitches]
      updated[index] = reloaded
      return updated
    })

    return reloaded
  }

  const duplicatePitch = async (id: number, newTitle: string): Promise<void | PitchModel> => {

    try {

      const newPitchId = await (await API.post(`/pitch/${id}/duplicate`, { 'title': newTitle })).data.id
      return await getNewPitchFromDB(newPitchId)

    }
    catch (err) {
      LogError(err)
      throw err
    }

  }

  const deletePitch = async (id: number): Promise<void> => {

     return API.delete(`/pitch/${id}/`).then(res => {

        getPitch(id).Deleted = true
        setPitches (pitches.filter (x=> !x.Deleted ))

      }).
        catch(err => {

          LogError(err)

        })
  
  }


  // MARK: - Visual Aids 

  const getVisualAid = (id?: number, localID?: string): VisualAidModel | null => {
    let result: VisualAidModel | null = null

    const allVisualAids = pitches
      .flatMap((p) => p.segments)
      .flatMap(s => s.video?.allVisualAids)

    if (id) {
      result = allVisualAids.find(va => va?.id === id) ?? null
    }

    if (localID) {
      result = allVisualAids.find(va => va?.localID === localID) ?? null
    }

    return result
  }


  // MARK: - Admin 

  const [allPendingPitches, setAllPendingPitches] = useState<PitchModel[]>([])

  useEffect(() => {

    try {

      if (!loggedInUser || !auth.isAdmin()) {
        setAllPendingPitches([])
        return

      } else {
        // logged in AND is Admin

        GetPitchesPendingManualReview()
          .then((pendingPitches) => {
            setAllPendingPitches(pendingPitches)
          })
          .catch(res => {
            LogError(res)
          })
      }
    }

    catch (err) {
      LogError(err)
      ServerError()
      throw err
    }

  }, [loggedInUser?.id])

  const pendingPitches = (): PitchModel[] => {
    let result: PitchModel[] = []
    if (loggedInUser && auth.isAdmin()) {
      result = allPendingPitches
    }

    return result
  }

  const getPendingPitch = (id: number): PitchModel => {

    const pitch = allPendingPitches.find((x: PitchModel) => x.id === Number(id)) as PitchModel

    return pitch

  }

  return {

    pitches,
    getPitch,
    newPitch,
    reloadPitch,
    
    loaded,
    duplicatePitch,
    deletePitch,

    getVisualAid,

    pendingPitches,
    getPendingPitch

  } as IProviderPitch
}

