import React from "react"
import { darkPrimaryDefault, secondaryDefault, secondaryLight75 } from "XJumpTheme"


interface XJIconProps {
    disabled?: boolean
    color? : string
    selected? : boolean
}

export class XJIcon extends React.Component<XJIconProps>
{
    protected disabled = false
   // protected color: string = secondaryDefault

    protected color = 'currentColor'

    constructor(props: XJIconProps) {
        super(props)
    }

    render() {
        if (this.props.selected !== undefined) {
            if (this.props.selected) {
                this.color = secondaryDefault
            } else {
                // "unselected"
                this.color = darkPrimaryDefault
            }
        }
        
        if (this.props.color)
        {
            this.color = this.props.color
        }

        if (this.props.disabled) {
            this.color = secondaryLight75
        }
    }


}

