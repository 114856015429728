import { IconPause, IconRecord, IconRehearse, IconRestart } from "UI Elements/XJIcons"
import { denotiveErrorDefault, lightPrimaryOverlay24, lightPrimaryOverlay32, lightPrimaryOverlay48, lightPrimaryOverlay8, secondaryDefault, secondaryDefaultOverlay24, secondaryDefaultOverlay32, secondaryDefaultOverlay8, secondaryLight60, secondaryLight75, secondaryLight85, whiteDefault } from "XJumpTheme"
import { XJButton } from "./XJButton"
import { SxProps } from "@mui/material"
import { FocusEvent, ReactElement } from "react"


export type RecordButtonVariant = 'primary-record' | 'primary-icon' | 'secondary'

interface IXJRecordButtonProps {

    sx?: SxProps 
    variant: RecordButtonVariant
    disabled?: boolean
    children: React.ReactNode

    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onFocus?: (event: FocusEvent<HTMLElement>) => void
    onBlur?: (event: FocusEvent<HTMLElement>) => void
}

export function XJRecordButton(props: IXJRecordButtonProps) {

    const sxPrimaryRecord = {

        background: secondaryDefault,
        ':focus-visible': { 
            background: secondaryDefault, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':focus-visible:hover': { 
            background: secondaryDefaultOverlay32, 
            outline: '2px solid ' + secondaryLight60 },
        ':hover': { background: secondaryDefaultOverlay24 },
        ':active': { background: secondaryDefaultOverlay8 },    // pressed
        color: whiteDefault
    } as SxProps

    const sxSecondary = {

        background: lightPrimaryOverlay8,
        ':focus-visible': { 
            background: lightPrimaryOverlay8, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':focus-visible:hover': { 
            background: lightPrimaryOverlay32, 
            outline: '2px solid ' + secondaryLight60 
        },
        ':hover': { background: lightPrimaryOverlay24 },
        ':active': { background: lightPrimaryOverlay48 },       // pressed
        color: secondaryDefault
    } as SxProps

    const sxDisabled = {
        color: secondaryLight75,
        backgroundColor: secondaryLight85
    } as SxProps

    const sxVariantStyle = () => {
        switch (props.variant) {
            case 'primary-record': {
                return sxPrimaryRecord
            }

            case 'primary-icon': {
                return sxPrimaryRecord
            }

            case 'secondary': {
                return sxSecondary
            }
        }
    }

    const style: SxProps = {
        ...props.sx, 
        position: 'relative',
        paddingY: '16px',
        paddingX: '28px',
        height: '56px',
        borderRadius: '100px',
        ...props.disabled ? sxDisabled : sxVariantStyle()
    } as SxProps

    return (
        <XJButton
            sx={style}
            iconLeft
            disabled={props.disabled}
            onClick={props.onClick}
            onFocus={props.onFocus} 
            onBlur={props.onBlur}
        >
            {props.children}
        </XJButton>
    )
}


export enum RecordButtonType {

    record,
    pause,
    redo,
    resume, 
    rehearse,
    rehearseOn,
    custom 
}

interface IXJSpecificRecordButtonProps {

    type: RecordButtonType
    disabled?: boolean

    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onFocus?: (event: FocusEvent<HTMLElement>) => void
    onBlur?: (event: FocusEvent<HTMLElement>) => void
}

export function XJSpecificRecordButton(props: IXJSpecificRecordButtonProps) {

    let title = ""
    let icon: ReactElement = <></>
    let variant: RecordButtonVariant = 'secondary'
    switch (props.type) {
        case RecordButtonType.record: {
            icon = <IconRecord color={props.disabled ? secondaryLight75 : denotiveErrorDefault} />
            title = 'Record'
            variant = 'primary-record'
            break
        }

        case RecordButtonType.pause: {
            icon = <IconPause color={props.disabled ? secondaryLight75 : whiteDefault} />
            title = 'Pause'
            variant = 'primary-icon'
            break
        }

        case RecordButtonType.resume: {
            icon = <IconRecord color={props.disabled ? secondaryLight75 : denotiveErrorDefault} />
            title = 'Resume'
            variant = 'primary-record'
            break
        }

        case RecordButtonType.redo: {
            icon = <IconRestart color={props.disabled ? secondaryLight75 : secondaryDefault} />
            title = 'Restart'
            variant = 'secondary'
            break
        }

        case RecordButtonType.rehearseOn: 
        case RecordButtonType.rehearse: {
            icon = <IconRehearse color={props.disabled ? secondaryLight75 : secondaryDefault} />
            title = 'Practice'
            variant = 'secondary'
            break
        }

        case RecordButtonType.custom: {
            
            break
        }
    }

    const sx = props.type === RecordButtonType.rehearseOn ? {border: 'solid 2px ' + secondaryDefault} : {}

    return (
        <XJRecordButton onClick={props.onClick} variant={variant} disabled={props.disabled} onFocus={props.onFocus} onBlur={props.onBlur} sx={sx}>
            {icon}
            {title}
        </XJRecordButton>
    )
}