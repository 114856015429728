import { useHistory } from "react-router-dom";

import { theme } from 'XJumpTheme';
import { useAuth } from 'hooks/UseAuth';

import { Box, SxProps } from '@mui/system';
import { XJMenuButton } from '../UI Elements/Buttons/XJMenuButton';
import XJVerifyEmailBanner from './Founder/Profile/XJVerifyEmailBanner';
import { XJShowIfAdminRole } from 'UI Elements/XJShowIfAdminRole';
import { ReactNode, useState } from "react";
import { XJTooltip } from "UI Elements/XJTooltip";
import { RoutingController } from "Controllers/RoutingController";
import { XJMediaUploadProvider } from "Components/XJMediaUpload";
import {  XJPitchuraLogo } from "UI Elements/XJPitchuraLogo";
import { MenuIconContactUs, MenuIconLogout, MenuIconProfile, MenuIconVideos } from "UI Elements/XJIcons";
import PreviewIcon from '@mui/icons-material/Preview';
import TopicIcon from '@mui/icons-material/Topic';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Paper } from "@mui/material";

const drawerWidth = '240px'
export const rightDrawerWidth = '300px'
const LeftNavBarWidth = '72px'

/* 
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${LeftNavBarWidth} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${LeftNavBarWidth} + 1px)`,
    }
});
 */


const classes = {

    root: {
        display: 'flex'

    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    appBarShiftRight: {
        width: `calc(100% - ${rightDrawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: rightDrawerWidth,
    },

    menuButton: {
        marginRight: 36,
    },

    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        height: '100%vh'
    },

    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(20), // + 1,
        [theme.breakpoints.up('sm')]: {
            width: LeftNavBarWidth, //+ 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(5, 6),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: 26,
        paddingRight: 26,
        paddingBottom: 16,
        marginBottom: 16,
        position: 'relative',

        // padding: theme.spacing(16),
    },
    contentRight: {
        flexGrow: 1,
        padding: theme.spacing(10),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
    },
    contentShiftRight: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: rightDrawerWidth,
    }
} as const

enum Branch {
    home,
    profile,
    contact,
    templates,
    topics,
    questions,
    review,
    tutorials
}


const Layout = (props: { bannerHeight?: number, children: ReactNode }) => {

    // const [open, setOpen] = React.useState<boolean>(false);
    // const rightDrawerOpen = useTutorialState().tutorialOpen

    const auth = useAuth();

    /* 
        const handleDrawerToggle = () => {
            // setOpen(!open);
        }
     */

    const history = useHistory();

    const [branch, setBranch] = useState<Branch>(Branch.home)


    // MARK: - Actions 

    const onLogo = () => {
        onPitches()
    }

    const onPitches = () => {
        setBranch(Branch.home)

        history.push(RoutingController.Home())
    }

    const onTemplates = () => {
        setBranch(Branch.templates)

        history.push(RoutingController.AdminTemplates())
    }

    const onTopics = () => {
        setBranch(Branch.topics)

        history.push(RoutingController.AdminTopicsEditor())
    }

    const onTutorialsEditor = () => {
        setBranch(Branch.tutorials)

        history.push(RoutingController.AdminTutorialsEditor())
    }


    const onQuestions = () => {
        setBranch(Branch.questions)

        history.push(RoutingController.AdminQuestionsEditor())
    }

    const onScriptReview = () => {
        setBranch(Branch.review)

        history.push(RoutingController.AdminManualReview())
    }

    const onProfile = () => {
        setBranch(Branch.profile)

        history.push(RoutingController.Profile())
    }

    const onContact = () => {
        setBranch(Branch.contact)
        history.push(RoutingController.Support())
    }

    const onLogout = () => {
        auth.Logout(() => {
            history.push(RoutingController.Landing())
        })
    }

    
    // MARK: - Layout 

    const styles = {
        root: {
            width: '100%',
            height: '100%',
            paddingTop: '12px',
            paddingBottom: '32px',
            paddingLeft: '12px',
            paddingRight: '12px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'inline-flex',

            pt: `${props.bannerHeight}px`
        },

        navBar:
            {
                position: 'fixed',
                top: 0,
                left: 0,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                height: '100vh',
                overflowX: 'hidden',
                // width: theme.spacing(20), // + 1,
                [theme.breakpoints.up('sm')]: {
                    width: LeftNavBarWidth, //+ 1,
                }
            } as SxProps,
        topSection: {
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '32px',
            display: 'inline-flex'
        },
        logo: {
            margin: '12px',
            mt: '16px',
            width: '40px' ,
            height: '40px' ,
            cursor: 'pointer'
        } as SxProps,
        menuButtons: {
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '8px',
            display: 'inline-flex',
            px: '4px'
        },
        menuButton: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        bottomSection: {
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            gap: '8px',
            display: 'inline-flex',
            px: '4px'
        }
    }

    const NavBarWidth = '72px'

    return (
        <>

            {/*    <Drawer
                    open={false}
                    variant="permanent"
                    sx={{...classes.drawer, ...classes.drawerClose}}
        
            >  */}

            <Paper sx={{ ...styles.navBar, width: NavBarWidth}}>

                <Box sx={styles.root}>
                    <Box sx={styles.topSection}>
                        <Box sx={styles.logo} onClick={onLogo}>
                            <XJPitchuraLogo variant={'SVG'} />
                        </Box>

                        <Box sx={styles.menuButtons}>
                            <Box sx={styles.menuButton}>
                                <XJMenuButton variant={'primary'} onClick={onPitches}>
                                    <XJTooltip title="Pitches" >
                                        <MenuIconVideos selected={branch === Branch.home} />
                                    </XJTooltip>
                                </XJMenuButton>
                            </Box>
                            <Box sx={styles.menuButton}>
                                <XJMenuButton variant={'primary'} onClick={onProfile}>
                                    <XJTooltip title="Profile" >
                                        <MenuIconProfile selected={branch === Branch.profile} />
                                    </XJTooltip>
                                </XJMenuButton>
                            </Box>

                            <XJShowIfAdminRole>
                                <Box sx={styles.menuButton} mt={64}>
                                    <XJMenuButton variant={'primary'} onClick={onScriptReview}>
                                        <XJTooltip title="Script Review" >
                                            <PreviewIcon />
                                        </XJTooltip>
                                    </XJMenuButton>
                                </Box>

                                <Box sx={styles.menuButton}>
                                    <XJMenuButton variant={'primary'} onClick={onTemplates}>
                                        <XJTooltip title="Templates" >
                                            <SettingsIcon />
                                        </XJTooltip>
                                    </XJMenuButton>
                                </Box>

                                <Box sx={styles.menuButton}>
                                    <XJMenuButton variant={'primary'} onClick={onQuestions}>
                                        <XJTooltip title="Questions" >
                                            <ContactSupportIcon />
                                        </XJTooltip>
                                    </XJMenuButton>
                                </Box>

                                <Box sx={styles.menuButton}>
                                    <XJMenuButton variant={'primary'} onClick={onTopics}>
                                        <XJTooltip title="Topics" >
                                            <TopicIcon />
                                        </XJTooltip>
                                    </XJMenuButton>
                                </Box>


                                <Box sx={styles.menuButton}>
                                    <XJMenuButton variant={'primary'} onClick={onTutorialsEditor}>
                                        <XJTooltip title="Topics" >
                                            <TopicIcon />
                                        </XJTooltip>
                                    </XJMenuButton>
                                </Box>
                            </XJShowIfAdminRole>

                        </Box>
                    </Box>

                    <Box sx={styles.bottomSection}>
                        <Box sx={styles.menuButton}>
                            <XJMenuButton variant={'primary'} onClick={onContact}>
                                <XJTooltip title="Contact us" >
                                    <MenuIconContactUs selected={branch === Branch.contact} />
                                </XJTooltip>
                            </XJMenuButton>
                        </Box>
                        <Box sx={styles.menuButton}>
                            <XJMenuButton variant={'primary'} onClick={onLogout}>
                                <XJTooltip title="Logout">
                                    <MenuIconLogout selected={false} />
                                </XJTooltip>
                            </XJMenuButton>
                        </Box>
                    </Box>
                </Box>
            </Paper>

            {/* </Drawer> */}

            <Box sx={{ width: '100%' }} ml={NavBarWidth} >
                <XJVerifyEmailBanner />
                <main >
                    {/* <Box sx={rightDrawerOpen ? { ...classes.content, ...classes.contentShiftRight } as SystemStyleObject : { ...classes.content } as SystemStyleObject}> */}
                    <Box sx={classes.content}>
                        <XJMediaUploadProvider>
                            {props.children}
                        </XJMediaUploadProvider>
                    </Box>
                </main>
            </Box>
        </>
    )
}

export default Layout;