export {}

declare global {
    interface HTMLElement {
        clientOffset: {x: number, y: number}
    }
}

function clientOffset(this: HTMLElement): {x: number, y: number} {
    let x = this.offsetLeft
    let y = this.offsetTop

    let parent = this.offsetParent as HTMLElement
    while(parent && parent.offsetLeft !== undefined) {
        x = x + parent.offsetLeft
        y = y + parent.offsetTop
        parent = parent.offsetParent as HTMLElement
    }

    return {x: x, y: y};
}

Object.defineProperty(HTMLElement.prototype, "clientOffset", {get: clientOffset})