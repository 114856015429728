import { Box, LinearProgress, Slide, SxProps } from "@mui/material"
import API from "api-axios"
import axios, { AxiosProgressEvent, AxiosRequestConfig, CanceledError } from "axios"
import { MediaType } from "Common/Enums"
import { HTTP_SUCCESS } from "Common/HTTPCodes"
import { LogError } from "Controllers/Logging"
import { createContext, createRef, forwardRef, ReactNode, Ref, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { uuid } from "tools/Utils"
import { XJMenuButton } from "UI Elements/Buttons/XJMenuButton"
import { IconUploadClose, IconUploadSuccess } from "UI Elements/XJIcons"
import { copySecondaryBold, copySecondaryRegular, darkPrimaryDefault, lightPrimaryOverlay32, primaryDefault, secondaryDefault, theme, whiteDefault } from "XJumpTheme"

const XJMediaUploadRef = createRef<XJMediaUploadAPI>()

const XJMediaUploadContext = createContext(XJMediaUploadRef)

export const XJMediaUploadProvider = (props: { children: ReactNode }) => {

    return <XJMediaUploadContext.Provider value={XJMediaUploadRef}>

        <XJMediaUpload ref={XJMediaUploadRef} key={'mediaUploader'}>
            {props.children}
        </XJMediaUpload>
    </XJMediaUploadContext.Provider>

}

export const useXJMediaUploadContext = () => useContext(XJMediaUploadContext)

export enum MediaUploadStatus {
    started,
    Progressing,
    uploaded,
    error,
    aborted
}

export type FileUploadEntry = {
    id: string,
    type: MediaType,
    media: Blob,
    fileName: string | null,
    status: MediaUploadStatus,
    progress: number,
    localFileName: string,
    entityNeedsSaving: boolean,     // flag that marks that after upload the entity must be sync with backend 
    abortSignal: AbortSignal,
    error?: string
}


export interface XJMediaUploadAPI {

    // verifyUpload: (fileEntryId: string, parentId: number) => void
    upload: (
        type: MediaType,        // video or image         
        media: Blob,
        fileExtension: string,
        localFileName: string,
        onComplete: (entry: FileUploadEntry) => void,
        onError: () => void,
        onStarted: (entry: FileUploadEntry) => void) => string,

    getEntry: (id: string) => FileUploadEntry | null, 
    cancelUpload: () => void,
    setonUploadInProgressFunction: (ptr: (inProgress: boolean) => void) => void,
    setonUploadStartedFunction: (ptr: (entry: FileUploadEntry) => void) => void
    setonUploadCompleteFunction: (ptr: (entry: FileUploadEntry) => void) => void
    setonUploadFailedFunction: (ptr: (entry: FileUploadEntry) => void) => void
    setonUploadAbortedFunction: (ptr: () => void) => void

}

export interface XJMediaUploadProps {

    children: ReactNode

}

export type uploaderRefType = React.RefObject<XJMediaUploadAPI>

export const XJMediaUpload = forwardRef((props: XJMediaUploadProps, ref: Ref<XJMediaUploadAPI>) => {

    const [entries, setEntries] = useState(new Array<FileUploadEntry>())
    const [show, setShow] = useState(false)
    const abortContrlRef = useRef<AbortController>()

    useImperativeHandle(ref, () => ({
        upload, getEntry, cancelUpload, setonUploadInProgressFunction, setonUploadStartedFunction, setonUploadCompleteFunction, setonUploadFailedFunction, setonUploadAbortedFunction
    }))

    const entriesRef = useRef<FileUploadEntry[]>()
    const setEntriesRef = useRef<React.Dispatch<React.SetStateAction<FileUploadEntry[]>>>()

    const showTimeout = 500

    entriesRef.current = entries
    setEntriesRef.current = setEntries

    const onUploadInProgressPtr = useRef<(inProgress: boolean) => void>()
    const setonUploadInProgressFunction = (ptr: (inProgress: boolean) => void) => {
        onUploadInProgressPtr.current = ptr
    }

    const onUploadStarted = useRef<(entry: FileUploadEntry) => void>()
    const setonUploadStartedFunction = (ptr: (entry: FileUploadEntry) => void) => {
        onUploadStarted.current = ptr
    }

    const onUploadComplete = useRef<(entry: FileUploadEntry) => void>()
    const setonUploadCompleteFunction = (ptr: (entry: FileUploadEntry) => void) => {
        onUploadComplete.current = ptr
    }

    const onUploadFailed = useRef<(entry: FileUploadEntry) => void>()
    const setonUploadFailedFunction = (ptr: (entry: FileUploadEntry) => void) => {
        onUploadFailed.current = ptr
    }

    const onUploadAborted = useRef<() => void>()
    const setonUploadAbortedFunction = (ptr: () => void) => {
        onUploadAborted.current = ptr
    }

    useEffect(() => {

        if (entries.find(x => x.status === MediaUploadStatus.started || x.status === MediaUploadStatus.Progressing)) {
            if (!show) {

                setShow(true)
            }
        }
        else {
            if (show) {
                setTimeout(() => {
                    setShow(false)

                }, showTimeout);
            }
        }


        if (entries.find(x => x.status === MediaUploadStatus.Progressing)) {
            onUploadInProgressPtr.current && onUploadInProgressPtr.current(true)
        }
        else {
            onUploadInProgressPtr.current && onUploadInProgressPtr.current(false)
        }

    }, [entries])


    // ---- METHODS



    /*  const verifyUpload = async (fileEntryId: string, parentId: number) => {
 
         try {
 
         
             const fileEntry = entriesRef.current!.find(e => e.id === fileEntryId)
 
             if (!fileEntry) {
 
                 console.error('couldn not find file entry ' + fileEntryId)
                 throw "error in assignParent" + parentId
             }
 
             const completeUrl = `/media/${fileEntry.type}/${parentId}/uploadComplete`
             const completeData = { 'size': fileEntry.media.size }
 
             await API.post(completeUrl, completeData)
         
         }
         catch (err)
         {
             console.error(err)
             throw new Error (err)
         }     
 
     } */

    const getEntry = (id: string) => {

        let result: FileUploadEntry | null = null 

        const index = entriesRef.current!.findIndex(entry => entry.id === id)
        if (index >= 0) {
            result = entriesRef.current![index]
        }

        return result 
    }

    const cancelUpload = () => {

        if ( abortContrlRef.current)
            abortContrlRef.current.abort()

        if (onUploadAborted.current) onUploadAborted.current()

    }

    const clearEntryFromQueue = useCallback((fileEntry: FileUploadEntry) => {

        setTimeout(() => {
            // clean up 
            const newEntries = entriesRef.current!.filter(x => x.id !== fileEntry.id)
            setEntries(newEntries)
        }, 3000)

    }, [entries])


    const upload = (
        type: MediaType,        // video or image         
        media: Blob,
        fileExtension: string,
        localFileName: string,
        onComplete: (entry: FileUploadEntry) => void,
        onError: () => void,
        onStarted: (entry: FileUploadEntry) => void) => 
    {

        const id = uuid()

        const abortCtrl = new AbortController()
        abortContrlRef.current = abortCtrl

        const newFileEntry: FileUploadEntry =
        {
            id: id,
            type: type,
            fileName: '',
            media: media,
            status: MediaUploadStatus.started,
            progress: 0,
            localFileName: localFileName,
            entityNeedsSaving: false, 
            abortSignal: abortContrlRef.current!.signal
        }

        setEntries(value => [...value, newFileEntry])
        onStarted(newFileEntry)

        if (onUploadStarted.current) onUploadStarted.current(newFileEntry)

        const onUploadError = (entry: FileUploadEntry) => {

           LogError('Upload media failed: ' + entry.error?.json())
            entry.status = MediaUploadStatus.error

            setEntries(value => [...entriesRef.current!])

            onError()

            clearEntryFromQueue(entry)
        }

        const config: AxiosRequestConfig = {
            onUploadProgress: (progress: AxiosProgressEvent) => {
                if (progress.total && progress.total > 0) {

                    const percentCompleted = Math.round((progress.loaded * 100) / progress.total!)

                    newFileEntry.progress = percentCompleted
                    newFileEntry.status = MediaUploadStatus.Progressing
                    setEntries([...entriesRef.current!])

                }
            },

            baseURL: '',
            headers: { 'Content-Type': 'application/octet-stream' },
            signal: newFileEntry.abortSignal
        }

        API.get(`/media/${type}/extension/${fileExtension}/upload`)

            .then((res) => {

                const PutUrl = res.data.PutURL
                const fileName = res.data.fileName
                const HeadUrl = res.data.HeadURL

                axios.put(PutUrl, media, config)

                    .then((response) => {
                        // Callbacks on finish
                        if (response.status === HTTP_SUCCESS) {

                            axios.head(HeadUrl).then(res => {
                                const size = res.headers['content-length']
                                if (size && (Number(size) === media.size)) {
                                    newFileEntry.fileName = fileName
                                    newFileEntry.status = MediaUploadStatus.uploaded

                                    setEntries([...entriesRef.current!])
                                    onComplete(newFileEntry)
                                    if (onUploadComplete.current) onUploadComplete.current(newFileEntry)

                                    clearEntryFromQueue(newFileEntry)

                                }
                                else {

                                    const errorMsg = `Upload couldnt verify sizees: Put: ${media.size} and Get ${res.data.size}`
                                    LogError(errorMsg)
                                    newFileEntry.status = MediaUploadStatus.error
                                    onUploadError(newFileEntry)
                                    if (onUploadFailed.current) onUploadFailed.current(newFileEntry)

                                }

                            })
                            .catch(err => {

                               LogError(err)
                                newFileEntry.status = MediaUploadStatus.error
                                onUploadError(newFileEntry)
                                if (onUploadFailed.current) onUploadFailed.current(newFileEntry)
                            })

                        } else {

                            newFileEntry.status = MediaUploadStatus.error
                            newFileEntry.error = response.data.error

                            onUploadError(newFileEntry)
                            if (onUploadFailed.current) onUploadFailed.current(newFileEntry)
                        }
                    })

                    .catch((error) => {

                        if (error instanceof CanceledError) {

                            newFileEntry.status = MediaUploadStatus.aborted
                            abortContrlRef.current = new AbortController()
                            setEntries(value => [...entriesRef.current!])

                            clearEntryFromQueue(newFileEntry)

                        }

                        else {

                            LogError(error)

                            newFileEntry.status = MediaUploadStatus.error
                            newFileEntry.error = error

                            onUploadError(newFileEntry)
                            if (onUploadFailed.current) onUploadFailed.current(newFileEntry)
                        }

                    })
            })

            .catch(err => {

                newFileEntry.status = MediaUploadStatus.error
                newFileEntry.error = err

                onUploadError(newFileEntry)
                if (onUploadFailed.current) onUploadFailed.current(newFileEntry)
            })
        
        return id 
    }


    // MARK: - STYLE

    const getMediaType = (m: MediaType) => {

        switch (m) {
            case MediaType.video:
                {
                    return "Video"
                }
            case MediaType.visualAidImage:
                {
                    return "Image"
                }
            case MediaType.visualAidVideo:
                {
                    return "Video"
                }
            case MediaType.link:
                {
                    return "Link"
                }
            case MediaType.undefined:
                {
                    return "Other"
                }

            default:
                {
                    LogError("Unable to translate getMediaType")
                    return ""
                }

        }

    }

    const describeMediaType = (type: MediaType) => {
        switch (type) {

            case MediaType.video:
                return "video"
            case MediaType.visualAidImage:
                return "image"
            default:
                return 'unknown'

        }

    }

    const styles = {
        root: {
            position: 'absolute',
            left: 16,
            width: '30%',
            zIndex: theme.zIndex.snackbar,
            flexDirection: 'column',
            gap: 8
        } as SxProps,
        
        fileNameCell: {
            width: '100%'
        } as SxProps
    }

    const convertFileSize = (fileSizeInBytes: number) => {

        if (fileSizeInBytes < 0) {
            throw new Error("File size cannot be negative");
        }

        if (fileSizeInBytes < 1024) {
            return fileSizeInBytes.toFixed(1) + " bytes";
        } else if (fileSizeInBytes < 1024 * 1024) {
            return (fileSizeInBytes / 1024).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kb";
        } else {
            return (fileSizeInBytes / (1024 * 1024)).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " mb";
        }
    }


    // MARK: - RENDER 

    return <>

        <Box sx={styles.root} >
            <Slide in={show} direction={'up'} timeout={1000}>
                <Box sx={{ position: 'fixed', bottom: 16, width: '30%' }}>

                    {
                        entriesRef.current.map((m, i) => {

                            const FileItemSX: SxProps = {
                                padding: 8,
                                backgroundColor: whiteDefault,
                                borderRadius: '2px',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 4
                            }

                            const progressBar: SxProps = {

                                backgroundColor: lightPrimaryOverlay32,
                                height: '2px',
                                borderRadius: '2px',

                                '& .MuiLinearProgress-bar':
                                {
                                    backgroundColor: secondaryDefault,
                                    height: '2px',
                                    borderRadius: '2px'
                                },
                            }

                            return <Box sx={FileItemSX} key={`uploadItem${i}`}>

                                <Box mr={10} display={'flex'} flexDirection={'column'} gap={2} width={'100%'}>
                                    <Box sx={{ ...copySecondaryBold, textOverflow: 'ellipsis' }}>
                                        {m.localFileName === '' ? 'Recorded' : m.localFileName}
                                    </Box>

                                    <Box display={'flex'} gap={8} width={'100%'} alignItems={'center'}>

                                        <Box width={'100%'} display={m.status === MediaUploadStatus.Progressing ? 'block' : 'none'}>

                                            <LinearProgress
                                                variant={'determinate'}
                                                sx={progressBar}
                                                value={m.progress} />
                                        </Box>

                                        <Box sx={{
                                            whiteSpace: 'nowrap'
                                        }}>

                                            {
                                                m.status === MediaUploadStatus.Progressing && <Box sx={{ ...copySecondaryRegular, color: darkPrimaryDefault }}>{`${m.progress}%`} </Box>
                                            }
                                            {
                                                (m.status === MediaUploadStatus.uploaded) && <Box sx={{ ...copySecondaryRegular, color: darkPrimaryDefault }} >
                                                    {describeMediaType(m.type)} &bull; {convertFileSize(m.media.size)}

                                                </Box>
                                            }
                                            {
                                                m.status === MediaUploadStatus.started && <Box sx={{ ...copySecondaryRegular, color: darkPrimaryDefault }}>started</Box>
                                            }

                                            {
                                                m.status === MediaUploadStatus.aborted && <Box sx={{ ...copySecondaryRegular, color: darkPrimaryDefault }}>aborted</Box>
                                            }


                                        </Box>
                                    </Box>
                                </Box>


                                <Box p={0} display={'flex'} alignItems={'center'}>

                                    {(m.status === MediaUploadStatus.uploaded) && <IconUploadSuccess color={primaryDefault} />}

                                    {m.status === MediaUploadStatus.Progressing && <XJMenuButton sx={{ p: 0 }} onClick={() => { cancelUpload() }} variant={'primary'} >

                                        <IconUploadClose color={secondaryDefault} />

                                    </XJMenuButton>}

                                </Box>
                            </Box>


                        })
                    }
                </Box>
            </Slide>
        </Box >

        {props.children}
    </>

})