import { Box, List, ListItem, ListItemText, SxProps, TextField, Typography } from "@mui/material"
import { LogError } from "Controllers/Logging"
import { SystemQuestions } from "Controllers/SystemQuestionsController"
import { isUndefined } from "lodash"
import { QuestionContentModel } from "Model/QuestionContenModel"
import { Dispatch, useEffect, useState } from "react"


interface IQuestionsListProps {
    selectedQuestion: QuestionContentModel | undefined
    setSelectedQuestion: Dispatch<React.SetStateAction<QuestionContentModel | undefined>>
    onOpenEditor: () => void

}

export default function QuestionsList(props: IQuestionsListProps) {

    const allQuestions = SystemQuestions.questions
    const [questions, setQuestions] = useState(allQuestions)
    const [questionFilter, setQuestionFilter] = useState<string>('')

    const [topicFilter, setTopicFilter] = useState<string>('')
    const [segmentFilter, setSegmentFilter] = useState<string>('')

    const HighlightedItem: SxProps =
    {
        backgroundColor: 'black',
        color: 'white !important'
    }

    useEffect(() => {
        setQuestions(allQuestions.filter(
            x =>
            {
                const titleMatch = x.title && x.title.length > 0 ? x.title.toLowerCase().includes(questionFilter.trim().toLowerCase()) : false

                const topicMatch = x.Topic && x.Topic.length > 0 ? x.Topic.toLowerCase().includes(topicFilter.trim().toLowerCase()) : false

                const segmentMatch = x.Segment && x.Segment.length > 0 ? x.Segment.toLowerCase().includes(segmentFilter.trim().toLowerCase()) : false

                return (titleMatch || !questionFilter || questionFilter.length === 0) && (topicMatch || !topicFilter || topicFilter.length === 0)  && (segmentMatch || !segmentFilter || segmentFilter.length === 0)
            }
                
        ))
        

}, [questionFilter, topicFilter, segmentFilter])


    const FilterTextBox = () => {
        return <Box display={'flex'} gap={32}>

            <TextField
                label='filter'
                value={questionFilter}
                inputProps={{ key: 'questionFilter1' }}
                id='questionsfilter'
                size="small"
                name='questionsFilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setQuestionFilter(e.currentTarget.value)

                }}

            />

<TextField
                label='segment'
                value={segmentFilter}
                inputProps={{ key: 'questionFilter3' }}
                id='segmentFilter'
                size="small"
                name='segmentFilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setSegmentFilter(e.currentTarget.value)

                }}

            />


            <TextField
                label='topic'
                value={topicFilter}
                inputProps={{ key: 'questionFilter2' }}
                id='topicfilter'
                size="small"
                name='topicfilter'
                // onChange={handleInputChange}
                onChange={(e) => {
                    e.preventDefault()
                    setTopicFilter(e.currentTarget.value)

                }}

            />


        </Box>




    }

    const OnClickQuestionListTopic = (QID: number, openEdit?: boolean) => {

        const Q = questions!.find(q => q.id === QID)
        if (Q) props.setSelectedQuestion(Q)

        if (openEdit) {

            props.onOpenEditor()
        }

    }


    return <>

        <Box display={'flex'}>
            <Typography variant="h3">All Questions</Typography>
            {FilterTextBox()
            }</Box>

        <List>
            {
                questions!.sort((a, b) => {

                    if (a.id === undefined) return 1
                    if (b.id === undefined) return -1
                    if (a.id > b.id) return -1

                    return 1
                })
                    .map((x: QuestionContentModel) => {

                        if (x.id !== undefined) {

                            return <ListItem
                                
                                onDoubleClick={() => {
                                    OnClickQuestionListTopic(x.id!, true)

                                }}

                                key={`question_qid_${x.id}`}
                                sx={props.selectedQuestion === x ? HighlightedItem : null}
                                onClick={() => {

                                    OnClickQuestionListTopic(x.id!)
                                }
                                }>

                                <ListItemText>
                                    {x.title} {x.Segment ? `(${x.Segment}) - ` : ''}  {x.Topic ? `(${x.Topic})` : ''}
                                </ListItemText>

                            </ListItem>
                        }
                        else
                        {
                            LogError (`QuestionContent without an ID: ${x.title}`)

                        }
                    })
            }
        </List>

    </>
}