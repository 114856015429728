import { useLocalStorage as useSystemLocalStorage } from 'usehooks-ts'
import { IAuthData, useAuth } from "./UseAuth"
import { ExplainerID, ExplainerState } from "./UseExplainersShouldShow"

interface LocalStorage {

    user: () => IAuthData
    updateUser: (user: IAuthData) => void

    explainer: (id: ExplainerID) => ExplainerState | undefined
    updateExplainer: (explainer: ExplainerState) => void 

    selectedCameraId: () => string
    updateSelectedCameraId: (id: string) => void

}

type StorageValues = {
    id: string
    explainers: ExplainerState[]
    cameraID: string
}

export function usePitchuraLocalStorage(): LocalStorage {

    const Keys = {
        user: 'PitchuraUser',
        values: 'PitchuraUserStorageValues',
        explainers: 'PitchuraExplainers',
        camera: 'CameraID'
    }

    const getDefaultValues = (id: string) => {
        return {
            id: id,
            explainers: [],
            cameraID: ''
        } as StorageValues
    }

    const [storageUser, setStorageUser] = useSystemLocalStorage(Keys.user, {} as IAuthData)
    const [userValues, setUserValues] = useSystemLocalStorage<StorageValues[]>(Keys.values, [])

    const currentUser = useAuth()

    const _getUserValues = (values: StorageValues[]) => {
        const id = String(currentUser.user.Id)
        let result = values.find(u => u.id === id)
        if (!result) {
            result = getDefaultValues(id)
            values.push(result)
        }
        
        return result
    }

    const user = () => {
        return storageUser
    }

    const updateUser = (user: IAuthData) => {
        setStorageUser(user)
    }

    const explainer = (id: ExplainerID) => {
        const values = _getUserValues(userValues)
        return values.explainers.find(x => x.id === id)
    }

    const updateExplainer = (explainer: ExplainerState) => {
        setUserValues(values => {
            const userValues = _getUserValues(values)
            const index = userValues.explainers.findIndex(x => x.id === explainer.id)
            if (index >=0 ) {
                userValues.explainers[index] = explainer
            } else {
                userValues.explainers.push(explainer)
            }

            // update user data 
            values[values.findIndex(u => u.id === userValues.id)] = userValues

            return values 
        })
    }

    const selectedCameraId = () => {
        const values = _getUserValues(userValues)
        return values.cameraID
    }

    const updateSelectedCameraId = (id: string) => {
        
        setUserValues(values => {
            const userValues = _getUserValues(values)
            userValues.cameraID = id     
            return values
        })
    }

    return {
        user,
        updateUser,
        explainer,
        updateExplainer,
        selectedCameraId,
        updateSelectedCameraId
    }
}