import { Box, SxProps, Typography } from "@mui/material"
import useExplainerShouldShow, { ExplainerID } from "hooks/UseExplainersShouldShow"
import { ReactNode, useEffect, useState } from "react"
import { copyPrimaryRegular, darkPrimaryDefault, SVGStyle } from "XJumpTheme"
import { XJButton } from "../Buttons/XJButton"
import XJCheckBox from "../XJCheckBox"
import { XJModal } from "UI Elements/PopUps/XJModal"
import { ReactComponent as ExplainerGeneral } from '../../svg/ExplainerGeneral.svg'


type IXJExplainerProps = {

    id: ExplainerID,
    title: string
    welcomeText: string
    iconSrc?: ReactNode
    explainerSteps?: JSX.Element[],
    children?: React.ReactNode,
    conditionFn?: () => boolean,    // gotta be a useCallback to avoid double render 
    minHeight?: string | number
}

export const ExplainerStepSx: SxProps =
{
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    alignItems: 'center'
}

export const ExplainerStepCopy: SxProps =
{
    ...copyPrimaryRegular,
    color: darkPrimaryDefault

}

export default function XJExplainer(props: IXJExplainerProps) {

    const explainerHook = useExplainerShouldShow()
    const explainerState = explainerHook.ExplainerState(props.id)
    const StepsCount = props.explainerSteps ? props.explainerSteps.length : 0

    const [open, setOpen] = useState<boolean>(false)
    const [step, setStep] = useState<number>(-1)

    const [DontShowAgain, setDontShowAgain] = useState(explainerState.dontShowAgain)

    const conditionalValue = props.conditionFn ? props.conditionFn() : undefined

    useEffect(() => {

        let condFunction = true
        
        if (props.conditionFn) {
            condFunction = props.conditionFn()
        }

        setOpen(explainerHook.ShouldShow(props.id) && condFunction)

    }, [props.conditionFn, conditionalValue])

    useEffect(() => {

        if (open) {
            explainerHook.ShowedToUser(props.id)
        }

    }, [open])

    // if this is the last time - automatically set don't show again 
    const isLastTime = explainerHook.IsLastTime(props.id)
    useEffect(() => {
        if (isLastTime) {
            setDontShowAgain(true)
        }
    }, [isLastTime])

    useEffect(() => {

        explainerHook.DontShowAgain(props.id, DontShowAgain)

    }, [DontShowAgain])


    const StepScreen = () => {

        return props.explainerSteps && <Box display={'flex'} flexDirection={'column'} gap={16}>
            <Box >
                <Typography variant="h1" textAlign={'center'}>
                    {props.title}
                </Typography>
            </Box>
            <Box minHeight={props.minHeight ? props.minHeight : 'inherited'}>
                {props.explainerSteps[step]}
            </Box>
        </Box>
    }

    const onNext = () => {
        setStep(step + 1 < StepsCount ? step + 1 : step)
    }

    const onBack = () => {
        if (step === -1) {
            onClose()
            return
        }

        setStep(step >= -1 ? step - 1 : step)
    }

    const WelcomeScreen = () => {

        return <>
            <Box display={'flex'} gap={8} flexDirection={'column'}>
                <Box>
                    <Typography variant="h1" textAlign={'center'}>
                        {props.title}
                    </Typography>
                </Box>

                <Box>
                    <Box textAlign={'justify'} sx={{ ...copyPrimaryRegular, color: darkPrimaryDefault }}>
                        <div dangerouslySetInnerHTML={{ __html: props.welcomeText }}></div>
                    </Box>
                </Box>

                <Box>{props.children}</Box>
            </Box>
        </>
    }

    const OnDontShowAgainClicked = (value: boolean) => {

        setDontShowAgain(value)
        if (!value) {
            // if user turns off don't show again - reset the counter 
            explainerHook.ResetShownCounter(props.id)
        }
    }

    const onClose = () => {
        setOpen(false)
    }

    const IconSX: SxProps = {

        //  height: '48px',
        width: '50%',
        //borderRadius: '50%',
        //  backgroundColor: theme.palette.background.default,
        //  padding: '48px'
    }

    return (
        <XJModal show={open} onClose={onClose}>
            <Box sx={IconSX}>
                {props.iconSrc ? props.iconSrc : <ExplainerGeneral style={SVGStyle} />}
            </Box>
                
            <Box
                px={16}
                py={8}
                display={'flex'}
                flexDirection={'column'}
                gap={16}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight='400px'
            // minHeight={props.minHeight ? props.minHeight : undefined}
            >
                <Box>
                    {step === -1 ? <WelcomeScreen /> : <Box>{StepScreen()}</Box>}
                </Box>

                <Box display={'flex'} justifyContent={'center'} gap={8} flexDirection={'column'} >
                    <Box display={'flex'} justifyContent={'center'} gap={16} >
                        <XJButton width="70px" variant={StepsCount > 0 ? 'tertiary' : 'secondary'} onClick={onBack}> {step === -1 ? StepsCount > 0 ? 'Skip' : 'Close' : 'Back'}</XJButton>

                        {StepsCount > 0 && <XJButton width="70px" hidden={step === StepsCount - 1} variant={'secondary'} onClick={onNext}>Next</XJButton>}
                        {StepsCount > 0 && <XJButton width="70px" hidden={step !== StepsCount - 1} variant={'secondary'} onClick={onClose}>Close</XJButton>}
                    </Box>

                    <XJCheckBox checked={DontShowAgain} label={'Do not show this again'} onChange={OnDontShowAgainClicked} />
                </Box>
            </Box>
        </XJModal>
    )
}