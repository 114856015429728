import API from "api-axios"
import { QuestionTutorial } from "Common/QuestionContentDAO"
import { TopicContentDAO } from "Common/TopicContentDAO"
import { LogError } from "Controllers/Logging"




export class TopicContentModel {

    async save(): Promise<boolean> {

        try {

            const TopicContentData =
                {
                    id: this.id,
                    Title: this.title,
                    explanation: this.explanation,
                    tutorialVideoLink: this.tutorialsVideoLink,                   
                    tutorial: JSON.stringify(this.tutorials),
                    AIPrompt: this.AIPrompt,
                    TemplateHint: this.templateHint


                } as TopicContentDAO

            if (!this.id) {


                this.id = (await API.post('/admin/topic/create', TopicContentData)).data.id
                return true
            }
            else {


                await API.post(`/admin/topic/${this.id}/update`, TopicContentData)
                return true

            }

        }
        catch (err) {
            LogError(err)
            throw (err)
        }

        

    }

    id: number | undefined
    title = ''
    explanation = ''
    tutorialsVideoLink: string | undefined
    tutorials: QuestionTutorial[] = []
    AIPrompt: string | null = null
    templateHint = ''

}