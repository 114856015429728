import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom"
import { usePitches } from 'hooks/UsePitches'
import { QaQuestion } from 'Pages/Founder/Interview/Qa_Question'
import { SendPitchToAIScript } from 'Controllers/AdminController'
import { QT_Null } from 'Model/Types'
import { RoutingController } from 'Controllers/RoutingController'
import { useAlert } from 'hooks/UseAlert'
import { useSpinner } from 'UI Elements/XJSpinner'
import { PitchStatus } from 'Common/Enums'
import { LogError } from 'Controllers/Logging'




export function SegmentQuestionsEdit(props: { clarify?: boolean }) {


    const { pid } = useParams<any>();   // Pitch ID 
    const { qid } = useParams<any>();  // question ID

    const pitchId = pid

    // let questionId: number = parseInt(qid)     

    const history = useHistory()
    const spinner = useSpinner()
    const pitchHook = usePitches()

    const pitch = pitchHook.getPitch(pitchId)
    const currentQuestion = pitch.getQuestion(Number(qid))
    const segment = currentQuestion.segment

    const alertSuccess = useAlert().success

    const nextPrevURL = (step: QT_Null): string => {


        const URL = RoutingController.QA_Edit(step)

        return URL ? URL : RoutingController.SegmentEditor(segment)

    }


    const nextQuestion = async () => {

        const nextQuestion = segment.goNext(currentQuestion)

        const URL = nextPrevURL(nextQuestion)

        if (!nextQuestion) {

            // END OF INTERVIEW 

            spinner(true)

            try {

                const result = await SendPitchToAIScript(pitch)
                await pitch.setStatusAndSave(PitchStatus.script)
                if (result && pitch && pitch.id) {
                    await pitchHook.reloadPitch(pitch.id)
                }
                else
                {
                    LogError (`SendPitchToAIScript failed for id: ${pitch.id}`)
                }

            }
            catch (err) {
                LogError(err)
            }
            finally 
            {
                spinner(false)
                history.push(URL)

            }

        }
        else {
            spinner(false)
            history.push(URL)

        }

    }

    const prevQuestion = () => {


        const PrevQuestion = segment.goBack(currentQuestion)
        const URL = nextPrevURL(PrevQuestion)

        history.push(URL)

    }


    const FinishLater = async () => {

        if (!props.clarify) {
            spinner(true)
            
            const result = await SendPitchToAIScript(pitch)
            if (result && pitch && pitch.id) {
                await pitchHook.reloadPitch(pitch.id)
            }
            spinner(false)
        }

        history.push(RoutingController.SegmentEditor(currentQuestion.segment))

        if (props.clarify) alertSuccess("Answer updated and sent to script review")

    }

    return <QaQuestion
        currentQuestion={currentQuestion}
        onNextQuestion={nextQuestion}
        onPrevQuestion={prevQuestion}
        onFinishLater={FinishLater}
        FinishLaterLabel={props.clarify ? 'Cancel' : 'Save'}
        clarify={props.clarify}

    />

}
