import { Box, SxProps, Typography } from "@mui/material"

interface XJPitchuraLogoProps {
    variant: 'PNG' | 'PNG+Type' | 'SVG' | 'SVG+Type',
    color?: string,
    backgroundColor?: string,
    sx?: SxProps,
    noBackground?: boolean
}

export function XJPitchuraLogo(props: XJPitchuraLogoProps) {

    const styles = {
        pngCommon: {
            borderRadius: '2px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginX: 'auto',
            ...props.sx
        } as SxProps,
        png: {
            backgroundImage: `url('PitchuraLogo.png')`,
            width: '24px !important',
            height: '24px !important',
        },
        pngTyped: {
            backgroundImage: `url('PitchuraLogoTyped.png')`,
            width: '155px !important',
            height: '32px !important',
        }
    }

    const fillColor = props.noBackground ? 'none' : props.backgroundColor ?? "#519A7E"
    const color = props.color ?? 'white'

    const SVGLogo = () => {
        return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.0554 27.7837C22.0755 28.8038 23.7293 28.8038 24.7494 27.7837L29.235 23.2982C30.255 22.2781 30.255 20.6243 29.235 19.6042L21.847 12.2163C20.8269 11.1962 19.1731 11.1962 18.153 12.2163L10.765 19.6042C9.74498 20.6243 9.74498 22.2781 10.765 23.2982L15.2506 27.7837C16.2707 28.8038 17.9245 28.8038 18.9446 27.7837L17.6253 26.4645C17.3339 26.7559 16.8613 26.7559 16.5699 26.4645L12.0843 21.9789C11.7929 21.6875 11.7929 21.2149 12.0843 20.9235L19.4723 13.5355C19.7637 13.2441 20.2363 13.2441 20.5277 13.5355L27.9157 20.9235C28.2071 21.2149 28.2071 21.6875 27.9157 21.9789L23.4301 26.4645C23.1387 26.7559 22.6661 26.7559 22.3747 26.4645L17.8892 21.9789C17.5977 21.6875 17.5977 21.2149 17.8892 20.9235L19.4723 19.3404C19.7637 19.0489 20.2363 19.0489 20.5277 19.3404L22.1108 20.9235C22.4023 21.2149 22.4023 21.6875 22.1108 21.9789L21.0554 23.0343L22.3747 24.3536L23.4301 23.2982C24.4502 22.2781 24.4502 20.6243 23.4301 19.6042L21.847 18.0211C20.8269 17.001 19.1731 17.001 18.153 18.0211L16.5699 19.6042C15.5498 20.6243 15.5498 22.2781 16.5699 23.2982L21.0554 27.7837Z" fill={color} />
        </svg>
    }


    switch (props.variant) {
        case 'PNG':
            return <Box sx={{ ...styles.pngCommon, ...styles.png }} />
            
        case 'PNG+Type':
            return <Box sx={{ ...styles.pngCommon, ...styles.pngTyped }} />

        case 'SVG':
            return <SVGLogo/>
        
        case "SVG+Type":

            return <Box display={'flex'} gap={0} alignItems={'center'}>

                <SVGLogo/> <Typography color={color} variant="h2">PITCHURA</Typography>

            </Box>
            
    }

    

}