import { Box, SxProps, Typography } from "@mui/material"
import { useState } from "react"
import {  XJButton } from "UI Elements/Buttons/XJButton"
import { CropMedia, DragNDropMedia, UploadType, DragNDropContentVisualAid } from "./DragNDropMedia"

interface NewVisualAidProps {
    onMediaCropped: (mediaURL: string, filename: string, extension: string, size: number) => void,
    onCancel: () => void
}

export const NewVisualAidMedia = (props: NewVisualAidProps) => {

    const [mediaURL, setMediaUrl] = useState('')
    const [filename, setFilename] = useState('')
    const [extension, setExtension] = useState('')
    const [size, setSize] = useState(0)

    const onMediaSelected = (mediaURL: string, filename: string, extension: string, size: number) => {
        setMediaUrl(mediaURL)
        setFilename(filename)
        setExtension(extension)
        setSize(size)
    }

    const onCropComplete = (aMediaURL: string) => {
        props.onMediaCropped(aMediaURL, filename, extension, size)
    }

    const onCropCancel = () => {
        props.onCancel()
    }


    // MARK: - Render 

    const dropSX = {
        picker: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "250px"
        } as SxProps
    }

    return <>
        <Box sx={{ minHeight: '400px' }}>
            <Box mb={16}>
                <Typography variant="h2">Upload slide</Typography>
            </Box>
            <Box sx={dropSX.picker}>
                <DragNDropMedia 
                    type={UploadType.image}
                    content={DragNDropContentVisualAid}
                    onMediaSelected={onMediaSelected}
                />
                {/*  <Box sx={styles.previousAids}>
                    {renderPreviousMedia()}
                </Box> */}
                <Box mt={32}>
                    <XJButton onClick={props.onCancel} variant={'tertiary'}>Cancel</XJButton>
                </Box>
            </Box>
        </Box>

        <Box>
            <CropMedia mediaURL={mediaURL} onCancel={onCropCancel} onMediaComplete={onCropComplete} />
        </Box>
    </>
}
