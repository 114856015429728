export const pitchTags : string[] = 
    [
        'Crypto / Web',
        'SaaS',
        'B2B',
        'Fintech',
        'Developer Tools',
        'Marketplace',
        'Artifical Intelligence',
        'Machine Learning',
        'E-commerce',
        'Climate',
        'Education',
        'Consumer',
        'Consumer Health Services',
        'Data Engineering',
        'Healthcare',
        'API',
        'Payments',
        'Hardware',
        'Productivity',
        'Analytics',
        'Proptech',
        'Biotech',
        'Community',
        'Logistics',
        'Digital Health',
        'Hard Tech',
        'Gaming',
        'Medical Devices',
        'Robotics',
        'Security',
        'Computer Vision',
        'Enterprise',
        'Health Tech',
        'Social',
        'Design Tools',
        'Food Tech',
        'Construction',
        'Delivery',
        'Neobank',
        'Open Source',
        'Recruiting',
        'Generative AI',
        'HR Tech',
        'Insurance',
        'Mental Health Tech',
        'Telemedicine',
        'eLearning',
        'Subscriptions',
        'Sales',
        'DevSecOps',
        'Entertainment',
        'Banking as a Service',
        'IoT',
        'Real Estate1',
        'Creator Economy',
        'Supply Chain',
        'Video',
        'Deep Learning',
        'Collaboration',
        'Grocery',
        'DeFi',
        'Travel',
        'AI-powered Drug Discovery',
        'Documents',
        'GovTech',
        'Retail',
        'Therapeutics',
        'AI-Enhanced Learning',
        'ClimateTech',
        'FinOps',
        'Human Resources',
        'No-code',
        'AIOps',
        'NLP',
        'Robotic Process Automation',
        'Electric Vehicles',
        'Diagnostics',
        'Investing',
        'Nonprofit',
        'Telehealth',
        'Virtual Reality',
        'Augmented Reality',
        'Energy',
        'Media',
        'Messaging',
        'Remote Work',
        'Transportation',
        'Housing',
        'Identity',
        'NFT',
        'Carbon Capture and Removal',
        'Drug discovery',
        'Kubernetes',
        'Synthetic Biology',
        'Warehouse Management Tech',
        'Ghost Kitchens',
        'Manufacturing',
        'Social Media',
        'Solar Power',
        'Airplanes',
        'Drones',
        'Health Insurance',
        'Sports Tech',
        'Assistive Tech',
        'Compliance',
        'Space Exploration',
        'Sustainable Fashion',
        'Agriculture',
        'Customer Success',
        'Fitness',
        'Genomics',
        'Renewable Energy',
        'Satellites',
        'Social Network',
        'Autonomous Trucking',
        'Energy Storage',
        'Food Service Robots & Machines',
        'Gene Therapy',
        'Legal Tech',
        'Payroll',
        'Privacy',
        'COVID-19',
        'Metaverse',
        'Nanotechnology',
        'Neurotechnology',
        'Primary Care',
        'Regtech',
        'Sleep Tech',
        'Commercial Space Launch',
        'Digital Freight Brokerage',
        'Fertility Tech',
        'Oncology',
        '3D Printing',
        'Air Taxis',
        'Autonomous Delivery',
        'Cellular Agriculture',
        'Civic Tech',
        'Cloud Workload Protection',
        'Crowdfunding',
        'Femtech',
        'Geographic Information System',
        'Income Share Agreements',
        'Legal',
        'Microfluidics',
        'Next-gen Network Security',
        'Anti-Aging',
        'Cannabis',
        'Cloud Gaming',
        'Medical Robotics',
        'Nanosensors',
        'Podcasts',
        'Smart Clothing',
        'Alternative Battery Tech',
        'Auto Commerce',
        'Beauty',
        'Biometrics',
        'Bioplastic',
        'Cashierless Checkout',
        'Cultivated Meat',
        'Cultured Meat',
        'DAO',
        'Industrial Workplace Safety',
        'Nanomedicine',
        'Pediatrics',
        'Security Orchestration, Automation and Response (SOAR)',
        'Speech Recognition',
        'Vertical Farming',
        'CRISPR',
        'Conversational Banking',
        'Election Tech',
        'Hydrogen Energy',
        'Indoor Mapping',
        'Microinsurance',
        'Quantum Computing',
        'Reinforcement Learning',
        'Small Modular Reactors',
        'Smart Home Assistants',
        'Sustainable Tourism',
        'Unmanned Vehicle',
        'VR Health',
        '3D Printed Foods',
        'Alternative Fuels',
        'Art Trading Platforms',
        'Autonomous Shipping',
        'Batteryless IoT Sensors',
        'Clean Meat',
        'Computational Storage',
        'Cryogenics',
        'Deepfake Detection',
        'Edge Computing Semiconductors',
        'Fusion Energy',
        'IoT Security',
        'Lab-on-a-chip',
        'Nanomedicines',
        'Robotic Surgery',
        'Rocketry',
        'Smart Locks'
]

