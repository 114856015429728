import { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { useTheme, Typography, Grid } from '@mui/material'
import { useHistory, useParams } from 'react-router'
import { VisualAidModel } from 'Model/VisualAidModel'
import XJBreadcrumbs from 'UI Elements/XJBreadcrumbs'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { usePitches } from 'hooks/UsePitches'
import { Timeline } from './Timeline'
import { VisualAidsList } from './VisualAidsList'
import XJExplainer, { ExplainerStepCopy, ExplainerStepSx } from 'UI Elements/PopUps/XJExplainer'
import { mapVideoModel, VideoPlayer } from '../../Video/Player/VideoPlayer'
import { Header, HeaderRightButtons, PageTitle } from 'XJumpTheme'
import { Handle } from 'tools/Types'
import { RoutingController } from 'Controllers/RoutingController'
import { useAlert } from 'hooks/UseAlert'
import { useSpinner } from 'UI Elements/XJSpinner'

import { ExplainerID } from 'hooks/UseExplainersShouldShow'
import { VisualAidURLResolve } from 'Model/VideoModel'
import { LogError } from 'Controllers/Logging'


export type AidCallback = (aid: VisualAidModel) => void


interface PathParams {
    pid: string,
    sid: string,
    vid: string
}

export default function VideoEditing() {

    const { pid, sid } = useParams<PathParams>()

    // TODO: get updated video model from the  backend
   
    const history = useHistory()

    const pitch = usePitches().getPitch(Number(pid))
    const segment = pitch.getSegmentById(parseInt(sid))
    const [video, setVideo] = useState(segment.video!.clone())
    const [visualAidEditing, setVisualAidEditing] = useState(false)


    const [isUploading, setIsUploading] = useState(false)

    const spinner = useSpinner()

    const alert = useAlert()

    useEffect(() => {
        // subscribes for url updates from original video model
        // because clone would never get an update 
        const onUrlUpdate = (url: string, aids: VisualAidURLResolve[]) => {
            if (video.url.isEmpty()) {
                const newVideo = video.clone()
                newVideo.updateServerUrl(url, aids)
                setVideo(newVideo)
            }
        }
        // subcribe for video url update 
        segment.video!.subscribeForUrlUpdates(onUrlUpdate)
        return () => {
            // unsubscribe 
            segment.video!.unsubscribeForUrlUpdates(onUrlUpdate)
        }

    }, [segment.video, video.url])


    // MARK: - Player

    let playerPtr: Handle<typeof VideoPlayer>
    const [progress, setProgress] = useState(0)

    const onProgressCallback = (state: {
        played: number
        loaded: number
    }) => {
        setProgress(state.played)
    }


    // MARK: - Video 

    const [videoObject, setVideoObject] = useState(mapVideoModel(video))

    useEffect(() => {

        setVideoObject(mapVideoModel(video))

    }, [video])


    // MARK: - Events 

    const onAidSelected = (aid: VisualAidModel) => {
        playerPtr?.seekTo(aid.startsAt)
    }

    const onAidUpdated = (localID: string, data: object) => {

        setVideo((current) => {
            const newVideo = current.clone()
            const aids = [...newVideo.allVisualAids]

            const index = aids.findIndex(va => va.localID === localID)
            if (index >= 0) {
                aids[index] = Object.assign(aids[index], data) // update only provided fields 
            }
            newVideo.visualAids = aids

            return newVideo
        })
    }

    const onRefreshVisualAidsList = (vaList?: VisualAidModel[]) => {

        if (vaList) {
            video.visualAids = vaList
        }

        setVideo(video.clone())
    }

    const onTimelineMarkerMoved = (pos: number) => {
        
        let newPos = pos

        if (newPos < 0) {
            newPos = 0
        } else if (newPos > video.duration) {
            newPos = video.duration
        }

        playerPtr?.seekTo(pos)
    }


    // MARK: - Actions 

    const onSave = async () => {

        if (segment && segment.video && video) {

            spinner(true)

            // Only update visual aids in the original video, as it's only property that's editable in the VideoEditor 
            // This allows to keep async updates that won't be reflected in the VideoEditor's copy 
            segment.video.visualAids = video.allVisualAids

            segment.video.save().then(res => {

                history.push(RoutingController.SegmentEditor(segment))
                alert.successSave()

            })
            .catch(err => {
                LogError(err)
                alert.error("Error while saving")
            })
            .finally(() => {
                spinner(false)
            })

        }
    }

    const onCancel = () => {
        if (segment) {
            history.push(RoutingController.SegmentEditor(segment))
        }
    }


    // MARK: - Render 

    const theme = useTheme()

    const renderSaveButtons = () => {
        return (
            <Box sx={HeaderRightButtons}>
                <XJButton variant={'tertiary'} onClick={onCancel}>Cancel</XJButton>
                <XJButton variant={'secondary'} disabled={isUploading} onClick={onSave}>Save</XJButton>
            </Box>
        )
    }

    const styles = {

        timeline: {
            mt: theme.spacing(20)
        },
        visualAids: {
            pl: theme.spacing(16)
        }
    }

    return (

        <Grid container={true}>

            <XJExplainer

                id={ExplainerID.VideoEditor}
                welcomeText={"Add slides to your video to make a stronger point."}
                title={'Video Editor'}
                explainerSteps={[

                    <Box sx={ExplainerStepSx}>
                        <Typography variant='h2'>
                            1. Upload a "slide"
                        </Typography>
                        <Box sx={ExplainerStepCopy}>
                            A slide can be any image file and is used to illustrate a point you're making on the video: a chart, a picture of something, etc.
                        </Box>
                    </Box>,
                    <Box sx={ExplainerStepSx}>
                        <Typography variant='h2'>
                            2. Set time and length
                        </Typography>
                        <Box sx={ExplainerStepCopy}>
                            Once uploaded, the slide will show on the video timeline.  You can then move on the timeline to set start and end. You can also set how long the slide will show for.
                        </Box>
                    </Box>,
                    <Box sx={ExplainerStepSx}>

                        <Typography variant='h2'>
                            3. Picture in Picture
                        </Typography>
                        <Box sx={ExplainerStepCopy}>
                            By default, the slide will be shown on the entire screen. You can select a Picture in Picture mode that also shows your face in one of the corners of the slide.
                        </Box>

                    </Box>

                ]

                }

            />


            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                {!visualAidEditing && renderSaveButtons()}
            </Grid>

            <Grid item xs={12} sx={PageTitle}>
                <Typography variant="h1">Segment: "{segment.title}"</Typography>
            </Grid>



            <Grid item xs={7} >
                <VideoPlayer
                    id="VideoEditorPlayer"
                    ref={(child) => { playerPtr = child! }}
                    video={videoObject}
                    visualAids={video.visualAids}

                    showDefaultControls

                    onProgress={onProgressCallback}
                />

                <Timeline
                    sx={styles.timeline}
                    video={video}
                    progress={progress}
                    onMarkerPositionChange={onTimelineMarkerMoved}
                    onAidChanged={(va: VisualAidModel) => {
                        onRefreshVisualAidsList()
                    }}
                />
            </Grid>

            <Grid item xs={5} sx={styles.visualAids} >
                <VisualAidsList
                    onUploadInProgress={(inProgress) => {

                        setIsUploading(inProgress)
                    }}
                    video={video}
                    progress={progress}
                    onAidSelected={onAidSelected}
                    onAidUpdated={onAidUpdated}
                    onVisualAidEdit={(editing) => { setVisualAidEditing(editing) }}
                    onAidsListUpdated={(aids) => {onRefreshVisualAidsList(aids)}}
                />
            </Grid>
        </Grid>
    )
}