import { Box, SxProps, Typography } from "@mui/material"
import { VideoDimensions } from "Common/Enums"
import { tertiaryDefault } from "XJumpTheme"
import VideoCamera from "./VideoCamera"
import { classes } from "./VideoSetup"


interface IVideoSetupFrameProps {
    onComplete: () => void

}

const frame: SxProps = {

    position: 'absolute',
    border: '3px solid',
    width: '250px',
    height: '185px',
    top: '68px',
    left: '125px',
    borderColor: tertiaryDefault

}


export default function VideoSetupFrame(props: IVideoSetupFrameProps) {

    return <>


        <Box sx={classes.header}>

            <Typography variant="h2">

               looks

            </Typography>
        </Box>
        <Box sx={classes.explaination} textAlign={'center'}>

            Position yourself in front of the camera so your face fills out the target frame.  Adjust lightning around you so your face is clear. It's best to wear a solid color shirt. It doesn't need to be a collar shirt.  Use the same shirt for all your recordings. 

        </Box>

        <Box sx={classes.mainBox} >

            <Box mx={'auto'} sx={{ textAlign: 'center', position: 'relative' }}>

                <Box sx={frame}></Box>
                <Box sx={{ width: '450px', aspectRatio: `${VideoDimensions.aspectRatio}` }} mx={'auto'}>
                    <VideoCamera />
                </Box>

            </Box>
        </Box>
    </>
}