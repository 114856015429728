import { Box, Dialog, Typography } from "@mui/material"
import { useKeyPress } from "hooks/UseKeyPress"
import { ReactNode } from "react"

import { copyPrimaryRegular, darkPrimaryDefault } from "XJumpTheme"
import {  XJButton } from "../Buttons/XJButton"


interface IXJConfirmationDialogProps {

    open: boolean,
    onConfirm: () => void,
    onCancel?: () => void,
    title?: string,
    message?: string,
    ConfirmButtonText?: string,
    CancelButtonText?: string,
    SVGComponent?: ReactNode
    SingleButton? : boolean

}

export default function XJConfirmationDialog(props: IXJConfirmationDialogProps) {

    useKeyPress({ char: 'Enter' }, props.open ? props.onConfirm : undefined)
    
    return <>

        <Dialog  onClose={props.onCancel} open={props.open}  PaperProps={{ sx: { borderRadius: '2px' } }} >

            <Box display={'flex'} flexDirection={'column'} gap={16} p={32} alignItems={'center'}>
                {props.SVGComponent && <Box>

                    {props.SVGComponent}
                    
                </Box>}

                <Box display={'flex'} flexDirection={'column'} gap={8} alignItems={'center'} textAlign={'center'}>

                    <Typography variant="h2">
                        {props.title}
                    </Typography>

                    <Box sx={{ color: darkPrimaryDefault, ...copyPrimaryRegular }}>
                        <div dangerouslySetInnerHTML={{
                            __html:props.message ? props.message : '' }} ></div>
                    </Box>

                </Box>

                <Box display={'flex'} gap={16} justifyContent={'center'}>
                   
                    {!props.SingleButton && <XJButton
                        variant={'secondary'}
                        onClick={props.onCancel}
                    >
                        {props.CancelButtonText ? props.CancelButtonText : 'Cancel'}
                    </XJButton>}
                   
                    <XJButton
                        variant={props.SingleButton ? 'secondary' : 'tertiary'}
                        onClick={props.onConfirm}
                    >
                        {props.ConfirmButtonText ? props.ConfirmButtonText : 'Continue'}
                    </XJButton>

                </Box>
            </Box>
        </Dialog>
    </>

}