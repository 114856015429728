import { Box, Grid, Typography } from "@mui/material";
import XJIconButton from "UI Elements/Buttons/XJIconButton";
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";
import { ExplanationBox } from "UI Elements/XJExplanationBox";
import { Header, PageTitle } from "XJumpTheme";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { XJButton } from "UI Elements/Buttons/XJButton";


export function Contact() {

   

    const onWhatsApp = () => {
        window.open('https://wa.me/message/XRJ3S3TC7KGCK1', '_blank')

    }

    return <>

        <Grid container>

            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />

            </Grid>
            <Grid item xs={12}>
                <Box sx={PageTitle}>

                    <Typography variant='h1'>
                        Contact us
                    </Typography>
                    {/* <NotificationsNoneIcon /> */}

                </Box>
            </Grid>

            <Grid item xs={10}>
                <ExplanationBox>
                    For any questions or support issues, please use the button below to contact us via WhatsApp.
                </ExplanationBox>
            </Grid>
            <Grid item xs={2}>

            </Grid>

            <Grid item xs={12} mt={32}>

                    <XJButton onClick={onWhatsApp} >
                        <WhatsAppIcon />
                        Contact Us
                    </XJButton>

                
            </Grid>
        </Grid>
    </>


}