
import { Box, Grid, SxProps, Typography } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import { XJButton } from "UI Elements/Buttons/XJButton"
import XJImage from "UI Elements/XJImage"
import { useIntersectionObserver } from "usehooks-ts"
import { darkPrimaryDefault, landingCopyPrimary, landingCopySecondary, landingH1, landingH2, landingH3, landingLinkPrimaryDefault, landingLinkSecondaryDefault, lightPrimaryOverlay48, primaryDefault, secondaryDefault, secondaryDefaultOverlay8, SVGStyle, theme, whiteDefault, whiteOverlay16, whiteOverlay60 } from "XJumpTheme"

import ReactPlayer from "react-player"
import { LinkedInButton } from "Components/XJLinkedInButton"
import { LinkVariant, XJLink } from "UI Elements/XJLink"
import {  XJPitchuraLogo } from "UI Elements/XJPitchuraLogo"

import { ReactComponent as WhyPitchuraIcon } from '../../svg/whyPitchura.svg'
import { ReactComponent as WhyPitchuraScript } from '../../svg/whyPitchuraScript.svg'
import { ReactComponent as WhyPitchuraHosting } from '../../svg/whyPitchuraHosting.svg'
import { ReactComponent as CostIsFree } from '../../svg/CostIsFree.svg'
import { ReactComponent as WhyPitchuraVideo } from '../../svg/whyPitchuraVideo.svg'

const enum sections {
    Hero,
    Why,
    WhoWeAre,
    Package,
    Pricing,
    FAQ
}

export function Home() {

    const [sectionInView, setSectionInView] = useState(sections.Hero)

    const ObserverThreshold = 0.4

    const setActiveSection = (section: sections) => {
        setSectionInView(section)
    }

    const HeroRef = useIntersectionObserver({
        threshold: ObserverThreshold,
        onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Hero)
            }

        }
    }).ref

    const WhyPitchuraRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Why)
            }

        }
    }).ref

    const WhoAreWeRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.WhoWeAre)
            }

        }
    }).ref

    const PackageRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Package)
            }

        }
    }).ref

    const PricingRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.Pricing)
            }

        }
    }).ref

    const FAQRef = useIntersectionObserver({
        threshold: ObserverThreshold, onChange: (IsIntersecting, entry) => {

            if (IsIntersecting) {
                setActiveSection(sections.FAQ)
            }

        }
    }).ref

    const ScrollIntoView = (section: sections) => {

        const el = document.querySelector<HTMLElement>(`[data-index='${section}']`)

        if (el) {
            el.scrollIntoView({ block: 'center', behavior: "smooth" })
        }
        
    }

    const [open, setOpen] = useState(false)

    const NavBar = () => {

        const LinkItem = (label: string, section: sections) => {

            const onClick = () => {
                setOpen(false)
                ScrollIntoView(section)
            }

            const styles = {
                link: {
                    textDecoration: 'none'
                }, 
                text: {
                    color: darkPrimaryDefault,
                    textDecoration: 'none'
                },
            }

            return (
                <Link onClick={onClick} style={styles.link} to={'#'}>
                    <Typography sx={styles.text} variant="h2">
                        {label}
                    </Typography>
                </Link>
            )
        }

        const TextItem = (label: string) => {
            const styles = {
                color: secondaryDefault,
                textDecoration: 'underline'
            }

            return <Typography sx={styles} variant="h2">{label}</Typography>
        }

        const styles = {
            wrapper: {
                position: 'absolute',
                left: 0,
                top: 0, 
                right: 0
            } as SxProps,
            root: {
                display: 'flex',
                justifyContent: 'center',
                px: { xs: 8, md: 24 },
                height: '48px',
                alignItems: 'center',
                backgroundColor: whiteOverlay16,
                position: 'fixed',
                width: '100%',
                zIndex: theme.zIndex.appBar,
                boxShadow: theme.dropshadow.card.default
            } as SxProps,
            logo: {
                display: 'flex', 
                gap: 4, 
                alignItems: 'center', 
                position: 'absolute', 
                my: 'auto', 
                left: {xs: '16px', md: '48px'}
            } as SxProps,
            menu: {
                gap: 16, 
                display: {xs: 'none', lg: 'flex'}
            } as SxProps,
            buttons: {
                display: 'flex', 
                gap: 8, 
                justifyContent: 'end',
                position: 'absolute', 
                my: 'auto', 
                right: {xs: '16px', md: '48px'}
            } as SxProps
        }

        return (
            <Box sx={styles.wrapper}>
                <Box sx={styles.root}>
                    <Box sx={styles.logo}>
                        <XJPitchuraLogo variant='PNG+Type' sx={{mx: 0}} />
                    </Box>

                    <Box sx={styles.menu}>
                        {sectionInView === sections.Hero ? TextItem("Home") : LinkItem("Home", sections.Hero)}
                        {sectionInView === sections.Why ? TextItem("Why pitchura") : LinkItem("Why pitchura", sections.Why)}

                        {sectionInView === sections.Package ? TextItem("how it works") : LinkItem("how it works", sections.Package)}
                        {sectionInView === sections.Pricing ? TextItem("Price") : LinkItem("Price", sections.Pricing)}
                        {sectionInView === sections.WhoWeAre ? TextItem("about us") : LinkItem("about us", sections.WhoWeAre)}
                        {/* {sectionInView === sections.FAQ ? TextItem("FAQ") : LinkItem("FAQ", sections.FAQ)} */}
                    </Box>

                    <Box sx={styles.buttons} > 
                        <XJButton to="/dashboard" variant={'tertiary'}>Login</XJButton>
                        <XJButton to="/signup" variant={'secondary'}>Get Started</XJButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const sectionHeight = '852px'

    const Hero = () => {

        const style: SxProps = {

            pt: {xs: '94px', md: '115px'},
            display: 'flex',
            justifyContent: 'center',
            width: { xs: '90%', md: '60%' },
            maxWidth: {md: '618px'},
            flexDirection: 'column',
            alignItems: 'center',
            mx: 'auto',
            pb: {xs: '32px', md: '67px'},
            height: {xs: 'auto', md: sectionHeight}

        }

        const playerConfig = {
            file: {
                attributes: {
                    playsInline: true
                }
            }
        }

        return <Box sx={{
            backgroundColor: secondaryDefault
        }}>
            <Box sx={style}
                ref={HeroRef}
                data-index={sections.Hero}
            >

                <Box sx={{ ...landingH1, color: whiteDefault }}>
                    the video pitch platform for startups
                </Box>

                <Box sx={{
                    ...landingCopyPrimary,
                    color: lightPrimaryOverlay48,
                    textAlign: 'center',
                    mt: 16
                }}>
                    A 5-minute pitch video can open doors with investors. Pitchura helps you write, record, host and distribute your pitches - securely.
                </Box>

                <Box mt={32} width={{ xs: '100%', md: '600px' }}>

                    <Box sx={{ position: 'relative', pt: '56.25%' }}>
                        <ReactPlayer
                            config={playerConfig}
                            url={'https://videos.app.pitchura.ai/home.mov'}
                            controls
                            width='100%'
                            height='100%'
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0
                            }}
                        />
                    </Box>

                </Box>
            </Box>
        </Box>
    }

    const WhyPitchura = () => {

        const styles = {
            root: {
                width: '100%',
                backgroundColor: whiteOverlay16,
                height: {xs: 'auto', md: sectionHeight}
            } as SxProps,
            content: {
                display: 'flex',
                width: {xs: '85%', md: '538px'},
                height: {xs: 'auto', md: sectionHeight}, 
                flexDirection: 'column',
                gap: '32px',
                justifyContent: 'center', 
                alignItems: 'flex-end',
                marginTop: {xs: '32px', md: 'auto'},
                marginLeft: 'auto',
                marginRight: {xs: 'auto', md: '16px'}
            } as SxProps,
            icon: {
                height: '100%',
                display: 'flex',
                marginLeft: {xs: 'auto', md: '16px'},
                marginRight: 'auto',
                alignItems: 'center',
                width: {xs: '85%', md: '538px'}
            } as SxProps,
            text: {
                ...landingCopyPrimary, 
                color: darkPrimaryDefault
            } as SxProps
        }

        return (
            <Box sx={styles.root} ref={WhyPitchuraRef} data-index={sections.Why}>

                <Grid container>

                    <Grid item xs={12} md={6}>
                        
                        <Box sx={styles.content}> 

                            <Box sx={{ ...landingH1, ...{textAlign: 'left'} }} >
                                Video now opens doors
                            </Box>

                            <Box sx={styles.text}>

                                {/*  Investors see hundreds of pitches a year. To ensure an efficient use of their time, they are selective on which founders they meet.  

                                <br />
                                <br /> */}
                                Historically, it has been difficult for founders who were not connected, through professional or school networks, to get a meeting with investors.
                                <br /> <br />
                                {/* But now investors are open to listen to short 5-minute video pitches to decide whether to take meetings with founders they don't know, coined <a target={'_blank'} href='https://x.com/Jason/status/1402386798827892737'>"Loom, Zoom, Room"</a> by investor <a href={'https://x.com/Jason'} target={'_blank'}>@Jason</a> Calacanis and <a href='https://www.loom.com/blog/loom-zoom-room' target={'_blank'}>explained</a> in more detail by Loom CEO, Joe Thomas. */}
                                But now investors are open to listen to short 5-minute video pitches to decide whether to take meetings with founders they don't know, coined <XJLink variant={LinkVariant.primary} absolute newWindow sx={landingLinkPrimaryDefault} to='https://x.com/Jason/status/1402386798827892737'>"Loom, Zoom, Room"</XJLink> by investor <XJLink variant={LinkVariant.primary} newWindow absolute sx={landingLinkPrimaryDefault} to={'https://x.com/Jason'}>@Jason</XJLink> Calacanis and <XJLink variant={LinkVariant.primary} absolute newWindow sx={landingLinkPrimaryDefault} to='https://www.loom.com/blog/loom-zoom-room'>explained</XJLink> in more detail by Loom CEO, Joe Thomas.

                                <br /> <br />
                                Pitchura is taking this evolution to the next level, helping founders produce to-the-point investor-grade 5-mimute pitch videos.
                                <br /><br />
                                The first impression you make is the only impression you'll make.

                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} alignItems={'center'}>
                        <Box sx={styles.icon}>
                            <Box sx={{
                                /*  //  width: '100%',
                                height: '400px',
                                backgroundColor: lightPrimaryOverlay32,
                                borderRadius: '10px',
                            */

                                // mx: '15%',
                                my: {xs: '32px', md: 0}
                            }}>

                                <WhyPitchuraIcon style={SVGStyle} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    const WhoAreWe = () => {

        const styles = {
            root: {
                width: '100%',
                height: {xs: 'auto', md: sectionHeight},
                backgroundColor: whiteOverlay16,
                pt: 10                              // fix for nav bar covering over title 
            } as SxProps,
            content: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 20,
                width: {xs: '85%', md: '50%'},
                maxWidth: '763px',
                mx: 'auto',
                py: {xs: '32px', md: 'auto'},
            } as SxProps,
            text: {
                display: 'flex',
                flexDirection: 'column', 
                gap: 16, 
                alignItems: 'center'
            } as SxProps,
            title: {
                ...landingH1
            } as SxProps,
            copy: {
                ...landingCopyPrimary, 
                color: darkPrimaryDefault, 
                textAlign: 'center' 
            } as SxProps,
            citing: {
                display: 'flex',
                flexDirection: 'column', 
                gap: 8,
                alignItems: 'center',
                maxWidth: '650px'
            } as SxProps,
            message: {
                display: 'flex',
                gap: 8,
                alignItems: 'center'
            } as SxProps,
            chevron: {
                color: secondaryDefault,
                fontFamily: 'Poppins',
                fontSize: '60px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '60px',
                textTransform: 'uppercase'
            } as SxProps,
            messageCopy: {
                ...landingH2, 
                color: secondaryDefault, 
                textAlign: 'center', 
            } as SxProps,
            author: {
                ...landingH3,
                color: darkPrimaryDefault, 
                textAlign: 'center'
            } as SxProps,
            people:
            {
                width: '100%',
                display: 'flex',
                mx: 'auto',
                gap: 16,
                justifyContent: 'space-evenly',
            } as SxProps,
            person: {
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                alignItems: 'center'
            } as SxProps,
            personName: {
                ...landingH2, 
                color: secondaryDefault,
                display: 'flex',
                gap: 8,
                alignItems: 'center'
            } as SxProps, 
            personDescription: {
                ...landingCopySecondary,
                textAlign: 'justify',
                color: darkPrimaryDefault
            } as SxProps,
            picture: {
                width: '82px',
                height: '82px',
                borderRadius: '41px',
                overflow: 'hidden'
            } as SxProps
        }

        return <> 
            <Box sx={styles.root} ref={WhoAreWeRef} data-index={sections.WhoWeAre}>

                <Box sx={styles.content}>

                    <Box sx={styles.text}>
                        <Box sx={styles.title}>
                            about us
                        </Box>
                        <Box>
                            <Typography sx={styles.copy}>We’ve been in your shoes. We know how hard it is to raise the first money.  It takes dozens of investor meetings to learn what to say and how to say it.   We are serial entrepreneurs whose companies raised over $20 million of venture capital.</Typography>
                            <Typography sx={styles.copy}>We built pitchura for our younger selves. To give to others the help we didn’t have.</Typography>
                        </Box>
                    </Box>

                    <Box sx={styles.citing}>
                        <Box sx={styles.message}>
                            <Box sx={styles.chevron}>
                                «
                            </Box>
                            <Box>
                                <Box sx={styles.messageCopy}>
                                    The best thing a human being can do is to help another human being know more.
                                </Box>

                            </Box>
                            <Box sx={styles.chevron}>
                                »
                            </Box>
                        </Box>
                        <Box sx={styles.author}>
                            Charlie Munger (1924-2023)
                        </Box>
                    </Box>

                    <Box sx={styles.people}>

                        <Grid container>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={11} md={5}>
                                <Box sx={styles.person}>
                                    <Box sx={styles.picture}>
                                        <XJImage src={"/img/miki.jpg"} />
                                    </Box>

                                    <Box sx={styles.personName}>
                                        <Box>Miki Mullor </Box>
                                        <LinkedInButton profileURL={'https://www.linkedin.com/in/mikimullor/'} />
                                    </Box>

                                    <Box sx={styles.personDescription}>
                                        Jobaline, Ancora Technologies, Microsoft, three other start ups, IDF 8200, Reichman University (Israel)
                                    </Box>
                                </Box>

                            </Grid>

                            <Grid item xs={1}></Grid>
                            <Grid item xs={11} md={5}>

                                <Box sx={styles.person} >
                                    <Box sx={styles.picture}>
                                        <XJImage src={"/img/jose.jpg"} />
                                    </Box>
                                    <Box sx={styles.personName}>
                                        <Box> Jose Contreras</Box>
                                        <LinkedInButton profileURL={'https://www.linkedin.com/in/josedanielcontreras/'} />
                                    </Box>

                                    <Box sx={styles.personDescription}>
                                        Microsoft, Jobaline, SAP, 100ThingsClub, University of Miami
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>

                    </Box>
                </Box>

            </Box>
        </>
    }


    const Footer = () => {

        const onEmailClick = (event: any) => {
            window.location.href = 'mailto:info@pitchura.ai'
            event.preventDefault()
        }

        const styles = {
            root: {
                borderTop: '1px solid ' + whiteOverlay60,
                backgroundColor: whiteOverlay16,
                width: '100%',
                height: 'auto',
                pb: {xs: 4, md: 32}
            } as SxProps, 
            links: {
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                gap: {xs: 4, md: 16}, 
                justifyContent: 'center',
                alignItems: 'center',
                py: 12
            } as SxProps,
            link: {
                ...landingLinkSecondaryDefault,
                color: secondaryDefault
            },
            copyright: {
                ...landingCopySecondary,
                display: 'flex',
                justifyContent: 'center',
                color: darkPrimaryDefault
            } as SxProps
        }

        return <Box sx={styles.root}>
            <Box sx={styles.links}>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} to={'/terms'}>Terms of Use</XJLink>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} to={'/privacy'}>Privacy</XJLink>
                <XJLink variant={LinkVariant.secondary} sx={styles.link} color={whiteDefault} to={''} onClick={onEmailClick}>info@pitchura.ai</XJLink>
            </Box>

            <Box sx={styles.copyright}>
                2024 Pitchura Inc. All Rights Reserved ©
            </Box>
        </Box>
    }

    const Package = () => {

        const styles = {
            root: {
                backgroundColor: secondaryDefault,
                width: '100%',
                height: {xs: 'auto', md: sectionHeight},
                pt: {xs: '32px', md: 'auto'},
                pb: {xs: '32px', md: 'auto'},
                display: 'flex', 
                flexDirection: 'column',
                gap: '64px',
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps,
            title: {
                ...landingH1,
                color: whiteDefault
            } as SxProps,
            package: {
                display: 'flex', 
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 16
            } as SxProps,
            packageItem: {
                position: 'relative',
                height: '556px',
                maxWidth: '310px'
            } as SxProps,
            headerNumberContainer: {
                position: 'absolute',
                width: '100%',
                top: '-24px',
            } as SxProps,
            headerNumber: {
                width: '48px',
                height: '48px',
                mx: 'auto',
                backgroundColor: primaryDefault,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            } as SxProps,
            headerNumberCopy: {
                ...landingH2,
                color: whiteDefault,
                width: '24px',
                height: '36px',
                textAlign: 'center',
                margin: 'auto'
            } as SxProps,
            packageCard: { 
                width: '100%', 
                height: '100%', 
                backgroundColor: secondaryDefaultOverlay8,
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                alignItems: 'center',
                justifyContent: 'flex-start', 
                px: 16, 
                pb: 16,
                pt: 24
            } as SxProps,
            icon: {
                pt: 5
            } as SxProps,
            packageText: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'center'
            } as SxProps,
            packageItemDescription: {
                ...landingCopySecondary,
                textAlign: 'justify',
                color: lightPrimaryOverlay48
            } as SxProps
        }

        return <>

            <Box sx={styles.root} ref={PackageRef} data-index={sections.Package}>

                <Box sx={styles.title}>
                    Pitchura:<br /> all-in-one solution
                </Box>

                <Box sx={styles.package}>
                    <Box sx={styles.packageItem} >

                        <Box sx={styles.packageCard}>

                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>1</Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraScript style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH2, color: whiteDefault }}>
                                    Script
                                </Box>

                                {/* <Box sx={packageItemDescription}> */}
                                <Box>
                                    {/* <p> What to tell investors? What's more and less imporatnt? What terms to use?</p>
                                    <p>  By asking you a series of questions about your venture, we develop an outline for your pitch and then use AI to turn it into a script. </p>
                                    <p>    Your script will address the most common questions investors ask when they decide if they want to meet a founder.</p> */}
                                    {/* What to tell investors? What's more and less imporatnt? What terms to use?<Box mb={'8px'} />
                                    By asking you a series of questions about your venture, we develop an outline for your pitch and then use AI to turn it into a script.<Box mb={'8px'} />
                                    Your script will address the most common questions investors ask when they decide if they want to meet a founder. */}

                                    <Typography sx={styles.packageItemDescription}>What to tell investors? What's more and less imporatnt? What terms to use?</Typography>
                                    <Typography sx={styles.packageItemDescription}>By asking you a series of questions about your venture, we develop an outline for your pitch and then use AI to turn it into a script.</Typography>
                                    <Typography sx={styles.packageItemDescription}>Your script will address the most common questions investors ask when they decide if they want to meet a founder.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={styles.packageItem}>

                        <Box sx={styles.packageCard}>
                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>2 </Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraVideo style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH2, color: whiteDefault }}>
                                    Video
                                </Box>

                                <Box>
                                    <Typography sx={styles.packageItemDescription}>The video is recorded directly by Pitchura:</Typography>
                                    <Typography sx={styles.packageItemDescription}>A teleprompter will play the script for you to read at an optimum speed.</Typography>
                                    <Typography sx={styles.packageItemDescription}>The video is split to small parts to make it easy to retake and optimize.</Typography>
                                    <Typography sx={styles.packageItemDescription}>It's easy to make different video versions of the same pitch.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={styles.packageItem} >
                        <Box sx={styles.packageCard}>
                            <Box sx={styles.headerNumberContainer}>
                                <Box sx={styles.headerNumber}>
                                    <Box sx={styles.headerNumberCopy}>3 </Box>
                                </Box>
                            </Box>

                            <Box sx={styles.icon}>
                                <WhyPitchuraHosting style={SVGStyle} />
                            </Box>

                            <Box sx={styles.packageText}>
                                <Box sx={{ ...landingH2, color: whiteDefault }}>
                                    hosting
                                </Box>

                                <Box sx={styles.packageItemDescription} >
                                    <Typography sx={styles.packageItemDescription}>Confidentiality is critical. Pitchura hosts your pitch videos privately: no YouTube, no Vimeo, no Loom.</Typography>
                                    <Typography sx={styles.packageItemDescription}>Pitches are confidential: you have total control of who can view them.</Typography>
                                    <Typography sx={styles.packageItemDescription}>High quality delivery through AWS CDN and variable bitrate videos.</Typography>
                                </Box>
                            </Box>
                        </Box>    
                    </Box>
                </Box>
            </Box >
        </>
    }

    const Pricing = () => {

        const styles = {
            root: {
                width: '100%',
                backgroundColor: whiteOverlay16,
                height: {xs: 'auto', md: sectionHeight}
            } as SxProps,
            icon: {
                height: {xs: 'auto', md: '100%'},
                display: 'flex',
                marginTop: {xs: '32px', md: 'auto'},
                marginLeft: 'auto',
                marginRight: {xs: 'auto', md: '16px'},
                alignItems: 'center',
                width: {xs: '85%', md: '538px'}
            } as SxProps,
            content: {
                display: 'flex',
                width: {xs: '85%', md: '538px'},
                height: {xs: 'auto', md: sectionHeight}, 
                flexDirection: 'column',
                gap: '32px',
                justifyContent: 'center', 
                marginTop: {xs: '32px', md: 'auto'},
                marginLeft: {xs: 'auto', md: '16px'},
                marginRight: 'auto'
            } as SxProps,
            title: {
                ...landingH1, 
                textAlign: 'left'
            } as SxProps,
            text: {
                ...landingCopyPrimary, 
                color: darkPrimaryDefault
            } as SxProps
        }

        return (
            <Box ref={PricingRef} data-index={sections.Pricing} sx={styles.root}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.icon}>
                            <CostIsFree style={SVGStyle} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Box sx={styles.content}>
                            <Box sx={styles.title} >
                                Free for founders
                            </Box>

                            <Box>
                                <Typography sx={styles.text}>We will never charge founders money - never.</Typography>
                                <Typography sx={styles.text}>We know how every dollar is important when starting a company.</Typography>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    const FAQ = () => {


        return <>

            <Box
                ref={FAQRef}
                data-index={sections.FAQ}
            >
                <Box width={'50%'} mx={'auto'} mt={50} >

                    <Box mx={'auto'} mb={32} sx={{ ...landingH1, color: secondaryDefault }}>FAQ </Box>

                    <Box sx={{ ...landingCopyPrimary }} >
                        NDA <br />
                        confidentiality,<br />
                        VC are do not want to build.<br />
                        Vcs are investors finance people not builders<br />

                    </Box>


                </Box>

                <Box height={100}>

                </Box>

            </Box>
        </>

    }

    const styles = {
        root: {
            width: '100%',
            position: 'relative',
            backgroundColor: whiteOverlay16
        } as SxProps
    }


    return (
        <Box sx={styles.root}>
            <NavBar />
            {Hero()}
            {WhyPitchura()}

            {Package()}
            {Pricing()}
            {WhoAreWe()}

            {/* {FAQ()} */}
            {Footer()}
        </Box>
    )

}