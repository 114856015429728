import { Box, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import XJAudioMeter from "UI Elements/XJAudioMeter"
import { classes } from "./VideoSetup"

interface IVideoSetupVolumeProps {
    onComplete: () => void
}


export default function VideoSetupVolume(props: IVideoSetupVolumeProps) {

    const [stream, setStream] = useState<MediaStream>()
    const streamRef = useRef<MediaStream>()

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(currentStream => {
                setStream(currentStream)
                streamRef.current = currentStream
            }
            )
    }, [])

    useEffect(() => {
        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((t) => t.stop())
            }
        }
    }, [])

    return <>

        <Box sx={classes.header}>

            <Typography variant="h2">

                Speaking Volume

            </Typography>
        </Box>
        <Box sx={classes.explaination} textAlign={'center'}>

            Practice using an adequate speaking volume to make the bars green.

        </Box>

        <Box sx={classes.mainBox} >
            <XJAudioMeter stream={stream}  />

        </Box>

    </>


}