import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { usePitches } from 'hooks/UsePitches';
import { ExplanationBox } from '../../UI Elements/XJExplanationBox';
import { XJButton } from '../../UI Elements/Buttons/XJButton';
import { GridSx, Header, HeaderRightButtons, PageTitle, whiteDefault, darkPrimaryDefault, secondaryDefault, copySecondaryRegular, MainContent, SVGStyle, copyPrimaryRegular } from '../../XJumpTheme';
import { Box, Collapse, Grid, Skeleton, Switch, SxProps, Typography } from '@mui/material';
import { PitchModel } from 'Model/PitchModel';
import { PitchStatus } from 'Common/Enums';
import XJBreadcrumbs from 'UI Elements/XJBreadcrumbs';
import { IconPlay } from 'UI Elements/XJIcons';
import { useAlert } from 'hooks/UseAlert';
import { XJSingleInputForm } from 'UI Elements/PopUps/XJSingleInputForm';
import { PitchStudioSection, RoutingController } from 'Controllers/RoutingController';
import { useSpinner } from 'UI Elements/XJSpinner';
import XJConfirmationDialog from 'UI Elements/PopUps/XJConfirmationDialog';
import XJIconButton from 'UI Elements/Buttons/XJIconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { XJTooltip } from 'UI Elements/XJTooltip';
import { LogError } from 'Controllers/Logging';
import XJExplainer from 'UI Elements/PopUps/XJExplainer';
import { ExplainerID } from 'hooks/UseExplainersShouldShow';
import { ReactComponent as Dashboard1 } from '../../svg/dashboard-1.svg'
import { ReactComponent as Dashboard2 } from '../../svg/dashboard-2.svg'
import { ReactComponent as Dashboard3 } from '../../svg/dashboard-3.svg'
import BigAlertContext from 'UI Elements/XJBigAlerts';
import { XJLinkButton } from 'UI Elements/Buttons/XJLinkButton';

const SkeletonRow = () => {

    const styles = {
        root: {
            width: "100%",
            display: "flex",
            '& > *': {
                borderBottom: 'unset',
            }
        } as SxProps,
        cell: (cols: number) => {
            return {
                padding: 4,
                width: (cols / 12.0 * 100).toString() + "%"
            } as SxProps
        }
    }

    return <Box key={1} sx={styles.root}>

        <Box sx={styles.cell(3)}>
            <Skeleton />
        </Box>

        <Box sx={styles.cell(1 + 1)}>
            <Skeleton />
        </Box>

        {/* <Box sx={styles.cell(1)}>
            <Skeleton />
        </Box>

        <Box sx={styles.cell(1)}>
            <Skeleton />
        </Box>

        <Box sx={styles.cell(1)}>
            <Skeleton />
        </Box> */}

        <Box sx={styles.cell(1 + 1)}>
            <Skeleton />
        </Box>

        <Box sx={styles.cell(1 + 1)}>
            <Skeleton />
        </Box>

        <Box sx={styles.cell(2)}>
            <Skeleton />
        </Box>

    </Box>
}

function Row(props: any) {

    const pitch = props.row as (PitchModel)
    const history = useHistory()

    const [analyticsOpen, setAnalyticsOpen] = React.useState(false)

    const duplicatePitch = usePitches().duplicatePitch
    const alertSuccess = useAlert().success
    const alertError = useAlert().error
    const deletePitch = usePitches().deletePitch

    const spinner = useSpinner()

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showToggleStatusConfirm, setShowToggleStatusConfirm] = useState(false)

    const lastPlayedDate = pitch.lastPlayed === null ? '' : pitch.lastPlayed.toDateString()


    // MARK: - Actions 

    const pitchLinkClick = (pitch: PitchModel) => {

        if (pitch.status === PitchStatus.QandA || pitch.status === PitchStatus.started) {

            const currentStep = pitch.enterInterview()

            if (currentStep)
                history.push(RoutingController.InterviewStep(currentStep))

            /* if (currentStep instanceof QuestionModel) {
                history.push(`/pitch/${pitch.PitchId}/question/${currentStep.id}`)
            }
            else if (currentStep instanceof TopicModel) {
                history.push(`/pitch/${pitch.PitchId}/topic/${currentStep.id}`)
            } */

        } else {
            history.push(RoutingController.PitchEditor(pitch))

        }
    }

    const [openDuplicatePitchInput, setopenDuplicatePitchInput] = useState(false)

    const [duplicatePitchId, setDuplicatePitchId] = useState<number>(-1)

    const previewIsDisabled = pitch.status === PitchStatus.started ||
        pitch.status === PitchStatus.QandA || 
        pitch.status === PitchStatus.script

    const linksAreAvailable = pitch.status === PitchStatus.live ||
        pitch.status === PitchStatus.unpublished

    const onPreview = () => {
        history.push(RoutingController.PitchEditor(pitch, PitchStudioSection.video))
    }

    const onClonePitch = async () => {

        setDuplicatePitchId(pitch.id!)
        setopenDuplicatePitchInput(true)

    }

    const onDuplicatePitchDialogCancel = () => {

        setopenDuplicatePitchInput(false)
    }

    const onDeletePitch = () => {
        setShowDeleteConfirmation(true)
    } 

    const onConfirmDeletePitch = async () => {

        spinner(true)
        setShowDeleteConfirmation(false)

        deletePitch(pitch.id!).then(res => {
            alertSuccess('Pitch was deleted successfully')
        }).catch(err => {
            alertError("Something happened: pitch wasn't deleted")
        }).finally(() => {
            spinner(false)
        })
    }

    const onConfirmLiveToggle = async () => {

        setShowToggleStatusConfirm(false)   
        spinner(true)

        pitch.toggleLiveStatus().then(res => {
            alertSuccess("Pitch status successfully changed")
        })
        .catch(err => {
            LogError(err)
            alertError("An error has occured.")
        })
        .finally(() => {
            spinner(false)
        })
    }

    const onDuplicatPitchDialogOK = async (newTitle: string) => {

        if (newTitle.length === 0) {
            alertError("New title is required")
            return
        }

        spinner(true)

        duplicatePitch(duplicatePitchId, newTitle).then((res) => {
            spinner(false)
            alertSuccess("Pitch duplicated susccessfully")
            setopenDuplicatePitchInput(false)

        })
        .catch(res => {

            spinner(false)
            LogError(res)
            alertError("An error occurred")
            setopenDuplicatePitchInput(false)

        })
    }


    // MARK: - Render 

    return <>

        <XJConfirmationDialog
            open={showDeleteConfirmation}
            title={`Delete "${pitch!.title}"?`}
            ConfirmButtonText='Yes'
            CancelButtonText='no'
            onConfirm={onConfirmDeletePitch}
            onCancel={() => { setShowDeleteConfirmation(false) }} 
        />

        {pitch.status === PitchStatus.unpublished && <XJConfirmationDialog
            open={showToggleStatusConfirm}
            title={`Set ${pitch.title} status to live?`}
            ConfirmButtonText={'yes'}
            CancelButtonText={'no'}
            onConfirm={onConfirmLiveToggle}
            onCancel={() => { setShowToggleStatusConfirm(false) }} 
        />}

        {pitch.status === PitchStatus.live && <XJConfirmationDialog
            open={showToggleStatusConfirm}
            title={`Set ${pitch.title} status to offline?`}
            ConfirmButtonText={'yes'}
            CancelButtonText={'no'}
            onConfirm={onConfirmLiveToggle}
            onCancel={() => { setShowToggleStatusConfirm(false) }} 
        />}

        <XJSingleInputForm
            open={openDuplicatePitchInput}
            title={'Duplicate Pitch'}
            inputFieldTitle={'Set Pitch Title'}
            inputFieldInitValue={''}
            onOK={onDuplicatPitchDialogOK}
            onCancel={onDuplicatePitchDialogCancel}
        />

        {/* // Title  */}
        <Grid item xs={3} sx={GridSx.itemBold} display='flex' alignItems={'center'}>
            <XJLinkButton onClick={() => { pitchLinkClick(pitch) }} to={'#'}>{pitch.title}</XJLinkButton>
        </Grid>

        {/* //  Status  */}
        <Grid item xs={1 + 1} sx={GridSx.item} display='flex' alignItems={'center'}>

            <Box display={'flex'} gap={8} alignItems={'center'}>

                {pitch.getStatusLabel()}

                {(pitch.status === PitchStatus.live || pitch.status === PitchStatus.unpublished) && <Switch
                    sx={{ position: 'relative', top: 4 }}
                    checked={pitch.status === PitchStatus.live}
                    onChange={() => { setShowToggleStatusConfirm(true) }}
                />}

            </Box>

        </Grid>
        
        {/* // Views
        <Grid item xs={1} sx={GridSx.item} display='flex' alignItems={'center'}>
            <Box sx={{ textAlign: 'right', width: '100%' }}>
                <XJLink to={'#'}

                // onClick={() =>setAnalyticsOpen(!analyticsOpen)}

                >{pitch.totalViews}</XJLink>

            </Box>
        </Grid>

        // % Viewed
        <Grid item xs={1} sx={GridSx.item} display='flex' alignItems={'center'}>
            <Box sx={{ textAlign: 'right', width: '100%' }}>
                {pitch.percentPlayed}
            </Box>
        </Grid>

        // Last Played 
        <Grid item xs={1} sx={GridSx.item}>
            {lastPlayedDate}
        </Grid> */}
        
        {/* // Links  */}
        <Grid item xs={1 + 1} sx={GridSx.item} display='flex' alignItems={'center'}>
            {linksAreAvailable && <Box sx={{ textAlign: 'right', width: '100%' }}>
                <XJLinkButton to={RoutingController.PitchLinks(pitch.id!)}>{pitch.linksCount}</XJLinkButton >
            </Box>}
        </Grid>
        
        {/* // Preview  */}
        <Grid item xs={1 + 1} sx={GridSx.item} display='flex' alignItems={'center'}>
            <Box sx={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                
                <XJIconButton variant='tertiary' onClick={onPreview} disabled={previewIsDisabled}>
                    <IconPlay />
                </XJIconButton>
                
            </Box>
        </Grid>
        
        {/* // Action  */}
        <Grid item xs={2} sx={{
            ...GridSx.item,
            // ...GridSx.itemDashboardButton
        }}>
            <Box display={'flex'} gap={8} width={'100%'} justifyContent={'center'}>

                <XJIconButton variant='tertiary' onClick={onClonePitch}>
                    <XJTooltip title='Duplicate' placement="bottom">
                        <ContentCopyIcon />
                    </XJTooltip>
                </XJIconButton>

                <XJIconButton variant='tertiary' onClick={onDeletePitch}>
                    <XJTooltip title='Delete' placement="bottom">
                        <DeleteOutlineIcon />
                    </XJTooltip>
                </XJIconButton>
            </Box>
        </Grid>

        <Grid item xs={12}>
            <Collapse in={analyticsOpen} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                        Tracking Analytics
                    </Typography>

                    <Box display="flex" flex-direction="row" flex-jusitfycontent="space-between">
                        <Typography>Chart 1</Typography>
                        <Typography>Chart 2</Typography>
                        <Typography>Chart 3</Typography>
                        <Typography>Chart 4</Typography>
                    </Box>
                </Box>
            </Collapse>
        </Grid>
    </>
}


export default function Dashboard() {

    const history = useHistory()
    const pitchesHook = usePitches()
    const pitches = pitchesHook.pitches
    const pitchesLoaded = pitchesHook.loaded

    function createNewPitchClicked() {
        history.push(RoutingController.NewPitch());
    }


    // MARK: - Render 

    const NoPitches = () => {

        const styles = {
            root: {
                display: 'flex',
                gap: 16,
                flexDirection: 'column',
                justifyContent: 'center',
                mx: 'auto'
            } as SxProps,
            title: {
                textAlign: 'center',
                color: secondaryDefault
            } as SxProps,
            content: {
                display: 'flex',
                justifyContent: 'center',
                gap: 16, 
                pb: 8
            } as SxProps,
            itemsSX: {
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 16,
                minWidth: '250px',
            } as SxProps,
            itemImage: {
                backgroundColor: whiteDefault,
                borderRadius: '8px',
                px: '16px',
                pt: '24px',
                pb: '32px'
            } as SxProps,
            itemTextSx: {
                display: 'flex',
                gap: 8,
                flexDirection: 'column',
                maxWidth: '240px'
            } as SxProps,
            explaintextSX: {
                ...copyPrimaryRegular,
                color: darkPrimaryDefault,
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word'
            } as SxProps,
            button: {
                mx: 'auto',
                width: 'fit-content'
            } as SxProps
        }

        return (
            <Box sx={styles.root}>

                <Typography variant='h2' sx={styles.title}>
                    Anatomy of a pitch
                </Typography>

                <Box sx={styles.content}>
                    <Box sx={styles.itemsSX}>
                        <Box sx={styles.itemImage}>
                            <Dashboard1 style={SVGStyle}/>
                        </Box>
                        <Box sx={styles.itemTextSx}>
                            <Typography variant='h2'>
                                Interview
                            </Typography>
                            <Typography sx={styles.explaintextSX}>
                                Q&A and tutorials to help you crystallize the points most important for investors
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={styles.itemsSX}>
                        <Box sx={styles.itemImage}>
                            <Dashboard2 style={SVGStyle}/>
                        </Box>
                        <Box sx={styles.itemTextSx}>
                            <Typography variant='h2'>
                                Script Development
                            </Typography>
                            <Typography sx={styles.explaintextSX}>
                                We'll develop a 5-minute script for your pitch, structured to effectively communicate your company's story
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={styles.itemsSX}>
                        <Box sx={styles.itemImage}>
                            <Dashboard3 style={SVGStyle}/>
                        </Box>
                        <Box sx={styles.itemTextSx}>
                            <Typography variant='h2'>
                                Video Recording
                            </Typography>
                            <Typography sx={styles.explaintextSX}>
                                Recording and hosting your pitch video on our platform, securely and confidentially
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={styles.button}>
                    <XJButton variant={'secondary'} onClick={() => createNewPitchClicked()}>New Pitch</XJButton>
                </Box>

            </Box>
        )

    }

    const TitleRow = () => {

        return <>
            <Grid item xs={3} sx={GridSx.header}>
                <Typography variant='h3'>
                    Title
                </Typography>
            </Grid>
            <Grid item xs={1 + 1} sx={GridSx.header}>
                <Typography variant='h3'>
                    status
                </Typography>
            </Grid>
            {/* <Grid item xs={1} sx={GridSx.header}>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant='h3'>
                        views
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={1} sx={GridSx.header}>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography style={{ whiteSpace: 'nowrap' }} variant='h3'>% viewed</Typography>
                </Box>
            </Grid>
            <Grid item xs={1} sx={GridSx.header}>
                <Typography variant='h3'>
                    Last played
                </Typography>
            </Grid> */}
            <Grid item xs={1 + 1} sx={GridSx.header}>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant='h3'>
                        Links
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={1 + 1} sx={GridSx.header}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h3'>
                        Preview
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2} sx={GridSx.header}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h3'>
                        Action
                    </Typography>
                </Box>
            </Grid>
        </>

    }


    const bigAlert = useContext(BigAlertContext)
    

    return (<>

        <XJExplainer
            id={ExplainerID.Dashboard}
            title={'Welcome to Pitchura'}
            welcomeText={"Welcome to Pitchura. This page is the Pitches page, where you will be able to create and manage different versions of your pitch."}    
        />

        <Grid container>

            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton variant={'secondary'} onClick={(e) => createNewPitchClicked()}>
                        New Pitch
                    </XJButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={PageTitle}>

                    <Typography variant='h1'>
                        Video Pitches
                    </Typography>
                    {/* <NotificationsNoneIcon /> */}

                </Box>
            </Grid>

            {/*  <Grid item xs={12}>
                <XJDashboardTips />
            </Grid> */}

            <Grid item xs={10}>
                <ExplanationBox sx={{maxWidth: '725px'}}>
                    A pitch is a 5 minute video intended to get an investor interested in meeting with you.  From here you can develop and record multiple versions of your pitch, to share with different investors. Pitchura will help you tell investors your company's story in a way and in words they are used to. <b>The first impression is the only impression. </b>
                </ExplanationBox>
            </Grid>

            <Grid item xs={12} sx={MainContent}>
                <Grid container>
                    {pitches && pitchesLoaded && pitches.length > 0 && <TitleRow />}

                    {(pitches === null || !pitchesLoaded) && <SkeletonRow />}

                    {pitches && pitchesLoaded && pitches.length === 0 && <NoPitches />}

                    {pitches && pitches.map((row: PitchModel) => {
                        return <Row key={row.id} row={row} />
                    })}
                </Grid>
            </Grid>
        </Grid>
    </>)
}