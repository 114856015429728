import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Box, Card, CardContent, Grid } from '@mui/material'
import { usePitches } from 'hooks/UsePitches'
import XJBreadcrumbs from '../../UI Elements/XJBreadcrumbs'
import { useTemplates } from 'hooks/UseTemplates'
import { PitchTemplateModel } from 'Model/PitchTemplateModel'
import { SxProps } from '@mui/material'
import { CompanyStage, PitchRound } from 'Common/Enums'
import { XJButton } from 'UI Elements/Buttons/XJButton'
import { ExplanationBox } from 'UI Elements/XJExplanationBox'
import { XJPitchRound } from 'Components/XJPitchRound'
import { XJPitchStage } from 'Components/XJPitchStage'
import { XJPitchIndustry } from 'Components/XJPitchIndustry'
import { XJPitchTag } from 'Components/XJPitchTag'
import { theme, Header, HeaderRightButtons, PageTitle } from 'XJumpTheme'
import XJInputBox from 'UI Elements/XJInputBox'
import XJNumberTextfield from 'UI Elements/XJNumberTextfield'
import { RoutingController } from 'Controllers/RoutingController'
import { ArrowLeftIcon, ArrowRightIcon } from 'UI Elements/XJIcons'
import { useSpinner } from 'UI Elements/XJSpinner'
import { useAlert } from 'hooks/UseAlert'
import { LogError } from 'Controllers/Logging'

const enum Step {
    industry,
    stage,
    round,
    tag,
    title,
    ask,
    template,
    finished
}

const classes =
{
    root: {

    },
    title: {
        marginBottom: 8,
        justifyContent: 'center',
        display: 'flex'
    },
    explainer: {
        marginBottom: 16,
        width: '50%',
        maxWidth: '508px',
        marginX: 'auto'

    } as SxProps,

    buttons: {
        display: 'flex',
        justifyContent: 'center',
        gap: 8,
        marginTop: 16
    }
}

export const MAX_NUMBER_OF_TAGS = 1


export default function NewPitch() {

    const history = useHistory()
    const addPitch = usePitches().newPitch
    const templates = useTemplates().templates

    const [stage, setStage] = useState<CompanyStage>()
    const [round, setRound] = useState<PitchRound>()
    const [industry, setIndustry] = useState<string>()
    const [subIndustry, setSubIndustry] = useState<string>()
    const [tag, setTag] = useState<string[]>()
    const [step, setStep] = useState<Step>(Step.industry)
    const [pitchTemplate, SetPitchTemplate] = useState<PitchTemplateModel | null>(null)
    const [titleForm, setTitleForm] = useState<{ ['Internal Title']: string, ['Public Title']: string }>({"Internal Title": '', "Public Title": ''})

    const [copyTitle, setCopyTitle] = useState<boolean>(titleForm?.['Public Title'].isEmpty() ?? true)
    

    const spinner = useSpinner()

    /*     const [showBanner, setShowBanner] = useState(false) */

    //events

    const refRoundAsk = useRef<number>(0)

    const pitches = usePitches().pitches

    /*    useEffect(() => {
   
           if (pitches && pitches.length === 0) {
               setShowBanner(true)
           }
   
   
       }, [pitches]) */



    const alerts = useAlert()

    const onPrevious = () => {


        switch (step) {

            /* case enumSteps.Start:
                {
                    history.push('/dashboard')
                    return
                }
 */
            case Step.industry:
                {
                    //   setStep(enumSteps.Start)
                    history.push('/dashboard')

                    return
                }

            case Step.tag:
                {
                    setStep(Step.industry)
                    return
                }

            case Step.stage:
                {
                    setStep(Step.tag)
                    return
                }

            case Step.round:
                {
                    setStep(Step.stage)
                    return
                }

            case Step.title:
                {
                    setStep(Step.template)
                    return
                }

            case Step.ask:
                {
                    setStep(Step.title)
                    return
                }

            case Step.template:
                {
                    setStep(Step.round)
                    return
                }


            case Step.finished:
                {
                    setStep(Step.ask)
                    return
                }

        }

    }


    const ShouldShowNextBtn = (): boolean => {
        switch (step) {

            /*  case enumSteps.Start:
                 return true; */


            case Step.industry:
                {
                    return industry !== undefined
                }

            case Step.tag:
                {
                    return tag !== undefined && tag.length > 0
                }

            case Step.stage:
                {
                    return stage !== undefined
                }

            case Step.round:
                {
                    return round !== undefined
                }

            case Step.template:
                {
                    return pitchTemplate !== null
                }

            case Step.title:
                {
                    return titleForm !== undefined && titleForm['Internal Title'] !== undefined && titleForm['Public Title'] !== undefined

                }

            case Step.ask:
                {
                    return true // (ask !== undefined && ask > 0)
                }

            case Step.finished:
                {
                    return true
                }

            default:
                return false

        }


    }
    const onNext = () => {

        switch (step) {

            /* case enumSteps.Start:
                {
                    setStep(enumSteps.industry)
                    return
                } */

            case Step.industry:
                {
                    setStep(Step.tag)
                    return
                }

            case Step.tag:
                {
                    setStep(Step.stage)
                    return
                }


            case Step.stage:
                {
                    setStep(Step.round)
                    return
                }


            case Step.round:
                {
                    setStep(Step.template)
                    return
                }

            case Step.template:
                {
                    setStep(Step.title)
                    return
                }

            case Step.title:
                {
                    setStep(Step.ask)
                    return
                }

            case Step.ask:
                {
                    setStep(Step.finished)
                    return
                }

            case Step.finished:
                {
                    OnNewPitch()
                    return
                }
        }
    }

    const onTemplateClick = (e: any, t: PitchTemplateModel) => {

        // prevent a double click 

        e.preventDefault()
        
        SetPitchTemplate(value=> value?.Id !== t.Id ? t : null);

        setStep(Step.title)
    }


    // MARK: - Render 

    const RoundAsk = () => {

        const [roundAsk, setRoundAsk] = useState<number>(0)

        useEffect(() => { setRoundAsk(refRoundAsk.current) }, [])

        return <>

            <Box sx={classes.title}>
                <Typography variant='h2'>Round amount (ask)</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered >
                    How much money are you raising? put the minimum amount you need for this stage. (a round amount is also called "the ask")
                </ExplanationBox>
            </Box>


            <Box display="flex" justifyContent='center'>
                <XJNumberTextfield

                    step={0}
                    id='ask'
                    key='ask'
                    autofocus
                    currency
                    value={roundAsk}
                    onFinish={(val) => {

                        //  e.preventDefault()

                        const numericValue = val.replace(/[^\d.-]/g, '')
                        if (!isNaN(+numericValue)) {

                            if (Number(numericValue) < 1000000000)
                                setRoundAsk(Number(numericValue))
                            refRoundAsk.current = Number(numericValue)
                        }

                    }}
                />

            </Box>

        </>

    }

    const OnNewPitch = async () => {

        try {


            spinner(true)

            const newPitch = await addPitch(
                pitchTemplate!,
                titleForm["Internal Title"],
                titleForm["Public Title"],
                round!,
                stage!,
                industry!,
                subIndustry ? subIndustry : '',
                tag!,
                refRoundAsk.current)

            const FirstTopic = newPitch.enterInterview()


            const url = RoutingController.InterviewStep(FirstTopic!)

            history.push(url)

        }
        catch (err) {

            spinner(false)
            LogError(err)
            alerts.APIError()

        }

    }

    const Round = () => {
        return <>
            <Box sx={classes.title}>
                <Typography variant='h2'>Which round?</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered >
                    Rounds are typically named by their order of investment and the progress the company made. Different investors invest in different rounds. Pick the exact round you're raising to ensure maximum compatibility with the investor's criteria.
                </ExplanationBox>
            </Box>
            <Box display="flex" flexWrap="wrap" alignContent="center" justifyContent='center'>
                <XJPitchRound round={round ? round : null} onRoundChanged={(e) => { setRound(e ? e : undefined) }} />
            </Box>

        </>

    }

    const Stage = () => {
        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'>company Stage</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered >
                    The company stage tells investors on the progress of the company. Different investors like to invest in different stages. Some prefer the very early stage, some only invest in later stages.
                </ExplanationBox>
            </Box>

            <Box display="flex" flexWrap="wrap" alignContent="center" justifyContent='center'>
                <XJPitchStage stage={stage ? stage : null} onStageChanged={(e) => setStage(e ? e : undefined)} />
            </Box>
        </>

    }

    const Industry = () => {

        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'>Industry</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered >Pick the industry your venture is in.  Some industries will have sub-industries to pick from optionally. Some investors like to invest in industrie they know already, so this helps highlight your pitch from others.</ExplanationBox>
            </Box>

            <Box display="flex" flexWrap="wrap" alignContent="center" justifyContent='center'  >
                <XJPitchIndustry
                    industry={industry ? [industry, subIndustry ? subIndustry : null] : null}
                    onIndustryChanged={(e) => { 
                        if (e !== industry) {
                            setIndustry(e ? e : undefined) 
                            setSubIndustry(undefined)
                        }
                    }}
                    onSubIndustryChanged={(e) => { setSubIndustry(e ? e : undefined) }} 
                />
            </Box>

        </>
    }

    const Tag = () => {

        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'>space</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered>Many investors invest by "themes", which focuses them on "spaces" of an industry. Picking a space helps investors immediately understand the context of your venture. Type in the box to search for spaces and pick one.  You're limited to picking one space. </ExplanationBox>
            </Box>

            <Box display="flex" flexWrap="wrap" alignContent="center" justifyContent='center'>
                <XJPitchTag 
                    selectedTag={tag ? tag : undefined}
                    autofocus
                    onChangedSelectedTag={(e) => { if (e && e.length <= MAX_NUMBER_OF_TAGS) setTag(e) }} 
                />
            </Box>

        </>

    }

    const Title = () => {

        const onInternalTitleChange = (value: string) => {
            if (copyTitle) {
                setTitleForm({'Public Title': value, 'Internal Title': value})
            }
        }

        const onPublicTitleChange = (value: string) => {
            setCopyTitle(value.isEmpty())
        }

        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'>Pitch title</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered>The title is internal only. Only you will see it.  The "Public Title" is what investors will see, so it's best to set it as the company name + the round, like "ACME Seed Round".   </ExplanationBox>
            </Box>

            <Box display="flex" flexDirection={'column'} justifyContent='center' alignItems={'center'}>

                <XJInputBox 
                    name='Internal Title' 
                    key='pitchtitle' 
                    value={titleForm} 
                    setValue={setTitleForm} 
                    onChange={onInternalTitleChange}
                    autofocus
                />

                <XJInputBox 
                    name='Public Title' 
                    key='pitchPublictitle' 
                    value={titleForm} 
                    setValue={setTitleForm} 
                    onChange={onPublicTitleChange}
                />
            </Box>

        </>

    }

    const Finished = () => {
        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'>Ready to start</Typography>
            </Box>

            <Box sx={classes.explainer}>
                <ExplanationBox centered>A new pitch has been created. Next, we will guide you through specific Q&A for this type of pitch. You don't have to finish it all at once, in fact you're encouraged to take your time.</ExplanationBox>
            </Box>

        </>

    }

    const Template = () => {

        const TemplateCard = (props: {key: string, sx?: SxProps, template: PitchTemplateModel, onClick: (event: MouseEvent<HTMLButtonElement>) => void}) => {

            const styles = {
                root: {
                    width: '184px', 
                    px: 10,
                    pb: 10, 
                    pt: 12,
                    color: theme.palette.lightPrimary.default,
                    background: theme.palette.white.default,
                    boxShadow: theme.dropshadow.card.default,
                    ':hover': {
                        boxShadow: theme.dropshadow.card.hover,
                    },
                    ":active": {
                        boxShadow: theme.dropshadow.card.pressed
                    }
                } as SxProps,
                cardContent: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8
                } as SxProps,
                content: {
                    width: '100%', 
                    height: '100%', 
                    px: 2, 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start', 
                    alignItems: 'flex-start', 
                    gap: 8
                } as SxProps,
                title: {

                } as SxProps, 
                description: {

                } as SxProps,
                button: {

                } as SxProps
            }

            return (
                <Card sx={styles.root}>

                    <CardContent sx={styles.cardContent} >

                        <Box sx={styles.content}>
                            <Typography variant='h3' color={theme.palette.secondary.default}>
                                {props.template.title}
                            </Typography>                                                    

                            <Box sx={{ ...theme.typography.secondary.regular, color: theme.palette.darkPrimary.default }}>
                                {props.template.explanation}
                            </Box>
                        </Box>

                        <XJButton variant={'secondary'} onClick={props.onClick}>
                            Choose
                        </XJButton>
                        
                    </CardContent>
                </Card>
            )
        }

        const styles = {
            cards: {
                display: "flex", 
                flexWrap: "wrap",
                alignContent: "center", 
                justifyContent: 'center'  
            } as SxProps
        }
        
        return <>
            <Box sx={classes.title} >
                <Typography variant='h2'> Pitch template</Typography>
            </Box>
            <Box sx={classes.explainer}>
                <ExplanationBox centered>Pick the template that is the most relevant to your venture. Different templates have different Q&A to tailor your pitch more closely to what investors are expecting.   </ExplanationBox>
            </Box>

            <Box sx={styles.cards}>
                {templates
                    .filter((x) =>
                    ((x.tag === tag![0] || !x.tag) &&
                        (x.industry === industry || !x.industry) &&
                        (x.stage === stage || !x.stage) &&
                        (x.round === round || !x.round))
                    )
                    .map(template => {
                        return <TemplateCard 
                            template={template}
                            key={`templcard-id${template.Id}`}
                            onClick={(e) => onTemplateClick(e, template)}
                        />
                    })
                }
            </Box>
        </>
    }


    const RenderStep = () => {
        switch (step) {

            /*  case enumSteps.Start:
                 return <Start /> */

            case Step.title:
                return Title()

            case Step.finished:
                return <Finished />

            case Step.round:
                return Round ()

            case Step.industry:
                return  Industry()

            case Step.stage:
                return Stage ()

            case Step.tag:
                return <Tag />

            case Step.ask:
                return < RoundAsk />

            case Step.template:
                return <Template />

        }

    }


    // page render 

    return <>

        {/*         <XJModal show={showBanner} onClose={() => { }}>
            <Start />
            <Box display={'flex'} justifyContent={'center'} mt={16}>
                <XJButton Variant={'tertiary'} onClick={() => { setShowBanner(false) }}>
                    Close
                </XJButton>
            </Box>

        </XJModal> */}
        <Grid container>
            <Grid item xs={12}>
                <Box sx={Header} >
                    <XJBreadcrumbs />
                    <Box sx={HeaderRightButtons}>
                        <XJButton variant={'tertiary'} to={'/dashboard'} >Cancel</XJButton>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box sx={PageTitle}>
                    <Typography variant='h1'> New Pitch </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>

                {RenderStep()}

                <Box sx={classes.buttons}>

                    <XJButton variant={'tertiary'} iconLeft onClick={onPrevious}><ArrowLeftIcon />Back</XJButton>
                    {step !== Step.template && <XJButton disabled={!ShouldShowNextBtn()} variant={'secondary'} iconRight onClick={onNext}>Next<ArrowRightIcon /></XJButton>}

                </Box>
            </Grid>
        </Grid>



    </>

} 