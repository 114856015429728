import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { RoutingController } from "Controllers/RoutingController";
import { usePitches } from "hooks/UsePitches";
import { XJLinkButton } from "UI Elements/Buttons/XJLinkButton";
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";
import { Header, PageTitle } from "XJumpTheme";


export function ScriptsPendingReview() {

    // show a list of all pitches pending review 

    const pitches = usePitches().pendingPitches()

    return <>
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
            </Grid>
            <Grid item xs={12}>
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Pitches pending review 
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Title
                            </TableCell>
                            <TableCell>
                                Venture
                            </TableCell>
                            <TableCell>
                                Review
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            pitches.map((p,i) => {

                                return <TableRow key={'pitchrow' + i}>

                                    <TableCell>
                                       <XJLinkButton to={RoutingController.AdminManualReviewPitch(p)}>{p.title}</XJLinkButton> 
                                    </TableCell>
                                    <TableCell>
                                        {p.founder?.companyName}
                                    </TableCell>
                                    <TableCell>
                                        { }
                                    </TableCell>

                                </TableRow>
                            })
                        }

                    </TableBody>
                </Table>
            </Grid>

        </Grid>

    </>

}