import { IconButton, SxProps } from "@mui/material"
import React from "react"
import { darkPrimaryDefault, secondaryDefault, secondaryDefaultOverlay32, secondaryLight60, secondaryLight75, secondaryLight85, theme, whiteDefault, whiteOverlay32 } from "XJumpTheme"



interface IXJGroupIconButtonProps {
    children: React.ReactNode
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    selected?: boolean
    disabled?: boolean
    sx?: SxProps
}

export default function XJGroupIconButton(props: IXJGroupIconButtonProps) {

    const SxBase = {

        width: '48px',
        height: '48px',
        borderRadius: '2px',
    }

    const selected = props.selected ? true : false

    const SxUnselected =
    {
        background: 'inherit',
        color: darkPrimaryDefault,

        ":focus-visible":
        {
            background: 'inherit',
            outline: '2px solid ' + secondaryLight60 
        },

        ':hover':
        {
            background: whiteOverlay32,

        },

        ":hover:focus-visible":
        {
            background: whiteOverlay32,
            outline: '2px solid ' + secondaryLight60 
        },

        ":active":
        {
            background: theme.palette.white.overlay60
        },

        ":disabled":
        {
            color: secondaryLight75
        },
        "[disabled]":
        {
            color: secondaryLight75

        }


    }


    const SxSelected =
    {
        color: whiteDefault,
        background: secondaryDefault,

        ":focus-visible":
        {
            background: secondaryDefault,
            outline: '2px solid ' + secondaryLight60 
        },

        ':hover':
        {
            background: secondaryDefaultOverlay32,
        },

        ":hover:focus-visible":
        {
            background: secondaryDefaultOverlay32,
            outline: '2px solid ' + secondaryLight60 
        },
        
        ":active":
        {
            background: theme.palette.secondary.overlay8
        },

        ":disabled":
        {
            color: secondaryLight75,
            background: secondaryLight85
        },
        "[disabled]":
        {
            color: secondaryLight75,
            background: secondaryLight85

        }


    }


    let Sx = {}

    if (selected)
        Sx = { ...SxBase, ...SxSelected }
    else
        Sx = { ...SxBase, ...SxUnselected }

    
    return <>
        <IconButton
            disabled={props.disabled}
            disableRipple={true}
            onClick={props.onClick}
            sx={{ ...Sx, ...props.sx } }

        >
            {React.Children.map(props.children, child => {

                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { key: 'icon' })
                }

                return child

            })}

        </IconButton>
    </>

}
