export const HTTP_UNAUTHORIZED = 401
export const HTTP_SUCCESS = 200
export const HTTP_ERROR = 500
export const HTTP_BAD_REQUEST = 400
export const HTTP_FORBIDDEN = 403


// custom PITCHURA errors 

export const HTTP_PITCHURASHORTCODE_INVALID = 450
export const HTTP_PITCHURASHORTCODE_INACTIVE = 451
export const HTTP_VIDEO_NOT_READY = 452
export const HTTP_AI_REQUEST_DENIED_BECAUSE_PENDING = 453

export const HTTP_ENTITY_NOT_FOUND = 460
export const HTTP_PITCH_ID_DOESNT_EXIST = 461

export const HTTP_ENTITY_OUT_OF_USER_SCOPE = 470
export const HTTP_PITCH_OUT_OF_USER_SCOPE = 471
export const HTTP_LINK_OUT_OF_USER_SCOPE = 472
export const HTTP_SEGMENT_OUT_OF_USER_SCOPE = 473
