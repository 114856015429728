import { Box, Dialog, Typography } from "@mui/material"
import { useKeyPress } from "hooks/UseKeyPress"
import { useEffect, useReducer, useState } from "react"
import { copyPrimaryRegular, darkPrimaryDefault } from "XJumpTheme"
import { XJButton } from "../Buttons/XJButton"
import XJInputBox from "../XJInputBox"


export interface IPropsXJSingleInputForm {
    open: boolean
    title: string
    inputFieldTitle: string
    inputFieldInitValue: string
    explanation?: string
    onOK: (value: string, onError: (errorMsg: string) => void) => void
    onCancel: () => void
}

export function XJSingleInputForm(props: IPropsXJSingleInputForm) {

    const reducer = (state: string, newValue: object | string): string => {

        if (typeof (newValue) === 'object') {
            const key = props.inputFieldTitle as keyof typeof newValue
            return newValue[key] as string

        }
        else {
            return newValue

        }
    }

    useEffect(() => {
        
        setFieldValue('')
        setErrorMsg('')
        
    }, [props.open])

    const [FieldValue, setFieldValue] = useReducer(reducer, props.inputFieldInitValue)

    const [errorMsg, setErrorMsg] = useState('')

    const onError = (errMsg: string) => {
        setErrorMsg(errMsg)
    }

    useEffect(() => {
        
        setErrorMsg('')
       
    }, [FieldValue])

    
    // MARK: - Actions 

    const onOK = () => {

        if (FieldValue.length === 0) {

            setErrorMsg ("Field is empty")
            return

        }

        props.onOK(FieldValue, onError)
        //  setFieldValue('')
    }

    const onCancel = () => {
        props.onCancel()
        //   setFieldValue('')
    }

    useKeyPress({ char: 'Enter' }, props.open ? onOK : undefined)


    // MARK: - Render 

    return <>

        <Dialog open={props.open} onClose={onCancel} PaperProps={{ sx: { borderRadius: '2px' } }}  >

            <Box display={'flex'} flexDirection={'column'} gap={16} p={32}>
                <Box display={'flex'} flexDirection={'column'} gap={8} alignItems={'center'} textAlign={'center'}>

                    <Typography variant="h2">
                        {props.title}
                    </Typography>

                    <Box sx={{ color: darkPrimaryDefault, ...copyPrimaryRegular }}>
                        {props.explanation}
                    </Box>

                </Box>

                <Box width={'100%'}>
                    <XJInputBox
                        width={'100%'}
                        name={props.inputFieldTitle}
                        value={FieldValue}
                        setValue={setFieldValue}
                        autofocus
                        
                        sx={{ mb: 0 }}
                    
                     //   validator={(value) => { if (value.length === 0) return false; else return true }}
                        validationError={errorMsg}

                    />
                </Box>

                <Box display={'flex'} gap={16} justifyContent={'center'}>
                    <XJButton variant={'tertiary'} onClick={onCancel}>Cancel</XJButton>
                    <XJButton variant={'secondary'} onClick={onOK}>OK</XJButton>
                </Box>
            </Box>
        </Dialog>

    </>


}