import { useDraggable } from "@dnd-kit/core"
import { Box, SxProps } from "@mui/material"
import { VisualAidModel } from "Model/VisualAidModel"
import { CSS } from '@dnd-kit/utilities'


interface TimelineVisualAidProps {
    aid: VisualAidModel
    index: number

    isSelected: boolean
}

export function TimelineVisualAid(props: TimelineVisualAidProps) {

    const aid = props.aid
    const kVisualAidHandleWidth = 8

    const { setNodeRef, listeners, attributes, transform } = useDraggable({
        id: `aid${props.index}`,
        data: {
            index: props.index,
            type: 'aid'
        }
    })


    // Styling

    //TODO: Convert the styling to use the desing system. 

    const selectedStyle: SxProps = props.isSelected ? { boxShadow: "0px 0px 2px 1px green" } : {}
    const hiddenStyle: SxProps = aid.isVisible ? {} : { opacity: 0.2 }


    const style: SxProps = {

        left: Math.round((aid.startsAt / aid.video.duration) * 10000) / 100 + '%',
        width: Math.round((aid.duration / aid.video.duration) * 10000) / 100 + '%',
        position: 'absolute',
        ...selectedStyle,
        ...hiddenStyle
    }

    const styles = {
        root: {
            height: '100%',
            borderRadius: '4px',
            borderWidth: '1px',
            borderColor: 'lightgray',
            borderStyle: 'solid',
            ...style
        } as SxProps,

        handle: {
            position: "absolute",
            width: kVisualAidHandleWidth + 'px',
            height: "100%",
            opacity: '100%',
            ':hover':
            {
                cursor: 'grab'
            }
        } as SxProps,

        visualAid: {
            width: '100%',
            height: '100%',
            backgroundSize: 'contain',
            backgroundImage: "url(" + aid.url + ")"
        } as SxProps
    }


    
    const transformStyle = {
        transform: CSS.Translate.toString(transform),
    }

    //TODO:  Handle dragging of item'e ends to change the duration 
    
    return (
        <Box ref={setNodeRef}  {...listeners}  {...attributes} style={transformStyle} sx={styles.root}>
            <Box height={'100%'}>
                <Box sx={{ ...styles.handle, ...{ left: '0px' } }} />
                <Box sx={{ ...styles.handle, ...{ right: '0px' } }} />
                <Box sx={styles.visualAid} />
            </Box>
        </Box>    
    )
}