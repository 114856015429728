import { useRef } from 'react';
import { FounderProfile } from 'Pages/Founder/Profile/FounderProfile';
import { useHistory } from 'react-router-dom';
import { XJButton } from '../../../UI Elements/Buttons/XJButton';
import { Box, Grid, Typography } from '@mui/material';
import { Header, HeaderRightButtons, MainContent, PageTitle } from 'XJumpTheme';
import React from 'react';
import XJBreadcrumbs from 'UI Elements/XJBreadcrumbs';
import { useAlert } from 'hooks/UseAlert';
import { RoutingController } from 'Controllers/RoutingController';

export function ProfilePage() {

    const history = useHistory()
    const alerts = useAlert()

    const HandleNext = () => {

        alerts.clearWorking()
        history.push(RoutingController.Home())
        alerts.success('Profile has been updated')
      
    }

    const profileComponentRef = useRef<any>()

    return (

        <Grid container>

            <Grid item xs={12} sx={Header}>

                <XJBreadcrumbs />

                <Box sx={HeaderRightButtons}>

                    <XJButton to='/dashboard' variant={'tertiary'}>
                        Cancel
                    </XJButton>

                    <XJButton variant={'secondary'} onClick={() => {
                       // alerts.working()
                        profileComponentRef!.current!.handleSave()
                    }}>
                        Save
                    </XJButton>

                   
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Your profile
                    </Typography>
                </Box>

            </Grid>

            <Grid item xs={12}  sx={MainContent} >
                <FounderProfile ref={profileComponentRef} onSaveSuccess={HandleNext} onSaveFailure={() => alerts.error()}/>
            </Grid>

        </Grid>
    )

}