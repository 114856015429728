import React from 'react'
// import SVGInline from 'react-svg-inline'
import styled from 'styled-components'

const SVGWrapper = styled.div`
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 140px;
`

const IconSVG = (props) => (
  <svg width={210} height={150} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M202.313 63.636a3.845 3.845 0 0 1 3.843-3.846A3.845 3.845 0 0 1 210 63.636c0 27.388-13.698 49.818-37.197 65.555C152.983 142.464 127.156 150 104.497 150c-23.268 0-48.52-6.778-67.77-19.38C13.405 115.35 0 92.692 0 63.636a3.845 3.845 0 0 1 3.844-3.846 3.845 3.845 0 0 1 3.843 3.846c0 26.34 12.01 46.641 33.25 60.547 17.93 11.74 41.72 18.125 63.56 18.125 21.165 0 45.502-7.101 64.03-19.51 21.49-14.39 33.786-34.526 33.786-59.162Zm-37.738-38.461c-6.947 0-12.579-5.636-12.579-12.588C151.996 5.636 157.628 0 164.575 0c6.947 0 12.579 5.636 12.579 12.587 0 6.952-5.632 12.588-12.579 12.588Zm-116.705 0c-6.947 0-12.579-5.636-12.579-12.588C35.291 5.636 40.923 0 47.87 0c6.947 0 12.579 5.636 12.579 12.587 0 6.952-5.632 12.588-12.579 12.588Z"
      fill="#4D4D4D"
      fillRule="nonzero"
    />
  </svg>
)

const DisconnectedView = () => (
  <SVGWrapper>
    <IconSVG />
  </SVGWrapper>
)

export default DisconnectedView
