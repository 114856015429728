import { QuestionContentModel } from "Model/QuestionContenModel";
import { useState } from "react";
import { QuestionsEditor } from "./QuestionsEditor";




export function SystemQuestions () 
{

    const [selectedQuestion, setSelectedQuestion] = useState<QuestionContentModel>()

    const onSelectedQuestionChanged = (newSelectedQuestion: QuestionContentModel) =>
    {
        setSelectedQuestion (newSelectedQuestion)
    }
    
    return <QuestionsEditor onSelectedQuestionsChanged={onSelectedQuestionChanged} selectedQuestion={selectedQuestion}/>





}