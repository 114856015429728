import { PitchuraRole } from "Common/Enums";
import { UserDAO } from "Common/UserDAO";

export class UserModel {

  constructor( _id: number)
  {
    this.id = _id
  }

  load = (u: UserDAO) =>
  
{
    this.firstName = u.FirstName
    this.lastName = u.LastName
    this.title = u.Title
    this.phone = u.Mobile
    this.email = u.Email
    this.linkedInProfileURL = u.LinkedInURL
    this.emailVerified = u.EmailVerified
    this.role = u.Role

    this.companyCity = u.Venture?.City
    this.companyLogo = u.Venture?.Logo
    this.companyState = u.Venture?.State
    this.companyCountry = u.Venture?.Country
    this.companyId = u.Venture?.Id
    this.companyName = u.Venture?.Name

    
  }

  // data
  id: number
  firstName: string | undefined;
  lastName: string | undefined
  linkedInProfileURL: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  title: string | undefined;
  emailVerified = false




  companyName: string | undefined
  companyLogo: string | undefined
  companyCity: string | undefined
  companyCountry: string | undefined
  companyId: number | undefined
  companyState: string | undefined

  role: PitchuraRole


  hasCompleteData(): boolean {
    return (
      !!this.firstName &&
      !!this.lastName &&
      !!this.phone &&
      !!this.email &&
      this.emailVerified &&
      !!this.title &&
      !!this.companyName &&
      !!this.companyLogo &&
      !!this.companyCountry &&
      !!this.companyCity
    )
  }
}
