import { setupAxiosInterceptors } from "api-axios";
import { useHistory } from "react-router";
import { useEffect } from "react"



export default function InjectAxiosInterceptors() {

    const History = useHistory()

    useEffect(
        
        () => {
            setupAxiosInterceptors(History)
        }, [History])

    return null


} 