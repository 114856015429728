import { Box, Typography } from "@mui/material"
import { ActionCallback } from "tools/Types"
import {  XJMenuButtonRound } from "UI Elements/Buttons/XJMenuButtonRound"
import { PlayerButtonVariant, XJPlayerButton } from "UI Elements/Buttons/XJPlayerButton"
import { Forward5Icon, Rewind5Icon } from "UI Elements/XJIcons"
import { copySecondaryBold, copySecondaryRegular, whiteDefault } from "XJumpTheme"
import { _durationToTimeString } from "./PlayerControls"
import PlayerTimeline from "./PlayerTimeline"



interface PlayerMiniControlsProps {
    duration: number
    progress: number
    playing: boolean
    onPlay: ActionCallback
    onPause: ActionCallback
    onRestart: ActionCallback
    onRewind: ActionCallback
    onForward: ActionCallback
    onTimelineClick: (position: number) => void

}


export function PlayerMiniControls(props: PlayerMiniControlsProps) {



    const onPause = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    {
        
      
        e.preventDefault()
        e.stopPropagation()
        props.onPause()
        
    }

    const onPlay = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    {
        
        e.preventDefault()
        e.stopPropagation()
        props.onPlay()

    }
        
    return <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' , backgroundColor: 'rgba(61, 65, 71, 0.5)' }}>

        <Box sx={{ position: 'relative', top: '50%' }} display={'flex'} justifyContent={'center'} gap={8}>

            <XJMenuButtonRound onClick={props.onRewind} variant={'primary'}>
                <Rewind5Icon />
            </XJMenuButtonRound>

            {props.playing ?
                <XJPlayerButton variant={PlayerButtonVariant.pause} onClick={onPause} /> :
                <XJPlayerButton variant={PlayerButtonVariant.play} onClick={onPlay} />}

            <XJMenuButtonRound onClick={props.onForward} variant={'primary'} >
                <Forward5Icon />
            </XJMenuButtonRound>

        </Box>

        <Box sx={{ position: 'absolute', bottom: '5%', left: 0 , width: '100%', height: '48px'}}>

            <Box ml={8} my={4} display='flex' sx={{ alignItems: 'center', color: whiteDefault}}>
                <Box><Typography sx={{ ...copySecondaryBold }} >{_durationToTimeString(props.progress)}</Typography></Box>
                <Box><Typography sx={{ ...copySecondaryRegular }}>&nbsp;/&nbsp; {_durationToTimeString(props.duration)}</Typography></Box>
            </Box>

            <PlayerTimeline
                duration={props.duration}
                progress={props.progress}

                onClick={props.onTimelineClick}
            />
        </Box>


    </Box>












}

