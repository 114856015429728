import LinkedInIcon from '@mui/icons-material/LinkedIn'




export function LinkedInButton(props: { profileURL: string }) {

    return <a
        target="_blank"
        href={props.profileURL}
        rel="noreferrer"
        style={{ display: 'flex', alignItems: 'center' }}

    >
        <LinkedInIcon color="secondary" fontSize="medium" />
    </a>


}