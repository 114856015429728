import { DndContext, useDraggable } from "@dnd-kit/core"
import { Box, SxProps } from "@mui/material"
import { secondaryDefault } from "XJumpTheme"
import { CSS } from '@dnd-kit/utilities'

export interface ITimelineMarkerProps {

    position: number
}

export function TimelineMarker(props: ITimelineMarkerProps) {


    const { setNodeRef, listeners, attributes, transform } = useDraggable({
        id: `TimelineMarker`,
        data:
        {
            type: 'marker'
        }
    })

    if (transform && transform.y) {
        transform.y = 0
        transform.x = 0
    }

    const transformStyle = {
        transform: CSS.Translate.toString(transform),
    }

 
    const markerSx = {
        container: {
            position: "absolute",
            backgroundColor: secondaryDefault,
            width: '2px',
            height: '70px',
            bottom: '0px',
            left: props.position.toString() + "%"
        } as SxProps,
        top: {
            borderRadius: '50%',
            backgroundColor: secondaryDefault,
            width: '16px',
            height: '16px',
            position: 'absolute',
            top: '-7px',
            left: '-7px'
        } as SxProps
    }

    return (

        <DndContext>
            <Box ref={setNodeRef} sx={markerSx.container}  {...listeners}  {...attributes} style={transformStyle} >
                <Box sx={markerSx.top} />
            </Box>
        </DndContext>

    )

}