import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { copyPrimaryRegular, darkPrimaryDefault } from 'XJumpTheme';

// Explanation box presents text explanation of what is happenning on a page 



export function ExplanationBox(props: { children: ReactNode, sx?: SxProps, centered?: boolean }) {

        const style: SxProps = {
                // background: darkPrimaryDefault,
                marginTop: 0,
                marginBottom: 0,
                marginRight: 5,
                ...props.sx
        }

        return <Box
                sx={style}
        //display="flex" 
        //justifyContent="left"
        >

                <Box sx={{ ...copyPrimaryRegular, color: darkPrimaryDefault, textAlign: props.centered ? 'center' : 'left', whiteSpace: 'pre-line' }}>
                        {props.children}
                </Box>
        </Box>
}


