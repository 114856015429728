import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { QuestionContentModel } from "Model/QuestionContenModel";
import { QuestionType } from "Common/Enums";
import { ChangeEvent, useEffect, useRef, useState } from "react";

import { useAlert } from "hooks/UseAlert";
import { isValidUrl } from "tools/Utils";
import { XJButton } from "UI Elements/Buttons/XJButton"
import QuestionsList from "./QuestionsList";
import { SystemQuestions } from "Controllers/SystemQuestionsController";
import { QuestionSelection, QuestionTutorial } from "Common/QuestionContentDAO";
import { useKeyPress } from "hooks/UseKeyPress";
import { XJAIHelper } from "../XJAIHelper";
import { XJLinkButton } from "UI Elements/Buttons/XJLinkButton";


export default function EditQuestion(props: { open: boolean, qid: number | undefined, onClose: () => void }) {

    const [question, setQuestion] = useState<QuestionContentModel>()

    const SystemQuestionsCtrl = SystemQuestions

    const alert = useAlert()

    const SuccessAlert = useAlert().success

    useEffect(() => {

        setQuestion(SystemQuestionsCtrl.GetQuestion(props.qid!))

    }, [props.qid])


    const [form, setForm] = useState({
        Qid: undefined as number | undefined,
        title: '',
        type: QuestionType.open,
        explanation: '',
        tutorialsVideoLink: '',
        tutorials: [] as QuestionTutorial[],
        multiAnswers: [] as QuestionSelection[],
        AIPrompt: null as string | null,
        topic: '',
        segment: ''

    })

    useEffect(() => {

        setForm({
            ...form,
            Qid: question ? question.id : undefined,
            title: question ? question.title : '',
            type: question ? question.type : QuestionType.open,
            explanation: question ? question.explanation : '',
            tutorialsVideoLink: question ? (question.tutorialsVideoLink ? question.tutorialsVideoLink : '') : '',
            tutorials: question ? question.tutorials : [],
            multiAnswers: question ? question.multiAnswers : [],
            AIPrompt: question ? question.AIPrompt : null,
            topic: question ? question.Topic : '',
            segment: question ? question.Segment : ''


        })

    }, [question])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {

        const value = event.currentTarget.value;
        const name = event.currentTarget.name;

        setForm({
            ...form, [name]: value
        });
    }


    const handleInputChangeForTutorials = (v: string, member: string, index: number): void => {


        const tutorials = form.tutorials

        if (tutorials.length === index) tutorials.push({ title: '', body: '' } as QuestionTutorial)

        tutorials[index][member] = v

        setForm({ ...form, tutorials })

    }

    const multiAnswerOptionTitle = (index: number, title: string) => {

        const f = form.multiAnswers
        f.find(x => x.index === index)!.title = title
        setForm({ ...form, multiAnswers: f })

    }


    const multiAnswerOptionAIPrompt = (index: number, AIPrompt: string) => {

        const f = form.multiAnswers
        f.find(x => x.index === index)!.AIPrompt = AIPrompt
        setForm({ ...form, multiAnswers: f })

    }





    const multiAnswerOptionNextQID = (index: number, _id: number) => {

        const f = form.multiAnswers
        f.find(x => x.index === index)!.NextQuestionId = _id
        setForm({ ...form, multiAnswers: f })

    }

    const onAddMultiAnswerOption = () => {

        const f = form.multiAnswers
        const maxvalue = f.length ? Math.max(...f.map(x => x.index)) : -1
        f.push({ title: '', index: maxvalue + 1 } as QuestionSelection)

        setForm({ ...form, multiAnswers: f })


    }


    const onClose = async () => {

        const Q = question ? question : new QuestionContentModel()

        Q!.explanation = form.explanation
        // Q!.id = form.Qid
        Q!.title = form.title
        Q!.type = form.type
        Q!.tutorialsVideoLink = isValidUrl(form.tutorialsVideoLink) ? form.tutorialsVideoLink : undefined
        Q!.tutorials = form.tutorials
        Q!.multiAnswers = form.multiAnswers
        Q!.AIPrompt = form.AIPrompt
        Q!.Topic = form.topic
        Q!.Segment = form.segment

        const newQuestion = Q.id === undefined

        // save the question 
        if (await Q!.save()) {
            if (Q.id && newQuestion) SystemQuestionsCtrl.AddQuestion(Q)
            props.onClose()

        }
        else {

            alert.error("couldn't save question")
        }


    }

    const onCancel = () => {

        props.onClose()
    }

    const [showQuestions, setShowQuestions] = useState(false)
    const [MultiOptionNextQuestion, setMultiOptionNextQuestion] = useState<QuestionSelection>()
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionContentModel>()

    useEffect(() => {

        if (MultiOptionNextQuestion) {

            multiAnswerOptionNextQID(MultiOptionNextQuestion.index, selectedQuestion!.id!)
            // MultiOptionNextQuestion.NextQuestionId = selectedQuestion?.id
        }


    }, [selectedQuestion])


    useKeyPress({ char: 's', ctrl: true }, onClose)


    const [AIHelper, setAIHelper] = useState(false)

    const [AIPrompt, setAIPrompt] = useState('')
    const currentTutorial = useRef(-1)

    const onAIReturn = (val: string) => {

        setAIHelper(false)
    
      /*   const textInput = tutorialExplantaionRefs.current.current[currentTutorial.current]
        
        textInput!.value = val */
        
        handleInputChangeForTutorials(val, 'body', currentTutorial.current)
            

    }

    const showAI = (index: number, prompt: string) => {
        
        currentTutorial.current = index

        setAIPrompt(prompt)
        setAIHelper(true)

    }

    const onDeleteMultiAnswer = (index: number) => {
        
        const f = form.multiAnswers.filter(x => x.index != index)
        
        setForm({ ...form, multiAnswers: f })

    }
    const tutorialExplantaionRefs = useRef(useRef<Array<HTMLInputElement | null>>([]))

    return <Dialog open={props.open} maxWidth={showQuestions ? 'xl' : 'md'} fullWidth={true} onClose={onCancel}>

        <XJAIHelper

            open={AIHelper}
            prompt={AIPrompt}
            onReturn={onAIReturn}
            onCancel={() => setAIHelper(false)} />

        <DialogTitle>Edit Question</DialogTitle>

        <DialogContent>

            <Box display={'flex'} gap={16} >

                <Box display={'flex'} flexDirection={'column'} gap={16} width={'100%'}>

                    <TextField name='title' value={form.title} onChange={handleInputChange} label='title' fullWidth />
                    <Select
                        value={form.type.toString()}
                        onChange={(event: SelectChangeEvent) => {

                            setForm({ ...form, type: Number(event.target.value) })
                        }}

                    >

                        <MenuItem value={QuestionType.open.toString()}>open</MenuItem>
                        <MenuItem value={QuestionType.openmultiline.toString()}>open multiline</MenuItem>
                        <MenuItem value={QuestionType.select.toString()}>select</MenuItem>

                    </Select>


                    <TextField name='explanation'

                        multiline={true}
                        rows={3}
                        value={form.explanation} onChange={handleInputChange} label='explanation' />
                     <TextField name='segment' value={form.segment} label='Segment' onChange={handleInputChange} />
                    <TextField name='topic' value={form.topic} label='Topic' onChange={handleInputChange} />
                   
                    <TextField name='tutorialsVideoLink' value={form.tutorialsVideoLink} onChange={handleInputChange} label='tutorialsVideoLink' />
                    <TextField name='AIPrompt' value={form.AIPrompt} onChange={handleInputChange} label='AIPrompt' />

                    <Box display={form.type === QuestionType.select ? 'flex' : 'none'}>

                        <Typography variant="h3">Options </Typography>

                        {(form.multiAnswers.length > 0) && <List>

                            {form.multiAnswers.map(x => {

                                return <ListItem key={'ma' + x.index}>

                                    <TextField

                                        onChange={(e) => multiAnswerOptionTitle(x.index, e.target.value)}
                                        placeholder={'title'}
                                        value={x.title}></TextField>

                                    <TextField

                                        onChange={(e) => multiAnswerOptionAIPrompt(x.index, e.target.value)}
                                        placeholder={'AI Prompt'}
                                        value={x.AIPrompt}></TextField>

                                    <TextField

                                        onFocus={() => {
                                            setMultiOptionNextQuestion(x)
                                           if (x.NextQuestionId) setSelectedQuestion(SystemQuestionsCtrl.GetQuestion(x.NextQuestionId))
                                            setShowQuestions(true)
                                        }}
                                        onChange={(e) => multiAnswerOptionNextQID(x.index, Number(e.target.value))}
                                        value={SystemQuestionsCtrl.GetQuestion(x.NextQuestionId) ? SystemQuestionsCtrl.GetQuestion(x.NextQuestionId)!.title : ''}>

                                    </TextField>
                                    <XJButton variant={'tertiary'} onClick={ e => onDeleteMultiAnswer (x.index)} >delete</XJButton>

                                </ListItem>


                            })}

                        </List>
                        }
                        <XJButton onClick={onAddMultiAnswerOption} variant={'tertiary'}>Add</XJButton>

                    </Box>

                    <Box>

                        <Typography variant="h3">Tutorials </Typography>
                        {
                            form.tutorials.map((x, index) => {

                                const title = index === 0 && x.title.isEmpty() ? form.title : x.title
                                
                                return <Box display={'flex'} key={`tutokey${index}`} flexDirection={'column'} mt={8}>

                                    <Box display={'flex'} gap={16}  alignItems={'center'}  >
                                        <Typography variant="h3">{index}:</Typography>
                                        <XJLinkButton to={'#'} onClick={() => {

                                            showAI(index, title)

                                        }}>AI</XJLinkButton>

                                    </Box>

                                    <TextField
                                        key={`tutorialtitle${index}`}
                                        value={ title}
                                        onChange={(e) => { handleInputChangeForTutorials(e.target.value, 'title', index) }}
                                    
                                    />
                                    <TextField

                                        inputRef={el => tutorialExplantaionRefs.current.current[index] = el}

                                        multiline rows={3} key={`tutorialbody${index}`} value={x.body} onChange={(e) => { handleInputChangeForTutorials(e.target.value, 'body', index) }} />
                                    
                                </Box>

                            })
                        }

                        <XJButton onClick={() => {

                            const t = form.tutorials
                            t.push({ title: '', body: '' })
                            setForm({ ...form, tutorials: t })

                        }}

                            variant={'tertiary'}>
                            add tutorial
                        </XJButton>
                    </Box>
                </Box>

                <Box display={showQuestions ? 'block' : 'none'} >

                    <QuestionsList
                        selectedQuestion={selectedQuestion}
                        setSelectedQuestion={setSelectedQuestion}
                        onOpenEditor={() => { return }}
                    />

                </Box>


            </Box>


        </DialogContent>
        <DialogActions>
            <XJButton variant={'secondary'} onClick={onCancel}>Cancel</XJButton>
            <XJButton variant={'secondary'} onClick={onClose}>Save</XJButton>
        </DialogActions>
    </Dialog>




}