import { Box, Typography } from "@mui/material";



export function Privacy() {




    return <Box m={16}>

        <Typography variant="h1">
            Privacy Policy for Pitchura
        </Typography>
        
        <p>Welcome to Pitchura! We value your privacy and are committed to protecting your confidential information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our video hosting site.</p>

        <Typography variant="h2">1. Information We Collect</Typography>
        <p><strong>Personal Information:</strong> When you sign up for Pitchura, we may ask for your name, email address, and other personal details to create your account.</p>
        <p><strong>Uploaded Content:</strong> Any videos, images, or other content you upload to Pitchura.</p>
        <p><strong>Usage Data:</strong> We collect data on how you use our site, such as the videos you watch and the features you use.</p>

        <Typography variant="h2">2. How We Use Your Information</Typography>
        <p><strong>To Provide Services:</strong> We use your information to operate and improve Pitchura, including personalizing your experience.</p>
        <p><strong>Communication:</strong> We may send you updates, newsletters, and other information related to Pitchura. You can opt out of these communications at any time.</p>
        <p><strong>Security:</strong> We use your information to help keep Pitchura safe and secure.</p>

        <Typography variant="h2">3. Sharing Your Information</Typography>
        <p><strong>Third-Party Services:</strong> We may share your information with third-party service providers who help us run Pitchura. These providers are required to protect your information.</p>
        <p><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights and safety.</p>

        <Typography variant="h2">4. Protecting Your Information</Typography>
        <p>We use standard security measures to protect your information. Despite our efforts, we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur. By using Pitchura, you accept this risk.</p>

        <Typography variant="h2">5. Your Choices</Typography>
        <p><strong>Account Information:</strong> You can update or delete your account information at any time through your account settings.</p>
        <p><strong>Communication Preferences:</strong> You can opt out of promotional communications from us by following the instructions in those messages.</p>
        <p><strong>Cookies:</strong> Most web browsers are set to accept cookies by default. You can adjust your browser settings to remove or reject cookies, but this may affect your use of Pitchura.</p>



        <Typography variant="h2">6. Changes to This Policy</Typography>
        <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on Pitchura and updating the date at the top. Continued use of our site after such changes indicates your acceptance of the new policy.</p>

        <Typography variant="h2">7. Contact Us</Typography>
        <p>If you have any questions or concerns about this Privacy Policy or how we handle your information, please contact us at <a href="mailto:privacy@pitchura.ai">privacy@pitchura.ai</a>.</p>

        <p>Thank you for using Pitchura. We are committed to protecting your privacy and providing a safe and enjoyable experience.</p>




    </Box>
}