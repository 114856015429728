/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/ 

export {}

declare global {
    interface Array<T> {
        isEmpty(): boolean
        contains(element: T): boolean
        remove(element: T): boolean
        copy(): T[]
    }
}

function isEmpty<T>(this: Array<T>) {
    return this.length === 0
}
Object.defineProperty(Array.prototype, "isEmpty", {value: isEmpty})

function contains<T>(this: T[], element: T) {
    let result = false

    const index = this.indexOf(element)
    if (index !== -1) {
        result = true 
    }
    
    return result
}
Object.defineProperty(Array.prototype, "contains", {value: contains})

function remove<T>(this: T[], element: T) {
    let result = false

    const index = this.indexOf(element)
    if (index !== -1) {
        this.splice(index, 1)
        result = true
    }
    
    return result
}
Object.defineProperty(Array.prototype, "remove", {value: remove})

function copy<T>(this: T[]) {
    return this.map((item: T) => {return item})
}
Object.defineProperty(Array.prototype, "copy", {value: copy})