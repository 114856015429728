import { Box, IconButton, SxProps } from '@mui/material'
import React from 'react'

import { darkPrimaryDefault, secondaryDefault, secondaryLight60, secondaryLight75, whiteOverlay32, whiteOverlay60 } from 'XJumpTheme'

interface IXJMenuButtonrProps {


    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    children: React.ReactNode,
    variant:  'primary'|'secondary' // MenuButtonVariant,
    disabled? : boolean,
    sx?: SxProps

}


export function XJMenuButton(props: IXJMenuButtonrProps) {

    const Sx = {

        color: props.variant === 'primary' ? secondaryDefault : darkPrimaryDefault,
        background: 'inherit',
        borderRadius: '2px',
        border: '2px',
        padding: '8px',
        margin: 0,
        width: '40px', 
        height: '40px',

        ':focus-visible': { 
            background: 'inherit', 
            outline: '2px solid ' + secondaryLight60 
        },

        ':hover':
        {
            background: whiteOverlay32,

        },

        ':hover:focus-visible':
        {
            background: whiteOverlay32,
            outline:  '2px solid ' + secondaryLight60
        },

        ':active':
        {
            background: whiteOverlay60
        },

        ...props.sx
    }

    if (props.disabled)
    {
        
        Sx.color = secondaryLight75
        Sx.background = 'inherit'
    }

    return <IconButton sx={Sx} onClick={props.onClick} disabled={props.disabled} >
        <Box width={'24px'} height={'24px'} display={'flex'} alignContent={'center'} justifyContent={'center'} flexWrap={'wrap'}>
            {props.children}
        </Box>

    </IconButton>


}