import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { PitchType } from "Common/Enums"
import { useEffect, useState } from "react"



export default function XJPitchTypeDropdown(props: { value: PitchType, onPitchTypeChanged: (t: PitchType) => void }) {


    const [templateType, setTemplateType] = useState(PitchType.undefined)

    useEffect(() => {

        setTemplateType(props.value)

    }, [props.value])


    const PitchTypeKeys = Object.keys(PitchType).filter((v) => isNaN(Number(v)))
    const PitchTypeValue = Object.keys(PitchType).filter((v) => !isNaN(Number(v)))

    const onChange = (event: SelectChangeEvent) => {
        const t = parseInt(event.target.value) as PitchType
        setTemplateType(t)
        props.onPitchTypeChanged(t)

    }

    return <Select
        value={templateType === PitchType.undefined ? PitchType.undefined.toString() : templateType.toString()}
        onChange={onChange}>
        {

            PitchTypeKeys.map((key, index) => {
                return <MenuItem key={index} value={PitchTypeValue[index]}> {Number(PitchTypeValue[index]) !== PitchType.undefined ? key : '(select)'}  </MenuItem>
            })
        }

        <MenuItem value={-1}>  </MenuItem>

    </Select>

}