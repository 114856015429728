import { SelectChangeEvent } from "@mui/material"
import { PitchRound } from "Common/Enums"
import { XJSelect } from "UI Elements/XJSelect"
import { selectBox, selectBoxAuto } from "XJumpTheme"


export interface IPropXJPitchRound {

  round: PitchRound | null
  onRoundChanged: (newRound: PitchRound | null) => void,
  allowNoSelection?: boolean
  autoWidth? : boolean
}


export function XJPitchRound(props: IPropXJPitchRound) {

  const handleChange = (event: SelectChangeEvent) => {

    const round = titleToRound(event.target.value)
    props.onRoundChanged(round ?? null)

  }

  const rounds = [
    PitchRound.NotRaising,
    PitchRound.FriendsAndFamily,
    PitchRound.PreSeed,
    PitchRound.Seed,
    PitchRound.SeriesA,
    PitchRound.SeriesB,
    PitchRound.SeriesC
  ]

  const roundToTitle = (round: PitchRound) => {
    let result = ''
    switch (round) {
      case PitchRound.NotRaising: 
        result = 'Not Raising'
        break 
      case PitchRound.FriendsAndFamily: 
        result = 'Friends and Family'
        break
      case PitchRound.PreSeed:
        result = 'PreSeed'
        break
      case PitchRound.Seed:
        result = 'Seed'
        break
      case PitchRound.SeriesA:
        result = 'Series A'
        break
      case PitchRound.SeriesB:
        result = 'Series B'
        break
      case PitchRound.SeriesC:
        result = 'Series C'
        break
    }

    return result
  }

  const titleToRound = (title: string) => {
    let result = undefined

    switch (title) {
      case roundToTitle(PitchRound.NotRaising): 
        result = PitchRound.NotRaising
        break 
      case roundToTitle(PitchRound.FriendsAndFamily): 
        result = PitchRound.FriendsAndFamily
        break
      case roundToTitle(PitchRound.PreSeed):
        result = PitchRound.PreSeed
        break
      case roundToTitle(PitchRound.Seed):
        result = PitchRound.Seed
        break
      case roundToTitle(PitchRound.SeriesA):
        result = PitchRound.SeriesA
        break
      case roundToTitle(PitchRound.SeriesB):
        result = PitchRound.SeriesB
        break
      case roundToTitle(PitchRound.SeriesC):
        result = PitchRound.SeriesC
        break
    }

    return result
  }

  return (
    <XJSelect 
      sx={{ ...(props.autoWidth ? selectBoxAuto : selectBox) }}

      value={props.round ? roundToTitle(props.round) : ''}
      options={rounds.map(s => roundToTitle(s))}

      allowNoSelection={props.allowNoSelection}

      onChange={handleChange}
    />
  )


}