export interface IPitchIndustry {
    title: string,
    subIndustry: string[]

}


export const PitchIndustries: IPitchIndustry[] =
    [
        {
            title: 'B2B Software and Services',
            subIndustry: [
                'Analytics',
                'Engineering, Product and Design',
                'Finance and Accounting',
                'Human Resources',
                'Infrastructure',
                'Legal',
                'Marketing',
                'Office Management',
                'Operations',
                'Productivity',
                'Recruiting and Talent',
                'Retail',
                'Sales',
                'Security',
                'Supply Chain and Logistics']
        },
        {
            title: 'Education', subIndustry: []
        },
        {
            title: 'Consumer', subIndustry: [
                'Apparel and Cosmetics',
                'Consumer Electronics',
                'Content',
                'Food and Beverage',
                'Gaming',
                'Home and Personal',
                'Job and Career Services',
                'Social',
                'Transportation Services',
                'Travel, Leisure and Tourism',
                'Virtual and Augmented Reality']
        },
        {
            title: 'Healthcare', subIndustry: [
                'Consumer Health and Wellness',
                'Diagnostics',
                'Drug Discovery and Delivery',
                'Healthcare IT',
                'Healthcare Services',
                'Industrial Bio',
                'Medical Devices',
                'Therapeutics']
        },
        {
            title: 'Financial Technology',
            subIndustry: [
                'Asset Management',
                'Banking and Exchange',
                'Consumer Finance',
                'Credit and Lending',
                'Insurance',
                'Payments']
        },
        {
            title: 'Real Estate and Construction', subIndustry: [
                'Construction',
                'Housing and Real Estate']
        },
        {
            title: 'Industrials', subIndustry: [
                'Agriculture',
                'Automotive',
                'Aviation and Space',
                'Climate',
                'Drones',
                'Energy',
                'Manufacturing and Robotics']
        },
        { title: 'Government', subIndustry: [] },
        { title: 'Unspecified', subIndustry: [] }]
