import { useLocation, useParams } from 'react-router-dom'
import { usePitches } from 'hooks/UsePitches'
import { PitchModel } from 'Model/PitchModel'
import { SegmentModel } from 'Model/SegmentModel'

import { darkPrimaryDefault, secondaryPrimaryBold } from 'XJumpTheme'
import { Box, Typography, Breadcrumbs } from '@mui/material'
import { XJLink } from './XJLink'
import { RightArrowIcon } from './XJIcons'
import { RoutingController } from 'Controllers/RoutingController'



export default function XJBreadcrumbs() {

    // pages 
    // TODO: move to routing 
    const kDashboardBranch = "/dashboard"
    const kPitchPathPart = "/pitch"
    const kTopicPathPart = "/topic"
    const kQuestionPathPart = "/question"
    const kEditInterviewPartPart = "/segmentedit"
    const kSegmentPathPart = "/segment"
    const kEditPathPart = "/edit"
    const kRecordPathPart = "/record"
    const kUploadPathPart = "/upload"
    const kVideoEditorPathPart = "/editor"
    const kGoLivePathPart = "/golive"
    const kPreviewPathPart = "/preview"
    const kLinksPathPart = "/links"
    const kProfileBranch = '/profile'
    const kTemplatesBranch = '/admin/templates'
    const kQuestionsBranch = '/admin/questions'
    const kTopicsBranch = '/admin/topics'
    const kManualReviewBranch = '/admin/manualreview'
    const kManualReviewPathPart = '/admin/manualreview'
    const kSupport = '/support'
    const kTutorialsEditor = '/admin/tutorials'

    const location = useLocation()

    const path = location.pathname.toLowerCase()
    const parts = path.split("/")

    const { pid, sid } = useParams<any>()
    let pitch: PitchModel | undefined = undefined
    let segment: SegmentModel | undefined = undefined

    const pitches = usePitches()
    if (pid) {
        pitch = pitches.getPitch(pid)
        if (path.includes(kManualReviewPathPart)) {
            pitch = pitches.getPendingPitch(pid)
        }
    }

    if (sid && pitch) {
        segment = pitch.getSegmentById( Number (sid))
    }
    

    // checks for .../pitch/<pitchID>/edit

    const isPitchEditing = (parts.length > 3) &&
        (("/" + parts[parts.length - 1]) === kEditPathPart) &&
        (("/" + parts[parts.length - 3]) === kPitchPathPart)

    const inQnA = path.includes(kPitchPathPart) &&
        (path.includes(kTopicPathPart) || path.includes(kQuestionPathPart)) &&
        !path.includes(kEditInterviewPartPart)

    const BCTypography = (props: any) => {
        return <Typography sx={{ ...secondaryPrimaryBold, fontSize: '16px' , lineHeight: '28px'}}  >
            {props.children}
        </Typography>
    }

    const BCLink = (props: any) => {

        return (
            <XJLink to={props.to} breadcrumbs>
                {props.children}
            </XJLink>
        )
       
    }

    const renderPitchBC = (pitch?: PitchModel, segment?: SegmentModel) => {
        // let result: (JSX.Element | undefined)[] = [<BCLink to={RoutingController.Home()} key={kDashboardPathPart}>Dashboard</BCLink>]
        let result: (JSX.Element | undefined)[] = []
        if (pitch) {
            result = [...result, ...[
                <BCLink to={RoutingController.Home()} key={kDashboardBranch}>Video pitches</BCLink>,
                // if edit is the last part 
                isPitchEditing || inQnA ?
                    <BCTypography key={kPitchPathPart} >{pitch.title}</BCTypography> :
                    <BCLink key={kPitchPathPart} to={RoutingController.PitchEditor(pitch)}>{pitch.title}</BCLink>,

                (path.includes(kQuestionPathPart) || path.includes(kTopicPathPart)) ?
                <BCTypography key={kQuestionPathPart} >{'Q&A'}</BCTypography> : undefined
            ]]

            if (path.includes(kSegmentPathPart) && segment) {
                result = [...result, ...renderSegmentBC(segment)]
            }
                    
            result = [...result, ...[
                path.includes(kGoLivePathPart) ?
                <BCTypography key={kGoLivePathPart} >Go live</BCTypography> : undefined,

                path.includes(kPreviewPathPart) ?
                <BCTypography key={kPreviewPathPart} >Preview</BCTypography> : undefined,

                path.includes(kLinksPathPart) ?
                <BCTypography key={kLinksPathPart} >Pitch links</BCTypography> : undefined
            ]]
        } else {
            result = [<BCTypography key='newPitch'>New Pitch</BCTypography>] 
        }

        return result
    }

    const renderSegmentBC = (segment: SegmentModel) => {
        return [
            path.endsWith(kEditPathPart) ?
                <BCTypography key={kSegmentPathPart} >{segment.title}</BCTypography> :
                <BCLink key={kSegmentPathPart} to={RoutingController.SegmentEditor (segment)}>{segment.title}</BCLink>,

            path.includes(kRecordPathPart) ?
            <BCTypography key={kRecordPathPart}>Record</BCTypography> : undefined,
            
            path.includes(kUploadPathPart) ?
            <BCTypography key={kUploadPathPart}>Upload</BCTypography> : undefined,

            path.includes(kVideoEditorPathPart) ?
            <BCTypography key={kVideoEditorPathPart}>Edit recording</BCTypography> : undefined
        ]
    }

    const renderManualReviewBC = (pitch: PitchModel) => {
        return [
            <BCLink to={RoutingController.AdminManualReview()} key={kManualReviewBranch}>Manual review</BCLink>,
            <BCTypography key={kManualReviewBranch + pitch.id}>{pitch.title}</BCTypography>
        ]
    }

    const BCSepratorMargin =
    {
        '& .MuiBreadcrumbs-separator':
        {
            marginLeft: '4px',
            marginRight: '4px',
            color: darkPrimaryDefault
        }

    }

    return <>
        <Box mb={0} mt={0} alignItems={'flex-start'} display={'flex'} justifyItems={'center'}>
            <Breadcrumbs maxItems={4} sx={BCSepratorMargin} separator={<RightArrowIcon />} aria-label="breadcrumb" >

                {path === kDashboardBranch && <BCTypography key={kDashboardBranch}>Video pitches</BCTypography>}
                {path.includes(kPitchPathPart) && renderPitchBC(pitch, segment)}

                {path === kProfileBranch && <BCTypography key={kProfileBranch}>Profile </BCTypography>}

                {path === kTemplatesBranch && <BCTypography key={kTemplatesBranch}>Templates </BCTypography>}

                {path === kQuestionsBranch && <BCTypography key={kQuestionsBranch}>Questions </BCTypography>}

                {path === kSupport && <BCTypography key={kSupport}>Support </BCTypography>}

                {path === kTopicsBranch && <BCTypography key={kTopicsBranch}>Topics </BCTypography>}

                {path === kTutorialsEditor && <BCTypography key={kTopicsBranch}>Tutorials </BCTypography>}

                

                {path === kManualReviewBranch && <BCTypography key={kProfileBranch}>Manual review </BCTypography>}
                {path.includes(kManualReviewPathPart) && pitch && renderManualReviewBC(pitch)}

            </Breadcrumbs>
        </Box>
    </>
}