
import { usePitches } from "hooks/UsePitches";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useSpinner } from "UI Elements/XJSpinner";


export function RequiresData(props:
    {
        pitch?: boolean,
        userProfile?: boolean,
        children: ReactNode
    }) {

    const pitches = usePitches().pitches

    const spinner = useSpinner()
    const [dataExists, setDataExists] = useState(false)
    
    const loading = useRef (false)

    useEffect(() => {

        if (props.pitch) {
            if (pitches && pitches.length > 0) {
                setDataExists (true)
                if (loading.current)
                {
                    spinner(false)
                    loading.current = false
                } 
                
            }
        }

        if (!dataExists) {
            spinner(true)
            loading.current = true
        }

       

    })

    return dataExists ? <>{props.children}</> : null

}