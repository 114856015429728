

import { EmptyPageMessage } from "./EmptyPageMessage";
import { ReactComponent as SuccessIcon } from '../../svg/successIcon.svg'

import { Box } from "@mui/material";
import { XJButton } from "UI Elements/Buttons/XJButton";
import { RoutingController } from "Controllers/RoutingController";


export function EmailVerified() {

    return <Box mx={'auto'} mt={'5%'} >
        <EmptyPageMessage

            title="Email successfully verified"
            message=""
            mainIcon={<SuccessIcon />}

        />

        <Box mx={'auto'} mt={16} width={'fit-content'}>
            <XJButton variant="secondary" to={RoutingController.Home()} >
                Home
            </XJButton>
        </Box>


    </Box>



}
