import { MenuItem, Select, SelectChangeEvent, SxProps, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { theme, copySecondaryBold, copySecondaryRegular, darkPrimaryDefault, lightPrimaryOverlay48, secondaryDefault, secondaryLight60, whiteOverlay32 } from "XJumpTheme"
import { IconListCheckmark } from "./XJIcons"


interface XJSelectProps {
    sx?: SxProps
    fullwidth?: boolean 

    value: string 
    options: string[]
    placeholder?: string 

    allowNoSelection?: boolean

    onChange: (e: SelectChangeEvent) => void
}

export function XJSelect(props: XJSelectProps) {
    
    const DropdownItem = (props: {title: string, selected?: boolean, isValue?: boolean, placeholder?: string}) => {

        const isEmpty = props.title.isEmpty()
        const placeholder = props.placeholder ?? 'Choose option'

        const styles = {
            root: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: '40px',
                my: 2, 
                mx: props.isValue ? 0 : 6,
                px: 8,
                borderRadius: 1,
                ':hover': {
                    backgroundColor: props.isValue ? 0 : whiteOverlay32
                }
            } as SxProps,
            placeholder: {
                ...copySecondaryRegular,
                color: darkPrimaryDefault
            } as SxProps
        }

        return <Box sx={styles.root}>
            {isEmpty ? <Typography sx={styles.placeholder}>{placeholder}</Typography>
            :
            <Typography sx={props.selected || props.isValue ? {...copySecondaryBold} : {...copySecondaryRegular}}>{props.title}</Typography>}
            {props.selected ? <IconListCheckmark /> : null}
        </Box>
    }

    const styles = {
        root: {
            ...props.sx,
            borderRadius: '2px', 
            // focused state override
            '&.MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    border: '1px solid ' + secondaryDefault,
                },
            },
            // internal element padding, height and focus
            '& .MuiOutlinedInput-input': {
                '&.MuiSelect-select': {
                    padding: 0,
                    minHeight: '48px'
                },
                ':focus': {
                    outline: '2px solid ' + secondaryLight60,
                    borderRadius: '2px' 
                },
            },
            // icon color 
            '& .MuiSelect-icon': {
                color: secondaryDefault,
                right: '12px'
            },
            // custom paddings 
            '& .MuiList-root': {
                px: 6,
                py: 4
            }
        } as SxProps,
        dropdownList: {
            boxShadow: theme.dropshadow.dropdown.default
        } as SxProps,
        option: {
            '&.Mui-selected': {
                backgroundColor: 'inherit',
                '&.Mui-focusVisible': {
                    backgroundColor: 'inherit'
                }, 
            },
            ':hover': {
                backgroundColor: 'inherit'
            },
            ':focus': {
                backgroundColor: 'inherit'
            },
            '&.Mui-selected:hover': {
                backgroundColor: 'inherit'
            },
            '&.MuiMenu-paper': {
                px: 6,
                py: 4
            },
            backgroundColor: 'inherit',
            p: 0
        } as SxProps
    }

    return (
        <Select 
            sx={styles.root}
            fullWidth={props.fullwidth}

            value={props.value}
            onChange={props.onChange}

            displayEmpty
            renderValue={(value) => <DropdownItem isValue title={value} selected={false} placeholder={props.placeholder} />}

            MenuProps={{PaperProps:
                {sx: styles.dropdownList}
            }}
        >
            {props.allowNoSelection ? <MenuItem value='null' key={'indnull'}>(none)</MenuItem> : null}

            {props.options.map(
                (option, i) => {
                    return <MenuItem value={option} key={`ind${i}`} sx={styles.option}>
                        <DropdownItem title={option} selected={option === props.value} />
                    </MenuItem>
                })
            }
        </Select>
    )
}