import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { NoEncryption } from "@mui/icons-material"
import { Badge, Box, Tab, Tabs, Typography } from "@mui/material"
import React from "react"
import { ReactElement, ReactNode, useEffect, useState } from "react"
import { secondaryDefault } from "XJumpTheme"


interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            style={{ display: value !== index ? 'none' : 'block' }}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>
                    {children}
            </Box>
            
             {/* {value === index && (
                <Box>
                    {children}
                </Box>
            )} */}
        </div>
    )
}

export default function XJTabs(props: { children: React.ReactNode[], index?: number, XJTabKey? : string }) {

    
    
    const [selectedIndex, SetSelectedIndex] = useState(0)
    const sessionKey = `XJTab-${window.location.href}/${props.XJTabKey}`

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        SetSelectedIndex(newValue)
        sessionStorage.setItem (sessionKey, newValue.toString())
    }
    

    useEffect(() => {

        if (props.index) {
            SetSelectedIndex(props.index)
        }

    }, [])

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.startsWith ('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);



    return <>

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 16 }}>

                <Tabs textColor='secondary' indicatorColor='secondary' value={selectedIndex} onChange={handleChange} aria-label="Pitchura Tabs">

                    {props.children.map((node, index) => {

                        return node ? 
                            <Tab
                                label={<Typography variant="h2">{(node as ReactElement).props.id}</Typography>}
                                key={`tab-${index}`}
                                id={`tab-${index}`}
                                value={index}
                                aria-controls={`tabpanel-${index}`}
                            />  : null
                    })}

                </Tabs>
            </Box>

            {props.children.map((node, index) => {

                return <TabPanel key={`tabPanel-key-${index}`} value={selectedIndex} index={index}>
                    {node}
                </TabPanel>

            })}

        </Box>

    </>

}