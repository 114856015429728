import { Box, Grid, SxProps, Typography } from "@mui/material";
import { SystemQuestions } from "Controllers/SystemQuestionsController";
import { TopicContentModel } from "Model/TopicContentModel";

import { useEffect, useState } from "react";
import { XJButton } from "UI Elements/Buttons/XJButton";
import { Header, HeaderRightButtons, PageTitle } from "XJumpTheme";
import EditTopic from "./EditTopic";
import TopicsList from "./TopicsList";
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";



export interface ITopicsEditorProps {

    onSelectedTopicsChanged: (selectedTopic: TopicContentModel) => void
    selectedTopic: TopicContentModel | undefined

}


const HighlightedItem: SxProps =
{
    backgroundColor: 'black',
    color: 'white !important'
}



export function TopicsEditor(props: ITopicsEditorProps) {

    const [selectedTopic, setSelectedTopic] = useState<TopicContentModel>()
    const [TopicEditorState, setTopicEditorState] = useState(false)

    const allTopics = SystemQuestions.topics
    const [Topics, setTopics] = useState(allTopics)
    const [TopicFilter, setTopicFilter] = useState<string>()
    //const SystemTopicsCtrl = SystemTopics
    
    // effects 

    useEffect(() => {
        setTopics(
            TopicFilter ?
                allTopics!.filter(x => x.title.toLowerCase().includes(TopicFilter.trim().toLowerCase())) :
                allTopics!)
    }, [TopicFilter, allTopics])


    useEffect(() => {

        setSelectedTopic(props.selectedTopic)
        //if (selectedTopic) props.onSelectedTopicsChanged(selectedTopic)

    }, [props.selectedTopic])


    // events 


    const onTopicEditorClose = () => {
        setTopicEditorState(false)
    }

    useEffect(() => {
        if (selectedTopic) props.onSelectedTopicsChanged(selectedTopic)

    }, [selectedTopic])


    const onNewTopic = () => {
        setSelectedTopic(undefined)
        setTopicEditorState(true)
    }

    // components 

    const TopicDetails = () => {

        return <>

            <Typography>Title:   {selectedTopic?.title} </Typography>
            {/*  <Typography>QID:  {selectedTopic?.id}</Typography> */}
            <Typography>Segment:   {selectedTopic?.templateHint} </Typography>
            <Typography> Explanation: {selectedTopic?.explanation}</Typography>
            <Typography> AI: {selectedTopic?.AIPrompt} </Typography>

            <XJButton variant={'secondary'} onClick={() => setTopicEditorState(true)} >edit Topic</XJButton>
        </>

    }
   
    return <>

        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton variant={'secondary'} onClick={onNewTopic}>New Topic</XJButton>
                </Box>

            </Grid>

            <Grid item xs={12}>

                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Topics Editor
                    </Typography>
                </Box>

                <EditTopic
                    open={TopicEditorState}
                    tid={selectedTopic?.id}
                    onClose={onTopicEditorClose} 
                />

            </Grid>

            <Grid item xs={6}>
                <Box m={'20px'}>
                    <TopicsList selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} onOpenEditor={()=> setTopicEditorState(true)} />
                </Box>
            </Grid>
            <Grid item xs={6}>

                <Box m={'20px'}>
                    {selectedTopic && TopicDetails()}

                </Box>

            </Grid>


        </Grid>
    </>

}