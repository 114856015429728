import { Box, SelectChangeEvent } from "@mui/material"
import { PitchIndustries } from "Common/PitchIndustries"
import { useEffect, useState } from "react"
import { XJSelect } from "UI Elements/XJSelect"
import { selectBox, selectBoxAuto } from "XJumpTheme"

export interface IPropsPitchIndustry {
    industry: [string, string | null] | null,
    onIndustryChanged: (value: string | null) => void
    onSubIndustryChanged: (value: string | null) => void,
    allowNoSelection?: boolean
    SideBySide?: boolean
    autoWidth? : boolean
}

export function XJPitchIndustry(props: IPropsPitchIndustry) {

    const [industry, setIndustry] = useState<string>('')
    const [subIndustry, setSubIndustry] = useState<string>('')
    const [subIndustryList, setSubIndustryList] = useState<string[]>([])
    const setNull = props.allowNoSelection ? 'null' : ''

    useEffect(() => {

        setIndustry(props.industry ? props.industry[0] : setNull)
        setSubIndustry(props.industry ? (props.industry[1] ? props.industry[1] : setNull) : setNull)

    }, [props.industry])

    useEffect(() => {

        const subInds = PitchIndustries.find(x => x.title === industry)?.subIndustry
        if (subInds) {
            setSubIndustryList(subInds)  
        }
    }, [industry])

    const handleIndustryChange = (e: SelectChangeEvent) => {

        const ind = e.target.value
        setIndustry(ind)
        setSubIndustry(setNull)
        props.onIndustryChanged(ind)
    }

    const handleSubIndustryChange = (e: SelectChangeEvent) => {

        const subind = e.target.value
        setSubIndustry(subind)
        props.onSubIndustryChanged(subind)
    }

    return <>
        <Box display={'flex'} flexDirection={props.SideBySide ? 'row' : 'column'} gap={props.SideBySide ? 16 : 8}>
            <XJSelect sx={{...(props.autoWidth ? selectBoxAuto : selectBox )}} 
                value={industry} 
                options={PitchIndustries.map(x => x.title)}
                
                allowNoSelection={props.allowNoSelection}

                onChange={handleIndustryChange} 
            />

            {!subIndustryList.isEmpty() && (subIndustryList.contains(subIndustry) || subIndustry === '') && 
            <XJSelect sx={{...(props.autoWidth ? selectBoxAuto : selectBox )}} 
                value={subIndustry} 
                options={subIndustryList}
                
                allowNoSelection={props.allowNoSelection}

                onChange={handleSubIndustryChange} 
            />}
        </Box>    
    </>
}
