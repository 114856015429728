import { EmptyPageMessage } from "./EmptyPageMessage";

import { ReactComponent as InfoIcon } from '../../svg/Info.svg'
import { ReactElement } from "react";


export function InactiveLink(props: {
    
    brandname: string,
    logo: ReactElement | null
}) 
{

    return <EmptyPageMessage
    
        title="Offline link"
        message="This pitch link is currently offline"    
        mainIcon={<InfoIcon />}
        brandName={props.brandname}
        brangLogo={props.logo}
    
    />



}