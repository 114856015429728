import { Backdrop, CircularProgress, SxProps } from "@mui/material"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { theme } from "XJumpTheme"

export interface XJSpinnerAPI {

    show: () => void
    close: () => void

}

const XJSpinnerState = createContext(
    {
        isSpinnig: false,
        SetSpinner: (spinning: boolean) => { return }
    }
)

export const XJSpinnerProvider = (props: { children: ReactNode }) => {

    const [isSpinnig, SetSpinner] = useState(false)

    const value = { isSpinnig, SetSpinner }

    const location = useLocation()

    useEffect(() => {
        if (isSpinnig) {
            if (!location.pathname.includes('/auth/') && !location.pathname.includes('/callback') || !location.pathname.includes('/login'))
                SetSpinner(false)
        }

    }, [location.pathname])

    return <XJSpinnerState.Provider value={value}>

        <XJSpinner />
        {props.children}

    </XJSpinnerState.Provider>
}

export const XJSpinner = () => {

    const isSpinning = useContext(XJSpinnerState).isSpinnig

    const style = {
        backdrop: {
            color: '#fff',
            zIndex: theme.zIndex.loader
        } as SxProps
    }

    return (
        <Backdrop sx={style.backdrop} open={isSpinning}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export const useSpinner = () => useContext(XJSpinnerState).SetSpinner