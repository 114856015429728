import { PitchTemplateDAO, templateQuestion, templateSegment, templateTopic } from "Common/PitchTemplateDAO"
import API from "api-axios"
import { CompanyStage, PitchRound, PitchType, SegmentType } from "Common/Enums"
import { useAlert } from "hooks/UseAlert"
import { TopicContentModel } from "./TopicContentModel"
import { LogError } from "Controllers/Logging"



export class PitchTemplateModel {

    private _type: PitchType = PitchType.Custom

    get type(): PitchType {
        return this._type
    }

    set type(v: PitchType) {
        this._type = v
    }

    icon = ''
    segments: templateSegment[] = []
    partnerId = -1
    Id: number | undefined
    title = ''
    explanation = ''
    stage: CompanyStage | null = null
    tag: string | null = null 
    industry: string | null = null 
    subIndustry: string | null  = null
    round: PitchRound  | null = null

    
    load(p: PitchTemplateDAO) {

        this.Id = p.id
        this.explanation = p.explanation ? p.explanation : ''
        this.icon = p.icon ? p.icon : ''
        this.partnerId = p.partnerId ? p.partnerId : -1
        this.title = p.title ? p.title : ''
        this._type = p.type ? p.type : PitchType.undefined
        this.segments = p.template ? JSON.parse(p.template!) : []
        this.round = p.round
        this.stage = p.companyStage
        this.industry = p.industry
        this.subIndustry = p.subIndustry
        this.tag = p.tag

    }

    async save() {
        // convert template to JSON 
        try {
            const templateJson = JSON.stringify(this.segments)

            const TemplatePitchData =
                {
                    title: this.title,
                    type: this._type,
                    partnerId: this.partnerId,
                    icon: this.icon,
                    explanation: this.explanation,
                    template: templateJson,
                    id: this.Id,
                    companyStage: this.stage,
                    round: this.round,
                    industry: this.industry,
                    subIndustry: this.subIndustry,
                    tag: this.tag


                } as PitchTemplateDAO

            if (!this.Id) {

               
                API.post('/admin/template/create', TemplatePitchData)
                    .then(res => {
                    
                        this.Id = res.data.id
                    })
                    .catch(res => {
                    
                        LogError (res)
                        throw new Error("couldn't create template")
                        
                    })
            }
            else {

                API.post(`/admin/template/${this.Id}/update`, TemplatePitchData)
            }


        }

        catch (error) {
            LogError(error)
            useAlert ().APIError ()
        }

    }

    addSegment(_title: string): templateSegment {

        const newTemplate: templateSegment =
        {
            sequence: this.segments.length,
            title: _title,
            topics: [],
            segmentType: SegmentType.undefined,
            AIPrompt: ''

        }

        this.segments.push(newTemplate)

        return newTemplate

    }
    addTopic(segmentSequence: number) {

        const segment = this.segments.find(x => x.sequence === segmentSequence)

        const newTopic: templateTopic = {

            questions: [],
            sequence: segment!.topics.length,
            topciContentId: -1,

        }

        segment!.topics.push(newTopic)

        return newTopic

    }



    reorderQuestions(topic: templateTopic, startIndex: number, endIndex: number) {

        const result = Array.from(topic.questions);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        topic.questions = result.map<templateQuestion>((x, index) => {
            x.sequence = index
            return x
        })

        return topic.questions


    }

    reorderSegments(startIndex: number, endIndex: number) {

        const result = Array.from(this.segments);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        this.segments = result.map<templateSegment>((x, index) => {
            x.sequence = index
            return x
        })

        return this

    }

    reorderTopics(segment: templateSegment, startIndex: number, endIndex: number) {

        const result = Array.from(segment.topics);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        segment.topics = result.map<templateTopic>((x, index) => {
            x.sequence = index
            return x
        })

        return segment

    }
    deleteSegment(segment: templateSegment) {
        this.segments.remove(segment)
        this.save()
    }
    deleteTopic(segment: templateSegment, topic: templateTopic) {
        segment.topics.remove(topic)
        this.save()
    }
    deleteQuestion(topic: templateTopic, question: templateQuestion) {
        topic.questions.remove(question)
        this.save()
    }

}


