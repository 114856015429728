import { Box, SxProps, Typography } from "@mui/material"
import { ReactNode } from "react"
import { copyPrimaryRegular, copySecondaryRegular, darkPrimaryDefault, secondaryDefault, SVGStyle, theme, whiteDefault, whiteOverlay60 } from "XJumpTheme"
import { ReactComponent as SignUpSVG } from '../../svg/signup-1.svg'


export const LoginLayoutClasses: any =
{
    rightContainer:
    {
        margin: 'auto',
        width: 'auto',

        '& .MuiBox-root':
        {
            width: 'fit-content'
        }
    },


    doneButton:
    {
        marginTop: '35px',
        textAlign: 'center'
    },


    formHeader:
    {
        //marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        alignItems: 'center',
        marginRight: 'auto',
        gap: '48px'
    },

    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    iconBox: {
        width: 40,
        height: 40,
        backgroundColor: secondaryDefault
    },

    form:
    {
        margin: 'auto',
        '& .MuiOutlinedInput-input':
        {
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '16px',
            width: '100%'

        },
        '& .MuiTextField-root':
        {
            width: "100%"
        }

    },

    FormBoxes:
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
    },

    formCopy:
    {
        color: darkPrimaryDefault,
        ...copyPrimaryRegular

    },

    root: {
        minHeight: '92vh',
        height: '100%',
        width: '100%',

    } as SxProps,

    pane: {
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',

    },

    paneLeft: {
        backgroundColor: whiteOverlay60,
        [theme.breakpoints.down('lg')]: {
            width: 0
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%'
        },

        minHeight: '600px'
    },

    paneRight: {

        paddingY: 32,
        paddingX: 40,
        backgroundColor: whiteDefault,
        minHeight: { xs: '100vh', lg: '92vh' }

    },
    innerBox: {

        display: 'flex',
        flexDirection: 'row-reverse',
        my: { xs: 0, lg: '4vh' },
        mx: { xs: 0, lg: '67px' },
        backgroundColor: '#F5F5F5'


    } as SxProps

}

export default function LoginLayout(props: { children: ReactNode }) {

    return <>
        <Box sx={LoginLayoutClasses.root}>

            <Box sx={LoginLayoutClasses.innerBox}>
                <Box sx={{ ...LoginLayoutClasses.paneLeft, ...LoginLayoutClasses.pane }} >
                    <Box
                        sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>

                        <SignUpSVG style={SVGStyle} />

                        {/* <XJSvg fileName="signup-1.svg"/>  */}
                    </Box>
                    {/* <Typography variant='h3'>
                    Pitchura!
                </Typography>

                <Typography>
                    Login or sign up for free
                </Typography> */}

                </Box>
                <Box sx={{ ...LoginLayoutClasses.pane, ...LoginLayoutClasses.paneRight }} flexGrow={1}>

                    <Box sx={LoginLayoutClasses.rightContainer}>

                        {props.children}

                    </Box>
                </Box>
            </Box>
        </Box>
    </>
}