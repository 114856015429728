import { Box, Fade, SxProps } from "@mui/material"
import { useState } from "react"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { XJDropdown } from "UI Elements/XJDropdown"
import { DropDownIconSmall, DropUpIconSmall } from "UI Elements/XJIcons"
import { whiteDefault } from "XJumpTheme"

export const kLocalStorageSelectedValue = 'SelectedDeviceID'

interface CameraSelectorProps {

    devices: MediaDeviceInfo[]
    selectedID?: string

    sx?: SxProps
    disabled?: boolean

    onSelect: (deviceId: string) => void 
}

export default function CameraSelector(props: CameraSelectorProps) {

    const selectedDevice = props.devices.find(vd => vd.deviceId === props.selectedID)
    
    const [showDropdownList, setShowDropdown] = useState<boolean>(false)

    const onDropdownClick = () => {
        setShowDropdown((prev) => !prev)
    }

    const onDeviceSelected = (deviceID: string) => {
        if (deviceID !== selectedDevice?.deviceId) {
            setShowDropdown(false)
            props.onSelect(deviceID)
        }
    }


    // MARK: - Utils 

    const formatTitle = (title: string) => {
        return removeCameraID(title)
    }

    const removeCameraID = (title: string) => {
        const regex = /\([0-9A-Fa-f]+:[0-9A-Fa-f]+\)/g
        return title.replace(regex, '')
    }


    // MARK: - Render 

    const styles = {
        root: {
            width: "100%"
        } as SxProps,
        button: {
            width: '100%',
            wordBreak: 'break-all'
        } as SxProps,
        title: {
            overflow: 'hidden', 
            display: "-webkit-box", 
            WebkitBoxOrient: "vertical", 
            WebkitLineClamp: 1
        } as SxProps,
        dropdownList: {
            maxWidth: 360,
            width: '100%',
            backgroundColor: whiteDefault
        } as SxProps,
        dropdownOptions: {
            width: 'fit-content',
            maxHeight: '200px',
            visibility: showDropdownList ? 'visible' : 'hidden',
        } as SxProps
    }

    return (
        <Box sx={styles.root}>
            <XJButton sx={styles.button} fontSx={styles.title} variant="tertiary" dropdown disabled={props.disabled} onClick={onDropdownClick}>
                {selectedDevice ? formatTitle(selectedDevice.label) : '-'}
                {showDropdownList ? <DropUpIconSmall /> : <DropDownIconSmall />}
            </XJButton>
            <Fade in={showDropdownList}>
                <Box sx={styles.dropdownOptions}>
                    <XJDropdown
                        sx={{position: 'absolute', width: '300px'}}
                        options={props.devices.map(vd => {return {value: vd.deviceId, title: formatTitle(vd.label)}})}
                        value={selectedDevice?.deviceId}
                        onSelect={onDeviceSelected}
                    />
                </Box>
            </Fade>
        </Box>
    )
}