import { SelectChangeEvent } from "@mui/material"
import { CompanyStage, StageToString } from "Common/Enums"
import { XJSelect } from "UI Elements/XJSelect"
import { selectBox, selectBoxAuto } from "XJumpTheme"

export interface IPropXJPitchStage {
  stage: CompanyStage | null
  onStageChanged: (newStage: CompanyStage | null) => void,
  allowNoSelection?: boolean
  autoWidth?: boolean
}

export function XJPitchStage(props: IPropXJPitchStage) {

  const handleChange = (event: SelectChangeEvent) => {

    const stage = titleToStage(event.target.value)
    props.onStageChanged(stage ?? null)

  }

  const stages = [
    CompanyStage.Idea,
    CompanyStage.ProofOfConcept,
    CompanyStage.Demo,
    CompanyStage.MVPLaunch,
    CompanyStage.ProductLaunch,
    CompanyStage.FreeUsers,
    CompanyStage.PayingUsers
  ]

  /* const stageToTitle = (stage: CompanyStage) => {
    let result = ''
    switch (stage) {
      case CompanyStage.Idea: 
        result = 'Idea'
        break 
      case CompanyStage.MarketValidation: 
        result = 'Market'
        break
      case CompanyStage.ProofOfConcept:
        result = 'Proof of Concept'
        break
      case CompanyStage.Demo:
        result = 'Demo'
        break
      case CompanyStage.MVPLaunch:
        result = 'MVP Launched'
        break
      case CompanyStage.FreeUsers:
        result = 'Free Users'
        break
      case CompanyStage.PayingUsers:
        result = 'Paying Users'
        break
    }

    return result
  } */

  const titleToStage = (title: string) => {
    let result = undefined

    switch (title) {
      case StageToString(CompanyStage.Idea): 
        result = CompanyStage.Idea
        break 
      case StageToString(CompanyStage.ProductLaunch): 
        result = CompanyStage.ProductLaunch
        break
      case StageToString(CompanyStage.ProofOfConcept):
        result = CompanyStage.ProofOfConcept
        break
      case StageToString(CompanyStage.Demo):
        result = CompanyStage.Demo
        break
      case StageToString(CompanyStage.MVPLaunch):
        result = CompanyStage.MVPLaunch
        break
      case StageToString(CompanyStage.FreeUsers):
        result = CompanyStage.FreeUsers
        break
      case StageToString(CompanyStage.PayingUsers):
        result = CompanyStage.PayingUsers
        break
    }

    return result
  }

  return (
    <XJSelect 
      sx={{ ...(props.autoWidth ? selectBoxAuto : selectBox) }}

      value={props.stage ? StageToString(props.stage) : ''}
      options={stages.map(s => StageToString(s))}

      allowNoSelection={props.allowNoSelection}

      onChange={handleChange}
    />
  )


}