import { Box, Dialog, SxProps, Typography } from "@mui/material"
import { VideoDimensions } from "Common/Enums"
import { XJButton } from "UI Elements/Buttons/XJButton"
import { Ellipse55Icon, ZoomInIcon, ZoomOutIcon } from "UI Elements/XJIcons"
import { copyPrimaryBold, copySecondaryBold, copySecondaryRegular, darkPrimaryDefault, secondaryDefault, SVGStyle, theme, whiteDefault } from "XJumpTheme"
import { ComponentType, useEffect, useState } from "react"
import { DropzoneOptions, FileError, FileRejection, useDropzone } from "react-dropzone"
import Cropper from "react-easy-crop"
import { Area, Point } from "react-easy-crop/types"
import { getCroppedImage } from "tools/Utils"
import XJIconButton from "UI Elements/Buttons/XJIconButton"
import { ReactComponent as DragNDropIcon } from "../../../svg/dragndrop.svg"
import { ReactComponent as UploadVideoSmall } from "../../../svg/upload-video-small.svg"


export enum UploadType {
    image = 1,
    video = 2
}

interface DragNDropMediaProps {
    type: UploadType,
    content: ComponentType<DragNDropContentProps>,
    onMediaSelected: (mediaURL: string, filename: string, extension: string, size: number) => void,
    isUploading?: boolean
    disabled?: boolean
}

export const DragNDropMedia = (props: DragNDropMediaProps) => {

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        // show alert in case of rejections 
        if (fileRejections.length > 0) {
            let errorMessage = ""

            fileRejections.forEach((rejection: FileRejection) => {
                rejection.errors.forEach((error: FileError) => {
                    if (!errorMessage.includes(error.message)) {
                        errorMessage += error.message + "\n"
                    }
                })
            })

            if (errorMessage.isEmpty()) {
                errorMessage = "Some files are incorrect for this type of input"
            }

            alert(errorMessage)
        } else if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0]
            const aFilename = file.name
            if (aFilename) {
                const url = URL.createObjectURL(file)
                props.onMediaSelected(url, aFilename, getExtension(aFilename), file.size)
            } else {
                alert("The selected file has no extension")
                // TODO: or work with MIME type
            }
        }
    }

    const typeToAcceptTypes = (type: UploadType) => {

        let fileTypes = []

        switch (type) {

            case UploadType.video:
                fileTypes = [
                    ".mp4",
                    ".mov",
                    ".avi",
                    ".webm",
                    ".weba"
                ]

                break

            case UploadType.image:
            default:
                fileTypes = [".gif", ".jpg", ".jpeg", ".png"]
        }

        return fileTypes

    }

    const getExtension = (filename: string) => {
        return filename.split('.').pop() ?? ""
    }

    const dropzoneOptions: DropzoneOptions = {
        accept: typeToAcceptTypes(props.type),
        maxFiles: 1,
        disabled: props.disabled,
        onDrop: onDrop
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions)


    // MARK: - Render 

    const styles = {
        dropzone: {
            padding: 8,
            backgroundColor: whiteDefault,
            width: "100%",
            height: '100%',
            boxShadow: theme.dropshadow.card.default,
            borderRadius: '2px'

        },
        innerDropzone: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.lightPrimary.overlay8,
            ':hover': {
                backgroundColor: theme.palette.primary.light94
            },
            transition: 'background 0.5s',
            border: 'dashed 1.5px',
            borderColor: theme.palette.lightPrimary.overlay32,
            borderRadius: '2px',
        } as SxProps
    }

    return (
        <Box {...getRootProps({ sx: styles.dropzone, onDrop: (event) => event.preventDefault() })}>
            <Box sx={styles.innerDropzone}>
                <input {...getInputProps()} />
                <props.content isDragging={isDragActive} isUploading={props.isUploading} isDisabled={props.disabled} />
            </Box>
        </Box>
    )
}

interface DragNDropContentProps {
    isDragging: boolean,
    isUploading?: boolean
    isDisabled?: boolean
}

export const DragNDropContentVisualAid = (props: DragNDropContentProps) => {

    const styles = {
        root: {
            width: '100%',
            height: '100%',
            position: 'relative'
        } as SxProps,
        content: {
            opacity: props.isDragging ? '0' : '1',   // keeps the same size of the field 
            position: 'relative',
            gap: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: 16
        } as SxProps,
        centerText: {
            ...copyPrimaryBold,
            textAlign: "center",
            fontSize: '16px',
            color: darkPrimaryDefault,
            cursor: 'default'
        } as SxProps,
        boldText: {
            ...copyPrimaryBold,
            fontSize: '16px',
            color: secondaryDefault,
            dispay: 'inline',
            cursor: 'default'
        },
        bottomText: {
            ...copySecondaryRegular,
            textAlign: 'center',
            color: darkPrimaryDefault,
            cursor: 'default'
        } as SxProps,
        link: {
            ...copySecondaryBold,
            color: secondaryDefault,
            textDecoration: 'underline',
            display: 'inline',
            cursor: 'pointer'

        },
        dragging: {
            opacity: props.isDragging ? '1' : '0',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            width: '100%',
            height: '100%',
            position: 'absolute'
        } as SxProps
    }

    return (
        <Box sx={styles.root}>
            <Box sx={styles.content}>
                <Box>
                    <DragNDropIcon style={SVGStyle} />

                </Box>

                <Box sx={styles.centerText}>
                    Drag & Drop any <Box sx={styles.boldText} display={'inline'}>image files </Box>
                </Box>
                <Box sx={styles.bottomText}>
                    or <Box sx={styles.link} display={'inline'}>select</Box> files from your computer
                </Box>
            </Box>
            {props.isDragging && <Box sx={styles.dragging}>
                <Ellipse55Icon color={theme.palette.lightPrimary.overlay48} />
                <Box sx={styles.centerText}>
                    Drop’em right <Box sx={styles.boldText} display={'inline'}>here</Box>!
                </Box>
            </Box>}
        </Box>
    )
}

export const DragNDropContentVideo = (props: DragNDropContentProps) => {

    const styles = {
        root: {
            position: 'relative',
            gap: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            paddingX: 8
        } as SxProps,
        center: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 32 * 100 / 428 + '%'    // temporary, based on fixed sizes in the figma handoff
        } as SxProps,
        centerText: {
            ...copyPrimaryBold,
            textAlign: "center",
            color: darkPrimaryDefault,
            m: '9px 0px'
        } as SxProps,
        centerTextDark: {
            ...copyPrimaryBold,
            textAlign: "center",
            color: secondaryDefault,
            m: '9px 0px'
        } as SxProps,

        boldText: {
            ...copyPrimaryBold,
            fontSize: '16px',
            color: secondaryDefault,
            dispay: 'inline',
            cursor: 'default'
        },
        bottomContainer: {
            display: 'flex',
            justifyContent: 'center',
            gap: 16,
            alignItems: 'center',
            position: 'absolute',
            bottom: 40 * 100 / 428 + '%' // temporary, based on fixed sizes in the figma handoff
        } as SxProps,
        bottomTextBlock: {
            m: '0px 10px'
        } as SxProps,
        bottomTextTitle: {
            ...copySecondaryRegular,
            textAlign: 'center',
            fontSize: '12px',
            color: darkPrimaryDefault
        } as SxProps,
        bottomTextValue: {
            ...copySecondaryBold,
            textAlign: 'center',
            fontSize: '12px',
            color: secondaryDefault,
            marginTop: 3
        } as SxProps,
        dragging: {
            opacity: props.isDragging ? '1' : '0',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            width: '100%',
            height: '100%',
            position: 'absolute'
        } as SxProps
    }

    return (
        <Box sx={styles.root}>
            <Box sx={styles.center}>
                {!props.isDragging ?
                    <Box sx={styles.center}>
                        <Box>
                            <UploadVideoSmall style={SVGStyle} />
                        </Box>
                        <Box display={'flex'}>

                            <Box display={props.isUploading ? 'none' : 'contents'}>
                                <Typography sx={styles.centerTextDark}> Drag & Drop</Typography>
                                &nbsp;
                                <Typography sx={styles.centerText}>a video file here or</Typography>
                            </Box>

                            <Box display={props.isUploading ? 'contents' : 'none'}  >

                                <Typography sx={styles.centerText}>Uploading file...</Typography>
                            </Box>

                        </Box>
                        <Box mt={10} display={props.isUploading ? 'none' : 'block'}>
                            <Box display={props.isUploading ? 'none' : 'block'}>

                                <XJButton variant={'secondary'} disabled={props.isUploading || props.isDisabled} >
                                    select FILE
                                </XJButton>
                            </Box>

                        </Box>

                    </Box>
                    :
                    <Box sx={styles.dragging}>
                        <Box>
                            <UploadVideoSmall style={SVGStyle} />
                        </Box>
                        <Box sx={styles.centerText}>
                            Drop’em right <Box sx={styles.boldText} display={'inline'}>here</Box>!
                        </Box>
                    </Box>
                }
            </Box>
            <Box sx={styles.bottomContainer}>
                <Box sx={styles.bottomTextBlock}>
                    <Typography sx={styles.bottomTextTitle}>
                        Minimum quality
                    </Typography>
                    <Typography sx={styles.bottomTextValue}>
                        at least HD
                    </Typography>
                </Box>
                <Box sx={styles.bottomTextBlock}>
                    <Typography sx={styles.bottomTextTitle}>
                        Aspect ratio
                    </Typography>
                    <Typography sx={styles.bottomTextValue}>
                        16 : 9
                    </Typography>
                </Box>
                <Box sx={styles.bottomTextBlock}>
                    <Typography sx={styles.bottomTextTitle}>
                        Supported formats
                    </Typography>
                    <Typography sx={styles.bottomTextValue}>
                        .mp4, .mov or .webm
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

interface CropMediaProps {
    mediaURL: string,
    onMediaComplete: (completeMediaURL: string) => void,
    onCancel: () => void
}

export const CropMedia = (props: CropMediaProps) => {

    const [open, setOpen] = useState(!props.mediaURL.isEmpty())
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [cropArea, setCropArea] = useState({ x: 0, y: 0, width: 0, height: 0 })

    useEffect(() => {
        setOpen(!props.mediaURL.isEmpty())
    }, [props.mediaURL])

    const zoomStep = 0.1
    const zoomMax = 3
    const zoomMin = 1

    const onContinue = async () => {
        const croppedUrl = await getCroppedImage(props.mediaURL, cropArea)
        setOpen(false)
        props.onMediaComplete(croppedUrl)
    }

    const onCancel = () => {
        setOpen(false)
        props.onCancel()
    }

    const onCropChange = (value: Point) => {
        setCrop(value)
    }

    const onCropAreaChange = (percentageArea: Area, pixelsArea: Area) => {
        setCropArea(pixelsArea)
    }

    const onZoomIn = () => {
        setZoom(currentZoom => Math.min((currentZoom + zoomStep), zoomMax))
    }

    const onZoomOut = () => {
        setZoom(currentZoom => Math.max((currentZoom - zoomStep), zoomMin))
    }


    // MARK: - Render 

    const styles = {
        dialog: {
            padding: 0,
            margin: 'auto',
            backgroundColor: theme.palette.white.default
        },
        paper: {
            height: '100%',
            paddingX: 32,
            paddingTop: 16,
            paddingBottom: 32
        },
        verticalContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        horizontalContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        buttonBox: {
            display: 'flex',
            gap: 8
        },
        cropBox: {
            position: 'relative',
            flexGrow: 1,
            marginY: 16
        },
        zoomButtonContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        zoomButtonBox: {
            display: 'flex',
            gap: 8
        }
    }

    return <>

        <Dialog open={open} sx={styles.dialog} fullWidth maxWidth={'lg'} PaperProps={{ sx: styles.paper }}>
            <Box sx={styles.verticalContainer}>
                <Box sx={styles.horizontalContainer}>
                    <Typography variant='h2'>
                        Crop
                    </Typography>

                    <Box sx={styles.buttonBox}>
                        <XJButton variant={'tertiary'} onClick={onCancel}>
                            Cancel
                        </XJButton>

                        <XJButton variant={'secondary'} onClick={onContinue}>
                            Crop
                        </XJButton>
                    </Box>
                </Box>

                <Box sx={styles.cropBox}>
                    <Cropper
                        style={{
                            containerStyle: {
                                width: '100%',
                                height: '100%',
                                position: 'relative'
                            }
                        }}
                        image={props.mediaURL}
                        crop={crop}
                        zoom={zoom}
                        objectFit='contain'
                        aspect={VideoDimensions.width / VideoDimensions.height}
                        onCropChange={onCropChange}
                        onCropComplete={onCropAreaChange}
                    />
                </Box>

                <Box sx={styles.zoomButtonContainer}>
                    <Box sx={styles.zoomButtonBox}>
                        <XJIconButton variant={'secondary'} onClick={onZoomIn}>
                            <ZoomInIcon />

                        </XJIconButton>
                        <XJIconButton variant={'secondary'} onClick={onZoomOut}>
                            <ZoomOutIcon />

                        </XJIconButton>

                    </Box>
                </Box>
            </Box>
        </Dialog>
    </>
}