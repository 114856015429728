import { Box, Grid, SxProps, Typography } from "@mui/material";
import { RoutingController } from 'Controllers/RoutingController'
import { usePitches } from "hooks/UsePitches";
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";
import { XJButton } from "UI Elements/Buttons/XJButton";
import XJCheckBox from "UI Elements/XJCheckBox";
import { Header, PageTitle, HeaderRightButtons, copyPrimaryRegular, darkPrimaryDefault, whiteDefault, copySecondaryRegular, SVGStyle } from "XJumpTheme";
import useExplainerShouldShow, { ExplainerID } from "hooks/UseExplainersShouldShow";


import { ReactComponent as RecordingSoundCheck } from '../../../../svg/recording-soundcheck.svg'
import { ReactComponent as RecordingPron } from '../../../../svg/recording-pron.svg'
import { ReactComponent as RecordingLooks } from '../../../../svg/recording-looks.svg'




const classes =
{

    explanation:
        {
            width: '500px',
            ...copyPrimaryRegular,
            color: darkPrimaryDefault

        } as SxProps,

    boxContainer:
    {
        // width: '150px'
    },

    box:
        {
            height: '184px',
            width: '184px',
            backgroundColor: whiteDefault,
            borderRadius: '8px',
            p: 16,
            mx: 'auto'

        } as SxProps,

    boxExplanation:
        {
            mt: 8,
            ...copyPrimaryRegular,
            color: darkPrimaryDefault,
            whiteSpace: 'break-spaces',
            width: '220px',
            marginX: 'auto'

        } as SxProps,

    boxText:
        {
            textAlign: 'center',
            marginTop: 16

        } as SxProps


}

export default function VideoSetupIntro() {

    const [dontShowAgain, setDontShowAgain] = useState(false)
    const history = useHistory()
    const { pid } = useParams<any>()
    const { sid } = useParams<any>()

    const segment = usePitches().getPitch(Number(pid)).getSegmentById(Number(sid))

    const explainerHook = useExplainerShouldShow()

    const isLastTime = explainerHook.IsLastTime(ExplainerID.VideoRecordingSetup)
    useEffect(() => {
        if (isLastTime) {
            setDontShowAgain(true)
        }
    }, [isLastTime])

    useEffect(() => {

        explainerHook.DontShowAgain(ExplainerID.VideoRecordingSetup, dontShowAgain)

    }, [dontShowAgain])


    useEffect(() => {

        explainerHook.ShowedToUser(ExplainerID.VideoRecordingSetup)

    }, [])

    return <>
        <Grid container>
            <Grid item xs={12} sx={Header} >
                <XJBreadcrumbs />
                <Box sx={HeaderRightButtons}>
                    <XJButton variant={'tertiary'} to={RoutingController.SegmentEditor(segment)}>
                        Cancel
                    </XJButton>

                    <XJButton to={RoutingController.SegmentVideoRecord(segment)} variant={'secondary'}>
                        Skip
                    </XJButton>
                </Box>
            </Grid>

            <Grid item xs={12} sx={PageTitle} >
                <Typography variant={'h1'}>
                    Record
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box mx={'auto'} width={'fit-content'}>
                    <Box width={'fit-content'} mx={'auto'} mb={8}>
                        <Typography variant="h2">
                            Setup for recording
                        </Typography>
                    </Box>

                    <Box sx={classes.explanation}>
                        The next quick steps will help you optimize how you look and sound on the video. Small things can make a difference how someone views you.
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mx={'auto'} mb={16} display={'flex'} mt={16} justifyContent={'center'} gap={16}>
                    <Box sx={classes.boxContainer}>
                        <Box sx={classes.box}>
                            <RecordingSoundCheck style={SVGStyle} />
                        </Box>

                        <Box sx={classes.boxText}>
                            <Typography variant='h2'>
                                Sound Check
                            </Typography>

                            <Box sx={classes.boxExplanation}>
                                Check your speaking volume to ensure people can hear you loud and clear.
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={classes.boxContainer}>
                        <Box sx={classes.box}>
                            <RecordingPron style={SVGStyle} />
                        </Box>

                        <Box sx={classes.boxText}>
                            <Typography variant='h2'>
                                Pronunciation
                            </Typography>

                            <Box sx={classes.boxExplanation}>
                                For non English speakers, this is a quick check of how clear you pronounce to American ears.
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={classes.boxContainer}>
                        <Box sx={classes.box}>
                            <RecordingLooks style={SVGStyle} />
                        </Box>

                        <Box sx={classes.boxText}>
                            <Typography variant='h2'>
                                Looks
                            </Typography>

                            <Box sx={classes.boxExplanation}>
                                Light, position and distance from the camera, as well as clothing, is key for a good impression.
                            </Box>
                        </Box>
                    </Box>
                    {/*  <Box sx={classes.boxContainer}>
                        <Box sx={classes.box}>

                        </Box>
                        <Box sx={classes.boxText}>

                            <Typography variant='h3'>
                                Looks
                            </Typography>

                            <Box sx={classes.boxExplanation}>
                                Explain Explain Explain Explain Explain Explain  Explain Explain  Explain Explain  Explain Explain  Explain Explain
                            </Box>

                        </Box>
                    </Box> */}
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box mt={24} width={'fit-content'} mx={'auto'} gap={8} display={'flex'} >
                    <XJButton
                        variant={'tertiary'}
                        onClick={() => {
                            history.push(RoutingController.SegmentEditor(segment))
                        }}
                    >
                        Back
                    </XJButton>

                    <XJButton onClick={() => {

                        history.push(RoutingController.SegmentVideoRecordSetup(segment))
                        // history.replace(`/pitch/${pid}/segment/${sid}/video/record/setup`)
                    }}
                        variant={'secondary'}
                    >
                        Next
                    </XJButton>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mx={'auto'} width={'fit-content'} mt={8}>
                    <XJCheckBox
                        checked={dontShowAgain}
                        label={"Don't show this again"}
                        onChange={(v) => setDontShowAgain(v)}
                    />
                </Box>
            </Grid>
        </Grid>
    </>
}