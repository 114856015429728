import { Box, Checkbox, SxProps, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { copyPrimaryRegular, copySecondaryRegular, darkPrimaryDefault, primaryDefault, primaryDefaultOverlay24, primaryDefaultOverlay32, secondaryDefault, secondaryLight75, theme } from "XJumpTheme"
import { CheckBoxSelectedIcon, CheckBoxUnselectedIcon } from "./XJIcons"

interface IXJCheckboxProps {
    label?: string,
    onChange: (newValue: boolean) => void,
    checked?: boolean,
    disabled?: boolean,
    sx?: SxProps

}

export default function XJCheckBox(props: IXJCheckboxProps) {


    const [value, setValue] = useState(props.checked ? props.checked : false)

    useEffect(() => {
        setValue(props.checked ?? false)
    }, [props])
    
    const states: SxProps =
    {
        color:  props.disabled ? secondaryLight75 : props.checked ? primaryDefault : secondaryDefault,

        ':hover':
        {
            color: props.disabled ? secondaryLight75  : props.checked ?  primaryDefaultOverlay24 : secondaryDefault,
            backgroundColor: 'transparent'
        },
        ':active':
        {
            color: primaryDefaultOverlay32 ,
        },
        ':disabled':
        {
            color: secondaryLight75
        },
        ...props.sx
    }

    return <>
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={states}
        >

            <Checkbox
          
                sx={states}
                size='small'
                disabled={props.disabled}
                checked={value}
                checkedIcon={<CheckBoxSelectedIcon />}
                icon={<CheckBoxUnselectedIcon />}
                inputProps={{ 'aria-label': 'controlled' }}
               
                onChange={(e) => {

                    setValue(e.target.checked)
                    props.onChange(e.target.checked)

                }} />

            {props.label ?  <Typography color={props.disabled ? secondaryLight75 : darkPrimaryDefault} sx={{...copyPrimaryRegular}} >{props.label}</Typography> : null}

        </Box>
    </>



}