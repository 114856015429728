/* eslint-disable react/jsx-pascal-case */
import { Box, Divider, SxProps, Typography, useTheme } from "@mui/material"
import {  XJButton } from "UI Elements/Buttons/XJButton"
import XJNumberTextfield from "UI Elements/XJNumberTextfield"
import { kVisualAidMinimumDuration, kVisualAidMinimumPeriodFromStart, kVisualAidMinimumPeriodToEnd } from "Model/VideoModel"
import { VisualAidPIPPosition, VisualAidModel } from "Model/VisualAidModel"
import { useEffect, useState } from "react"
import { timeFromSeconds, timeToSeconds } from "tools/Utils"
import { DeleteIcon, UploadIcon, VisibilityOnIcon, VisibilityOffIcon, VisualAidPIP_LowerRight, VisualAidPIP_UpperRight, VisualAidPIP_LowerLeft, VisualAidPIP_UpperLeft } from "UI Elements/XJIcons"
import { whiteDefault } from "XJumpTheme"
import XJImage from "UI Elements/XJImage"
import XJVerticalDivider from "UI Elements/XJVerticalDivider"
import XJCheckBox from "UI Elements/XJCheckBox"
import XJGroupIconButton from "UI Elements/Buttons/XJGroupIconButton"
import {  XJMenuButton } from "UI Elements/Buttons/XJMenuButton"

interface VisualAidViewProps {

    aid: VisualAidModel
    onMediaSelect: (aid: VisualAidModel) => void
    onAidChanged: (aid: VisualAidModel) => void
    onClose: (aid: VisualAidModel) => void
    onCancel: (aid: VisualAidModel) => void
    onDelete: (aid: VisualAidModel) => void

}

export function VisualAidView(props: VisualAidViewProps) {

    const [aid, setAid] = useState<VisualAidModel>(props.aid.clone())

    useEffect(() => {

        setAid(props.aid.clone())
    }, [props.aid])

    /*  useEffect(() => {
 
         props.onAidChanged(aid)
 
     }, [aid]) */


    // MARK: -  Properties change

    const handlePIPCheckedChange = (newValue: boolean) => {
        aid.showInPIP = newValue
        props.onAidChanged(aid)
        // setAid(aid.clone())
    }

    const onVideoPositionChange = (position: VisualAidPIPPosition) => {
        aid.PIPPosition = position
        props.onAidChanged(aid)

        //setAid(aid.clone())
    }

    const onVisibilityToggle = () => {
        aid.isVisible = !aid.isVisible
        props.onAidChanged(aid)
        //  setAid(aid.clone())
    }


    // MARK: -  Actions 

    const onMediaEdit = () => {
        props.onMediaSelect(aid)
    }

    const onSave = () => {
        const validation = validateForm()
        if (validation.result) {

            props.onClose(aid)

        } else {
            alert(validation.message)
        }
    }

    const onCancel = () => {
        props.onCancel(props.aid)
    }


    // MARK: - Delete visual aid 

    const onDelete = () => {

        props.onDelete(aid)

    }


    // MARK: - Text Fields 

    const validateStartTime = (value: string) => {
        let result = false

        const newStartingTime = convertStartTimeString(value)
        if (!isNaN(newStartingTime)) {
            if (newStartingTime >= kVisualAidMinimumPeriodFromStart && 
                newStartingTime <= aid.video.duration - aid.duration - kVisualAidMinimumPeriodToEnd) 
            {
                result = true
            }
        }

        return result
    }

    const formatStartTime = (value: number) => {
        return timeFromSeconds(value)
    }

    const convertStartTimeString = (value: string) => {
        return timeToSeconds(value)
    }

    const onStartTimeApplied = (value: string) => {

        const newStartingTime = convertStartTimeString(value)
        aid.startsAt = newStartingTime
        // setAid(aid.clone())
        props.onAidChanged(aid)

        setValidationMessage(validateForm().message)
    }

    const validateDuration = (value: string) => {
        let result = false

        const newDuration = convertDurationString(value)
        if (!isNaN(newDuration)) {
            if ((newDuration >= kVisualAidMinimumDuration) && 
                (newDuration <= aid.video.duration - kVisualAidMinimumPeriodFromStart - aid.startsAt - kVisualAidMinimumPeriodToEnd)) 
            {
                result = true
            }
        }

        return result
    }

    const formatDuration = (value: number) => {
        return value.toFixed(2).toString()
    }

    const convertDurationString = (value: string) => {
        return Number(value)
    }

    const onDurationApplied = (value: string) => {
        const newDuration = convertDurationString(value)
        aid.duration = newDuration
        // setAid(aid.clone())
        props.onAidChanged(aid)

        setValidationMessage(validateForm().message)
    }


    // MARK: - Validation 

    const validateForm = () => {
        let result = { result: true, message: "" }

        if (!aid.localBlobUrl) {
            result = { result: false, message: "No media selected" }
        } else if (!aid.video.isValidPositionForAid(aid, aid.startsAt, aid.duration)) {
            result = { result: false, message: "Invalid position for the aid" }
        }

        return result
    }

    // sets up validation message on changing validation status 
    const [validationMessage, setValidationMessage] = useState("")

    useEffect(() => {
        setValidationMessage(validateForm().message)
    }, [validateForm().message])


    // MARK: - Render

    const theme = useTheme()

    const Buttons = () => {
        const styles = {
            root: {
                display: 'flex',
                gap: 16
            }
        }

        return (
            <Box sx={styles.root}>
                <XJButton variant={'tertiary'} onClick={onCancel}>
                    Cancel
                </XJButton>

                <XJButton variant={'secondary'} onClick={onSave} disabled={!validateForm().result}>
                    Save
                </XJButton>
            </Box>
        )
    }

    const Timing = () => {
        const styles = {
            root: {
                display: 'flex',
                gap: theme.spacing(16)
            } as SxProps,
            start: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(8)
            } as SxProps,
            duration: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(8)
            } as SxProps
        }

        return <>
            <Box sx={styles.root}>
                <Box sx={styles.start}>
                    <Typography variant="h3">Starts at:</Typography>

                    <XJNumberTextfield
                        key={aid.localID + 'viewStart'}
                        value={aid.startsAt}
                        step={1}
                        EndAdornment={"Sec"}
                        errorMessage={() => { return "Invalid value" }}

                        validation={validateStartTime}
                        format={formatStartTime}

                        onFinish={onStartTimeApplied}
                    />
                </Box>

                <Box sx={styles.duration}>
                    <Typography variant="h3">Duration:</Typography>

                    <XJNumberTextfield
                        key={aid.localID + 'viewDuration'}
                        value={aid.duration}
                        step={1}
                        EndAdornment={"Sec"}
                        errorMessage={() => { return "Invalid value" }}

                        validation={validateDuration}
                        format={formatDuration}

                        onFinish={onDurationApplied}
                    />
                </Box>
            </Box>
        </>
    }

    const MediaSelection = () => {

        const styles = {
            root: {
                display: 'flex',
                justifyContent: 'space-between'
            } as SxProps,
            image: {
                height: "100px",
                width: "144px"
            },
            buttons: {
                display: 'flex',
                my: 'auto',
                ml: theme.spacing(8),
                gap: 2
            }
        }

        return <>
            <Box sx={styles.root}>
                <Box sx={styles.image}>
                    <XJImage
                        src={aid.localBlobUrl}
                        onClick={onMediaEdit}
                        showBorder
                    />
                </Box>

                <Box sx={styles.buttons}>
                    <XJMenuButton onClick={onVisibilityToggle} variant={'secondary'}>
                        {aid.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                    </XJMenuButton>

                    <XJVerticalDivider />

                    <XJMenuButton onClick={onMediaEdit} variant={'secondary'}>
                        <UploadIcon />
                    </XJMenuButton>

                    <XJVerticalDivider />

                    <XJMenuButton onClick={onDelete} variant={'secondary'}>
                        <DeleteIcon />
                    </XJMenuButton>
                </Box>
            </Box>
        </>
    }

    interface IPIPSectorSideIcon {
        children: React.ReactNode
        pos: VisualAidPIPPosition
    }

    const PIPSectorSideIcon = (props: IPIPSectorSideIcon) => {
        return (
            <XJGroupIconButton
                disabled={!aid.showInPIP}
                selected={aid.PIPPosition === props.pos}
                onClick={() => onVideoPositionChange(props.pos)} >
                {props.children}
            </XJGroupIconButton>
        )
    }

    const PIPSelector = () => {
        const styles = {
            root: {
                display: 'flex',
                justifyContent: 'space-between'
            },
            checkbox: {
                display: 'flex',
                alignItems: 'center'
            },
            selector: {
                display: 'flex'
            }
        }

        return <>
            <Box sx={styles.root}>
                <Box sx={styles.checkbox}>
                    <XJCheckBox
                        checked={aid.showInPIP as boolean | undefined}
                        onChange={handlePIPCheckedChange}
                        sx={{ pl: 0 }}
                        
                        label={'Picture in Picture'}
                    />
                </Box>

                <Box sx={styles.selector}>
                    <PIPSectorSideIcon pos={VisualAidPIPPosition.leftBottom}>
                        <VisualAidPIP_LowerLeft />
                    </PIPSectorSideIcon>

                    <PIPSectorSideIcon pos={VisualAidPIPPosition.leftTop}>
                        <VisualAidPIP_UpperLeft />
                    </PIPSectorSideIcon>

                    <PIPSectorSideIcon pos={VisualAidPIPPosition.rightBottom}>

                        <VisualAidPIP_LowerRight />
                    </PIPSectorSideIcon>

                    <PIPSectorSideIcon pos={VisualAidPIPPosition.rightTop}>
                        <VisualAidPIP_UpperRight />
                    </PIPSectorSideIcon>
                </Box>
            </Box>
        </>
    }

    const styles = {
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            backgroundColor: whiteDefault,
            padding: 8,
            borderRadius: '2px'
        } as SxProps
    }

    return <>
        <Box display={'flex'} mb={16}>

            <Typography variant='h2'>
                Slide
            </Typography>
            
        </Box>

        <Box sx={styles.root} key={"vidview" + aid.localID}>

            <MediaSelection />

            <Divider />

            <Timing />

            <Divider />

            <PIPSelector />

        </Box>

        <Box mt={'48px'} >
            <Buttons />
        </Box>
    </>
}