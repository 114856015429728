


export const LogError = (error, info) =>
{
    
    newrelic.noticeError(error)
    console.error (error)

}

export const LogInfo = (info) =>
{
    


    

}
    
