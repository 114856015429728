import { useCallback, useEffect, useState } from "react";

type KeyEventCallback = (key: string) => void

export function useKeyPress(targetKey: { char: string, cmd?: boolean, alt?: boolean, ctrl?: boolean }, onKeyDown?: KeyEventCallback, onKeyUp?: KeyEventCallback): boolean {

  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true

  const [cmdPressed, setCmdPressed] = useState(false)
  const [altPressed, setAltPressed] = useState(false)
  const [ctrlPressed, setCtrlPressed] = useState(false)

  const cmd = 'Meta'
  const alt = 'Alt'
  const ctrl = 'Control'

  const downHandler = useCallback(({ key }: { key: string }) => {


    if (key === cmd) setCmdPressed(true)
    if (key === alt) setAltPressed(true)
    if (key === ctrl) setCtrlPressed(true)

    if (key === targetKey.char && 
      (!targetKey.cmd || (targetKey.cmd && cmdPressed)) && 
      (!targetKey.alt || (targetKey.alt && altPressed)) && 
      (!targetKey.ctrl || (targetKey.ctrl && ctrlPressed))
    )
    
    {
      setKeyPressed(true)
      onKeyDown && onKeyDown(key)
    }


  }, [targetKey, onKeyDown])
  // If released key is our target key then set to false


  const upHandler = useCallback(({ key }: { key: string }) => {

    if (key === cmd) setCmdPressed(false)
    if (key === alt) setAltPressed(false)
    if (key === ctrl) setCtrlPressed(false)

    if (key === targetKey.char
    ) {

      setKeyPressed(false)
      onKeyUp && onKeyUp(key)
    }
  }, [targetKey, onKeyUp])


  // Add event listeners

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}