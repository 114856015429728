import API from "api-axios"
import { PitchTemplateDAO, templateSegment } from "Common/PitchTemplateDAO"
import { LogError } from "Controllers/Logging"
import { PitchTemplateModel } from "Model/PitchTemplateModel"
import { createContext, useContext, useEffect, useState } from "react"
import { useAlert } from "./UseAlert"
import { useAuth } from "./UseAuth"




interface IProviderTemplates {
    templates: PitchTemplateModel[]
    getTemplate: (id: number) => PitchTemplateModel | undefined
    addTemplate: (title: string) => PitchTemplateModel
    deleteTemplate: (id: number) => void
    duplicateTemplate: (templateId: number, newTemplateName: string) =>  Promise<void> 
}

interface IProps {
    children: React.ReactNode
    // any other props that come into the component
}

const templatesContext = createContext<IProviderTemplates>({} as IProviderTemplates)


export function ProvideTemplates({ children }: IProps) {

    const templates = useTemplatesProvider()
    return (

        <templatesContext.Provider value={templates}>
            {children}
        </templatesContext.Provider>
    )
}

export function useTemplates() {
    return useContext(templatesContext)
}



export function useTemplatesProvider(): IProviderTemplates {



    const [templates, setTemplates] = useState<PitchTemplateModel[]>([])
    const loggedInUser = useAuth().user.User

    const alert = useAlert()

    /*
    const createSeedTemplates = (): PitchTemplateModel[] => {


        let t = new PitchTemplateModel()

        t.type = PitchType.Saas

        t.icon = '/icon/url'
        t.partnerId = -1
        t.title = 'SAAS'

        let intro: templateSegment = {

            segmentType: SegmentType.intro,
            sequence: 0,
            title: 'intro segment',
            topics: [
                {
                    title: 'topic a',
                    sequence: 0,
                    questions: [
                    ]
                }

            ]

        }

        let problem: templateSegment = {

            segmentType: SegmentType.problem,
            sequence: 1,
            title: 'problem segment',
            topics: [
                {
                    title: 'topic b',
                    sequence: 0,
                    questions: [

                    ]
                }

            ]
        }


        let t1 = new PitchTemplateModel()

        t1.type = PitchType.Saas

        t1.icon = '/icon/url'
        t1.partnerId = -1
        t1.title = 'MARKETPLACE'

        let intro1: templateSegment = {

            segmentType: SegmentType.intro,
            sequence: 0,
            title: 'intro segment MP',
            topics: [
                {
                    title: 'topic a MP',
                    sequence: 0,
                    questions: [
                    ]
                }

            ]

        }


        let problem1: templateSegment = {

            segmentType: SegmentType.problem,
            sequence: 1,
            title: 'problem segment MP',
            topics: [
                {
                    title: 'topic b MP',
                    sequence: 0,
                    questions: [

                    ]
                }

            ]
        }

        t.segments = [intro, problem]
        t1.segments = [intro1, problem1]
        return [t, t1]

    }
*/

    useEffect(() => {

        try {
            if (!loggedInUser) {
                setTemplates([])
                return
            }

            let templpatesHookData: PitchTemplateModel[] = []

            API.get('/template/all')
                .then(res => {
                    templpatesHookData = (res.data as PitchTemplateDAO[]).map(t => {
                        const newTemplate = new PitchTemplateModel()
                        newTemplate.load(t!)
                        return newTemplate
                    })

                    setTemplates([...templpatesHookData])//, ...seedTemplates])
                })
        }

        catch (err) {
            LogError(err)
            alert.error(err)
        }

    }, [loggedInUser?.id])


    const getTemplate = (id: number): PitchTemplateModel | undefined => {
        return templates.find(x => x.Id === id)
    }



    const duplicateTemplate = (templateId: number, newTemplateName: string) : Promise<void> => {

        return API.post(`/admin/template/${templateId}/duplicate`, { 'Name': newTemplateName })
            .then(res => {

                const newTemplate = new PitchTemplateModel()
                newTemplate.load(res.data)
            
                setTemplates([...templates, newTemplate])//, ...seedTemplates])
                return 

            })
            .catch(err => {

                LogError(err)
                throw err

            })


    }

    const addTemplate = (_title: string): PitchTemplateModel => {

        const newTemplate = new PitchTemplateModel()

        //newTemplate.Id = ''
        newTemplate.title = _title

        setTemplates(templates => [...templates, newTemplate])

        return newTemplate
    }

    const deleteTemplate = (_id: number) => {

        try {
            API.get(`/admin/template/${_id}/delete`).then(res => {
                templates.remove(templates.find(x => x.Id === _id)!)

            })

                .catch(res => {
                    alert.error(res)
                    LogError(res)

                })

        }
        catch (error) {

            LogError(error)
            alert.error(error)
        }

    }

    return {

        templates,
        getTemplate,
        duplicateTemplate,
        addTemplate,
        deleteTemplate

    } as IProviderTemplates


}

