import { Box, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, ListItemText, Radio, RadioGroup, SxProps, TextField, Typography } from "@mui/material";
import { QuestionTutorial } from "Common/QuestionContentDAO";
import { SystemQuestions } from "Controllers/SystemQuestionsController";
import { useAlert } from "hooks/UseAlert";
import { cloneDeep } from "lodash";
import { QuestionContentModel } from "Model/QuestionContenModel";
import { TopicContentModel } from "Model/TopicContentModel";

import { useEffect, useState } from "react";
import { XJButton } from "UI Elements/Buttons/XJButton";
import XJBreadcrumbs from "UI Elements/XJBreadcrumbs";

import Editor from 'react-simple-wysiwyg';


import { Header, PageTitle } from "XJumpTheme";

export function TutorialsEditor() {


    const questions = SystemQuestions.questions

    const topics = SystemQuestions.topics

    const [items, setItems] = useState<QuestionContentModel[] | TopicContentModel[]>()

    useEffect(() => {

        setItems(questions)


    }, [])




    const [selectedQT, setSelectedQT] = useState<QuestionContentModel | TopicContentModel>()

    const selectedItem: SxProps =
    {
        background: 'black',
        color: 'white !important'


    }


    const [body, setBody] = useState('')
    const [title, setTitle] = useState('')


    useEffect(() => {

        if (selectedQT?.tutorials && selectedQT?.tutorials.length > 0) {

            setTitle(selectedQT?.tutorials[0].title)
            setBody(selectedQT?.tutorials[0].body)


        }
        else {

            setTitle('')
            setBody('')

        }

        setIsDirty(false)

    }, [selectedQT])

    const alert = useAlert().successSave

    const onSave = () => {

        if (selectedQT?.tutorials[0]) {

            selectedQT!.tutorials[0].body = body
            selectedQT!.tutorials[0].title = title
            selectedQT!.save().then(res => {

                setIsDirty(false)
                alert()

            })

        }

    }

    const [isDirty, setIsDirty] = useState(false)



    const [whichType, setWhichType] = useState('Q')

    useEffect(() => {

        setItems(whichType  === 'Q' ? questions : topics)


    }, [whichType])



    const AddTutotorial = (qt: QuestionContentModel | TopicContentModel) => {
        
        const t: QuestionTutorial = { title: '', body: '' }
        qt.tutorials.push(t)    

        setSelectedQT (cloneDeep (qt))
    
    }



    return <>
        <Grid container>
            <Grid item xs={12} sx={Header}>
                <XJBreadcrumbs />
            </Grid>
            <Grid item xs={12}>
                <Box sx={PageTitle}>
                    <Typography variant='h1'>
                        Tutorials editor
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>

                <Box display={'flex'} gap={32}>

                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Questions or Topics</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={whichType}
                            onChange={(e) => {

                                setWhichType(e.target.value)
                            }}>

                            <FormControlLabel value="Q" control={<Radio />} label="Questions" />
                            <FormControlLabel value="T" control={<Radio />} label="Topics" />
                        </RadioGroup>
                    </FormControl>

                </Box>

            </Grid>


            <Grid item xs={4}>
                <Box sx={{ height: '75vh', overflowY: 'auto' }} >
                    <List  >

                        {
                            items && items.map(q => {

                                return <ListItem key={`${items instanceof QuestionContentModel ? 'q' : 't' }${q.id}`}>

                                    <ListItemText sx={q.id === selectedQT?.id ? selectedItem : null} onClick={() => setSelectedQT(q)}>
                                        {q.title}
                                    </ListItemText>
                                </ListItem>

                            })
                        }
                    </List>
                </Box>
            </Grid>

            <Grid item xs={8}>


                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box>
                        <Box>
                            {selectedQT?.title}

                        </Box>
                        <Box>
                            {selectedQT?.explanation}

                        </Box>
                    </Box>

                    <Box>

                        <XJButton disabled={selectedQT?.tutorials.length !== 0}
                        
                            onClick={()=>{AddTutotorial (selectedQT!)}}

                        >
                            
                            New
                        </XJButton>

                        <XJButton

                            disabled={!isDirty}
                            onClick={onSave} > Save</XJButton>
                        
                    </Box>
                   

                </Box>
                <Box display={'flex'} gap={8}>
                        <XJButton variant='tertiary' onClick={() => {

                            setBody(value => value.replaceAll('**', ''))
                            setIsDirty (true)

                         }}>Remove **</XJButton>
                        
                        <XJButton variant='tertiary' onClick={() => {

                            setBody(value => value.replaceAll('###', ''))
                            setIsDirty (true)

                        }}>Remove ###</XJButton>
                        
                    </Box>

                <Box>
                    {selectedQT?.tutorials.map(t => {
                        return <>

                            <Box display={'flex'} flexDirection={'column'} width={'100%'}>

                                <TextField value={title } onChange={(e) => {
                                    setTitle(e.target.value)
                                    setIsDirty(true)

                                }} />

                                <Editor
                                
                             
                                    value={body.replaceAll ('\n', '<br/>')}
                                    
                                    onChange={(e) => {
                                        setBody(e.target.value)
                                        setIsDirty(true)
                                    }
                                    } />



                              
{/* 
                                <TextField
                                    multiline

                                    value={body} onChange={(e) => {
                                        setBody(e.target.value)
                                        setIsDirty(true)
                                    }
                                    } /> */}

                            </Box>




                        </>

                    })



                    }

                </Box>



            </Grid>

        </Grid>
    </>


}