import API from "api-axios"
import { QuestionType } from "Common/Enums"
import { QuestionContentDAO, QuestionSelection } from "Common/QuestionContentDAO"
import { TopicContentDAO } from "Common/TopicContentDAO"
import { QuestionContentModel } from "Model/QuestionContenModel"
import { TopicContentModel } from "Model/TopicContentModel"
import { LogError } from "./Logging"



class SystemQuestionsController {

    questions: QuestionContentModel[] = []
    topics: TopicContentModel[] = []
    

    destroy() {
        this.questions = []
        this.topics = []
    }

    init() {

        this.loadAllQuestions()
        this.loadAllTopics ()

    }


    private loadAllTopics = () => 
    {
        try {
            API.get('/topics/all')

                .then(res => {

                    (res.data as TopicContentDAO[]).forEach(t => {


                        const newT = new TopicContentModel()

                        newT.id = t.id
                        newT.explanation = t.explanation ?? ""
                        newT.title = t.Title
                        newT.tutorialsVideoLink = t.tutorialVideoLink ?? ''
                        newT.AIPrompt = t.AIPrompt
                        newT.templateHint = t.TemplateHint
    
                        if (t.tutorial) newT.tutorials = JSON.parse(t.tutorial.toString())

                        this.topics.push(newT)

                    })
                })

        }
        catch (err) {

            LogError(err)

        }
        
    }


    private loadAllQuestions = () => {
        try {
            API.get('/question/all')

                .then(res => {

                    (res.data as QuestionContentDAO[]).forEach(q => {


                        const newQ = new QuestionContentModel()

                        newQ.id = q.id
                        newQ.explanation = q.explanation ?? ''
                        newQ.title = q.title ?? ''
                        newQ.type = q.type! as QuestionType
                        newQ.tutorialsVideoLink = q.tutorialVideoLink ?? ''
                        newQ.multiAnswers = q.multiAnswers ? JSON.parse(q.multiAnswers!) as QuestionSelection[] : [] as QuestionSelection[]
                        newQ.AIPrompt = q.AIPrompt
                        newQ.Topic = q.Topic
                        newQ.Segment = q.Segment

                        if (q.QusetionTutorials) newQ.tutorials = JSON.parse(q.QusetionTutorials.toString())

                        this.questions.push(newQ)

                    })
                })

        }
        catch (err) {

            LogError(err)

        }
    }


    AddQuestion = (Q: QuestionContentModel) => {

        this.questions.push(Q)

    }

    AddTopic = (Q: TopicContentModel) => {

        this.topics.push(Q)

    }

    GetQuestion = (id: number | undefined): QuestionContentModel | undefined => {

        if (id) {
            return this.questions.find(x => x.id === id)
        }
        else {
            return undefined
        }
    }

    GetTopic = (id: number | undefined): TopicContentModel | undefined => {

        if (id) {
            return this.topics.find(x => x.id === id)
        }
        else {
            return undefined
        }
    }

}


export const SystemQuestions = new SystemQuestionsController()