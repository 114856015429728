import { Box, Tooltip } from "@mui/material";
import { ReactElement } from "react";


export function XJTooltip(props: {
    children: ReactElement,
    title: string,
    placement? : 'right' | 'bottom'
}) {

    return <Tooltip title={props.title} placement={props.placement ? props.placement : 'right' } enterDelay={1000}>
        <Box sx={{width: '100%', height: '100%'}}>
            {props.children}
        </Box>
    </Tooltip>

}