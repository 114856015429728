
import { ComponentType } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { ProvidePitches } from './hooks/UsePitches'
import { SnackbarProvider } from 'notistack';
import { ProvideAuth } from './hooks/UseAuth';

import 'tools/Extensions'
import Fade from '@mui/material/Fade';
import { TransitionProps } from '@mui/material/transitions';

//import { TutorialStateProvider } from 'hooks/TutorialState';
import { ProvideTemplates } from 'hooks/UseTemplates';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from "react-error-boundary";
import { LogError } from 'Controllers/Logging';
import { ErrorPage } from 'UI Elements/EmptyPageMessage/ErrorPage';

const rootElement = document.getElementById('root');

const root = createRoot(rootElement!)

declare global {
    // eslint-disable-next-line
    var JWTToken: string | null
    // eslint-disable-next-line
    var BaseURL: string | undefined
}

//globalThis.BaseURL = window.location.origin





root.render(
    //  <StrictMode>



    <BrowserRouter basename={globalThis.BaseURL}>

        {/* // https://iamhosseindhv.com/notistack/api */}
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            TransitionComponent={Fade as ComponentType<TransitionProps>}
            preventDuplicate={true}
        >
            <ProvideAuth>
                <ProvideTemplates>
                    <ProvidePitches>
                        {/* <TutorialStateProvider> */}
                        {/* <OverlayProvider> */}
                        <ErrorBoundary
                            
                            fallback={<ErrorPage/>}
                            onError={(error: Error, info: React.ErrorInfo) => {

                                LogError (error, info)
                                
                               
                            }}
                        >
                            <App />
                        </ErrorBoundary>
                        {/* </OverlayProvider> */}
                        {/* </TutorialStateProvider> */}
                    </ProvidePitches>
                </ProvideTemplates>
            </ProvideAuth>
        </SnackbarProvider>
    </BrowserRouter>

    //  </StrictMode >
)




